import { Modal } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import './BuyerAddSupplierModal.scss';
import { AppDropDown2 } from '../../../../general/AppDropDown2/AppDropDown2';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { Plus, Trash, Trash2, X } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  createSubSupplier,
  deleteSubSupplier,
  updateSubSupplier,
} from '../../../../../redux/services/subSuppliersService';
import {
  SubSupplierApiResponse,
  SubUserApiPostBody,
  SubUserApiPostBodySchema,
  UserLinkApiResponse,
} from '@dill/dill-shared';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import { toast } from 'react-toastify';
import { isEmailValid } from '../../../../../utils/helpers';
import { AppDeleteModal } from '../../../../general/AppDeleteModal/AppDeleteModal';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerAddSupplierModal = ({
  open,
  handleClose,
  selectedSubSupplierUser,
  onUnArchiveSubsupplier,
}: {
  open: boolean;
  selectedSubSupplierUser?: {
    subSupplierName: string;
    subSupplierId: string;
    user: UserLinkApiResponse;
  } | null;
  handleClose: () => void;
  onUnArchiveSubsupplier: (supplierId: string, supplierName: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const [contactList, setContactList] = useState<SubUserApiPostBody[]>([]);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [contactsToDelete, setContactsToDelete] = useState<SubUserApiPostBody[]>([]);
  const [selectedSubSupplier, setSelectedSubSupplier] = useState<SubSupplierApiResponse | null>(
    null
  );
  const [isShowContactErrors, setIsShowContactErrors] = useState(false);
  const [selectedSubSupplierId, setSelectedSubSupplierId] = useState('');
  const [companies, setCompanies] = useState<
    {
      id: string;
      name: string;
      archivedState: ('BUYER_ARCHIVED' | 'SUPPLIER_ARCHIVED')[] | undefined;
    }[]
  >([]);
  const [isAddSubSupplierSuccessModalOpen, setIsAddSubSupplierSuccessModalOpen] = useState(false);
  const [isDeleteSubSupplierSuccessModalOpen, setIsDeleteSubSupplierSuccessModalOpen] =
    useState(false);
  const [isAddSubSupplierErrorModalOpen, setIsAddSubSupplierErrorModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [supplierIsArchived, setSupplierIsArchived] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setSelectedSubSupplierId('');
    setSelectedSubSupplier(null);
    setContactList([]);
    setCompanyName(null);
    setCompanyNameError(false);

    return () => {};
  }, [open]);
  useEffect(() => {
    if (selectedSubSupplierUser && subSuppliers) {
      const subSupplier = subSuppliers.find(
        (subSupplierItem) => subSupplierItem.id === selectedSubSupplierUser.subSupplierId
      );
      if (subSupplier) {
        setSelectedSubSupplier(subSupplier);
        setNotes(subSupplier?.notes ?? '');
      } else {
        setSelectedSubSupplier(null);
      }
    }
    if (selectedSubSupplierUser) {
      setSelectedSubSupplierId(selectedSubSupplierUser.subSupplierId);
      setCompanyName(selectedSubSupplierUser.subSupplierName);
    }

    return () => {};
  }, [selectedSubSupplierUser, subSuppliers, open]);

  const columns = useMemo(() => {
    return [
      { id: 'title', name: 'Job Title', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'firstName', name: 'First Name', width: 250, cellType: 'VIEW_TEXT' },
      { id: 'lastName', name: 'Last Name', width: 250, cellType: 'VIEW_TEXT' },
      { id: 'email', name: 'Email', width: 300, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  useEffect(() => {
    if (subSuppliers.length > 0) {
      setCompanies([
        ...subSuppliers.map((subSupplier) => ({
          id: subSupplier.id,
          name: subSupplier.name,
          archivedState: subSupplier.archivedState,
        })),
      ]);
    }
    return () => {};
  }, [subSuppliers]);

  const handleAddContactToList = () => {
    const newContact: SubUserApiPostBody = {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      type: 'BUYER',
    };
    setContactList([...contactList, newContact]);
  };
  const handleDeleteContactFromList = (position: number) => {
    if (contactList.length > 0 && contactList[position] && contactList[position].id) {
      setContactsToDelete([...contactsToDelete, contactList[position]]);
    }

    setContactList([...contactList.filter((item, i) => i !== position)]);
  };
  const handleEditContactList = (position: number, key: string, value: string) => {
    setContactList([
      ...contactList.map((item, i) => {
        if (i === position) {
          return { ...item, [key]: value };
        }
        return { ...item };
      }),
    ]);
  };

  const checkContactsForErrors = () => {
    let isError = false;
    const newContactList = contactList.map((obj) => {
      const { isSetup, ...newContact } = obj;
      return newContact;
    });
    newContactList.forEach((contact) => {
      const emptyValue = Object.values(contact).filter((value) => !value);
      if (emptyValue.length > 0) {
        isError = true;
      }
      if (!isEmailValid(contact.email)) {
        isError = true;
      }
    });
    return isError;
  };

  const handleSubmitSubSupplier = async () => {
    if (!companyName && !selectedSubSupplierUser) {
      setCompanyNameError(true);
      return;
    }
    if (!selectedMainBuyerId) {
      return;
    }
    if (
      companyName &&
      companyName !== selectedSubSupplierUser?.subSupplierName &&
      selectedSubSupplierUser
    ) {
      const searched = subSuppliers.filter((subSupplier) => subSupplier.name === companyName);
      if (searched.length > 0) {
        setSupplierIsArchived(searched[0].archivedState?.includes('BUYER_ARCHIVED') ?? false);
        setCompanyNameError(true);
        return;
      }
    }
    if (!companyName || companyName.trim() === '') {
      setCompanyNameError(true);
      return;
    }
    if (contactList.length === 0 || checkContactsForErrors()) {
      setIsShowContactErrors(true);
    } else {
      setIsShowContactErrors(false);
      const results = await dispatch(
        createSubSupplier({
          mainBuyerId: selectedMainBuyerId,
          subSupplierDetails: {
            companyName: companyName,
            notes,
            companyId: selectedSubSupplier?.id || '',
            contacts: contactList.map((contact) => {
              let trimmedValues: typeof contact = { email: '' };
              Object.keys(contact).forEach((itemKey) => {
                const key = itemKey as keyof typeof contact;
                if (contact[key]) {
                  const item = contact[key] as string;
                  trimmedValues = {
                    ...trimmedValues,
                    [itemKey]: typeof item === 'string' ? item.trim() : item,
                  };
                } else {
                }
              });
              return { ...trimmedValues };
            }),
            contactIdsToDelete: [
              ...contactsToDelete.map((contact) => {
                return contact?.userId || '';
              }),
            ],
          },
        })
      );
      if (results.type === 'subSuppliers/createSubSupplier/fulfilled') {
        setIsAddSubSupplierSuccessModalOpen(true);
      } else {
        setIsAddSubSupplierErrorModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (selectedSubSupplier) {
      const rr: SubUserApiPostBody[] = [
        ...selectedSubSupplier.users.map((userLink) => {
          if (userLink.subUser) {
            const ee: SubUserApiPostBody = SubUserApiPostBodySchema.parse({
              ...userLink.subUser,
            });
            return ee;
          } else {
            const ee: SubUserApiPostBody = SubUserApiPostBodySchema.parse({
              ...userLink.user,
            });
            delete ee.id;
            return ee;
          }
        }),
      ];
      setContactList([...rr]);
      setContactsToDelete([]);
    }

    return () => {};
  }, [selectedSubSupplier, subSuppliers]);

  const handleDeleteSupplierCompany = async () => {
    if (selectedSubSupplier?.id && selectedMainBuyerId) {
      const response = await dispatch(
        updateSubSupplier({
          mainBuyerId: selectedMainBuyerId,
          subSupplierId: selectedSubSupplier?.id,
          subSupplierDetails: { archivedState: ['BUYER_ARCHIVED'] },
        })
      );
      if (response && response.type === 'subSuppliers/updateSubSupplier/fulfilled') {
        setIsDeleteSubSupplierSuccessModalOpen(true);
      } else {
        toast.error('Error deleting company', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          style: {
            backgroundColor: '#FEF0EF',
            color: '#F04438',
          },
        });
      }
    } else {
      toast.error('Supplier does not exist', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        style: {
          backgroundColor: '#FEF0EF',
          color: '#F04438',
        },
      });
    }
  };

  const checkChangesInNotes = useMemo(() => {
    if (selectedSubSupplierUser && selectedSubSupplier?.notes) {
      return selectedSubSupplier?.notes === notes;
    }
    return false;
  }, [notes]);

  const handleUnArchiveSubsupplier = (supplierId: string, supplierName: string) => {
    onUnArchiveSubsupplier(supplierId, supplierName);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="buyer-add-supplier-modal-content">
        <X
          className="absolute right-2 top-2 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleClose}
        />
        <AppSuccessModal
          open={isAddSubSupplierSuccessModalOpen}
          modalImage={successChecksCircle}
          title={selectedSubSupplierUser ? 'Supplier edited!' : 'New supplier added!'}
          subTitle={
            selectedSubSupplierUser
              ? 'Any new contacts have been invited to your company on Dill. Any deleted contacts have been removed from active releases; please reference your main dashboard for any further action'
              : 'This supplier has been invited to your company on Dill. You can now request lien waivers from this supplier'
          }
          buttonText="Back to All Suppliers"
          handleClose={() => {
            setIsAddSubSupplierSuccessModalOpen(false);
            handleClose();
          }}
        />

        <AppSuccessModal
          open={isAddSubSupplierErrorModalOpen}
          modalImage={errorCircle}
          title={selectedSubSupplierUser ? 'Editing supplier failed' : 'Adding supplier failed!'}
          subTitle="Contact customer support."
          buttonText="Close"
          secondButtonText="Retry"
          isSecondButton={true}
          onSecondButtonClick={() => {
            setIsAddSubSupplierErrorModalOpen(false);
            handleSubmitSubSupplier();
          }}
          handleClose={() => {
            setIsAddSubSupplierErrorModalOpen(false);
          }}
        />
        <AppDeleteModal
          open={isConfirmDeleteModalOpen}
          handleClose={() => setIsConfirmDeleteModalOpen(false)}
          title={`Are you sure you want to archive ${selectedSubSupplier?.name} as a company?`}
          subTitle="This will archive the supplier company and any releases linked to this company. This action can't be undone"
          buttonText="No, cancel"
          secondButtonText="Yes, I’m sure"
          onSecondButtonClick={() => {
            handleDeleteSupplierCompany();
            setIsConfirmDeleteModalOpen(false);
          }}
        />
        <AppSuccessModal
          open={isDeleteSubSupplierSuccessModalOpen}
          modalImage={successChecksCircle}
          title={`Successfully archived ${selectedSubSupplier?.name}!`}
          subTitle="This company and any associated contacts are now gone from your list of suppliers. Any linked releases to the company have also been archived"
          buttonText="View All Suppliers"
          handleClose={() => {
            setIsDeleteSubSupplierSuccessModalOpen(false);
            handleClose();
          }}
        />
        <h3 className="mx-5 my-3 font-semibold">
          {selectedSubSupplierUser ? 'Edit Supplier' : 'New Supplier'}
        </h3>
        <hr className="h-px  bg-gray-200 border-0 "></hr>
        <div className="buyer-add-supplier">
          <div className="mx-5 mt-2  mb-2 p-3 GREY_100-BG ">
            {selectedSubSupplierUser ? (
              <AppInputField
                label="Company Name"
                isRequired={true}
                value={companyName || ''}
                onTextChange={(text) => {
                  setCompanyNameError(false);
                  setCompanyName(text);
                  setSupplierIsArchived(false);
                }}
                errorText={
                  companyNameError && companyName !== ''
                    ? `This name is already taken by another supplier ${
                        supplierIsArchived ? 'that is archived' : ''
                      }. Please choose a different company name`
                    : companyName === '' && companyNameError
                    ? 'Company name is required'
                    : ''
                }
              />
            ) : (
              <AppDropDown2
                isRequired={true}
                label="Company Name"
                items={companies.map((item) => ({
                  label: item.name,
                  value: item.id,
                  labelInElement: (
                    <div className="flex mx-2 my-1 items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-black text-sm">{item.name}</div>
                        {item.archivedState?.includes('BUYER_ARCHIVED') && (
                          <div className="text-black text-sm ml-3 WARNING_600-CLR  WARNING_100-BG px-2 py-1 rounded-lg ">
                            Archived
                          </div>
                        )}
                      </div>
                      {item.archivedState?.includes('BUYER_ARCHIVED') && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUnArchiveSubsupplier(item.id, item.name);
                          }}
                          className="text-sm px-4 py-1 WHITE-BG PRIMARY_500-CLR rounded PRIMARY_500-BORDER border">
                          Restore
                        </button>
                      )}
                    </div>
                  ),
                }))}
                value={selectedSubSupplierId}
                inputFieldStyles={{ background: 'white' }}
                isCreatable={true}
                isSearchable={true}
                errorText={companyNameError ? 'Company name is required' : ''}
                // disabled={selectedSubSupplierUser ? true : false}
                onCreateNewItem={(newItem) => {
                  setCompanies([
                    ...companies.filter((company) => !company.id.startsWith('___')),
                    { name: newItem, id: `___${newItem}___`, archivedState: [] },
                  ]);
                  setSelectedSubSupplierId(`___${newItem}___`);
                  setSelectedSubSupplier(null);
                  setContactList([]);
                  setCompanyName(newItem);
                  setCompanyNameError(false);
                }}
                onSelectChange={(item) => {
                  const subSupplier = subSuppliers.find(
                    (subSupplierItem) => subSupplierItem.id === item.value
                  );
                  setCompanyNameError(false);
                  setCompanyName(item.label);
                  if (subSupplier) {
                    if (subSupplier.archivedState?.includes('BUYER_ARCHIVED')) {
                      onUnArchiveSubsupplier(item.value, item.label);
                      return;
                    }
                    setSelectedSubSupplier(subSupplier);
                  } else {
                    setSelectedSubSupplier(null);
                  }
                  setSelectedSubSupplierId(item.value);
                }}
              />
            )}
          </div>

          <p className="mx-5 my-2 text-xs font-bold">Contacts</p>
          <div className="w-full px-5 flex">
            <div className=" h-52 GREY_100-BG flex flex-col overflow-y-scroll px-2 py-1 w-full">
              {contactList.length > 0 &&
                contactList.map((contact, i) => {
                  return (
                    <div key={i + 'op'} className="w-full flex ">
                      {columns.map((column, j) => {
                        const value = contact[column.id as keyof SubUserApiPostBody];
                        return (
                          <div
                            key={j + 'oo'}
                            style={{ flex: column.width / totalWidth }}
                            className=" px-1 py-1  items-center">
                            {column.id === 'email' && (
                              <div>
                                <AppInputField
                                  label={column.name}
                                  isRequired={true}
                                  isDisabled={contact.id ? true : false}
                                  value={value || ''}
                                  onTextChange={(text) => {
                                    handleEditContactList(i, column.id, text.toLowerCase().trim());
                                  }}
                                  errorText={
                                    isShowContactErrors && !value
                                      ? 'Required'
                                      : isShowContactErrors &&
                                        value &&
                                        !isEmailValid(value.toString())
                                      ? 'Email is not valid'
                                      : ''
                                  }
                                />
                              </div>
                            )}
                            {column.id !== 'actions' && column.id !== 'email' && (
                              <div>
                                <AppInputField
                                  label={column.name}
                                  isRequired={true}
                                  value={value || ''}
                                  onTextChange={(text) => {
                                    handleEditContactList(i, column.id, text);
                                  }}
                                  errorText={isShowContactErrors && !value ? 'Required' : ''}
                                />
                              </div>
                            )}
                            {column.id === 'actions' && (
                              <div className="h-full flex  flex-col justify-end">
                                <Trash
                                  className={'bg-white rounded-lg p-2 cursor-pointer mb-1'}
                                  size={35}
                                  color={COLORS.ERROR_400}
                                  onClick={() => {
                                    handleDeleteContactFromList(i);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="mx-5 my-2 text-sm">
            <AppButton
              text="Add Contact"
              type="SECONDARY"
              buttonStyles={{ padding: '8px 14px' }}
              onClick={() => {
                handleAddContactToList();
              }}
              icon={<Plus color={COLORS.PRIMARY_500} size={16} />}
            />
          </div>
          <div className="mx-5 my-2">
            <AppInputField
              label="Notes"
              isRequired={false}
              inputType="textarea"
              value={notes || ''}
              onTextChange={(text) => {
                setNotes(text);
              }}
            />
          </div>
        </div>
        <hr className="h-px  bg-gray-200 border-0 "></hr>
        <div className="flex my-3 justify-between  px-4">
          {selectedSubSupplierUser && (
            <div
              className=" my-3 font-semibold flex items-center  w-full cursor-pointer "
              onClick={() => {
                setIsConfirmDeleteModalOpen(true);
              }}>
              <Trash2 size={20} color={COLORS.ERROR_500} className="mr-2" />
              <p className="text-sm TEXT_SECONDARY-CLR">Archive Supplier Company</p>
            </div>
          )}
          <div className="flex items-center w-full justify-end">
            <AppButton text="Close" type="TERTIARY" onClick={handleClose} />
            <AppButton
              text="Save"
              buttonStyles={{ marginLeft: '8px' }}
              onClick={() => {
                handleSubmitSubSupplier();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BuyerAddSupplierModal;
