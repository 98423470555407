import React, { useMemo, useState } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X, Eye } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import emptyListImg from '../../../assets/images/emptyList.png';
import { RequestedSupplierJobApiResponse } from '@dill/dill-shared';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import { useAppSelector } from '../../../redux/store';

const RequestedJobView = ({
  onClose,
  requestedSupplierJob,
  onAcceptSupplierJob,
  onEditSupplierJob,
  onDeclineSupplierJob,
  accepting,
  declining,
  onPreviewAttachment,
}: {
  onClose: () => void;
  requestedSupplierJob: RequestedSupplierJobApiResponse;
  onAcceptSupplierJob: (data: RequestedSupplierJobApiResponse) => void;
  onEditSupplierJob: (data: RequestedSupplierJobApiResponse) => void;
  onDeclineSupplierJob: (data: RequestedSupplierJobApiResponse) => void;
  accepting: boolean;
  declining: boolean;
  onPreviewAttachment: (url: string) => void;
}) => {
  const { supplierBuyers } = useAppSelector((state) => state.supplierBuyers);
  const [selectedBuyerId, setSelectedBuyerId] = useState<string>(
    requestedSupplierJob.buyerId || ''
  );
  const [buyerError, setBuyerError] = useState<string>('');
  const fetchedSupplierBuyers = useMemo(() => {
    if (supplierBuyers.length > 0) {
      return supplierBuyers;
    }
    return [];
  }, [supplierBuyers]);
  return (
    <div className="flex w-[560px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
      <div>
        <h2 className="text-lg font-semibold mx-4 my-3">{'Review Job'}</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={onClose}
        />
      </div>
      <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
      <div className="max-h-[calc(90vh-120px)] p-4 overflow-y-auto">
        <div className="text-xs font-semibold">Job Details</div>
        <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
          {requestedSupplierJob.buyerId === '' ? (
            <div>
              <div className="text-xs GREY_500-CLR">Customer</div>
              <div className="text-xs capitalize font-semibold mb-2">
                {requestedSupplierJob.buyerName.toLowerCase()}
              </div>
              <AppDropDown2
                label="Customer"
                value={selectedBuyerId}
                errorText={buyerError}
                items={[
                  ...fetchedSupplierBuyers.map((buyer) => {
                    return {
                      label: buyer.name,
                      value: buyer.id,
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item && item?.value !== null) {
                    setBuyerError('');
                    setSelectedBuyerId(item.value);
                  }
                }}
                placeholder="Select corresponding customer"
              />
            </div>
          ) : (
            <div>
              <div className="text-xs GREY_500-CLR">Customer</div>
              <div className="text-xs capitalize font-semibold">
                {requestedSupplierJob.buyerName.toLowerCase()}
              </div>
            </div>
          )}
          <div className="flex justify-between w-full mt-3">
            <div className="w-1/2">
              <div className="text-xs GREY_500-CLR">Job Name</div>
              {requestedSupplierJob.supplierJob?.name !== '' &&
              requestedSupplierJob.supplierJob?.name !== requestedSupplierJob.name ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.name ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.name}</div>
                </div>
              ) : (
                <div className="text-xs font-semibold">{requestedSupplierJob.name}</div>
              )}
            </div>
            {requestedSupplierJob.jobNumber && (
              <div className="w-1/2">
                <div className="text-xs GREY_500-CLR">Job Number</div>
                {requestedSupplierJob.supplierJob?.jobNumber !== '' &&
                requestedSupplierJob.supplierJob?.jobNumber !== requestedSupplierJob.jobNumber ? (
                  <div className="flex items-center">
                    <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                      {requestedSupplierJob.supplierJob?.jobNumber ?? ''}
                    </div>
                    <div className="text-xs font-semibold">{requestedSupplierJob.jobNumber}</div>
                  </div>
                ) : (
                  <div className="text-xs font-semibold">{requestedSupplierJob.jobNumber}</div>
                )}
              </div>
            )}
          </div>
          <div className="mt-3">
            <div className="text-xs GREY_500-CLR">Job Address</div>
            {requestedSupplierJob.supplierJob?.jobAddress !== '' &&
            requestedSupplierJob.supplierJob?.jobAddress !== requestedSupplierJob.jobAddress ? (
              <div className="flex items-center">
                <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                  {requestedSupplierJob.supplierJob?.jobAddress ?? ''}
                </div>
                <div className="text-xs font-semibold">{requestedSupplierJob.jobAddress}</div>
              </div>
            ) : (
              <div className="text-xs capitalize font-semibold">
                {requestedSupplierJob.jobAddress}
              </div>
            )}
          </div>
          <div className="flex justify-between w-full mt-3">
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Job City</div>
              {requestedSupplierJob.supplierJob?.jobCity !== '' &&
              requestedSupplierJob.supplierJob?.jobCity !== requestedSupplierJob.jobCity ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.jobCity ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.jobCity}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.jobCity}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Job State</div>
              {requestedSupplierJob.supplierJob?.jobState !== '' &&
              requestedSupplierJob.supplierJob?.jobState !== requestedSupplierJob.jobState ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.jobState ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.jobState}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.jobState}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Job Zip Code</div>
              {requestedSupplierJob.supplierJob?.jobZipCode !== '' &&
              requestedSupplierJob.supplierJob?.jobZipCode !== requestedSupplierJob.jobZipCode ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.jobZipCode ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.jobZipCode}</div>
                </div>
              ) : (
                <div className="text-xs font-semibold">{requestedSupplierJob.jobZipCode}</div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
          {requestedSupplierJob.jobState === 'AZ' &&
            requestedSupplierJob.jobLegalDescription &&
            requestedSupplierJob.jobCounty && (
              <div className="flex justify-between w-full mt-3">
                {requestedSupplierJob.jobCounty && (
                  <div className="w-1/2">
                    <div className="text-xs GREY_500-CLR">Job County</div>
                    {requestedSupplierJob.supplierJob?.jobCounty !== '' &&
                    requestedSupplierJob.supplierJob?.jobCounty !==
                      requestedSupplierJob.jobCounty ? (
                      <div className="flex items-center">
                        <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                          {requestedSupplierJob.supplierJob?.jobCounty ?? ''}
                        </div>
                        <div className="text-xs font-semibold">
                          {requestedSupplierJob.jobCounty}
                        </div>
                      </div>
                    ) : (
                      <div className="text-xs font-semibold">{requestedSupplierJob.jobCounty}</div>
                    )}
                  </div>
                )}
                {requestedSupplierJob.jobLegalDescription && (
                  <div className="w-1/2">
                    <div className="text-xs GREY_500-CLR">Job Legal Description</div>
                    {requestedSupplierJob.supplierJob?.jobLegalDescription !== '' &&
                    requestedSupplierJob.supplierJob?.jobLegalDescription !==
                      requestedSupplierJob.jobLegalDescription ? (
                      <div className="flex items-center">
                        <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                          {requestedSupplierJob.supplierJob?.jobLegalDescription ?? ''}
                        </div>
                        <div className="text-xs font-semibold">
                          {requestedSupplierJob.jobLegalDescription}
                        </div>
                      </div>
                    ) : (
                      <div className="text-xs font-semibold">
                        {requestedSupplierJob.jobLegalDescription}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          <div className="flex justify-between w-full mt-3">
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Job Type</div>
              {requestedSupplierJob.supplierJob?.jobType &&
              requestedSupplierJob.supplierJob?.jobType !== requestedSupplierJob.jobType ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.jobType ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.jobType}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.jobType}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Job Sector</div>
              {requestedSupplierJob.supplierJob?.jobSector &&
              requestedSupplierJob.supplierJob?.jobSector !== requestedSupplierJob.jobSector ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.jobSector ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.jobSector}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.jobSector}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">APN</div>
              {requestedSupplierJob.supplierJob?.apn !== '' &&
              requestedSupplierJob.supplierJob?.apn !== requestedSupplierJob.apn ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.apn ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.apn}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.apn ?? 'N/A'}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between w-full mt-3">
            <div className="w-1/2">
              <div className="text-xs GREY_500-CLR">Estimated Material Amount</div>
              {requestedSupplierJob.supplierJob?.estimatedMaterialAmount !== '' &&
              requestedSupplierJob.supplierJob?.estimatedMaterialAmount !==
                requestedSupplierJob.estimatedMaterialAmount ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.estimatedMaterialAmount ?? ''}
                  </div>
                  <div className="text-xs font-semibold">
                    {requestedSupplierJob.estimatedMaterialAmount}
                  </div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.estimatedMaterialAmount}
                </div>
              )}
            </div>
            <div className="w-1/2">
              <div className="text-xs GREY_500-CLR">Job Furnishings</div>
              {requestedSupplierJob.supplierJob?.jobFurnishings !== '' &&
              requestedSupplierJob.supplierJob?.jobFurnishings !==
                requestedSupplierJob.jobFurnishings ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.jobFurnishings ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.jobFurnishings}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.jobFurnishings}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
          <div>
            <div className="text-xs GREY_500-CLR">Owner</div>
            {requestedSupplierJob.supplierJob?.owner !== '' &&
            requestedSupplierJob.supplierJob?.owner !== requestedSupplierJob.owner ? (
              <div className="flex items-center">
                <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                  {requestedSupplierJob.supplierJob?.owner ?? ''}
                </div>
                <div className="text-xs font-semibold">{requestedSupplierJob.owner}</div>
              </div>
            ) : (
              <div className="text-xs capitalize font-semibold">{requestedSupplierJob.owner}</div>
            )}
          </div>
          <div className="mt-3">
            <div className="text-xs GREY_500-CLR">Owner Address</div>
            {requestedSupplierJob.supplierJob?.ownerAddress !== '' &&
            requestedSupplierJob.supplierJob?.ownerAddress !== requestedSupplierJob.ownerAddress ? (
              <div className="flex items-center">
                <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                  {requestedSupplierJob.supplierJob?.ownerAddress ?? ''}
                </div>
                <div className="text-xs font-semibold">{requestedSupplierJob.ownerAddress}</div>
              </div>
            ) : (
              <div className="text-xs capitalize font-semibold">
                {requestedSupplierJob.ownerAddress}
              </div>
            )}
          </div>
          <div className="flex justify-between w-full mt-3">
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Owner City</div>
              {requestedSupplierJob.supplierJob?.ownerCity !== '' &&
              requestedSupplierJob.supplierJob?.ownerCity !== requestedSupplierJob.ownerCity ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.ownerCity ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.ownerCity}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.ownerCity}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Owner State</div>
              {requestedSupplierJob.supplierJob?.ownerState !== '' &&
              requestedSupplierJob.supplierJob?.ownerState !== requestedSupplierJob.ownerState ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.ownerState ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.ownerState}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.ownerState}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">Owner Zip Code</div>
              {requestedSupplierJob.supplierJob?.ownerZipCode !== '' &&
              requestedSupplierJob.supplierJob?.ownerZipCode !==
                requestedSupplierJob.ownerZipCode ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.ownerZipCode ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.ownerZipCode}</div>
                </div>
              ) : (
                <div className="text-xs font-semibold">{requestedSupplierJob.ownerZipCode}</div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
          <div className="flex justify-between w-full">
            <div className="w-1/2">
              <div className="text-xs GREY_500-CLR">General Contractor</div>
              {requestedSupplierJob.supplierJob?.GC !== '' &&
              requestedSupplierJob.supplierJob?.GC !== requestedSupplierJob.GC ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.GC ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.GC}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">{requestedSupplierJob.GC}</div>
              )}
            </div>
            <div className="w-1/2">
              <div className="text-xs GREY_500-CLR">Customer Type</div>
              {requestedSupplierJob.supplierJob?.customerType &&
              requestedSupplierJob.supplierJob?.customerType !==
                requestedSupplierJob.customerType ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.customerType ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.customerType}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.customerType}
                </div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <div className="text-xs GREY_500-CLR">GC Address</div>
            {requestedSupplierJob.supplierJob?.GCAddress !== '' &&
            requestedSupplierJob.supplierJob?.GCAddress !== requestedSupplierJob.GCAddress ? (
              <div className="flex items-center">
                <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                  {requestedSupplierJob.supplierJob?.GCAddress ?? ''}
                </div>
                <div className="text-xs font-semibold">{requestedSupplierJob.GCAddress}</div>
              </div>
            ) : (
              <div className="text-xs capitalize font-semibold">
                {requestedSupplierJob.GCAddress}
              </div>
            )}
          </div>
          <div className="flex justify-between w-full mt-3">
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">GC City</div>
              {requestedSupplierJob.supplierJob?.GCCity !== '' &&
              requestedSupplierJob.supplierJob?.GCCity !== requestedSupplierJob.GCCity ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.GCCity ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.GCCity}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.GCCity}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">GC State</div>
              {requestedSupplierJob.supplierJob?.GCState !== '' &&
              requestedSupplierJob.supplierJob?.GCState !== requestedSupplierJob.GCState ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.GCState ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.GCState}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.GCState}
                </div>
              )}
            </div>
            <div className="w-1/3">
              <div className="text-xs GREY_500-CLR">GC Zip Code</div>
              {requestedSupplierJob.supplierJob?.GCZipCode !== '' &&
              requestedSupplierJob.supplierJob?.GCZipCode !== requestedSupplierJob.GCZipCode ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.GCZipCode ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.GCZipCode}</div>
                </div>
              ) : (
                <div className="text-xs font-semibold">{requestedSupplierJob.GCZipCode}</div>
              )}
            </div>
          </div>
        </div>
        {!requestedSupplierJob.hasNoLender ? (
          <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
            <div>
              <div className="text-xs GREY_500-CLR">Lender</div>
              {requestedSupplierJob.supplierJob?.lender !== '' &&
              requestedSupplierJob.supplierJob?.lender !== requestedSupplierJob.lender ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.lender ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.lender}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.lender}
                </div>
              )}
            </div>
            <div className="mt-3">
              <div className="text-xs GREY_500-CLR">Lender Address</div>
              {requestedSupplierJob.supplierJob?.lenderAddress !== '' &&
              requestedSupplierJob.supplierJob?.lenderAddress !==
                requestedSupplierJob.lenderAddress ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.lenderAddress ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.lenderAddress}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.lenderAddress}
                </div>
              )}
            </div>
            <div className="flex justify-between w-full mt-3">
              <div className="w-1/3">
                <div className="text-xs GREY_500-CLR">Lender City</div>
                {requestedSupplierJob.supplierJob?.lenderCity !== '' &&
                requestedSupplierJob.supplierJob?.lenderCity !== requestedSupplierJob.lenderCity ? (
                  <div className="flex items-center">
                    <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                      {requestedSupplierJob.supplierJob?.lenderCity ?? ''}
                    </div>
                    <div className="text-xs font-semibold">{requestedSupplierJob.lenderCity}</div>
                  </div>
                ) : (
                  <div className="text-xs capitalize font-semibold">
                    {requestedSupplierJob.lenderCity}
                  </div>
                )}
              </div>
              <div className="w-1/3">
                <div className="text-xs GREY_500-CLR">Lender State</div>
                {requestedSupplierJob.supplierJob?.lenderState !== '' &&
                requestedSupplierJob.supplierJob?.lenderState !==
                  requestedSupplierJob.lenderState ? (
                  <div className="flex items-center">
                    <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                      {requestedSupplierJob.supplierJob?.lenderState ?? ''}
                    </div>
                    <div className="text-xs font-semibold">{requestedSupplierJob.lenderState}</div>
                  </div>
                ) : (
                  <div className="text-xs capitalize font-semibold">
                    {requestedSupplierJob.lenderState}
                  </div>
                )}
              </div>
              <div className="w-1/3">
                <div className="text-xs GREY_500-CLR">Lender Zip Code</div>
                {requestedSupplierJob.supplierJob?.lenderZipCode !== '' &&
                requestedSupplierJob.supplierJob?.lenderZipCode !==
                  requestedSupplierJob.lenderZipCode ? (
                  <div className="flex items-center">
                    <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                      {requestedSupplierJob.supplierJob?.lenderZipCode ?? ''}
                    </div>
                    <div className="text-xs font-semibold">
                      {requestedSupplierJob.lenderZipCode}
                    </div>
                  </div>
                ) : (
                  <div className="text-xs font-semibold">{requestedSupplierJob.lenderZipCode}</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
            <div>
              <div className="text-xs GREY_500-CLR">Lender</div>
              <div className="text-xs capitalize font-semibold">N/A</div>
            </div>
          </div>
        )}
        {requestedSupplierJob.isJobBonded ? (
          <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
            <div>
              <div className="text-xs GREY_500-CLR">Surety</div>
              {requestedSupplierJob.supplierJob?.surety !== '' &&
              requestedSupplierJob.supplierJob?.surety !== requestedSupplierJob.surety ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.surety ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.surety}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.surety}
                </div>
              )}
            </div>
            <div className="mt-3">
              <div className="text-xs GREY_500-CLR">Surety Address</div>
              {requestedSupplierJob.supplierJob?.suretyAddress !== '' &&
              requestedSupplierJob.supplierJob?.suretyAddress !==
                requestedSupplierJob.suretyAddress ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.suretyAddress ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.suretyAddress}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.suretyAddress}
                </div>
              )}
            </div>
            <div className="flex justify-between w-full mt-3">
              <div className="w-1/3">
                <div className="text-xs GREY_500-CLR">Surety City</div>
                {requestedSupplierJob.supplierJob?.suretyCity !== '' &&
                requestedSupplierJob.supplierJob?.suretyCity !== requestedSupplierJob.suretyCity ? (
                  <div className="flex items-center">
                    <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                      {requestedSupplierJob.supplierJob?.suretyCity ?? ''}
                    </div>
                    <div className="text-xs font-semibold">{requestedSupplierJob.suretyCity}</div>
                  </div>
                ) : (
                  <div className="text-xs capitalize font-semibold">
                    {requestedSupplierJob.suretyCity}
                  </div>
                )}
              </div>
              <div className="w-1/3">
                <div className="text-xs GREY_500-CLR">Surety State</div>
                {requestedSupplierJob.supplierJob?.suretyState !== '' &&
                requestedSupplierJob.supplierJob?.suretyState !==
                  requestedSupplierJob.suretyState ? (
                  <div className="flex items-center">
                    <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                      {requestedSupplierJob.supplierJob?.suretyState ?? ''}
                    </div>
                    <div className="text-xs font-semibold">{requestedSupplierJob.suretyState}</div>
                  </div>
                ) : (
                  <div className="text-xs capitalize font-semibold">
                    {requestedSupplierJob.suretyState}
                  </div>
                )}
              </div>
              <div className="w-1/3">
                <div className="text-xs GREY_500-CLR">Surety Zip</div>
                {requestedSupplierJob.supplierJob?.suretyZipCode !== '' &&
                requestedSupplierJob.supplierJob?.suretyZipCode !==
                  requestedSupplierJob.suretyZipCode ? (
                  <div className="flex items-center">
                    <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                      {requestedSupplierJob.supplierJob?.suretyZipCode ?? ''}
                    </div>
                    <div className="text-xs font-semibold">
                      {requestedSupplierJob.suretyZipCode}
                    </div>
                  </div>
                ) : (
                  <div className="text-xs capitalize font-semibold">
                    {requestedSupplierJob.suretyZipCode}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3">
              <div className="text-xs GREY_500-CLR">Bond Number</div>
              {requestedSupplierJob.supplierJob?.bondNumber !== '' &&
              requestedSupplierJob.supplierJob?.bondNumber !== requestedSupplierJob.bondNumber ? (
                <div className="flex items-center">
                  <div className="text-xs font-semibold ERROR_500-CLR mr-1 line-through">
                    {requestedSupplierJob.supplierJob?.bondNumber ?? ''}
                  </div>
                  <div className="text-xs font-semibold">{requestedSupplierJob.bondNumber}</div>
                </div>
              ) : (
                <div className="text-xs capitalize font-semibold">
                  {requestedSupplierJob.bondNumber}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
            <div>
              <div className="text-xs GREY_500-CLR">Is Job Bonded?</div>
              <div className="text-xs capitalize font-semibold">No</div>
            </div>
          </div>
        )}
        {requestedSupplierJob?.jobInfoSheetAttachments &&
          requestedSupplierJob?.jobInfoSheetAttachments.length > 0 && (
            <div className="w-full p-2 rounded border GREY_200-BORDER PRIMARY_50-BG mt-2">
              <div className="text-xs GREY_500-CLR mb-1">Job Info Sheet Attachments</div>
              {requestedSupplierJob?.jobInfoSheetAttachments.map((attachment, index) => (
                <div
                  className="flex gap-2 items-center mb-1"
                  key={`requested-info-attachment-${index}`}>
                  <AppButton
                    type="TERTIARY"
                    buttonStyles={{
                      padding: '4px',
                      height: '35px',
                      width: '35px',
                    }}
                    icon={<Eye color={COLORS.GREY_500} size={18} />}
                    onClick={() => {
                      onPreviewAttachment(attachment?.url ?? '');
                    }}
                  />
                  <div className="text-xs capitalize font-semibold">{attachment.name}</div>
                </div>
              ))}
            </div>
          )}
      </div>
      <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
      {buyerError !== '' && (
        <p className="mx-3 ERROR_500-CLR mb-1 text-xs text-center">{`See errors above.`}</p>
      )}
      <div className="flex justify-between mx-3 mb-2 gap-2">
        <div className="flex gap-2">
          <AppButton text="Close" type="TERTIARY" onClick={onClose} />
          <AppButton
            text="Decline"
            type="SECONDARY"
            onClick={() =>
              onDeclineSupplierJob({ ...requestedSupplierJob, buyerId: selectedBuyerId })
            }
            isDisabled={declining}
            isLoading={declining}
            buttonStyles={{ width: '100px', height: '40px' }}
          />
        </div>
        <div className="flex gap-2">
          {requestedSupplierJob.buyerId !== '' && (
            <AppButton
              text="Edit"
              type="SECONDARY"
              onClick={() =>
                onEditSupplierJob({ ...requestedSupplierJob, buyerId: selectedBuyerId })
              }
            />
          )}
          <AppButton
            text="Accept"
            type="PRIMARY"
            onClick={() => {
              if (selectedBuyerId === '') {
                setBuyerError('Please select a customer');
              } else {
                onAcceptSupplierJob({ ...requestedSupplierJob, buyerId: selectedBuyerId });
              }
            }}
            buttonStyles={{ width: '100px', height: '40px' }}
            isDisabled={accepting}
            isLoading={accepting}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestedJobView;
