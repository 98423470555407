import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createSupplierJob,
  getSupplierJobs,
  updateSupplierJob,
  getSupplierJob,
  recursiveGetSupplierJobs,
  bulkUpdateSupplierJobs,
  archiveSupplierJob,
  supplierJobInfoSheetRequest,
} from '../services/supplierJobsService';
import { SupplierJobsInitialState } from '../globalSlices/supplierJobsSlice';
import { SupplierJobApiResponse } from '@dill/dill-shared';

export const createSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'createSupplierJob';
  builder.addCase(createSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'getSupplierJobs';
  builder.addCase(getSupplierJobs.pending, (state, action) => {
    if (!action.meta.arg?.isHideLoadingIndicator) {
      state.loadingList = [
        ...new Set([...state.loadingList, reducerId + action.meta.arg?.isHideLoadingIndicator]),
      ];
    }
    state.loadingErrorList = [];
  });
  builder.addCase(getSupplierJobs.fulfilled, (state, action) => {
    if (action.payload?.success && action.payload?.data && action.meta.arg?.includeInvoices) {
      if (!action.meta.arg?.isOnlyArchivedJobs) {
        const jobsStateUpdate = state.supplierJobsMap;
        const jobsNoInvoicesStateUpdate = state.supplierJobsNoInvoicesMap;
        action.payload?.data.forEach((item: SupplierJobApiResponse) => {
          if (item.isArchived) {
            delete jobsStateUpdate[item.id];
            delete jobsNoInvoicesStateUpdate[item.id];
          } else {
            jobsStateUpdate[item.id] = item;
            jobsNoInvoicesStateUpdate[item.id] = item;
          }
        });
        state.supplierJobsMap = { ...jobsStateUpdate };
        state.supplierJobsNoInvoicesMap = { ...jobsNoInvoicesStateUpdate };
      } else {
        const jobsStateUpdate = state.supplierJobsMap;
        const jobsNoInvoicesStateUpdate = state.supplierJobsNoInvoicesMap;
        const jobsJobsArchivedStateUpdate = state.supplierJobsArchivedMap;
        action.payload?.data.forEach((item: SupplierJobApiResponse) => {
          jobsJobsArchivedStateUpdate[item.id] = item;
          delete jobsStateUpdate[item.id];
          delete jobsNoInvoicesStateUpdate[item.id];
        });
        state.supplierJobsArchivedMap = { ...jobsJobsArchivedStateUpdate };
        state.supplierJobsMap = { ...jobsStateUpdate };
        state.supplierJobsNoInvoicesMap = { ...jobsNoInvoicesStateUpdate };
      }
    }
    if (
      action.payload?.success &&
      action.payload?.data &&
      !action.meta.arg?.includeInvoices &&
      !action.meta.arg?.isOnlyArchivedJobs
    ) {
      const jobsNoInvoicesStateUpdate = state.supplierJobsNoInvoicesMap;
      action.payload?.data.forEach((item: SupplierJobApiResponse) => {
        jobsNoInvoicesStateUpdate[item.id] = item;
      });
      state.supplierJobsNoInvoicesMap = { ...jobsNoInvoicesStateUpdate };
    }
    state.loadingList = state.loadingList.filter(
      (item) => item !== reducerId + action.meta.arg?.isHideLoadingIndicator
    );
  });
  builder.addCase(getSupplierJobs.rejected, (state, action) => {
    state.loadingList = state.loadingList.filter(
      (item) => item !== reducerId + action.meta.arg?.isHideLoadingIndicator
    );
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierJobs: action.payload }]),
      ];
    }
  });
};

export const updateSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'updateSupplierJob';
  builder.addCase(updateSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action?.payload?.success && action?.payload?.data) {
        const data: SupplierJobApiResponse = action.payload.data;
        const existingJob = state.supplierJobsMap[data.id];
        if (existingJob) {
          const jobsStateUpdate = state.supplierJobsMap;
          jobsStateUpdate[existingJob.id] = { ...existingJob, ...data };
          state.supplierJobsMap = jobsStateUpdate;
        }

        const existingJobNoInvoice = state.supplierJobsNoInvoicesMap[data.id];
        if (existingJobNoInvoice) {
          const jobsStateUpdate = state.supplierJobsNoInvoicesMap;
          jobsStateUpdate[existingJobNoInvoice.id] = { ...existingJobNoInvoice, ...data };
          state.supplierJobsNoInvoicesMap = jobsStateUpdate;
        }
      }
    }
  );
  builder.addCase(updateSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const archiveSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'archiveSupplierJob';
  builder.addCase(archiveSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    archiveSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.data && Array.isArray(action.payload.data)) {
        const updatedJobs: SupplierJobApiResponse[] = action.payload.data;
        const jobsStateUpdate = state.supplierJobsMap;
        const jobsNoInvoicesStateUpdate = state.supplierJobsNoInvoicesMap;
        const jobsArchivedStateUpdate = state.supplierJobsArchivedMap;
        updatedJobs.forEach((item: SupplierJobApiResponse) => {
          if (jobsArchivedStateUpdate[item.id]) {
            jobsArchivedStateUpdate[item.id] = { ...jobsArchivedStateUpdate[item.id], ...item };
          } else {
            jobsArchivedStateUpdate[item.id] = item;
          }
          if (jobsStateUpdate[item.id]) {
            delete jobsStateUpdate[item.id];
          }

          if (jobsNoInvoicesStateUpdate[item.id]) {
            delete jobsNoInvoicesStateUpdate[item.id];
          }
        });
        state.supplierJobsMap = jobsStateUpdate;
        state.supplierJobsNoInvoicesMap = jobsNoInvoicesStateUpdate;
        state.supplierJobsArchivedMap = jobsArchivedStateUpdate;
      }
    }
  );
  builder.addCase(archiveSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'getSupplierJob';
  builder.addCase(getSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedSupplierJob = action.payload.data;
        const data: SupplierJobApiResponse = action.payload.data;

        const existingJob = state.supplierJobsMap[data.id];
        if (existingJob) {
          const jobsStateUpdate = state.supplierJobsMap;
          jobsStateUpdate[existingJob.id] = { ...existingJob, ...data };
          state.supplierJobsMap = jobsStateUpdate;
        }

        const existingJobNoInvoice = state.supplierJobsNoInvoicesMap[data.id];
        if (existingJobNoInvoice) {
          const jobsStateUpdate = state.supplierJobsNoInvoicesMap;
          jobsStateUpdate[existingJobNoInvoice.id] = { ...existingJobNoInvoice, ...data };
          state.supplierJobsNoInvoicesMap = jobsStateUpdate;
        }
      }
    }
  );
  builder.addCase(getSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const recursiveGetSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'recursiveGetSupplierJobs';
  builder.addCase(recursiveGetSupplierJobs.pending, (state, action) => {
    if (action?.meta?.arg?.isShowLoadingIndicator) {
      state.loadingList = [...new Set([...state.loadingList, reducerId])];
    }
    state.loadingErrorList = [];
  });
  builder.addCase(recursiveGetSupplierJobs.fulfilled, (state, action) => {
    if (action.payload?.success && action.payload?.data) {
      if (action.meta.arg?.includeInvoices) {
        if (!action.meta.arg?.isOnlyArchivedJobs) {
          const jobsStateUpdate = state.supplierJobsMap;
          const jobsNoInvoiceStateUpdate = state.supplierJobsNoInvoicesMap;
          action.payload?.data.forEach((item: SupplierJobApiResponse) => {
            jobsStateUpdate[item.id] = item;
            jobsNoInvoiceStateUpdate[item.id] = item;
          });
          state.supplierJobsMap = { ...jobsStateUpdate };
          state.supplierJobsNoInvoicesMap = { ...jobsNoInvoiceStateUpdate };
        } else {
          const jobsStateUpdate = state.supplierJobsMap;
          const jobsNoInvoicesStateUpdate = state.supplierJobsNoInvoicesMap;
          const jobsJobsArchivedStateUpdate = state.supplierJobsArchivedMap;
          action.payload?.data.forEach((item: SupplierJobApiResponse) => {
            jobsJobsArchivedStateUpdate[item.id] = item;
            delete jobsStateUpdate[item.id];
            delete jobsNoInvoicesStateUpdate[item.id];
          });
          state.supplierJobsArchivedMap = { ...jobsJobsArchivedStateUpdate };
          state.supplierJobsMap = { ...jobsStateUpdate };
          state.supplierJobsNoInvoicesMap = { ...jobsNoInvoicesStateUpdate };
        }
      } else {
        if (!action.meta.arg?.isOnlyArchivedJobs) {
          const jobsNoInvoiceStateUpdate = state.supplierJobsNoInvoicesMap;
          action.payload?.data.forEach((item: SupplierJobApiResponse) => {
            jobsNoInvoiceStateUpdate[item.id] = item;
          });
          state.supplierJobsNoInvoicesMap = { ...jobsNoInvoiceStateUpdate };
        }
      }
    }
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(recursiveGetSupplierJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierJobs: action.payload }]),
      ];
    }
  });
};

export const bulkUpdateSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'bulkUpdateSupplierJob';
  builder.addCase(bulkUpdateSupplierJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    bulkUpdateSupplierJobs.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.data && Array.isArray(action.payload.data)) {
        const updatedJobs: SupplierJobApiResponse[] = action.payload.data;
        // Update supplierJobs by merging with the updated ones
        const jobsStateUpdate = state.supplierJobsMap;
        const jobsNoInvoicesStateUpdate = state.supplierJobsNoInvoicesMap;
        updatedJobs.forEach((item: SupplierJobApiResponse) => {
          jobsStateUpdate[item.id] = item;
          if (jobsStateUpdate[item.id]) {
            jobsStateUpdate[item.id] = { ...jobsStateUpdate[item.id], ...item };
          } else {
            jobsStateUpdate[item.id] = item;
          }
          if (jobsNoInvoicesStateUpdate[item.id]) {
            jobsNoInvoicesStateUpdate[item.id] = { ...jobsNoInvoicesStateUpdate[item.id], ...item };
          } else {
            jobsNoInvoicesStateUpdate[item.id] = item;
          }
        });
        state.supplierJobsMap = jobsStateUpdate;
        state.supplierJobsNoInvoicesMap = jobsNoInvoicesStateUpdate;
      }
    }
  );
  builder.addCase(bulkUpdateSupplierJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const supplierJobInfoSheetRequestExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'supplierJobInfoSheetRequest';
  builder.addCase(supplierJobInfoSheetRequest.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    supplierJobInfoSheetRequest.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action?.payload?.success && action?.payload?.data?.supplierJobUpdated) {
        const data: SupplierJobApiResponse = action.payload.data?.supplierJobUpdated;
        console.log(data);
        const existingJob = state.supplierJobsMap[data.id];
        if (existingJob) {
          const jobsStateUpdate = state.supplierJobsMap;
          jobsStateUpdate[existingJob.id] = { ...existingJob, ...data };
          state.supplierJobsMap = jobsStateUpdate;
        }

        const existingJobNoInvoice = state.supplierJobsNoInvoicesMap[data.id];
        if (existingJobNoInvoice) {
          const jobsStateUpdate = state.supplierJobsNoInvoicesMap;
          jobsStateUpdate[existingJobNoInvoice.id] = { ...existingJobNoInvoice, ...data };
          state.supplierJobsNoInvoicesMap = jobsStateUpdate;
        }
      }
    }
  );
  builder.addCase(supplierJobInfoSheetRequest.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
