import { useState } from 'react';
import './HelpPage.scss';
import { HelpCircle } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { CaretDown, Play } from 'phosphor-react';
import { AppVideoModal } from '../../../general/AppVideoModal/AppVideoModal.';

const HELPDATA = [
  {
    id: 1,
    title: 'How do I sign the release on Dill?',
    video: 'JATtYp-JwoU',
  },
  {
    id: 2,
    title: 'What if there’s something wrong with the release on Dill?',
    video: 'Tu0OvNwBaB4',
  },
  {
    id: 3,
    title: 'How do I track and access all of my releases on Dill?',
    video: 'bTdtGSOnvB4',
  },
  {
    id: 4,
    title: 'What are these locked features on Dill?',
    video: '',
  },
];
export const HelpPage = () => {
  const [openDropDown, setOpenDropDown] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [openVideo, setOpenVideo] = useState(false);
  const handleOpen = (id: number) => {
    setOpenDropDown(id);
  };
  return (
    <div className="w-full h-5/6 p-8 overflow-scroll">
      <AppVideoModal
        open={openVideo}
        handleClose={() => setOpenVideo(false)}
        video={selectedVideo}
      />
      {HELPDATA.map((item) => (
        <button
          className="help-item"
          key={`help_item_${item.id}`}
          onClick={() => handleOpen(item.id)}>
          <div className="row-container">
            <div className="detail-text">{item.title}</div>
            <CaretDown
              size={24}
              color={COLORS.GREY_600}
              style={{ rotate: openDropDown === item.id ? '180deg' : '0deg' }}
            />
          </div>
          {openDropDown === item.id && item.video !== '' && (
            <button
              className="video"
              onClick={() => {
                setOpenVideo(true);
                setSelectedVideo(item.video);
              }}>
              <iframe
                width="320"
                height="160"
                src={`https://www.youtube.com/embed/${item.video}`}
              />
              <div className="play-button">
                <Play size={24} color={COLORS.PRIMARY_500} />
              </div>
            </button>
          )}
          {openDropDown === item.id && item.video === '' && (
            <div className="sub-text">
              Locked features include a full platform that streamlines liens, lien waivers, and
              payments all in one. Other suppliers have used Dill to get paid faster and increase
              customer satisfaction. Reach out to customer support to learn more.
            </div>
          )}
        </button>
      ))}
      <div className="help-item">
        <div className="row-container">
          <div className="detail-text">
            Call or text us at <span>972-339-0799</span> for customer support or feature requests
          </div>
          <div className="help-circle">
            <HelpCircle color={COLORS.WARNING_600} />
          </div>
        </div>
      </div>
    </div>
  );
};
