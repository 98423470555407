import * as Sentry from '@sentry/react';

import { createAsyncThunk } from '@reduxjs/toolkit';

export interface VerifyJobRequestData {
  zipCode: string;
  state: string;
  city: string;
  address: string;
  buyerId: string;
  jobNumber: string;
  name: string;
}

export interface ContructionMonitorPermitOption {
  jobAddress: string;
  jobCity: string;
  jobState: string;
  jobZipCode: string;
  owner: string;
  constructionMonitorPermitId: string;
  ownerAddress: string;
  ownerCity: string;
  ownerZipCode: string;
  ownerState: string;
  gcName: string;
  gcAddress: string;
  gcZipCode: string;
  gcState: string;
  gcCity: string;
  gcEmail: string;
  apn: string;
  jobType: 'commercial' | 'residential';
}

export const fetchQueuryJobSiteAddresses = createAsyncThunk<
  ContructionMonitorPermitOption[],
  VerifyJobRequestData
>('jobOwner/fetchAddresses', async (requestData: VerifyJobRequestData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/jobOwner/queryJobSiteAddress`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    const json: ContructionMonitorPermitOption[] = await response.json();
    return json
      .filter((permit) => permit !== null)
      .map((item: any) => {
        const gcName =
          item._source.flatfile.bldr_cmpny ||
          `${item._source.flatfile.bldr_first} ${item._source.flatfile.bldr_last}`.trim();
        const gcAddress = item._source.flatfile.bldr_st;
        const gcZipCode = item._source.flatfile.bldr_zip;
        const gcState = item._source.flatfile.bldr_state;
        const gcCity = item._source.flatfile.bldr_city;
        const gcEmail = item._source.flatfile.bldr_email;

        let owner =
          item._source.flatfile.ownr_cmpny ||
          `${item._source.flatfile.ownr_first} ${item._source.flatfile.ownr_last}`;
        let ownerAddress = item._source.flatfile.ownr_st;
        let ownerCity = item._source.flatfile.ownr_city;
        let ownerZipCode = item._source.flatfile.ownr_zip;
        let ownerState = item._source.flatfile.ownr_state;

        if (
          item._source?.flatfile?.bldr_type === 'Contr-Owner' ||
          item._source?.flatfile?.bldr_type === 'Owner-Builder'
        ) {
          owner = gcName || owner;
          ownerAddress = gcAddress || ownerAddress;
          ownerCity = gcState || ownerCity;
          ownerZipCode = gcZipCode || ownerZipCode;
          ownerState = gcState || ownerState;
        }
        return {
          jobAddress: item._source.flatfile.site_addrs,
          jobCity: item._source.flatfile.site_city,
          jobState: item._source.flatfile.site_state,
          jobZipCode: item._source.flatfile.site_zip,
          owner,
          constructionMonitorPermitId: item._id,
          ownerAddress,
          ownerCity,
          ownerZipCode,
          ownerState,
          gcName,
          gcAddress,
          gcZipCode,
          gcState,
          gcCity,
          gcEmail,
          apn: item._source.parcel,
          jobType: item._source.flatfile.pmt_class.toLowerCase() || '',
        };
      });
  } catch (error) {
    console.error('Error fetching data: ', error);
    Sentry.captureException(error);
    return [];
  }
});
