import { Modal } from '@mui/material';
import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { FileArrowUp, UploadSimple, Eye, Warning } from 'phosphor-react';
import { ArrowsMerge } from '@phosphor-icons/react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import AppDatePicker from '../AppDatePicker/AppDatePicker';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../../redux/store';
import {
  LienTemplate,
  UserLinkApiResponse,
  PDFFieldReleaseTemplate,
  Job,
  FromFieldTypeReleaseTemplate,
  ReleaseFieldData,
  GroupedCSVRelease,
} from '@dill/dill-shared/dist/src';
import {
  removePDFFields,
  disablePDFFields,
  populatePdfWithFieldsData,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
  trimObject,
} from '../../../utils/helpers';
import { AppInputField } from '../AppInputField/AppInputField';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AppVirtualizedList } from '../AppVirtualizedList/AppVirtualizedList';
import { openMessageModal } from '../../../redux/globalSlices/genericSlice';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';

interface SubSupplierUsersType {
  subSupplierName: string;
  notes: string;
  subSupplierId: string;
  user: UserLinkApiResponse;
}

interface ProcessedReleaseData {
  amount: string | number;
  notes: string;
  jobId: string;
  recipientId: string;
  templateId: string;
  throughDate: string;
  dueDate: string;
}

export const AppBulkReleaseModal = ({
  open,
  handleClose,
  onPreviewRelease,
  onCreateBulkReleases,
  releaseOptions,
}: {
  open: boolean;
  handleClose: () => void;
  onCreateBulkReleases: (data: GroupedCSVRelease[]) => void;
  onPreviewRelease: (
    data: {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      dueDate: string | Date;
    },
    url: string
  ) => void;
  releaseOptions: {
    jobId: string;
    recipientId: string;
    throughDate: string;
    amount: string;
    templateId: string;
    dueDate: string;
  }[];
}) => {
  const dispatch = useAppDispatch();
  const { jobs } = useAppSelector((state) => state.jobs);
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const { buyerLienReleases } = useAppSelector((state) => state.liens);
  const { user } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [selected, setSelected] = useState<number[]>([]);
  const [error, setError] = useState<{ [key: number]: string[] }[]>([]);
  const [currentPdfUrl, setCurrentPdfUrl] = useState('');
  const [previewLoading, setPreviewLoading] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [processedInvoices, setProcessedInvoices] = useState<ProcessedReleaseData[]>([]);

  // New states

  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'select', name: '', width: 50, cellType: 'SELECT' },
      { id: 'recipientId', name: 'Recipient', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'jobId', name: 'Job', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'templateId', name: 'Template', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'throughDate', name: 'Through Date', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'notes', name: 'Notes', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'dueDate', name: 'Due Date', width: 140, cellType: 'VIEW_TEXT' },
      { id: 'action', name: '', width: 80, cellType: 'VIEW_TEXT' },
    ];
    return currentColumns;
  }, [processedInvoices]);

  const templates = useMemo(() => {
    if (lienTemplates.length > 0) {
      const processedLienTemplates = lienTemplates.filter(
        (template) => template.status === 'processed'
      );
      return processedLienTemplates;
    }
    return [];
  }, [lienTemplates]);

  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers.reduce(
        (
          prev: {
            subSupplierName: string;
            notes: string;
            subSupplierId: string;
            user: UserLinkApiResponse;
          }[],
          curr
        ) => {
          return [
            ...prev,
            ...curr.users
              .filter((userLink) => {
                if (
                  userLink.subUser &&
                  userLink.subUser.roles &&
                  (userLink.subUser.roles.includes('REVIEWER') ||
                    userLink.subUser.roles.length == 0)
                ) {
                  return true;
                }
                return !userLink?.subUser?.roles;
              })
              .map((userLink) => {
                return {
                  subSupplierId: curr.id,
                  subSupplierName: curr.name,
                  notes: curr.notes ?? '',
                  user: userLink,
                };
              }),
          ];
        },
        []
      );
    }
    return [];
  }, [subSuppliers]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const numberList = Array.from({ length: processedInvoices.length }, (_, index) => index);
      setSelected(numberList);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    if (open) {
      setError([]);
      setCurrentPdfUrl('');
      setSelected([]);
    } else {
      setProcessedInvoices([]);
    }
  }, [open]);

  const populatePdf = async (
    fieldDetails: PDFFieldReleaseTemplate[],
    selectedTemplate: LienTemplate
  ) => {
    if (!selectedTemplate?.originalPdf?.url) {
      return '';
    }

    let pdfUrl = '';
    const flatPDF = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
    pdfUrl = flatPDF ?? '';
    const populatedPdfUrl = await populatePdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
    });
    if (populatedPdfUrl) {
      return populatedPdfUrl;
    }
    return '';
  };

  const populateAllPdfData = async (
    selectedJob: Job,
    subSupplierUser: SubSupplierUsersType,
    selectedTemplate: LienTemplate,
    formFieldsData: {
      [x: string]: ReleaseFieldData;
    }
  ) => {
    const specialFieldsPDFData: { fromFieldType: FromFieldTypeReleaseTemplate; dataToUse: any }[] =
      [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    if (selectedJob) {
      specialFieldsPDFData.push({ fromFieldType: 'JOB_FIELD', dataToUse: selectedJob });
    }

    if (subSupplierUser) {
      specialFieldsPDFData.push({
        fromFieldType: 'SUPPLIER_FIELD',
        dataToUse: subSupplierUser,
      });
    }

    if (selectedTemplate) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_BUYER_FIELD',
        dataToUse: selectedMainBuyer,
      });

      const allNewFormFields = { ...formFieldsData };
      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        dynamicForFieldsPdfData.push({
          fromFieldId: formFieldKey,
          value: allNewFormFields[formFieldKey].value,
        });
      });
    }
    let pdfData: PDFFieldReleaseTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && selectedTemplate) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    const pdfUrl = await populatePdf(pdfData, selectedTemplate);
    return pdfUrl;
  };

  const populateTemplateFieldData = (
    selectedTemplate: LienTemplate,
    currentThroughDate: string,
    amount: number
  ) => {
    let data: { [x: string]: ReleaseFieldData } = {};
    if (selectedTemplate) {
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
          let defaultValue: any = '';
          if (
            formField.fieldType === 'CURRENCY' &&
            formField.defaultValue !== null &&
            formField.defaultValue !== undefined
          ) {
            defaultValue = Number(formField.defaultValue).toFixed(2);
          } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
            defaultValue = `${formField.defaultValue}`;
          } else {
            defaultValue = formField.defaultValue;
          }
          data = {
            ...data,
            [formField.id]: {
              isRequired: formField.isFieldRequired,
              value:
                formField.defaultValue !== null && formField.defaultValue !== undefined
                  ? defaultValue
                  : '',
              id: formField.id,
              name: formField.fieldName,
              type: formField.fieldType,
            },
          };
        }
      });
      let throughDateData: { [x: string]: ReleaseFieldData } = {};
      for (const key of Object.keys(data)) {
        if (key.startsWith('throughDate')) {
          throughDateData = {
            ...throughDateData,
            [key]: {
              ...data[key],
              value: new Date(currentThroughDate),
            },
          };
        }
      }
      let paymentAmountData: { [x: string]: ReleaseFieldData } = {};
      for (const key of Object.keys(data)) {
        if (key.startsWith('paymentAmount')) {
          paymentAmountData = {
            ...paymentAmountData,
            [key]: {
              ...data[key],
              value: amount,
            },
          };
        }
      }
      data = { ...data, ...throughDateData, ...paymentAmountData };
    }
    return { ...data };
  };

  const checkTempateRequirement = (selectedTemplate: LienTemplate) => {
    const requredField = [];
    if (selectedTemplate) {
      let data: { [x: string]: ReleaseFieldData } = {};
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
          let defaultValue: any = '';
          if (
            formField.fieldType === 'CURRENCY' &&
            formField.defaultValue !== null &&
            formField.defaultValue !== undefined
          ) {
            defaultValue = Number(formField.defaultValue).toFixed(2);
          } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
            defaultValue = `${formField.defaultValue}`;
          } else {
            defaultValue = formField.defaultValue;
          }
          data = {
            ...data,
            [formField.id]: {
              isRequired: formField.isFieldRequired,
              value:
                formField.defaultValue !== null && formField.defaultValue !== undefined
                  ? defaultValue
                  : '',
              id: formField.id,
              name: formField.fieldName,
              type: formField.fieldType,
            },
          };
        }
      });
      for (const key of Object.keys(data)) {
        if (key.startsWith('throughDate')) {
          requredField.push('throughDate');
        }
      }
      for (const key of Object.keys(data)) {
        if (key.startsWith('paymentAmount')) {
          requredField.push('amount');
        }
      }
    }
    return requredField;
  };

  useEffect(() => {
    const fetchedData = [];
    for (let index = 0; index < releaseOptions.length; index++) {
      const releaseOption = releaseOptions[index];
      let supplierUserId = '';
      const foundSupplier = subSupplierUsers.find(
        (subSupplier) =>
          subSupplier.subSupplierId.toLowerCase().trim() ===
          releaseOption.recipientId.toLowerCase().trim()
      );
      if (foundSupplier) {
        const userDetails = foundSupplier.user.subUser
          ? foundSupplier.user.subUser
          : foundSupplier.user.user;
        supplierUserId = `${foundSupplier.subSupplierId}__${userDetails?.id ?? ''}`;
      }
      fetchedData.push({
        ...releaseOption,
        recipientId: supplierUserId,
        throughDate: moment(releaseOption.throughDate).endOf('month').format('MM/DD/YYYY'),
        dueDate: moment(releaseOption.dueDate).endOf('month').format('MM/DD/YYYY'),
        notes: '',
      });
    }
    setProcessedInvoices(fetchedData);
    const numberList = Array.from({ length: fetchedData.length }, (_, index) => index);
    setSelected(numberList);
  }, [releaseOptions]);

  const handleCloseModal = () => {
    setProcessedInvoices([]);
    setError([]);
    setSelected([]);
    setCurrentPdfUrl('');
    handleClose();
  };

  const handleChangeValue = (key: keyof ProcessedReleaseData, index: number, value: string) => {
    const updateData = [...processedInvoices];
    updateData[index][key] = value;
    setProcessedInvoices(updateData);
  };

  const handleBlur = (key: keyof ProcessedReleaseData, index: number, value: string) => {
    const updateData = [...processedInvoices];
    updateData[index][key] = Number(value).toFixed(2);
    setProcessedInvoices(updateData);
  };

  const handleSelectFeature = (isChecked: boolean, index: number) => {
    const selectedArray = [...selected];
    if (isChecked) {
      selectedArray.push(index);
      setSelected(selectedArray);
    } else {
      const filterArray = selectedArray.filter((itm) => itm !== index);
      setSelected(filterArray);
    }
  };

  const errorData = (data: ProcessedReleaseData, template?: LienTemplate) => {
    let tempRequirement: string[] = [];
    if (template) {
      tempRequirement = checkTempateRequirement(template);
    }
    const errorsFound: string[] = [];
    if (data.jobId === '') {
      errorsFound.push('Job');
    }
    if (data.throughDate === '' && tempRequirement.includes('throughDate')) {
      errorsFound.push('Through Date');
    }
    if (data.amount === '' && tempRequirement.includes('amount')) {
      errorsFound.push('Amount');
    }
    if (data.recipientId === '') {
      errorsFound.push('Recipient');
    }
    if (data.templateId === '') {
      errorsFound.push('Template');
    }
    if (data.dueDate === '' || data.dueDate === 'Invalid date') {
      errorsFound.push('Due Date');
    }
    return errorsFound;
  };

  const handlePreview = async (index: number, data: ProcessedReleaseData) => {
    const temp = templates.find((tmp) => tmp.id === data.templateId);
    const errorsFound = errorData(data, temp);
    if (errorsFound.length > 0) {
      setPreviewLoading(null);
      setError([{ [index]: errorsFound }]);
      return;
    }
    const job = jobs.find((jb) => jb.id === data.jobId);
    const recipient = subSupplierUsers.find((sub) => {
      const recipientId = sub.user.subUser ? sub.user.subUser.id : sub.user.user?.id;
      const supplierSplit = data.recipientId.split('__');
      return recipientId === supplierSplit[1] && sub.subSupplierId === supplierSplit[0];
    });

    if (temp && job && recipient) {
      const tempRequirement = checkTempateRequirement(temp);
      const previewData = {
        recipient: `${
          recipient?.user?.subUser?.firstName ?? recipient?.user?.user?.firstName ?? ''
        } ${recipient?.user?.subUser?.lastName ?? recipient?.user?.user?.lastName ?? ''}`,
        type: temp?.type?.toLowerCase() ?? '',
        paymentAmount:
          data.amount && tempRequirement.includes('amount')
            ? Number(Number(data.amount).toFixed(2))
            : null,
        throughDate: tempRequirement.includes('throughDate') ? data.throughDate ?? '' : null,
        job: job?.name ?? '',
        status: 'Pending' as const,
        dueDate: data?.dueDate ?? '',
      };
      const populatedTemplateFields = populateTemplateFieldData(
        temp,
        data.throughDate ?? '',
        Number(Number(data.amount).toFixed(2))
      );
      const url = await populateAllPdfData(job, recipient, temp, populatedTemplateFields);
      const flatPDFUrl = await disablePDFFields(url);
      onPreviewRelease(previewData, flatPDFUrl ?? '');
    }
    setPreviewLoading(null);
  };

  const groupRelease = async (data: ProcessedReleaseData[]) => {
    const releaseArray = [];
    for (let index = 0; index < data.length; index++) {
      const release = data[index];

      const job = jobs.find((jb) => jb.id === release.jobId);
      const temp = templates.find((tmp) => tmp.id === release.templateId);
      const recipient = subSupplierUsers.find((sub) => {
        const recipientId = sub.user.subUser ? sub.user.subUser.id : sub.user.user?.id;
        const supplierSplit = release.recipientId.split('__');
        return recipientId === supplierSplit[1] && sub.subSupplierId === supplierSplit[0];
      });
      if (temp && job && recipient) {
        const populatedTemplateFields = populateTemplateFieldData(
          temp,
          release.throughDate ?? '',
          Number(Number(release.amount).toFixed(2))
        );
        const url = await populateAllPdfData(job, recipient, temp, populatedTemplateFields);
        const processedReleaseData = {
          jobId: job.id || '',
          notes: release.notes,
          previewPdfUrl: url,
          recipientId: {
            userId: recipient.user.user?.id || '',
            subUserId: recipient.user.subUser?.id || '',
          },
          subSupplierId: recipient?.subSupplierId ?? '',
          templateId: temp?.id || '',
          fieldsData: Object.values(populatedTemplateFields),
          emailReminderFrequency: 3,
          dueDate: new Date(release.dueDate),
          status: 'Requested' as const,
          type: temp.type,
          releaseMonth: new Date(release.throughDate),
          storagePathName: `buyerReleases/${selectedMainBuyerId}/${recipient?.subSupplierId}/${job?.id}`,
        };
        releaseArray.push(processedReleaseData);
      }
    }
    return releaseArray;
  };

  const handleBulkSendReleases = async () => {
    setIsLoading(true);
    const errors: { [key: number]: string[] }[] = [];
    const selectedRelease = [];
    for (let index = 0; index < selected.length; index++) {
      const selectedIndex = selected[index];
      const release = processedInvoices[selectedIndex];
      const temp = templates.find((tmp) => tmp.id === release.templateId);
      const errorsFound = errorData(release, temp);
      if (errorsFound.length > 0) {
        errors.push({ [selectedIndex]: errorsFound });
      }
      selectedRelease.push(release);
    }
    if (errors.length > 0) {
      setError(errors);
      setIsLoading(false);
      return;
    }
    setError([]);
    const updateRelease = await groupRelease(selectedRelease);
    setIsLoading(false);
    onCreateBulkReleases(updateRelease);
  };

  const renderRow = (item: { id: string; content: React.ReactNode }) => {
    const index = Number(item.id);
    const data = processedInvoices[index];
    return (
      <div key={index + 'oo'} className="w-full flex  px-4 justify-between items-center">
        {columns.map((column) => {
          const throudate = moment();
          const stringIndex = index.toString();
          type ObjectKey = keyof typeof data;
          const columnKey = column.id as ObjectKey;
          const errorList = error.find((itm) => Object.keys(itm)[0] === stringIndex);
          const errorValues = errorList ? Object.values(errorList)[0] : [];
          const temp = templates.find((tmp) => tmp.id === data.templateId);
          const subsuppliers = [
            ...subSupplierUsers.map((subSupplierUser, i) => {
              const userDetails = subSupplierUser.user.subUser
                ? subSupplierUser.user.subUser
                : subSupplierUser.user.user;
              return {
                label: `${subSupplierUser.subSupplierName} - ${userDetails?.firstName || ''} ${
                  userDetails?.lastName || ''
                } `,
                value: `${subSupplierUser.subSupplierId}__${userDetails?.id ?? ''}`,
              };
            }),
          ];
          let checkRequreTempField: string[] = [];
          if (temp) {
            checkRequreTempField = checkTempateRequirement(temp);
          }
          let value = '';
          let amount = '';
          if (column.id === 'throughDate') {
            if (checkRequreTempField.includes('throughDate')) {
              value = data[columnKey]?.toString();
            } else {
              value = 'N/A';
            }
          }
          if (column.id === 'amount') {
            if (checkRequreTempField.includes('amount')) {
              amount = (data as any)[column.id];
            } else {
              amount = 'N/A';
            }
          }
          if (column.id === 'dueDate') {
            if (data?.[columnKey]) {
              value = data[columnKey]?.toString();
            }
          }
          return (
            <div key={column.id} style={{ flex: column.width / totalWidth }} className="py-3 px-1">
              {column.cellType === 'SELECT' && column.id === 'select' && (
                <AppCheckBox
                  isChecked={selected.includes(index)}
                  onClick={(isChecked: boolean) => {
                    handleSelectFeature(isChecked, index);
                  }}
                />
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'recipientId' && (
                <AppDropDown
                  value={(data as any)[column.id]}
                  errorText={errorValues.includes(column.name) ? `${column.name} is required` : ''}
                  items={subsuppliers}
                  onSelectChange={(val) => {
                    if (val) {
                      handleChangeValue(columnKey, index, val.value);
                    }
                  }}
                  height={240}
                />
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'jobId' && (
                <AppDropDown
                  value={(data as any)[column.id]}
                  errorText={errorValues.includes(column.name) ? `${column.name} is required` : ''}
                  items={[
                    ...jobs
                      .filter((jb) => !jb?.archivedState?.includes('BUYER_ARCHIVED'))
                      .map((job) => {
                        return {
                          label: `${job?.number ? `${job.number} - ` : ''}${job.name}`,
                          value: job.id,
                        };
                      }),
                  ]}
                  onSelectChange={(val) => {
                    if (val) {
                      handleChangeValue(columnKey, index, val.value);
                    }
                  }}
                  height={240}
                />
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'templateId' && (
                <AppDropDown
                  value={(data as any)[column.id]}
                  errorText={errorValues.includes(column.name) ? `${column.name} is required` : ''}
                  items={[
                    ...templates.map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                  ]}
                  onSelectChange={(itm) => {
                    if (itm) {
                      handleChangeValue(columnKey, index, itm.value);
                    }
                  }}
                  height={240}
                />
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'amount' && (
                <div>
                  {amount === 'N/A' ? (
                    <div className="w-full ">{amount}</div>
                  ) : (
                    <AppInputField
                      inputType="number"
                      prefix={'$'}
                      errorText={
                        errorValues.includes(column.name) ? `${column.name} is required` : ''
                      }
                      onBlur={(e) => handleBlur(columnKey, index, e.target.value)}
                      value={amount}
                      onTextChange={(text) => {
                        handleChangeValue(columnKey, index, text);
                      }}
                    />
                  )}
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'notes' && (
                <div className="text-sm">
                  <AppInputField
                    inputType="text"
                    value={(data as any)[column.id]}
                    onTextChange={(text) => {
                      handleChangeValue(columnKey, index, text);
                    }}
                  />
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'throughDate' && (
                <div>
                  {value === 'N/A' ? (
                    <div>{value}</div>
                  ) : (
                    <AppDatePicker
                      errorText={
                        errorValues.includes(column.name) ? `${column.name} is required` : ''
                      }
                      selectedDate={value !== '' ? new Date((data as any)[column.id]) : null}
                      onDateSelected={(date) => {
                        if (date) {
                          handleChangeValue(columnKey, index, moment(date).format('MM/DD/YYYY'));
                        }
                      }}
                    />
                  )}
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'dueDate' && (
                <div>
                  {value === 'N/A' ? (
                    <div>{value}</div>
                  ) : (
                    <AppDatePicker
                      errorText={
                        errorValues.includes(column.name) ? `${column.name} is required` : ''
                      }
                      selectedDate={
                        value !== '' && value !== 'Invalid date'
                          ? new Date((data as any)[column.id])
                          : null
                      }
                      onDateSelected={(date) => {
                        if (date) {
                          handleChangeValue(columnKey, index, moment(date).format('MM/DD/YYYY'));
                        }
                      }}
                    />
                  )}
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'action' && (
                <div className="flex items-center justify-center w-full">
                  <AppButton
                    type="TERTIARY"
                    buttonStyles={{
                      padding: '8px',
                      margin: '5px 8px',
                    }}
                    icon={<Eye color={COLORS.GREY_500} size={20} />}
                    onClick={() => {
                      setPreviewLoading(index);
                      handlePreview(index, data);
                    }}
                    isLoading={previewLoading === index}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex w-[80vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <h2 className="text-lg font-semibold mx-4 my-3">Bulk Request Release</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleCloseModal}
        />
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className="mx-4 mt-3 mb-2">
          <div className="flex flex-col items-center w-full PRIMARY_50-BG justify-center p-4">
            <div className="flex items-center w-full mb-2">
              <div className="flex w-full my-2 font-medium text-sm">
                Select the releases you want to request
              </div>
            </div>
            <div className="flex w-full flex-col min-h-[40vh]  max-h-[55vh] overflow-y-scroll border WHITE-BG rounded-xl">
              {processedInvoices.length > 0 && (
                <div className="flex border-b px-5 items-center w-full sticky top-0 PRIMARY_50-BG py-3 z-20">
                  {columns.map((column) => {
                    return (
                      <div
                        key={column.id}
                        style={{ flex: column.width / totalWidth }}
                        className="flex w-full">
                        {column.cellType === 'SELECT' && column.id === 'select' && (
                          <AppCheckBox
                            isChecked={selected.length === processedInvoices.length}
                            onClick={(isChecked: boolean) => {
                              handleSelectAll(isChecked);
                            }}
                          />
                        )}
                        {column.cellType !== 'SELECT' && column.id !== 'select' && (
                          <p className="TEXT_SECONDARY-CLR text-xs w-full text-left">
                            {column.name}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {processedInvoices.length === 0 && (
                <div className="flex w-full h-[50vh] items-center justify-center font-semibold TEXT_SECONDARY-CLR">
                  {`No releases to be requested for this month`}
                </div>
              )}
              <div>
                {processedInvoices.length > 0 && (
                  <div className="h-[50vh]">
                    <AppVirtualizedList
                      itemsLength={processedInvoices.length}
                      itemHeight={70}
                      containerHeight={50}
                      renderRow={renderRow}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Cancel"
            type="TERTIARY"
            onClick={handleCloseModal}
            buttonStyles={{ marginRight: '8px' }}
          />
          <AppButton
            text="Request"
            onClick={handleBulkSendReleases}
            isDisabled={selected.length === 0}
            buttonStyles={{
              width: '100px',
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};
