import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createJob,
  getJobs,
  updateJob,
  getJob,
  updateBillingRepJobs,
  bulkUpdateBuyerJobs,
} from '../services/jobsService';
import { JobsInitialState } from '../globalSlices/jobsSlice';

export const createJobsExtraReducer = (builder: ActionReducerMapBuilder<JobsInitialState>) => {
  const reducerId = 'createJob';
  builder.addCase(createJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(createJob.fulfilled, (state, action: PayloadAction<AppServerResponse | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload && action.payload.success && action.payload?.data) {
      state.jobs = [...new Set([...state.jobs, action.payload.data])];
    }
  });
  builder.addCase(createJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getJobsExtraReducer = (builder: ActionReducerMapBuilder<JobsInitialState>) => {
  const reducerId = 'getJobs';
  builder.addCase(getJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(getJobs.fulfilled, (state, action: PayloadAction<AppServerResponse | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload && action.payload.success && action.payload?.data) {
      state.jobs = action.payload?.data;
    }
  });
  builder.addCase(getJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getJobs: action.payload }]),
      ];
    }
  });
};

export const updateJobExtraReducer = (builder: ActionReducerMapBuilder<JobsInitialState>) => {
  const reducerId = 'updateJob';
  builder.addCase(updateJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(updateJob.fulfilled, (state, action: PayloadAction<AppServerResponse | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(updateJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const bulkUpdateBuyerJobsExtraReducer = (
  builder: ActionReducerMapBuilder<JobsInitialState>
) => {
  const reducerId = 'bulkUpdateBuyerJobs';
  builder.addCase(bulkUpdateBuyerJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    bulkUpdateBuyerJobs.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(bulkUpdateBuyerJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const updateBillingRepJobsExtraReducer = (
  builder: ActionReducerMapBuilder<JobsInitialState>
) => {
  const reducerId = 'updateBillingRepJobs';
  builder.addCase(updateBillingRepJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateBillingRepJobs.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateBillingRepJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getJobExtraReducer = (builder: ActionReducerMapBuilder<JobsInitialState>) => {
  const reducerId = 'getJob';
  builder.addCase(getJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(getJob.fulfilled, (state, action: PayloadAction<AppServerResponse | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload && action.payload.success && action.payload.data) {
      state.selectedJob = action.payload.data;
    }
  });
  builder.addCase(getJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
