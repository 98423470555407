import React from 'react';
import successChecksCircle from '../../assets/svg/success_checks_circle.svg';
import { Warning } from 'phosphor-react';
import { COLORS } from '../../utils/colors';

const JobSavingSuccess = ({
  type,
  title,
  details,
}: {
  type: 'Success' | 'Error';
  title: string;
  details: string;
}) => {
  return (
    <div>
      {type === 'Success' && (
        <div className="flex flex-col WHITE-BG w-full mt-4 rounded-lg p-6 items-center justify-center">
          <img src={successChecksCircle} alt="" />
          <div className="mt-4 text-2xl font-semibold">{title}</div>
          <div className="mt-4 text-xl text-center mx-5">{details}</div>
        </div>
      )}
      {type === 'Error' && (
        <div className="flex flex-col WHITE-BG w-full mt-4 rounded-lg p-6 items-center justify-center">
          <div className="flex flex-col w-fit  ERROR_100-BG justify-center items-center rounded-full p-6">
            <Warning size={60} weight="bold" color={COLORS.ERROR_500} />
          </div>
          <div className="mt-4 text-2xl font-semibold">{title}</div>
          <div className="mt-4 text-xl text-center mx-5">{details}</div>
        </div>
      )}
    </div>
  );
};

export default JobSavingSuccess;
