import { Modal } from '@mui/material';
import { X } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../../utils/colors';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { AppButton } from '../../../general/AppButton/AppButton';
import { Buyer, BuyerUpdate } from '@dill/dill-shared';
import { useAppDispatch } from '../../../../redux/store';
import { updateSupplierBuyer } from '../../../../redux/services/supplierBuyersService';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';

function EditBuyerModal({
  open,
  buyer,
  handleClose = () => {},
}: {
  open: boolean;
  buyer?: Buyer | null;
  handleClose: (editSuccess?: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const [buyerName, setBuyerName] = useState('');
  const [buyerCity, setBuyerCity] = useState('');
  const [buyerState, setBuyerState] = useState('');
  const [buyerZipCode, setBuyerZipCode] = useState('');
  const [buyerAddress, setBuyerAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    buyerName: '',
    buyerAddress: '',
    buyerCity: '',
    buyerState: '',
    buyerZipCode: '',
  });
  useEffect(() => {
    if (open) {
      setErrors({
        buyerName: '',
        buyerAddress: '',
        buyerCity: '',
        buyerState: '',
        buyerZipCode: '',
      });
      setIsLoading(false);
    }
    return () => {};
  }, [open]);
  useEffect(() => {
    if (buyer) {
      setBuyerName(buyer?.name || '');
      setBuyerAddress(buyer?.address || '');
      setBuyerCity(buyer?.city || '');
      setBuyerState(buyer?.state || '');
      setBuyerZipCode(buyer?.zipCode || '');
    }
    return () => {};
  }, [buyer]);
  const validateInputs = () => {
    const newErrors = {
      buyerName: '',
      buyerAddress: '',
      buyerCity: '',
      buyerState: '',
      buyerZipCode: '',
    };

    let isValid = true;

    if (!buyerName) {
      newErrors.buyerName = 'This field is required.';
      isValid = false;
    }
    if (!buyerAddress) {
      newErrors.buyerAddress = 'This field is required.';
      isValid = false;
    }
    if (!buyerCity) {
      newErrors.buyerCity = 'This field is required.';
      isValid = false;
    }
    if (!buyerState) {
      newErrors.buyerState = 'This field is required.';
      isValid = false;
    }
    if (!buyerZipCode) {
      newErrors.buyerZipCode = 'This field is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleEditBuyer = async () => {
    if (!validateInputs()) {
      return;
    }

    let detailsToUpdate: BuyerUpdate = { name: buyerName };
    if (buyerAddress) {
      detailsToUpdate = { ...detailsToUpdate, address: buyerAddress };
    }
    if (buyerCity) {
      detailsToUpdate = { ...detailsToUpdate, city: buyerCity };
    }
    if (buyerState) {
      detailsToUpdate = { ...detailsToUpdate, state: buyerState };
    }
    if (buyerZipCode) {
      detailsToUpdate = { ...detailsToUpdate, zipCode: buyerZipCode };
    }
    setIsLoading(true);
    const resp = await dispatch(
      updateSupplierBuyer({
        supplierBuyerId: buyer?.id ?? '',
        supplierBuyerDetails: { ...detailsToUpdate },
      })
    );
    setIsLoading(false);
    if (resp.type === 'supplierBuyers/updateSupplierBuyer/fulfilled') {
      handleClose(true);
    } else {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Error deleting buyer',
          onClose: () => {
            handleClose();
          },
        })
      );
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-40 w-[40vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col px-5 py-1">
        <div className="sticky top-0 z-10 bg-white py-2">
          <X
            size={20}
            className="absolute right-0 top-3 cursor-pointer"
            color={COLORS.GREY_500}
            onClick={() => {
              handleClose();
            }}
          />
          <h3 className="text-lg font-bold ">Edit Customer/Buyer</h3>
        </div>

        <div className="w-full my-1.5">
          <AppInputField
            label="Name"
            placeholder=""
            isRequired={true}
            value={buyerName}
            errorText={errors.buyerName ? errors.buyerName : ''}
            onTextChange={(text) => {
              setBuyerName(text);
            }}
          />
        </div>
        <div className="w-full my-1.5">
          <AppInputField
            label="Address"
            placeholder=""
            value={buyerAddress}
            isRequired={true}
            errorText={errors.buyerAddress ? errors.buyerAddress : ''}
            onTextChange={(text) => {
              setBuyerAddress(text);
            }}
          />
        </div>
        <div className="flex flex-row justify-around gap-2 my-1.5">
          <div className="w-full">
            <AppInputField
              label="City"
              value={buyerCity}
              isRequired={true}
              errorText={errors.buyerCity ? errors.buyerCity : ''}
              onTextChange={(text) => {
                setBuyerCity(text);
              }}
            />
          </div>
          <div className="spacer" />
          <div className="w-full">
            <AppInputField
              label="State"
              value={buyerState}
              isRequired={true}
              errorText={errors.buyerState ? errors.buyerState : ''}
              onTextChange={(text) => {
                setBuyerState(text);
              }}
            />
          </div>
          <div className="spacer" />
          <div className="w-full">
            <AppInputField
              label="Zip Code"
              value={buyerZipCode}
              isRequired={true}
              errorText={errors.buyerZipCode ? errors.buyerZipCode : ''}
              onTextChange={(text) => {
                setBuyerZipCode(text);
              }}
            />
          </div>
        </div>

        <div className="flex flex-row self-end   mt-6">
          <AppButton
            text="Close"
            type="TERTIARY"
            isDisabled={isLoading}
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
              marginRight: '12px',
              height: '40px',
            }}
            onClick={handleClose}
          />
          <AppButton
            text="Edit"
            onClick={handleEditBuyer}
            isLoading={isLoading}
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
              width: '100px',
              height: '40px',
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default EditBuyerModal;
