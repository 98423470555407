import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { RootState } from '../store';

export const getGroupedBuyersSuppliers = createAsyncThunk(
  'buyers/getGroupedBuyersSuppliers',
  async ({ mainBuyerId, weeksLimit }: { mainBuyerId: string; weeksLimit: number }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let url = `${BASE_API_URL}/mainBuyers/${mainBuyerId}/buyers/getSuppliersForBuyer`;
      if (weeksLimit) {
        url = `${BASE_API_URL}/mainBuyers/${mainBuyerId}/buyers/getSuppliersForBuyer?weeksLimit=${weeksLimit}`;
      }
      const response = await axios.get<AppServerResponse>(url, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getBasicSupplier = createAsyncThunk(
  'buyers/getBasicSupplier',
  async ({ supplierDomainId }: { supplierDomainId: string }, thunkApi) => {
    try {
      if (!supplierDomainId) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/suppliers/getBasicSupplier/${supplierDomainId}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getInvoicePdf = createAsyncThunk(
  'buyers/getInvoicePdf',
  async (
    {
      supplierDomainId,
      invoiceId,
      supplierId,
      preview = false,
      print = false, // Added print option
    }: {
      supplierDomainId?: string;
      supplierId?: string;
      invoiceId: string;
      preview?: boolean;
      print?: boolean;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/invoicesAPI/invoicePdf/?invoiceId=${invoiceId}${
          supplierDomainId ? `&supplierDomainId=${supplierDomainId}` : ''
        }${supplierId ? `&supplierId=${supplierId}` : ''}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const file = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(file);
      if (print) {
        // Open the PDF in a new window and trigger the print dialog
        const pdfWindow = window.open(link.href);
        if (pdfWindow) {
          pdfWindow.onload = () => {
            pdfWindow.print();
          };
        }
      } else if (!preview) {
        link.download = `invoice-${invoiceId}-${+new Date()}.pdf`;
        link.click();
      }

      return { success: true, data: { pdfUrl: link.href } };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getDocumentPdf = createAsyncThunk(
  'buyers/getDocumentPdf',
  async (
    {
      supplierDomainId,
      id,
      type,
      supplierId,
      preview = false,
      print = false, // Added print option
    }: {
      supplierDomainId?: string;
      supplierId?: string;
      id: string;
      type: 'INVOICE' | 'CREDIT_MEMO';
      preview?: boolean;
      print?: boolean;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/invoicesAPI/documentPdf`,

        {
          params: {
            supplierDomainId,
            id,
            type,
            supplierId,
          },
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const file = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(file);
      if (print) {
        // Open the PDF in a new window and trigger the print dialog
        const pdfWindow = window.open(link.href);
        if (pdfWindow) {
          pdfWindow.onload = () => {
            pdfWindow.print();
          };
        }
      } else if (!preview) {
        link.download = `${type}-${id}-${+new Date()}.pdf`;
        link.click();
      }

      return { success: true, data: { pdfUrl: link.href } };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const downloadMultiInvoicePdfZip = createAsyncThunk(
  'buyers/downloadMultiInvoicePdfZip',
  async (
    {
      supplierDomainId,
      supplierId,
      invoiceIds,
      combined = false,
    }: { supplierDomainId?: string; supplierId?: string; invoiceIds: string[]; combined?: boolean },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/invoicesAPI/multiInvoicePdf`,
        {
          invoiceIds: invoiceIds,
          ...(supplierDomainId ? { supplierDomainId } : {}),
          ...(supplierId ? { supplierId } : {}),
          combined,
        },
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (!combined) {
        const file = new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `zip-${invoiceIds.length}-${supplierDomainId || supplierId}`;
        link.click();
      } else {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.download = `invoice-${supplierDomainId || supplierId}-${new Date().getTime()}.pdf`;

        link.href = window.URL.createObjectURL(file);
        link.click();
      }

      return { success: true, data: {} };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const downloadInvoicePdfReceipt = createAsyncThunk(
  'buyers/downloadInvoicePdfReceipt',
  async (
    {
      supplierId,
      transactionId,
      preview = false,
      supplierDomainId,
    }: { supplierId?: string; transactionId: string; preview?: boolean; supplierDomainId?: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(`${BASE_API_URL}/invoicesAPI/receiptPdf`, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params: { supplierId, transactionId, supplierDomainId },
      });
      const file = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(file);
      if (!preview) {
        link.download = `receipt-${transactionId}.pdf`;
        link.click();
      }

      return { success: true, data: { pdfUrl: link.href } };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getMainBuyer = createAsyncThunk(
  'buyers/getMainBuyer',
  async ({ mainBuyerId }: { mainBuyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      console.log('token', token);
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const addSendgridApiKeyToMainBuyer = createAsyncThunk(
  'buyers/addSendgridApiKeyToMainBuyer',
  async (
    { mainBuyerId, sendgridApiKey }: { mainBuyerId: string; sendgridApiKey: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      console.log('token', token);
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/addSendGridKey`,
        { sendgridApiKey },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
