import { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  disableIsDirectToInvoices,
  updateBuyerSupplierInvoicesDataWeeksLimit,
} from '../../../../redux/globalSlices/buyersSlice';
import { getGroupedBuyersSuppliers } from '../../../../redux/services/buyersService';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { SupplierForBuyer, roundTo } from '@dill/dill-shared';
import { AppButton } from '../../../general/AppButton/AppButton';
import distributorPlaceholder from '../../../../assets/svg/distributor_placeholder.svg';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { updateSelectedSupplierForBuyer } from '../../../../redux/globalSlices/invoiceSlice';
import { getMainBuyerPaymentMethods } from '../../../../redux/services/paymentsService';
import AppAddPaymentMethodModal from '../../../general/AppAddPaymentMethodModal/AppAddPaymentMethodModal';
import { useParamMainBuyerId } from '../../../../utils/hooks/useParamMainBuyerId';

export const Suppliers = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const {
    selectedBuyerSuppliers,
    isDirectToInvoices,
    loadingList: buyersLoadingList,
    buyerSupplierInvoicesDataWeeksLimit,
  } = useAppSelector((state) => state.buyers);
  const { user } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);

  const [suppliers, setSuppliers] = useState<SupplierForBuyer[]>([]);
  const [openAutopayModal, setOpenAutopayModal] = useState<boolean>(false);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [supplierName, setSupplierName] = useState('');
  const [supplierId, setSupplierId] = useState('');
  const [buyerPaymentMethodId, setBuyerPaymentMethodId] = useState('');
  const [updating, setUpdating] = useState<boolean>(false);
  const [openPaymentMethod, setOpenPaymentMethod] = useState<boolean>(false);
  const mainRoles = user?.roles.filter((role) => role !== 'DILL_ADMIN');

  useEffect(() => {
    dispatch(updateSelectedSupplierForBuyer(null));

    return () => {};
  }, []);
  useEffect(() => {
    setSuppliers(selectedBuyerSuppliers);

    return () => {};
  }, [selectedBuyerSuppliers]);

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(updateBuyerSupplierInvoicesDataWeeksLimit(2));
      dispatch(
        getGroupedBuyersSuppliers({
          mainBuyerId: selectedMainBuyerId,
          weeksLimit: 2,
        })
      );
    }

    return () => {};
  }, [selectedMainBuyerId]);

  useEffect(() => {
    if (selectedMainBuyerId && user?.userMainBuyers) {
      const mainBuyer = user?.userMainBuyers.find((mb) => mb.id === selectedMainBuyerId);
      if (!mainBuyer) {
        navigate('/companies');
      }
    }
  }, [user?.userMainBuyers]);

  useEffect(() => {
    if (
      suppliers &&
      suppliers.length === 1 &&
      params.restaurantGroupId &&
      location.pathname.startsWith(`/companies/${selectedMainBuyerId}/invoices/`) &&
      isDirectToInvoices
    ) {
      navigate(`/companies/${selectedMainBuyerId}/invoices/${suppliers[0].domainId}`);
      dispatch(disableIsDirectToInvoices());
    }

    return () => {};
  }, [suppliers]);

  const handleSaveAutopay = async (autopay: boolean, paymentMethodId: string) => {
    // setUpdating(true);
    // const buyerSelected = selectedBuyersGroup?.buyers.find(
    //   (buyer) => buyer.supplierId === supplierId
    // );
    // await dispatch(
    //   updateBuyer({
    //     buyerId: buyerSelected?.id ?? '',
    //     buyerDetails: {
    //       autopayDetails: {
    //         paymentMethodId: paymentMethodId,
    //         userId: user?.id ?? '',
    //       },
    //       isAutopayActive: autopay,
    //     },
    //   })
    // );
    // setUpdating(false);
    // setOpenAutopayModal(false);
  };

  const isAutoPaySelected = useMemo(() => {
    // const buyerSelected = selectedBuyersGroup?.buyers.find(
    //   (buyer) => buyer.supplierId === supplierId
    // );
    // if (buyerSelected && buyerSelected?.isAutopayActive) {
    //   setBuyerPaymentMethodId(buyerSelected.autopayDetails?.paymentMethodId ?? '');
    //   return buyerSelected.isAutopayActive;
    // }
    return false;
  }, [supplierId, selectedMainBuyer]);

  return (
    <div className="h-full w-full ">
      {/* <AppAutopayModal
        open={openAutopayModal}
        handleClose={() => setOpenAutopayModal(false)}
        supplierName={supplierName}
        buyerName={selectedBuyersGroup?.groupName.toLowerCase() ?? ''}
        onSaveAutopay={handleSaveAutopay}
        isAutoPaySelected={isAutoPaySelected}
        buyerPaymentMethodId={buyerPaymentMethodId}
        updating={updating}
        onAddNewPayment={() => setOpenPaymentMethod(true)}
      /> */}
      <AppAddPaymentMethodModal
        open={openPaymentMethod}
        handleClose={() => setOpenPaymentMethod(false)}
        paymentOptionsToShow="BANK"
        userType="buyer"
      />
      {suppliers.length > 0 && (
        <div className="w-full h-full overflow-y-scroll pb-3">
          <div className="flex flex-wrap">
            {suppliers.length > 0 &&
              suppliers.map((supplier, supplierIndex) => {
                // const buyerMapped = selectedBuyersGroup?.buyers.find(
                //   (buyer) => buyer.supplierId === supplier.id
                // );
                // const isAutopayActive = buyerMapped?.isAutopayActive ?? false;
                return (
                  <div
                    key={supplierIndex + 'oo'}
                    style={{}}
                    className="w-72 min-h-max rounded-2xl flex flex-col WHITE-BG py-6 px-6 items-center m-2">
                    <div
                      className={
                        'GREY_100-BG  rounded-lg flex items-center ' +
                        (supplier?.logo?.url ? 'w-full' : '')
                      }>
                      {!supplier.logo && <img className="m-4" src={distributorPlaceholder} />}
                      {supplier?.logo?.url && (
                        <img className="h-9 m-2 w-full object-contain" src={supplier?.logo?.url} />
                      )}
                    </div>

                    <p className="font-bold text-xl mb-4 mt-2">{supplier.name}</p>
                    <div className="w-full my-2 flex flex-row justify-between">
                      <p className="text-sm">Past Due</p>
                      <p className="text-sm TEXT_SECONDARY-CLR">{`$${roundTo(
                        parseFloat(
                          supplier.invoices?.pastDueInvoices.totalAmount?.toString() || '0'
                        ),
                        2
                      )}`}</p>
                    </div>
                    <div className="w-full my-2 flex flex-row justify-between">
                      <p className="text-sm">Current Due</p>
                      <p className="text-sm TEXT_SECONDARY-CLR">{`$${roundTo(
                        parseFloat(
                          supplier.invoices?.currentInvoices?.totalAmount?.toString() || '0'
                        ),
                        2
                      )}`}</p>
                    </div>
                    {/* <div className="w-full my-2 flex flex-row justify-between">
                      <p className="text-sm">Credits</p>
                      <p className="text-sm TEXT_SECONDARY-CLR">{`$${roundTo(
                        parseFloat(
                          supplier.invoices?.creditMemosDetails.totalRemainingCredit?.toString() ||
                            '0'
                        ),
                        2
                      )}`}</p>
                    </div> */}
                    <div style={{ height: '1px' }} className=" GREY_200-BG w-full mt-3"></div>
                    <div className="mt-6 w-full">
                      <AppButton
                        buttonStyles={{ width: '100%' }}
                        text="View Invoices"
                        type="PRIMARY"
                        onClick={() => {
                          dispatch(updateSelectedSupplierForBuyer(supplier));
                          navigate(
                            `/companies/${selectedMainBuyerId}/invoices/${supplier.domainId}`
                          );
                        }}
                      />
                      {/* Uncomment to show autopa button */}
                      {/* <button
                        onClick={() => {
                          setOpenAutopayModal(true);
                          setSupplierName(supplier.name ?? '');
                          setSupplierId(supplier.id);
                        }}
                        className={`w-full flex justify-between p-3 rounded-lg mt-3 items-center ${
                          isAutopayActive ? 'PRIMARY_50-BG' : 'WARNING_100-BG'
                        }`}>
                        <div className="flex align-center justify-center items-center">
                          <ArrowClockwise
                            size={24}
                            color={isAutopayActive ? COLORS.PRIMARY_500 : COLORS.WARNING_600}
                            style={{
                              backgroundColor: isAutopayActive ? '#E8EDFF' : COLORS.WARNING_300,
                              padding: 4,
                              borderRadius: 4,
                              marginRight: 8,
                            }}
                          />
                          <div className="text-black text-sm font-medium">Auto Pay</div>
                        </div>
                        <div className="flex align-center justify-center items-center">
                          <div className="text-sm font-medium mr-3 text-slate-500">
                            {isAutopayActive ? 'Active' : 'Inactive'}
                          </div>
                          <CaretRight size={20} color={COLORS.SECONDARY_500} />
                        </div>
                      </button> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {suppliers.length < 1 && (
        <div className="h-full w-full flex flex-col WHITE-BG items-center justify-center">
          <img className="w-1/2" src={emptyListImg} alt="" />
          <p className="font-semibold w-1/2 text-center">
            Your suppliers have not yet signed up for Dill invoicing & payments. If you would like
            to get automated lien waivers with payments, get your suppliers on Dill by reaching out
            to customer support.
            {/* <span className="PRIMARY_500-CLR font-bold whitespace-nowrap">972-339-0799</span> */}
          </p>
        </div>
      )}
    </div>
  );
};
