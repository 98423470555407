import { createSlice } from '@reduxjs/toolkit';
import {
  createRequestSupplierJobsExtraReducer,
  deleteRequestedSupplierJobExtraReducer,
  generateRequestJobInformationLinkExtraReducer,
  getRequestedSupplierJobExtraReducer,
  getRequestSupplierJobsExtraReducer,
  updateRequestedSupplierJobExtraReducer,
} from '../reducers/requestJobInformationReducers';
import { RequestedSupplierJobApiResponse } from '@dill/dill-shared';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface RequestJobInformationInitialState {
  loadingList: string[];
  selectedRequestedSupplierJob: RequestedSupplierJobApiResponse | null;
  requestedSupplierJobs: RequestedSupplierJobApiResponse[];
  selectedRequestedSupplierJobs: RequestedSupplierJobApiResponse[];
}

const initialState: RequestJobInformationInitialState = {
  loadingList: [],
  selectedRequestedSupplierJob: null,
  requestedSupplierJobs: [],
  selectedRequestedSupplierJobs: [],
};

export const requestJobInformationSlice = createSlice({
  name: 'requestJobInformation',
  initialState,
  reducers: {
    resetRequestedSupplierJobsRootState: () => initialState,
    selectRequestedSupplierJobs: (state, action: PayloadAction<any[]>) => {
      const ii = [...state.selectedRequestedSupplierJobs.map((iu) => ({ ...iu }))];
      const filteredRequestedSupplierJobs = ii.filter(
        (requestedSupplierJob) =>
          !action.payload.map((yy: any) => yy.id).includes(requestedSupplierJob.id)
      );
      state.selectedRequestedSupplierJobs = [...filteredRequestedSupplierJobs, ...action.payload];
    },
    unSelectRequestedSupplierJobs: (state, action: PayloadAction<any[]>) => {
      const filteredRequestedSupplierJobs = state.selectedRequestedSupplierJobs.filter(
        (requestedSupplierJob) =>
          !action.payload.map((yy: any) => yy.id).includes(requestedSupplierJob.id)
      );
      state.selectedRequestedSupplierJobs = [...filteredRequestedSupplierJobs];
    },
    resetRequestedSupplierJobs: (state) => {
      state.selectedRequestedSupplierJobs = [];
      state.requestedSupplierJobs = [];
    },
  },
  extraReducers(builder) {
    generateRequestJobInformationLinkExtraReducer(builder);
    createRequestSupplierJobsExtraReducer(builder);
    getRequestSupplierJobsExtraReducer(builder);
    updateRequestedSupplierJobExtraReducer(builder);
    getRequestedSupplierJobExtraReducer(builder);
    deleteRequestedSupplierJobExtraReducer(builder);
  },
});

export const {
  resetRequestedSupplierJobsRootState,
  selectRequestedSupplierJobs,
  unSelectRequestedSupplierJobs,
  resetRequestedSupplierJobs,
} = requestJobInformationSlice.actions;
