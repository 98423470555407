import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authSlice } from './globalSlices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
// import { contactSlice } from './globalSlices/contactSlice';
// import { companySlice } from './globalSlices/companySlice';
// import { priceListSlice } from './globalSlices/priceListSlice';
import { invoiceSlice } from './globalSlices/invoiceSlice';
import { buyersSlice } from './globalSlices/buyersSlice';
import { suppliersSlice } from './globalSlices/suppliersSlice';
import { paymentsSlice } from './globalSlices/paymentsSlice';
import { salesReceiptsSlice } from './globalSlices/salesReceiptsSlice';
import { lienTemplatesSlice } from './globalSlices/lienTemplatesSlice';
import { subSuppliersSlice } from './globalSlices/subSuppliersSlice';
import { jobsSlice } from './globalSlices/jobsSlice';
import { liensSlice } from './globalSlices/liensSlice';
import { genericSlice } from './globalSlices/genericSlice';
import { supplierJobsSlice } from './globalSlices/supplierJobsSlice';
import { supplierBuyersSlice } from './globalSlices/supplierBuyersSlice';
import { noticeTemplatesSlice } from './globalSlices/noticeTemplatesSlice';
import { preliminaryNoticesSlice } from './globalSlices/preliminaryNoticesSlice';
import { manualUploadSlice } from './globalSlices/manualUploadSlice';
import { APP_ENVIRONMENT } from '../utils/constants';
import { inforDistSXSlice } from './globalSlices/inforDistSX';
import { distributionOneSlice } from './globalSlices/distributionOneSlice';
import { sage100Slice } from './globalSlices/sage100Slice';
import { p21Slice } from './globalSlices/p21Slice';
import { supplierReleasesSlice } from './globalSlices/supplierReleasesSlice';
import { requestJobInformationSlice } from './globalSlices/requestJobInformationSlice';

// const reducers = combineReducers({});

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    // contact: contactSlice.reducer,
    // company: companySlice.reducer,
    // priceList: priceListSlice.reducer,
    invoice: invoiceSlice.reducer,
    buyers: buyersSlice.reducer,
    supplier: suppliersSlice.reducer,
    payments: paymentsSlice.reducer,
    salesReceipts: salesReceiptsSlice.reducer,
    lienTemplates: lienTemplatesSlice.reducer,
    subSuppliers: subSuppliersSlice.reducer,
    jobs: jobsSlice.reducer,
    liens: liensSlice.reducer,
    generic: genericSlice.reducer,
    supplierJobs: supplierJobsSlice.reducer,
    supplierBuyers: supplierBuyersSlice.reducer,
    noticeTemplates: noticeTemplatesSlice.reducer,
    preliminaryNotices: preliminaryNoticesSlice.reducer,
    manualUpload: manualUploadSlice.reducer,
    inforDistSX: inforDistSXSlice.reducer,
    distributionOne: distributionOneSlice.reducer,
    sage100: sage100Slice.reducer,
    p21: p21Slice.reducer,
    supplierReleases: supplierReleasesSlice.reducer,
    requestJobInformation: requestJobInformationSlice.reducer,
  },
  devTools: APP_ENVIRONMENT !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
