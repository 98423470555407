import { useState, useEffect } from 'react';
import { Modal } from '@mui/material';

import { SupplierJobApiResponse, SupplierJobUpdate } from '@dill/dill-shared';

import { getSupplierBuyers } from '../../../redux/services/supplierBuyersService';

// import './AppRequestInfoJobModal.scss';

import { useAppDispatch, useAppSelector } from '../../../redux/store';

import RequestInfoSheetForm from './RequestInfoSheetForm';
import RequestJobInfoForm from './RequestJobInfoForm';

type PartialSupplierJobUpdatePick = Pick<
  SupplierJobUpdate,
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'owner'
  | 'ownerAddress'
  | 'ownerCity'
  | 'ownerState'
  | 'ownerZipCode'
  | 'GC'
  | 'GCAddress'
  | 'GCCity'
  | 'GCState'
  | 'GCZipCode'
  | 'buyerId'
  | 'lender'
  | 'lenderAddress'
  | 'lenderCity'
  | 'lenderState'
  | 'lenderZipCode'
  | 'constructionMonitorPermitId'
  | 'apn'
  | 'estimatedMaterialAmount'
  | 'jobType'
  | 'jobSector'
  | 'customerType'
  | 'jobFurnishings'
  | 'hasNoLender'
>;

type ModalState = 'REQUEST_JOB_INFO_SHEET' | 'ADD_JOB';

export const AppRequestInfoJobModal = ({
  open,
  handleClose,
  viewState = 'ADD_JOB',
  onReload,
  selectedJob = null,
}: {
  open: boolean;
  handleClose: (data: { wasBuyerEdited: boolean }) => void;
  viewState?: ModalState;
  onReload?: () => void;
  selectedJob?: SupplierJobApiResponse | null;
}) => {
  const INITIAL_STATE = {
    name: '',
    jobNumber: '',
    jobAddress: '',
    jobCity: '',
    jobState: '',
    jobZipCode: '',
    owner: '',
    ownerAddress: '',
    ownerCity: '',
    ownerState: '',
    ownerZipCode: '',
    GC: '',
    GCAddress: '',
    GCCity: '',
    GCState: '',
    GCZipCode: '',
    buyerId: '',
    lender: '',
    lenderAddress: '',
    lenderCity: '',
    lenderState: '',
    lenderZipCode: '',
    constructionMonitorPermitId: null,
    apn: '',
    estimatedMaterialAmount: 0,
    jobType: 'commercial',
    jobSector: 'private',
    customerType: 'SUB' as const,
    jobFurnishings: '',
    hasNoLender: false,
  } as PartialSupplierJobUpdatePick;

  const dispatch = useAppDispatch();

  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);
  const [requestJobInfoSheet, setRequestJobInfoSheet] = useState({
    ...INITIAL_STATE,
    buyerName: '',
    id: '',
    buyer: null,
    selectedSupplierJob: null,
  });

  const { user } = useAppSelector((state) => state.auth);

  const [modalState, setModalState] = useState<ModalState>(viewState);
  const [wasBuyerEdited, setWasBuyerEdited] = useState(false);

  useEffect(() => {
    if (viewState) {
      setModalState(viewState);
    }
    return () => {};
  }, [viewState]);

  useEffect(() => {
    if (open) {
      dispatch(getSupplierBuyers());
      setWasBuyerEdited(false);
      setModalState(viewState);
    }
  }, [open]);

  const handleCloseModal = () => {
    handleClose({ wasBuyerEdited });
    setSupplierJobInput(INITIAL_STATE);
    setModalState(viewState);
  };

  useEffect(() => {
    if (open && selectedJob && user && viewState === 'REQUEST_JOB_INFO_SHEET') {
      const data: any = {
        name: selectedJob.name || '',
        id: selectedJob.id ?? '',
        buyerId: selectedJob.buyerId || '',
        buyerName: selectedJob?.buyer?.name || '',
        jobNumber: selectedJob.jobNumber || '',
        jobAddress: selectedJob.jobAddress || '',
        jobCity: selectedJob.jobCity || '',
        jobState: selectedJob.jobState || '',
        jobZipCode: selectedJob.jobZipCode || '',
        buyer: selectedJob?.buyer || null,
        selectedSupplierJob: selectedJob || null,
      };
      setRequestJobInfoSheet(data);
      setModalState('REQUEST_JOB_INFO_SHEET');
    }
    return () => {};
  }, [selectedJob, open]);

  const handleRequestInfoSheet = (data: any) => {
    setRequestJobInfoSheet(data);
    setModalState('REQUEST_JOB_INFO_SHEET');
    if (onReload) {
      onReload();
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <>
        {modalState === 'ADD_JOB' && (
          <RequestJobInfoForm
            jobSiteData={supplierJobInput}
            onClose={handleCloseModal}
            onBuyerEdit={() => {
              setWasBuyerEdited(true);
            }}
            onRequestInfoSheet={handleRequestInfoSheet}
          />
        )}
        {modalState === 'REQUEST_JOB_INFO_SHEET' && (
          <RequestInfoSheetForm
            onClose={handleCloseModal}
            data={{
              name: requestJobInfoSheet.name || '',
              supplierId: user?.userSupplier?.id ?? '',
              domainId: user?.userSupplier?.domainId ?? '',
              jobId: requestJobInfoSheet.id ?? '',
              buyerId: requestJobInfoSheet.buyerId || '',
              buyerName: requestJobInfoSheet.buyerName || '',
              jobNumber: requestJobInfoSheet.jobNumber || '',
              jobAddress: requestJobInfoSheet.jobAddress || '',
              jobCity: requestJobInfoSheet.jobCity || '',
              jobState: requestJobInfoSheet.jobState || '',
              jobZipCode: requestJobInfoSheet.jobZipCode || '',
              buyer: requestJobInfoSheet?.buyer || null,
              selectedSupplierJob: requestJobInfoSheet?.selectedSupplierJob || null,
            }}
          />
        )}
      </>
    </Modal>
  );
};
