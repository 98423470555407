import React, { useEffect, useMemo, useRef, useState } from 'react';
import { X } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { VerifiedJobDataPick } from './SupplierVerifyJobForm';

const SupplierCompareJob = ({
  onClose,
  verifyJobData,
  originalJobData,
  onReviewData,
}: {
  onClose: () => void;
  verifyJobData: VerifiedJobDataPick;
  originalJobData: VerifiedJobDataPick;
  onReviewData: (data: VerifiedJobDataPick) => void;
}) => {
  const [formState, setFormState] = useState<any>({});

  const onChange2 = (data: { name: string; value: string | number }) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [data.name]: data.value,
    }));
  };

  const handleReviewData = () => {
    onReviewData({ ...originalJobData, ...formState });
  };

  useEffect(() => {
    const updatedState: Record<string, string> = {};
    Object.keys(verifyJobData).forEach((key) => {
      if (
        verifyJobData[key as keyof VerifiedJobDataPick] !==
          originalJobData[key as keyof VerifiedJobDataPick] &&
        verifyJobData[key as keyof VerifiedJobDataPick]?.toString().trim() !== ''
      ) {
        updatedState[key] = verifyJobData[key as keyof VerifiedJobDataPick] as string;
      }
    });

    setFormState(updatedState);
  }, [verifyJobData, originalJobData]);

  const Option = ({
    label,
    verifiedData,
    originalData,
    onSelect,
  }: {
    label: string;
    verifiedData: { value: string; name: string };
    originalData: { value: string; name: string };
    onSelect: (data: { name: string; value: string | number }) => void;
  }) => {
    const hideRadioButton = () => {
      return verifiedData.value !== originalData.value && verifiedData.value.trim() !== '';
    };
    return (
      <div className="p-3 w-full flex">
        <div className="text-xs mb-1 font-semibold w-2/4">{label}</div>
        <div className="flex  items-center w-1/4">
          {hideRadioButton() && (
            <div
              className="flex items-center mr-1 cursor-pointer"
              onClick={() => onSelect({ name: verifiedData.name, value: verifiedData.value })}>
              <div
                className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                  formState[verifiedData.name] === verifiedData.value
                    ? 'PRIMARY_500-BORDER'
                    : 'GREY_500-BORDER'
                }`}>
                <div
                  className={`flex items-center h-2 w-2 rounded-full ${
                    formState[verifiedData.name] === verifiedData.value ? 'PRIMARY_500-BG ' : ''
                  }`}></div>
              </div>
            </div>
          )}
          <div className="text-xs TEXT_SECONDARY-CLR ">{verifiedData.value}</div>
        </div>
        <div className="flex  items-center w-1/4">
          {hideRadioButton() && (
            <div
              className="flex items-center mr-1 cursor-pointer"
              onClick={() => onSelect({ name: originalData.name, value: originalData.value })}>
              <div
                className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                  formState[originalData.name] === originalData.value
                    ? 'PRIMARY_500-BORDER'
                    : 'GREY_500-BORDER'
                }`}>
                <div
                  className={`flex items-center h-2 w-2 rounded-full ${
                    formState[originalData.name] === originalData.value ? 'PRIMARY_500-BG ' : ''
                  }`}></div>
              </div>
            </div>
          )}
          <div className="text-xs TEXT_SECONDARY-CLR ">{originalData.value}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={`new-job-modal-content pt-3`}>
      <div className="new-job-modal-title-container">
        <h3 className="font-medium text-lg">{`Compare Data`}</h3>
        <AppButton
          type="FLAT"
          buttonStyles={{ padding: '0px', margin: '5px 2px' }}
          icon={<X color={COLORS.GREY_300} size={30} />}
          onClick={onClose}
        />
      </div>
      <div className="p-3">
        <div className="text-xs my-2">Please choose which data you want added</div>

        <div className="flex flex-col h-full  w-full border rounded-lg overflow-hidden">
          <div className="flex w-full h-full flex-col">
            <div className="flex border-b px-2 items-center w-full sticky top-0 GREY_50-BG py-3 z-10">
              <div className="flex w-2/4 text-xs TEXT_SECONDARY-CLR ">Name</div>
              <div className="flex w-1/4 text-xs TEXT_SECONDARY-CLR ">Verified</div>
              <div className="flex w-1/4 text-xs TEXT_SECONDARY-CLR ">Your Data</div>
            </div>
          </div>
          <div className="h-[calc(90vh-200px)] !overflow-y-auto">
            <Option
              label="Job Name"
              verifiedData={{ value: verifyJobData.name ?? '', name: 'name' }}
              originalData={{ value: originalJobData.name ?? '', name: 'name' }}
              onSelect={onChange2}
            />
            <Option
              label="Job Number"
              verifiedData={{ value: verifyJobData.jobNumber ?? '', name: 'jobNumber' }}
              originalData={{ value: originalJobData.jobNumber ?? '', name: 'jobNumber' }}
              onSelect={onChange2}
            />
            <Option
              label="Job Address"
              verifiedData={{ value: verifyJobData.jobAddress ?? '', name: 'jobAddress' }}
              originalData={{ value: originalJobData.jobAddress ?? '', name: 'jobAddress' }}
              onSelect={onChange2}
            />
            <Option
              label="Job City"
              verifiedData={{ value: verifyJobData.jobCity ?? '', name: 'jobCity' }}
              originalData={{ value: originalJobData.jobCity ?? '', name: 'jobCity' }}
              onSelect={onChange2}
            />
            <Option
              label="Job State"
              verifiedData={{ value: verifyJobData.jobState ?? '', name: 'jobState' }}
              originalData={{ value: originalJobData.jobState ?? '', name: 'jobState' }}
              onSelect={onChange2}
            />
            <Option
              label="Job Type"
              verifiedData={{ value: verifyJobData.jobType ?? '', name: 'jobType' }}
              originalData={{ value: originalJobData.jobType ?? '', name: 'jobType' }}
              onSelect={onChange2}
            />
            <Option
              label="Property APN"
              verifiedData={{ value: verifyJobData.apn ?? '', name: 'apn' }}
              originalData={{ value: originalJobData.apn ?? '', name: 'apn' }}
              onSelect={onChange2}
            />
            <Option
              label="Owner"
              verifiedData={{ value: verifyJobData.owner ?? '', name: 'owner' }}
              originalData={{ value: originalJobData.owner ?? '', name: 'owner' }}
              onSelect={onChange2}
            />
            <Option
              label="Owner Address"
              verifiedData={{ value: verifyJobData.ownerAddress ?? '', name: 'ownerAddress' }}
              originalData={{ value: originalJobData.ownerAddress ?? '', name: 'ownerAddress' }}
              onSelect={onChange2}
            />
            <Option
              label="Owner City"
              verifiedData={{ value: verifyJobData.ownerCity ?? '', name: 'ownerCity' }}
              originalData={{ value: originalJobData.ownerCity ?? '', name: 'ownerCity' }}
              onSelect={onChange2}
            />
            <Option
              label="Owner State"
              verifiedData={{ value: verifyJobData.ownerState ?? '', name: 'ownerState' }}
              originalData={{ value: originalJobData.ownerState ?? '', name: 'ownerState' }}
              onSelect={onChange2}
            />
            <Option
              label="Owner Zip Code"
              verifiedData={{ value: verifyJobData.ownerZipCode ?? '', name: 'ownerZipCode' }}
              originalData={{ value: originalJobData.ownerZipCode ?? '', name: 'ownerZipCode' }}
              onSelect={onChange2}
            />
            <Option
              label="GC"
              verifiedData={{ value: verifyJobData.GC ?? '', name: 'GC' }}
              originalData={{ value: originalJobData.GC ?? '', name: 'GC' }}
              onSelect={onChange2}
            />
            <Option
              label="GC Address"
              verifiedData={{ value: verifyJobData.GCAddress ?? '', name: 'GCAddress' }}
              originalData={{ value: originalJobData.GCAddress ?? '', name: 'GCAddress' }}
              onSelect={onChange2}
            />
            <Option
              label="GC City"
              verifiedData={{ value: verifyJobData.GCCity ?? '', name: 'GCCity' }}
              originalData={{ value: originalJobData.GCCity ?? '', name: 'GCCity' }}
              onSelect={onChange2}
            />
            <Option
              label="GC State"
              verifiedData={{ value: verifyJobData.GCState ?? '', name: 'GCState' }}
              originalData={{ value: originalJobData.GCState ?? '', name: 'GCState' }}
              onSelect={onChange2}
            />
            <Option
              label="GC Zip Code"
              verifiedData={{ value: verifyJobData.GCZipCode ?? '', name: 'GCZipCode' }}
              originalData={{ value: originalJobData.GCZipCode ?? '', name: 'GCZipCode' }}
              onSelect={onChange2}
            />
          </div>
        </div>
      </div>

      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
      <div className="flex flex-row self-end px-6 gap-3 mb-2">
        <AppButton text={'Close'} type="TERTIARY" onClick={onClose} />
        <AppButton text="Review" type="PRIMARY" onClick={handleReviewData} />
      </div>
    </div>
  );
};

export default SupplierCompareJob;
