import { Modal } from '@mui/material';
import React from 'react';
import { AppButton } from '../AppButton/AppButton';
import './AppSignSuccessModal.scss';
import { COLORS } from '../../../utils/colors';
import { Checks } from 'phosphor-react';
import { LienApiResponse, formatMoney } from '@dill/dill-shared';
import moment from 'moment';

export const AppSignSuccessModal = ({
  open,
  handleClose,
  data,
  selectedLiensRelease,
}: {
  open: boolean;
  handleClose: () => void;
  selectedLiensRelease?: LienApiResponse | null;
  data: {
    type: string;
    from: string;
    throughDate?: Date | null;
    amount: number | null;
    job: string;
  };
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="signature-success-modal-content">
        <div className="successImage">
          <Checks size={64} color={COLORS.SUCCESS_600} />
        </div>
        <h1 className="signature-header">You’ve finished signing!</h1>
        <p className="header-description ">
          The sender will be notified that the release has been signed
        </p>
        <div className="details">
          <div className="side-details">
            <div className="details-side-header">From</div>
            <div className="details-side-text">{data.from}</div>
            <div className="details-side-header">Waiver Type</div>
            <div className="details-side-text capitalize">{data?.type?.replaceAll('_', ' ')}</div>
            {data.throughDate && <div className="details-side-header">Through Date</div>}
            {data.throughDate && (
              <div className="details-side-text">
                {moment(data.throughDate).format('MM/DD/YYYY')}
              </div>
            )}
          </div>
          <div className="side-details">
            <div className="details-side-header">Job</div>
            <div className="details-side-text">{data.job}</div>
            {data?.amount !== null && (
              <>
                <div className="details-side-header">Amount</div>
                <div className="details-side-text">{formatMoney(data?.amount ?? '', 2)}</div>
              </>
            )}
          </div>
        </div>
        <AppButton
          text="View All Lien Waivers"
          type="TERTIARY"
          buttonStyles={{
            marginLeft: '10px',
            marginRight: '10px',
          }}
          onClick={handleClose}
        />
        {/* <img src={modalImage} alt="" />
        <p className="mt-4 font-bold text-center text-2xl mb-1">{title}</p>
        <p className="text-sm mb-2">{subTitle}</p>
        {highlightedText && (
          <p className="PRIMARY_500-CLR text-sm font-medium">{highlightedText}</p>
        )}
        <div className="action-buttons">
          <AppButton
            text={buttonText}
            buttonTextColor={buttonTextColor}
            buttonColor={buttonColor}
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={handleClose}
          />
        </div> */}
      </div>
    </Modal>
  );
};
