import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
export interface MessageModalDetails {
  title: string;
  buttonText: string;
  modalType: 'ERROR' | 'SUCCESS' | 'WARN' | 'LOADING' | 'INFO';
  subTitle?: string;
  secondButtonText?: string;
  isSecondButton?: boolean;
  onSecondButtonClick?: () => void;
  onClose?: () => void;
  closeOnClickOutsideModal?: boolean;
  customIcon?: any;
  customIconContainerStyle?: object;
}

export interface GenericInitialState {
  isMessageModalOpen: boolean;
  messageModalDetails: MessageModalDetails | null;
  lastSyncTimeSupplier: { [x: string]: string };
  lastSyncTimeBuyer: { [x: string]: string };
  supplierQueueJobsRunning: string[];
  isSupplierSyncing: boolean;
  supplierManualUploadProgress: {
    status: string | null;
    message: string | null;
    jobs: string[] | null;
  } | null;
}

const initialState: GenericInitialState = {
  isMessageModalOpen: false,
  messageModalDetails: null,
  lastSyncTimeSupplier: {},
  lastSyncTimeBuyer: {},
  isSupplierSyncing: false,
  supplierQueueJobsRunning: [],
  supplierManualUploadProgress: null,
};

export const genericSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetGenericsRootState: () => initialState,
    openMessageModal: (state, action: PayloadAction<MessageModalDetails>) => {
      state.isMessageModalOpen = true;
      if (action.payload) {
        state.messageModalDetails = action.payload;
      }
    },
    closeMessageModal: (state) => {
      state.isMessageModalOpen = false;
      state.messageModalDetails = null;
    },
    updateLastSyncTimeBuyer: (state, action: PayloadAction<{ [x: string]: string }>) => {
      state.lastSyncTimeBuyer = action.payload;
    },
    updateSupplierQueueJobsRunning: (state, action: PayloadAction<string[]>) => {
      state.supplierQueueJobsRunning = action.payload;
    },
    updateLastSyncTimeSupplier: (state, action: PayloadAction<{ [x: string]: string }>) => {
      state.lastSyncTimeSupplier = action.payload;
    },
    updateSupplierManualUploadProgress: (
      state,
      action: PayloadAction<{
        status: string | null;
        message: string | null;
        jobs: string[] | null;
      } | null>
    ) => {
      state.supplierManualUploadProgress = action.payload;
    },
    updateIsSupplierSyncing: (state, action: PayloadAction<boolean>) => {
      state.isSupplierSyncing = action.payload;
    },
  },
  extraReducers(builder) {},
});

export const {
  openMessageModal,
  closeMessageModal,
  updateLastSyncTimeSupplier,
  updateLastSyncTimeBuyer,
  updateIsSupplierSyncing,
  updateSupplierQueueJobsRunning,
  updateSupplierManualUploadProgress,
  resetGenericsRootState,
} = genericSlice.actions;
