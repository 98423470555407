import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import type { ActionMeta, SingleValue } from 'react-select';
import './AppDropDown2.scss';
import { FormOptionItem, FormOptionItem2 } from '../../../utils/interfaces/generalInterfaces';
// import Select from '@mui/material/Select';
import { Chip } from '@mui/material';
import { AppButton } from '../AppButton/AppButton';
import { twMerge } from 'tailwind-merge';
import { COLORS } from '../../../utils/colors';
import { X } from 'react-feather';
import { Ellipsis } from 'react-css-spinners';

const Icon = ({ disabled }: { disabled: boolean }) => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path
        fill={disabled ? '#ccc' : '#CBD5E1'}
        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

// export const AppTestDropDown = ({ placeHolder, options, isMulti, isSearchable, onChange }) => {
export const AppDropDown2 = ({
  items = [],
  value,
  inputFieldStyles,
  isCreatable = false,
  isSearchable = true,
  isMulti = false,
  disabled = false,
  closeMenu = true,
  placeholder = 'Select',
  label = '',
  labelTextClassName = '',
  isRequired = false,
  onSelectChange = () => {},
  onMultiSelectChange = () => {},
  onCreateNewItem = () => {},
  multiValue,
  errorText = '',
  valueStyle = '',
  customViewElement = null,
  showSelectedItems = false,
  showMinimalDesign = false,
  numberOfItemsToShow = 2,
  itemWidth = 200,
  isLoading = false,
}: {
  label?: string;
  labelTextClassName?: string;
  isRequired?: boolean;
  items: FormOptionItem2[];
  value: string | null;
  isCreatable?: boolean;
  isSearchable?: boolean;
  closeMenu?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inputFieldStyles?: any;
  errorText?: string;
  valueStyle?: string;
  onSelectChange?: (newValue: FormOptionItem) => void;
  onCreateNewItem?: (newValue: string) => void;
  onMultiSelectChange?: (newValue: FormOptionItem[], oldMultiValue?: string[] | null) => void;
  multiValue?: string[] | null;
  customViewElement?: any;
  showSelectedItems?: boolean;
  showMinimalDesign?: boolean;
  numberOfItemsToShow?: number;
  itemWidth?: number;
  isLoading?: boolean;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  // const [selectedValue, setSelectedValue] = useState<FormOptionItem | FormOptionItem[] | null>(
  //   isMulti ? [] : null
  // );
  const [selectedValue, setSelectedValue] = useState<FormOptionItem | null>();
  const [selectedMultiValues, setSelectedMultiValues] = useState<FormOptionItem[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLButtonElement>(null);
  const customViewInputRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
    if (value) {
      const check = items.find(
        (item) => item.value && value && item.value.toLowerCase() === value.toLowerCase()
      );
      setSelectedValue(check);
      if (showMenu && !searchValue) {
        setSearchValue(check?.label || '');
      }
    } else {
      setSearchValue('');
    }
    if (!showMenu) {
      setSearchValue('');
    }
  }, [showMenu]);
  useEffect(() => {
    if (multiValue && isMulti) {
      setSelectedMultiValues(
        multiValue.reduce((prev: FormOptionItem2[], curr) => {
          const check = items.find((ii) => curr === ii.value);
          if (check) {
            return [...prev, check];
          }
          return prev;
        }, [])
      );
      // setSelectedMultiValues(multiValue);
    } else {
      if (isMulti) {
        setSelectedMultiValues([]);
        setSelectedValue(null);
      }
    }
  }, [multiValue]);

  useEffect(() => {
    if (value) {
      const check = items.find(
        (item) => item.value && value && item.value.toLowerCase() === value.toLowerCase()
      );
      setSelectedValue(check);
      if (showMenu && !searchValue && !isLoading) {
        setSearchValue(check?.label || '');
      }
    } else {
      setSelectedValue(null);
    }

    return () => {};
  }, [value, items, showMenu, isLoading]);

  useEffect(() => {
    const handler = (e: any) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
      if (customViewInputRef.current && !customViewInputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    const handlerContainer = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    if (closeMenu) {
      window.addEventListener('click', handlerContainer);
      return () => {
        window.removeEventListener('click', handlerContainer);
      };
    } else {
      window.addEventListener('click', handler);
      return () => {
        window.removeEventListener('click', handler);
      };
    }
  });
  const handleInputClick = (e: any) => {
    setShowMenu(!showMenu);
  };

  const removeOption = (option: FormOptionItem) => {
    return selectedMultiValues.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e: any, option: FormOptionItem) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedMultiValues(newValue);
    onMultiSelectChange(newValue, multiValue);
  };

  const getDisplay = () => {
    if (!selectedValue && !showSelectedItems) {
      return <p className="text-slate-400 text-xs">{placeholder}</p>;
    }
    if (isMulti && showSelectedItems) {
      return (
        <div>
          {selectedMultiValues.length > 0 ? (
            <div>
              {showMinimalDesign ? (
                <div className="flex justify-between">
                  <div className="flex overflow-hidden">
                    {selectedMultiValues.slice(0, numberOfItemsToShow).map((option) => (
                      <div
                        key={option.value}
                        className={`text-left max-w-[${itemWidth}px] font-semibold text-xs flex items-center TEXT_SECONDARY-CLR GREY_100-BG px-2 py-1 GREY_300-BORDER border rounded-[2px] mr-2 `}>
                        <div className="truncate text-xs">{option.label}</div>
                        <span
                          onClick={(e) => onTagRemove(e, option)}
                          className="flex items-center ml-2 font-semibold">
                          <X size={16} color={COLORS.GREY_500} />
                        </span>
                      </div>
                    ))}
                  </div>
                  {selectedMultiValues.length > numberOfItemsToShow && (
                    <div
                      className={`text-left max-w-[${itemWidth}px] font-semibold text-xs flex items-center TEXT_SECONDARY-CLR GREY_100-BG px-2 py-1 GREY_300-BORDER border rounded-[2px] mx-2`}>
                      {selectedMultiValues.length - numberOfItemsToShow}+
                    </div>
                  )}
                </div>
              ) : (
                <div className="dropdown-tags">
                  {selectedMultiValues.map((option) => (
                    <div
                      key={option.value}
                      className="text-left font-semibold text-xs flex items-center justify-center TEXT_SECONDARY-CLR GREY_100-BG px-2 py-1 GREY_300-BORDER border rounded-[2px]">
                      {option.label}
                      <span
                        onClick={(e) => onTagRemove(e, option)}
                        className="flex items-center ml-2 font-semibold">
                        <X size={16} color={COLORS.GREY_500} />
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <p className="text-slate-400 text-xs">{placeholder}</p>
          )}
        </div>
      );
    }
    return selectedValue?.label || '--';
  };

  const onItemClick = (option: FormOptionItem) => {
    if (isMulti) {
      let newValue;
      if (selectedMultiValues.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedMultiValues, option];
      }
      setSelectedMultiValues(newValue);
      onMultiSelectChange(newValue, multiValue);
    } else {
      // setSelectedValue(option);
      onSelectChange(option);
    }
    if (closeMenu) {
      setShowMenu(!showMenu);
    }

    // onChange(newValue);
  };

  const isSelected = (option: FormOptionItem) => {
    if (isMulti) {
      return selectedMultiValues.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue && selectedValue === option.value;
  };

  const onSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const getOptions = useMemo(() => {
    if (!searchValue) {
      return items;
    }

    return items
      .sort((optionA, optionB) => {
        if (optionA.label.toLowerCase() < optionB.label.toLowerCase()) {
          return -1;
        }

        return 1;
      })
      .sort((optionA, optionB) => {
        const sortValue = searchValue.toLowerCase().trim();
        const optionAValue = optionA.label.toLowerCase().trim();
        const optionBValue = optionB.label.toLowerCase().trim();
        const aIncludes = optionAValue.includes(sortValue);
        const bIncludes = optionBValue.includes(sortValue);
        if (aIncludes && !bIncludes) return -1;
        if (!aIncludes && bIncludes) return 1;

        if (aIncludes && bIncludes) {
          const aNonSortChars = optionAValue.replace(new RegExp(sortValue, 'g'), '').length;
          const bNonSortChars = optionBValue.replace(new RegExp(sortValue, 'g'), '').length;

          if (aNonSortChars !== bNonSortChars) return aNonSortChars - bNonSortChars;
        }

        if (optionAValue < optionBValue) {
          return -1;
        }

        return 1;
      });
  }, [searchValue, items, showMenu]);

  const checkIfItemExists = () => {
    if (!searchValue) {
      return items;
    }

    return items.filter(
      (option) => option.label.toLowerCase() === searchValue.toLowerCase().trim()
    );
  };
  const isSearchValueIncluded = () => {
    if (!searchValue) {
      return false;
    }

    const check = items.filter((option) =>
      option.label.toLowerCase().trim().includes(searchValue.toLowerCase().trim())
    );
    if (check.length > 0) {
      return true;
    }
    return false;
  };

  const handleCreateNew = () => {
    const availableOptions = items.filter(
      (option) => option.label.toLowerCase() === searchValue.toLowerCase()
    );
    if (availableOptions.length > 0) {
      setShowMenu(false);
      return;
    }

    onCreateNewItem(searchValue);
    setShowMenu(false);
  };

  return (
    <div className="w-full flex flex-col" ref={containerRef}>
      {(label || isRequired) && (
        <p
          className={twMerge(
            'text-xs mb-0.5 text-left whitespace-nowrap font-medium',
            labelTextClassName
          )}>
          {isRequired && <span className="text-red-500">*</span>}
          {label}
        </p>
      )}
      <div
        className={twMerge(
          `dropdown2-container h-fit border py-[5px] `,
          errorText ? 'ERROR_500-BORDER' : showMenu ? 'PRIMARY_500-BORDER' : 'GREY_200-BORDER '
        )}
        style={{
          ...inputFieldStyles,
        }}>
        {!customViewElement && (
          <button
            disabled={disabled}
            onClick={handleInputClick}
            className="dropdown-input"
            ref={inputRef}>
            <div
              className={
                disabled
                  ? `text-left text-xs disabled-dropdown-selected-value ${valueStyle}`
                  : `text-left text-xs dropdown-selected-value ${valueStyle}`
              }>
              {getDisplay()}
            </div>
            <div className="dropdown-tools">
              <div className="dropdown-tool">
                <Icon disabled={disabled} />
              </div>
            </div>
          </button>
        )}
        {customViewElement && (
          <div
            ref={customViewInputRef}
            className={
              disabled
                ? `disabled-dropdown-selected-value ${valueStyle}`
                : `dropdown-selected-value ${valueStyle}`
            }
            onClick={handleInputClick}>
            {customViewElement}
          </div>
        )}

        {showMenu && (
          <div className="dropdown2-menu h-fit">
            {isSearchable && (
              <div className="search-box2">
                <input onChange={onSearch} value={searchValue} ref={searchRef} />
              </div>
            )}
            <div className="dropdown2-menu-list">
              {getOptions.length > 0 &&
                getOptions.map((option, i) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onItemClick(option);
                    }}
                    key={option.value}
                    className={`dropdown2-item text-left ${isSelected(option) && 'selected'} ${
                      searchValue && i === 0 && isSearchValueIncluded()
                        ? 'bg-blue-600 text-white'
                        : 'text-black'
                    }`}>
                    {option.labelInElement ? option.labelInElement : option.label}
                  </div>
                ))}
              {isLoading && (
                <div className="py-2  sticky bottom-0">
                  <div className="progress-spinner-wrapper flex flex-col  h-4">
                    <div
                      className={twMerge(
                        'w-fit flex flex-col items-center justify-center rounded-lg '
                      )}>
                      <Ellipsis color="#3762FB" size={70} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {searchValue !== '' && isCreatable && checkIfItemExists().length === 0 && (
              <div onClick={handleCreateNew} className="px-0.5  mx-2 flex justify-between py-1">
                <span className="text-sm ">
                  <span className="text-xs">{'Add '} </span>
                  <span className="PRIMARY_500-CLR">{`"${searchValue}"`}</span>
                </span>
                <AppButton
                  text="Add New"
                  type="SECONDARY"
                  buttonStyles={{ padding: '5px 10px' }}
                  onClick={handleCreateNew}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {errorText && <p className="text-2xs mt-0.5 text-red-500">{errorText}</p>}
    </div>
  );
};
