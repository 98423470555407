import { Modal } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { AppButton } from '../AppButton/AppButton';
import './AppSelectedPayInvoicesModal.scss';
import deleteWarnCircle from '../../../assets/svg/delete_warn_circle.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import moment from 'moment';
import emptyListImg from '../../../assets/images/emptyList.png';
import { Plus, Loader, X, AlertOctagon } from 'react-feather';
import { Checks, Bank, CaretLeft, CreditCard, LightbulbFilament } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { AppRadioButton } from '../AppRadioButton/AppRadioButton';
import { AppInputField } from '../AppInputField/AppInputField';
import {
  QuickbooksInvoice,
  roundTo,
  formatMoney,
  capitalizeWordsInSentence,
  LienApiPostBody,
  DillPaymentMethod,
  GroupedLienAutoRelease,
  Job,
  LienTemplate,
  ReleaseFieldData,
  ReleaseTemplateField,
  InvoicePaymentDetails,
  calculateStripePartialFacilitationFeesAndAmount,
  QuickbooksCreditMemo,
} from '@dill/dill-shared';
import {
  createExternalPaymentMethod,
  getMainBuyerPaymentMethods,
  payInvoices,
  createPlaidPaymentMethod,
  getWeekPaymentTransactionsLimitationsForMainBuyer,
  createStripePaymentMethod,
  createStripeExternalBankToSupplierAccount,
  updateTransaction,
} from '../../../redux/services/paymentsService';
import { usePlaidLink } from 'react-plaid-link';
import type { PlaidLinkOnSuccess } from 'react-plaid-link';
import { getPlaidLinkToken } from '../../../redux/services/authService';
import { myFirebaseAuth } from '../../../firebase/config';
import axios from 'axios';
import { endProcessProgress, startProcessProgress } from '../../../redux/globalSlices/authSlice';
import { getGroupedBuyersSuppliers } from '../../../redux/services/buyersService';
import { BASE_API_URL } from '../../../utils/constants';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import { AppPaymentInvoiceTable } from '../AppPaymentInvoiceTable/AppPaymentInvoiceTable';
import { openDillToast } from '../../../utils/helpers';
import { closeMessageModal, openMessageModal } from '../../../redux/globalSlices/genericSlice';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';
import { resetSelectInvoice } from '../../../redux/globalSlices/invoiceSlice';
import { resetInvoiceIdsForDownload } from '../../../redux/globalSlices/buyersSlice';
import { getLienTemplates } from '../../../redux/services/leinTemplatesService';
import { getSubSuppliers } from '../../../redux/services/subSuppliersService';

const AppSelectedPayInvoicesModal = ({
  title = '',
  open,
  handleClose,
  selectedInvoices,
  paymentTo,
  paymentFrom,
  selectedCreditMemos,
}: {
  title?: string;
  open: boolean;
  handleClose: () => void;
  selectedInvoices: QuickbooksInvoice[];
  paymentTo: string | undefined;
  paymentFrom: string | undefined;
  selectedCreditMemos: QuickbooksCreditMemo[];
}) => {
  const dispatch = useAppDispatch();
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [isSelectPaymentMethod, setIsSelectPaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [modalType, setModalType] = useState('invoiceList');
  const [currentEditedInvoices, setCurrentEditedInvoices] = useState(
    [] as {
      editedPayment?: number;
      formattedEditedPaymentValue?: string;
      invoiceOriginal: QuickbooksInvoice;
      discountAmount?: number;
    }[]
  );
  const [creditCardInput, setCreditCardInput] = useState({} as any);
  // const [fundingSourceNameInput, setFundingSourceNameInput] = useState('');
  const [plaidPublicToken, setPlaidPublicToken] = useState('');
  const [stripeBankToken, setStripeBankToken] = useState('');
  const [paymentMethodCreateErrorMessage, setPaymentMethodCreateErrorMessage] = useState('');
  const [selectedPlaidAccountDetails, setSelectedPlaidAccountDetails] = useState<{
    accountName: string;
    institutionName: string;
    mask: string;
  } | null>(null);
  const [paymentType, setPaymentType] = useState('bankTransfer');
  const { paymentMethods, loadingList, paymentError, weekLimitations } = useAppSelector(
    (state) => state.payments
  );
  const { selectedSupplierForBuyer } = useAppSelector((state) => state.invoice);
  const { plaidTokenDetails } = useAppSelector((state) => state.auth);
  const { loadingList: buyersLoadingList, selectedMainBuyer } = useAppSelector(
    (state) => state.buyers
  );
  const [addingPayments, setAddingPayments] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [invoiceListErrorMessage, setInvoiceListErrorMessage] = useState('');
  const [partialPaymentReason, setPartialPaymentReason] = useState('');
  const [cardComplete, setCardComplete] = useState(false);
  const [isUseCredits, setIsUseCredits] = useState(false);
  const [totalCreditsToUse, setTotalCreditsToUse] = useState(0);
  const [sendRealese, setSendRelease] = useState(false);
  const [releaseData, setReleaseData] = useState<LienApiPostBody[]>([]);
  const [releaseGroupDetails, setReleaseGroupDetails] = useState<{
    [x: string]: {
      job: Job;
      originalJob: Job;
      template: LienTemplate | null;
      currentPdfUrl: string;
      originalPdfUrl: string;
      subSupplierPosition: number | null;
      amount: number;
      formFields: { [x: string]: ReleaseFieldData };
      templateFields: ReleaseTemplateField[] | null;
    };
  }>({});

  const [partialPaymentNotes, setPartialPaymentNotes] = useState('');
  const [partialPaymentAttachmentsUrls, setPartialPaymentAttachmentsUrls] = useState<
    {
      name: string;
      url: string;
    }[]
  >([]);
  const [partialPaymentAttachmentsFiles, setPartialPaymentAttachmentsFiles] = useState<File[]>([]);

  useEffect(() => {
    if (modalType === 'newPaymentMethod') {
      setSelectedPlaidAccountDetails(null);
      dispatch(getPlaidLinkToken());
      setPaymentMethodCreateErrorMessage('');
      setInvoiceListErrorMessage('');
      setAddingPayments(false);
    }

    return () => {};
  }, [modalType]);

  useEffect(() => {
    if (open) {
      setPlaidPublicToken('');
      setStripeBankToken('');
      setInvoiceListErrorMessage('');

      setIsUseCredits(false);
      setTotalCreditsToUse(0);
      setSendRelease(false);
      setReleaseGroupDetails({});
      setSelectedPlaidAccountDetails(null);
    }

    return () => {};
  }, [open]);
  useEffect(() => {
    if (selectedMainBuyerId && open) {
      dispatch(
        getWeekPaymentTransactionsLimitationsForMainBuyer({ mainBuyerId: selectedMainBuyerId })
      );
      dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getLienTemplates({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
    }

    return () => {};
  }, [selectedMainBuyerId, open]);

  useEffect(() => {
    if (plaidTokenDetails && plaidTokenDetails.link_token) {
      setLinkToken(plaidTokenDetails.link_token);
    }
    return () => {};
  }, [plaidTokenDetails]);

  const handlePlaidLinkSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    setPaymentMethodCreateErrorMessage('');
    setSelectedPlaidAccountDetails({
      accountName: metadata.accounts[0].name,
      institutionName: metadata.institution?.name || '',
      mask: metadata.accounts[0].mask,
    });
    // Exchange Plaid's Public Token for a Processor Token
    const token = await myFirebaseAuth.currentUser?.getIdToken();
    if (!token) {
      return null;
    }
    dispatch(startProcessProgress('getPlaidPublicToken'));
    const processorTokenResponse = await axios.post(
      `${BASE_API_URL}/paymentsAPI/plaid/publicToken`,
      { accountId: metadata.accounts[0].id, publicToken },
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    dispatch(endProcessProgress('getPlaidPublicToken'));

    if (processorTokenResponse.data && processorTokenResponse.data.success) {
      setPlaidPublicToken(processorTokenResponse.data?.data?.processorToken);
      setStripeBankToken(processorTokenResponse.data?.data?.stripeBankToken || '');
    }
  };

  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    onSuccess: handlePlaidLinkSuccess,
    token: linkToken,
  });

  const paymentOptions = useMemo(() => {
    const options = [
      { id: 'bankTransfer', name: 'Bank Transfer' },
      { id: 'creditCard', name: 'Credit / Debit Card' },
    ];
    return options;
  }, []);

  const selectedBuyerId = useMemo(() => {
    let selectedId = '';
    // if (buyerId) {
    //   const splitString = buyerId.split('__');
    //   selectedId = splitString[splitString.length - 1];
    // }
    if (selectedSupplierForBuyer) {
      selectedId = selectedSupplierForBuyer.buyer.id;
    }

    return selectedId;
  }, [selectedSupplierForBuyer]);

  const totalPay = useMemo(() => {
    if (currentEditedInvoices.length > 0) {
      const payment = currentEditedInvoices.reduce((accumulator: number, currentValue) => {
        return accumulator + (currentValue.editedPayment || 0);
      }, 0);
      if (
        selectedSupplierForBuyer &&
        selectedSupplierForBuyer.integrationType === 'CODAT_NETSUITE' &&
        selectedSupplierForBuyer?.invoices?.creditMemosDetails?.totalRemainingCredit &&
        payment <= selectedSupplierForBuyer?.invoices?.creditMemosDetails?.totalRemainingCredit
      ) {
        setTotalCreditsToUse(0);
        if (isUseCredits) {
          setIsUseCredits(false);
        }
        // return 0;
      }
      return +payment.toFixed(2);
    } else {
      return 0;
    }
  }, [currentEditedInvoices]);

  const totalDiscount = useMemo(() => {
    if (currentEditedInvoices.length > 0) {
      const discount = currentEditedInvoices.reduce((accumulator: number, currentValue) => {
        return accumulator + (currentValue.discountAmount || 0);
      }, 0);

      return +discount.toFixed(2);
    } else {
      return 0;
    }
  }, [currentEditedInvoices]);

  const fetchedPaymentMethods = useMemo(() => {
    if (paymentMethods.length > 0) {
      const bankPaymentMethod = paymentMethods.find(
        (method) => method.type === 'BANK_ACCOUNT' && !method?.status
      );
      if (isSelectPaymentMethod && !selectedPaymentMethod) {
        if (bankPaymentMethod?.id) {
          setSelectedPaymentMethod(bankPaymentMethod?.id ?? '');
        } else {
          if (!paymentMethods[0]?.status) {
            setSelectedPaymentMethod(paymentMethods[0]?.id ?? '');
          }
        }
      }
      return paymentMethods;
    } else {
      return [];
    }
  }, [paymentMethods, isSelectPaymentMethod]);

  const fees = useMemo(() => {
    const result = fetchedPaymentMethods.find((method) => method.id === selectedPaymentMethod);
    if (result && result.type === 'CREDIT_CARD' && selectedMainBuyer) {
      const amount = totalPay - totalCreditsToUse;
      const buyer = user?.userBuyers?.find(
        (buy) =>
          buy.supplierId === selectedSupplierForBuyer?.id &&
          selectedMainBuyer.quickBooksCustomerId === buy.quickbooksCustomerId
      );
      let calculateAmount = {} as {
        supplierFees: number;
        buyerFees: number;
        amount: number;
        amountToPay: number;
        totalFees: number;
      };
      if (selectedSupplierForBuyer) {
        calculateAmount = calculateStripePartialFacilitationFeesAndAmount({
          amount,
          supplier: selectedSupplierForBuyer,
          buyer,
        });
      }
      return calculateAmount?.buyerFees ?? 0;
    }
    return 0;
  }, [totalPay, selectedPaymentMethod, totalCreditsToUse, selectedMainBuyer]);

  useEffect(() => {
    if (selectedInvoices && selectedInvoices.length > 0) {
      setCurrentEditedInvoices(
        selectedInvoices.map((invoice) => ({
          editedPayment: invoice.balance ? +invoice.balance?.toFixed(2) : invoice.balance,
          formattedEditedPaymentValue: invoice.balance?.toFixed(2),
          invoiceOriginal: invoice,
          discountAmount: 0,
        }))
      );
      setInvoiceListErrorMessage('');
    }
  }, [selectedInvoices]);

  const handleCloseModal = () => {
    setIsSelectPaymentMethod(false);
    setModalType('invoiceList');
    setPartialPaymentReason('');
    setCurrentEditedInvoices([]);
    setTotalCreditsToUse(0);
    setSendRelease(true);
    setReleaseData([]);
    setSelectedPaymentMethod('');
    handleClose();
  };
  const handleChangeModalType = (typeValue: string) => {
    setModalType(typeValue);
  };
  const handleAddPaymentMethod = async () => {
    setAddingPayments(true);
    if (!selectedPlaidAccountDetails?.accountName) {
      setPaymentMethodCreateErrorMessage('Failed to get bank name');
      setAddingPayments(false);
      return;
    }
    if (stripeBankToken) {
      await dispatch(
        createStripeExternalBankToSupplierAccount({ bankOrCardToken: stripeBankToken })
      );
      setPlaidPublicToken('');
    }
    const results = await dispatch(
      createPlaidPaymentMethod({
        fundingSourceName: `${selectedPlaidAccountDetails.institutionName} ****${selectedPlaidAccountDetails.mask}`,
        plaidToken: plaidPublicToken,
      })
    );
    if (results.type === 'payments/createPlaidPaymentMethod/rejected' && results.payload.errors) {
      setPaymentMethodCreateErrorMessage(results.payload.errors[0].msg);
    } else {
      setPlaidPublicToken('');
      setStripeBankToken('');
      await dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
      handleChangeModalType('invoiceList');
      setCreditCardInput({});
    }
    setAddingPayments(false);
  };

  const handlePayNow = async () => {
    if (!selectedMainBuyerId) {
      return;
    }
    const adjustedSelectedInvoices = currentEditedInvoices.map((editedInvoice) => {
      return {
        ...editedInvoice,
        invoiceOriginal: {
          ...editedInvoice.invoiceOriginal,
          id: !editedInvoice?.invoiceOriginal?.recordType
            ? editedInvoice.invoiceOriginal?.id
            : editedInvoice.invoiceOriginal?.id ?? '',
        },
      };
    });
    const payInvoice = [];
    for (let index = 0; index < adjustedSelectedInvoices.length; index++) {
      const currentInvoice = adjustedSelectedInvoices[index].invoiceOriginal;
      const discountAmountValue = adjustedSelectedInvoices[index].discountAmount ?? 0;
      const paidAmountValue = adjustedSelectedInvoices[index].editedPayment ?? 0;
      const paidAmountAfterDiscount = paidAmountValue - discountAmountValue;
      payInvoice.push({
        amount: currentInvoice.amount,
        quickbooksInvoiceId: currentInvoice.platformInvoiceId || '',
        providerInvoiceId: currentInvoice.id,
        quickbooksJobId: currentInvoice?.quickBooksJob?.platformJobId || '',
        providerJobId: currentInvoice?.quickBooksJob?.id || '',
        invoiceNumber: currentInvoice.docNumber,
        amountPaid: Number(paidAmountAfterDiscount.toFixed(2)),
        dueDate: currentInvoice.dueDate,
        openAmount: currentInvoice.balance,
        recordType: currentInvoice.recordType ? currentInvoice.recordType : ('INVOICE' as const),
        discountAmount: Number(discountAmountValue.toFixed(2)),
        jobId: currentInvoice?.jobId || '',
        customerId: currentInvoice?.customerId || '',
      });
    }

    let remainingCreditToDistribute = totalCreditsToUse;
    const invoicesWithCredits = payInvoice.map((invoice) => {
      let amountPaidWithCredits = 0;
      if (
        remainingCreditToDistribute &&
        invoice.amountPaid &&
        selectedSupplierForBuyer?.integrationType !== 'INFOR_DIST_SX' &&
        selectedSupplierForBuyer?.integrationType !== 'DISTRIBUTION_ONE' &&
        selectedSupplierForBuyer?.integrationType !== 'SAGE_100' &&
        selectedSupplierForBuyer?.integrationType !== 'P21' &&
        selectedSupplierForBuyer?.integrationType !== 'MANUAL_UPLOAD'
      ) {
        if (invoice.amountPaid > remainingCreditToDistribute) {
          amountPaidWithCredits = remainingCreditToDistribute;
          remainingCreditToDistribute = 0;
        } else {
          amountPaidWithCredits = invoice.amountPaid;
          remainingCreditToDistribute = remainingCreditToDistribute - invoice.amountPaid;
        }
        return { ...invoice, amountPaidWithCredits };
      }
      return { ...invoice, amountPaidWithCredits };
    });

    const data = {
      senderDillPaymentMethodId: selectedPaymentMethod,
      invoices: invoicesWithCredits,
      receivingSupplierId: selectedSupplierForBuyer?.id ?? '',
      buyerId: selectedBuyerId,
      partialPaymentReason: partialPaymentReason,
      totalCreditsToUse,
      partialPaymentNotes,
      partialPaymentAttachmentsFiles,
      payDateString: moment().format('MM/DD/YYYY'),
      selectedCreditMemos,
    };
    const updatedReleaseData = [];
    if (releaseData.length > 0 && sendRealese) {
      for (let index = 0; index < releaseData.length; index++) {
        const release = releaseData[index];
        updatedReleaseData.push({
          ...release,
          status:
            isUseCredits && totalPay - totalCreditsToUse + fees === 0
              ? ('Requested' as const)
              : ('Pending' as const),
        });
      }
    }
    dispatch(
      openMessageModal({
        buttonText: '',
        modalType: 'LOADING',
        title: 'Processing payment',
        subTitle: 'This may take a few minutes. Please do not refresh the page',
      })
    );
    const paymentResponse = await dispatch(
      payInvoices({
        mainBuyerId: selectedMainBuyerId,
        invoiceData: data,
        sendRelease: sendRealese,
        releaseData: updatedReleaseData,
      })
    );
    if (paymentResponse.type === 'payments/pay/fulfilled') {
      const dataResults: any = paymentResponse.payload;
      if (dataResults && dataResults?.data?.data?.clientSecret) {
        const clientSecret = dataResults?.data?.data?.clientSecret;
        try {
          const stripeConfirmRes = await stripe?.confirmCardPayment(clientSecret, {});
          if (stripeConfirmRes?.error) {
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'ERROR',
                title: 'Error processing payment',
                subTitle: `${stripeConfirmRes?.error?.message || ''}`,
              })
            );
            return;
          }
        } catch (error) {
          console.log(error);
          dispatch(
            openMessageModal({
              buttonText: 'Close',
              modalType: 'ERROR',
              title: 'Error processing payment',
              subTitle: 'Error validating payment. Please try again',
            })
          );
          return;
        }
      }
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'SUCCESS',
          title: 'Payment successfully sent!',
          subTitle: `Please check your Transaction History for confirmation of final payment status`,
          onClose: () => {
            setModalType('invoiceList');
            if (selectedMainBuyerId && !buyersLoadingList.includes('getGroupedBuyersSuppliers')) {
              handleCloseModal();
              dispatch(
                getGroupedBuyersSuppliers({
                  mainBuyerId: selectedMainBuyerId,
                  weeksLimit: 2,
                })
              );
              dispatch(resetSelectInvoice());
              dispatch(resetInvoiceIdsForDownload());
            }
          },
        })
      );
    } else if (paymentResponse.type === 'payments/pay/rejected') {
      let errorMessage = '';
      if (paymentResponse.type === 'payments/pay/rejected') {
        errorMessage = paymentResponse.payload as any;
      }
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Error processing payment',
          subTitle: `${errorMessage} Contact 972-339-0799 for customer support`,
        })
      );
    } else {
      dispatch(closeMessageModal());
    }
  };

  const handleAddPayMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setCreditCardInput((values: any) => ({ ...values, [name]: value }));
  };
  const handlePlaid = () => {
    setPaymentMethodCreateErrorMessage('');
    openPlaidLink();
  };
  const handleCreateCreditCard = async (event: any) => {
    event.preventDefault();
    setPaymentMethodCreateErrorMessage('');
    if (!stripe || !elements) {
      return;
    }
    setAddingPayments(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      setPaymentMethodCreateErrorMessage(error.message);
    } else {
      const response = await dispatch(
        createStripePaymentMethod({
          mainBuyerId: selectedMainBuyerId || '',
          paymentMethodId: paymentMethod.id,
          billingName: `${capitalizeWordsInSentence(paymentMethod.card.brand)} ****${
            paymentMethod?.card?.last4
          }`,
        })
      );

      if (response.type === 'payments/createStripePaymentMethod/fulfilled') {
        await dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
        handleChangeModalType('invoiceList');
        setCreditCardInput({});
      } else {
        if (response?.payload) {
          const message = response?.payload as string;
          setPaymentMethodCreateErrorMessage(message);
        }
      }
    }
    setAddingPayments(false);
  };
  const handleCardChange = (event: any) => {
    setCardComplete(event.complete);
  };
  const showBankLimit = useMemo(() => {
    const result = fetchedPaymentMethods.find((method) => method.id === selectedPaymentMethod);
    if (result) {
      return result.type === 'BANK_ACCOUNT';
    }
    return false;
  }, [selectedPaymentMethod]);
  const handleAutoGenerateRelease = () => {
    setSendRelease(!sendRealese);
  };
  const handleReleaseDetails = (
    release: LienApiPostBody[],
    data: {
      [x: string]: {
        job: Job;
        originalJob: Job;
        template: LienTemplate | null;
        currentPdfUrl: string;
        originalPdfUrl: string;
        subSupplierPosition: number | null;
        amount: number;
        formFields: { [x: string]: ReleaseFieldData };
        templateFields: ReleaseTemplateField[] | null;
      };
    }
  ) => {
    setReleaseData(release);
    setReleaseGroupDetails(data);
  };

  const handlePaymentDetail = (
    credit: number,
    paymentMethodId: string,
    paymentReason: string,
    invs: {
      editedPayment?: number;
      formattedEditedPaymentValue?: string;
      invoiceOriginal: QuickbooksInvoice;
      discountAmount?: number;
    }[]
  ) => {
    setTotalCreditsToUse(credit);
    setSelectedPaymentMethod(paymentMethodId);
    setPartialPaymentReason(paymentReason);
    setCurrentEditedInvoices(invs);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const updateInvoice = currentEditedInvoices.map((item) => {
      if (item.invoiceOriginal.id === name && item.invoiceOriginal.balance) {
        if (Number(value) <= 0 || Number(value) > Number(item.invoiceOriginal.balance)) {
          return {
            ...item,
            editedPayment: +item.invoiceOriginal.balance?.toFixed(2),
            formattedEditedPaymentValue: Number(item.invoiceOriginal.balance)?.toFixed(2),
          };
        } else {
          return {
            ...item,
            editedPayment: +Number(value).toFixed(2),
            formattedEditedPaymentValue: Number(value)?.toFixed(2),
          };
        }
      } else {
        return item;
      }
    });
    setCurrentEditedInvoices(updateInvoice);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    const updateInvoice = currentEditedInvoices.map((item) => {
      if (item.invoiceOriginal.id === name && item.invoiceOriginal.balance) {
        return {
          ...item,
          editedPayment: Number(value),
          formattedEditedPaymentValue: value,
        };
      } else {
        return item;
      }
    });
    setCurrentEditedInvoices(updateInvoice);
    setInvoiceListErrorMessage('');
  };

  const handleDeleteEditedInvoice = (invoicePosition: number) => {
    setCurrentEditedInvoices(
      currentEditedInvoices.filter((invoice, index) => invoicePosition !== index)
    );
  };
  const handleToggleIsPaymentMethod = (value: boolean) => {
    setIsSelectPaymentMethod(value);
  };
  const handleAutoAdjustInvoicesToFitLimit = () => {
    setIsSelectPaymentMethod(false);
    const filteredInvoices = currentEditedInvoices
      .sort((invoiceA, invoiceB) => {
        if (
          invoiceA.invoiceOriginal.balance &&
          invoiceB.invoiceOriginal.balance &&
          invoiceA.invoiceOriginal.balance < invoiceB.invoiceOriginal.balance
        ) {
          return 1;
        }
        return -1;
      })
      .sort((invoiceA, invoiceB) => {
        if (
          invoiceA.invoiceOriginal.dueDate &&
          invoiceB.invoiceOriginal.dueDate &&
          moment(invoiceA.invoiceOriginal.dueDate).diff(
            moment(invoiceB.invoiceOriginal.dueDate),
            'seconds'
          ) > 0
        ) {
          return 1;
        }
        return -1;
      })

      .reduce(
        (
          prev: {
            sum: number;
            invoices: { editedPayment?: number; invoiceOriginal: QuickbooksInvoice }[];
          },
          curr
        ) => {
          const newSum = prev.sum + (curr?.invoiceOriginal.balance || 0);
          if (
            curr.invoiceOriginal.balance &&
            weekLimitations?.weekLimit &&
            newSum < weekLimitations?.weekLimit
          ) {
            return {
              sum: newSum,
              invoices: [
                ...prev.invoices,
                {
                  ...curr,
                  editedPayment: +curr?.invoiceOriginal.balance?.toFixed(2),
                  formattedEditedPaymentValue: curr?.invoiceOriginal.balance?.toFixed(2),
                },
              ],
            };
          }
          if (
            curr.invoiceOriginal.balance &&
            weekLimitations?.weekLimit &&
            newSum > weekLimitations?.weekLimit &&
            prev.sum !== weekLimitations?.weekLimit
          ) {
            const partialBalance = weekLimitations?.weekLimit - prev.sum;
            return {
              sum: prev.sum + partialBalance,
              invoices: [
                ...prev.invoices,
                {
                  ...curr,
                  editedPayment: +partialBalance.toFixed(2),
                  formattedEditedPaymentValue: partialBalance.toFixed(2),
                },
              ],
            };
          }
          return prev;
        },
        { sum: 0, invoices: [] }
      );
    setCurrentEditedInvoices([...filteredInvoices.invoices]);

    openDillToast({
      message: 'Invoices have been successfully adjusted to fit the limit',
      type: 'SUCCESS',
    });
  };

  const handleSetCreditToUse = (value: number) => {
    setTotalCreditsToUse(value);
  };

  const handleSelectPaymentMethod = (value: string) => {
    setSelectedPaymentMethod(value);
  };

  const useCreditAmount = useMemo(() => {
    if (selectedSupplierForBuyer?.invoices?.creditMemosDetails.totalRemainingCredit) {
      if (
        selectedSupplierForBuyer?.invoices?.creditMemosDetails.totalRemainingCredit > totalPay &&
        selectedSupplierForBuyer?.integrationType !== 'INFOR_DIST_SX' &&
        selectedSupplierForBuyer?.integrationType !== 'DISTRIBUTION_ONE' &&
        selectedSupplierForBuyer?.integrationType !== 'SAGE_100' &&
        selectedSupplierForBuyer?.integrationType !== 'P21' &&
        selectedSupplierForBuyer?.integrationType !== 'MANUAL_UPLOAD'
      ) {
        if (isUseCredits) {
          setTotalCreditsToUse(totalPay);
        } else {
          setTotalCreditsToUse(0);
        }
        return totalPay;
      } else {
        return selectedSupplierForBuyer.invoices.creditMemosDetails.totalRemainingCredit;
      }
    }
    return 0;
  }, [
    totalPay,
    selectedSupplierForBuyer?.invoices?.creditMemosDetails.totalRemainingCredit,
    isUseCredits,
  ]);

  const handleUseCredits = (value: boolean) => {
    setIsUseCredits(value);
  };

  useEffect(() => {
    if (selectedCreditMemos.length > 0) {
      setIsUseCredits(true);
      const creditAmount = selectedCreditMemos.reduce(
        (acc, creditMemo) => acc + (creditMemo.balance ?? 0),
        0
      );
      setTotalCreditsToUse(creditAmount);
    }
  }, [selectedCreditMemos]);

  const handleOnPartialPaymentDetailsChange = ({
    notes,
    urls,
    files,
  }: {
    notes?: string;
    urls?: {
      name: string;
      url: string;
    }[];
    files?: File[];
  }) => {
    if (files) {
      setPartialPaymentAttachmentsFiles(files);
    } else {
      setPartialPaymentAttachmentsFiles([]);
    }
    if (urls) {
      setPartialPaymentAttachmentsUrls(urls);
    } else {
      setPartialPaymentAttachmentsUrls([]);
    }
    if (notes) {
      setPartialPaymentNotes(notes);
    } else {
      setPartialPaymentNotes('');
    }
  };
  const isPartialPayment = useMemo(() => {
    if (currentEditedInvoices.length > 0 && selectedInvoices && selectedInvoices.length > 0) {
      let originalAmount = currentEditedInvoices.reduce((accumulator: number, currentValue) => {
        return accumulator + (currentValue.invoiceOriginal?.balance || 0);
      }, 0);
      originalAmount = +originalAmount.toFixed(2);
      let adjustedAmount = currentEditedInvoices.reduce((accumulator: number, currentValue) => {
        return accumulator + (currentValue.editedPayment || 0);
      }, 0);
      adjustedAmount = +adjustedAmount.toFixed(2);
      return originalAmount > adjustedAmount;
    }
    return false;
  }, [selectedInvoices, currentEditedInvoices]);

  const groupedInvoiceReleases = useMemo(() => {
    const result: GroupedLienAutoRelease[] = currentEditedInvoices.reduce(
      (acc: GroupedLienAutoRelease[], item) => {
        const jobId = item.invoiceOriginal.quickBooksJob?.platformJobId || '';
        const index = acc.findIndex((entry) => entry.jobId === jobId);

        if (index === -1) {
          acc.push({
            jobId,
            invoices: [
              { invoiceNumber: item.invoiceOriginal.docNumber, amount: item.editedPayment ?? 0 },
            ],
            totalAmount: item.editedPayment ?? 0,
          });
        } else {
          acc[index].invoices.push({
            invoiceNumber: item.invoiceOriginal.docNumber,
            amount: item.editedPayment ?? 0,
          });
          acc[index].totalAmount += item.editedPayment ?? 0;
        }

        return acc;
      },
      []
    );
    return result;
  }, [currentEditedInvoices]);

  return (
    <Modal
      open={open}
      // onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="app-invoice-select-pay-modal-content pt-3 ">
        {modalType === ('invoiceList' || '') && (
          <AppPaymentInvoiceTable
            modalType={modalType}
            selectedBuyerId={selectedBuyerId}
            editedInvoices={currentEditedInvoices}
            onDeleteEditedInvoice={handleDeleteEditedInvoice}
            paymentTo={paymentTo}
            paymentFrom={paymentFrom}
            onChangeModalType={handleChangeModalType}
            handleClose={handleCloseModal}
            onReleaseDetails={handleReleaseDetails}
            onPaymentDetail={handlePaymentDetail}
            onChangeInput={handleChange}
            onBlurInput={handleBlur}
            totalPay={totalPay}
            onToggleIsPaymentMethod={handleToggleIsPaymentMethod}
            onAutoAdjustInvoicesToFitLimit={handleAutoAdjustInvoicesToFitLimit}
            isSelectPaymentMethod={isSelectPaymentMethod}
            onAutoGenerateRelease={handleAutoGenerateRelease}
            sendRealese={sendRealese}
            fees={fees}
            paymentMethods={fetchedPaymentMethods}
            selectedPaymentMethod={selectedPaymentMethod}
            onSelectPaymentMethod={handleSelectPaymentMethod}
            totalCreditsToUse={totalCreditsToUse}
            creditAmount={useCreditAmount}
            onSetCreditToUse={handleSetCreditToUse}
            onUseCredits={handleUseCredits}
            isUseCredits={isUseCredits}
            selectedRelease={releaseGroupDetails}
            isPartialPayment={isPartialPayment}
            groupedInvoiceReleases={groupedInvoiceReleases}
            partialReason={partialPaymentReason}
            onPartialPaymentDetailsChange={handleOnPartialPaymentDetailsChange}
          />
        )}
        {modalType === 'newPaymentMethod' && (
          <div className="new-payment-method-select-modal">
            <div className="rowContainer">
              <AppButton
                type="FLAT"
                icon={<CaretLeft size={24} color={'#000000'} />}
                buttonStyles={{
                  width: '40px',
                  height: '40px',
                  marginLeft: '12px',
                  marginRight: '12px',
                }}
                onClick={() => handleChangeModalType('invoiceList')}
              />

              <h3 className="payment-content-title">Add New Payment Method</h3>
            </div>

            <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-200"></hr>

            <div className="flex flex-col px-4 mt-4 grow overflow-y-scroll">
              <div className="flex flex-col">
                <div className="flex flex-row items-center justify-between ml-3 mb-2">
                  <p className="text-sm font-bold ">Select a Payment Method</p>
                </div>

                {paymentOptions.map((option, i) => {
                  return (
                    <div key={i + 'oo'} className="w-full px-4 my-1">
                      <button
                        className={
                          'flex flex-row w-full rounded-xl WHITE-BG px-5 py-4 border  items-center cursor-pointer ' +
                          (option.id === paymentType ? 'PRIMARY_500-BORDER' : ' GREY_300-BORDER')
                        }
                        onClick={() => {
                          setPaymentType(option.id);
                        }}>
                        <div className="flex mr-2 items-center">
                          <AppRadioButton isChecked={option.id === paymentType} />
                          <div className="payment-type-logo">
                            {option.name === 'Bank Transfer' ? (
                              <Bank size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                            ) : (
                              <CreditCard
                                size={30}
                                style={{ display: 'flex', alignSelf: 'center' }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-bold text-left">{option.name}</p>
                        </div>
                      </button>
                    </div>
                  );
                })}

                {paymentType === 'bankTransfer' && (
                  <div className="flex flex-col p-4 PRIMARY_50-BG mt-2">
                    {isPlaidLinkReady && (
                      <AppButton
                        buttonStyles={{ width: '100%' }}
                        text="Link Bank Account"
                        onClick={() => {
                          handlePlaid();
                        }}
                      />
                    )}
                    {selectedPlaidAccountDetails && (
                      <div className="mt-3 ">
                        <p className="text-sm mb-1 font-bold">Selected bank details</p>
                        <p className="text-xs mb-1 ">
                          {`${selectedPlaidAccountDetails.accountName} from ${selectedPlaidAccountDetails.institutionName}`}
                        </p>
                      </div>
                    )}
                    {selectedPlaidAccountDetails && <div className="flex flex-col my-1"></div>}
                  </div>
                )}
                {paymentType === 'creditCard' && (
                  <div className="flex flex-col p-4 PRIMARY_50-BG mt-2">
                    <form onSubmit={handleCreateCreditCard}>
                      <label className="text-xs mb-1" htmlFor="card-element">
                        <CardElement
                          id="card-element"
                          onChange={handleCardChange}
                          options={{ disableLink: true }}
                        />
                      </label>
                    </form>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col w-full  mb-3">
              <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
              {paymentMethodCreateErrorMessage && (
                <p className="text-xs mb-3 mx-3 text-center  ERROR_500-CLR">
                  {paymentMethodCreateErrorMessage}
                </p>
              )}
              <div className="flex flex-row self-end ">
                <AppButton
                  text={'Close'}
                  type="TERTIARY"
                  buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={() => handleChangeModalType('invoiceList')}
                />
                {paymentType === 'creditCard' ? (
                  <AppButton
                    text="Create"
                    buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
                    onClick={handleCreateCreditCard}
                    // isDisabled={formIsValid}
                    isLoading={addingPayments}
                  />
                ) : (
                  <AppButton
                    text="Add"
                    buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
                    onClick={handleAddPaymentMethod}
                    isDisabled={!plaidPublicToken}
                    isLoading={addingPayments}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {modalType === 'paymentConfirmation' && (
          <div className="process-content">
            <div className="payment-modal-title-container">
              <h3 className="payment-confirmation">Payment Confirmation</h3>
              <AppButton
                type="FLAT"
                buttonStyles={{ padding: '0px', margin: '5px 2px' }}
                icon={<X color={COLORS.GREY_300} size={30} />}
                onClick={handleCloseModal}
              />
            </div>
            <div className="py-3 px-6 border-b border-gray-200">
              <div className="payment-confirmation-details">
                <div className="detailText">To</div>
                <p>{paymentTo}</p>
              </div>
              <div className="payment-confirmation-details">
                <div className="detailText">From</div>
                <p>{paymentFrom}</p>
              </div>
              {totalPay - totalCreditsToUse + fees > 0 && (
                <div className="payment-confirmation-details">
                  <div className="detailText">Method</div>
                  <p>{showBankLimit ? 'Bank Transfer' : 'Credit Card'}</p>
                </div>
              )}
              {totalCreditsToUse === totalPay && (
                <div className="payment-confirmation-details">
                  <div className="detailText">Method</div>
                  <p>Credits</p>
                </div>
              )}
              <div className="w-full">
                <div className="flex flex-col justify-between rounded-xl PRIMARY_50-BG px-5 py-4">
                  <div className="flex flex-row justify-between w-full pt-2">
                    <div className="total-pay-label">Total Payment</div>
                    <div className="totalText">{`${formatMoney(
                      totalPay - totalCreditsToUse - totalDiscount + fees,
                      2
                    )}`}</div>
                  </div>
                </div>
              </div>
              {totalCreditsToUse === totalPay && (
                <div className="w-full flex flex-row-reverse">
                  <div className="confirm-credit">{`(-CREDIT ${formatMoney(
                    totalCreditsToUse,
                    2
                  )})`}</div>
                </div>
              )}
              {sendRealese && (
                <div className="create-release-info ">
                  <div className="mr-2">
                    <LightbulbFilament color={COLORS.INFO_900} size={24} />
                  </div>
                  <div className="create-release-info-text ">
                    Your lien waiver request will get automatically sent to
                    <span>{`${releaseData.length} supplier${
                      releaseData.length > 1 ? 's' : ''
                    }`}</span>{' '}
                    once this payment is successfully processed. Track these requests in the Lien
                    Releases tab.
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-row justify-between  px-6 py-3">
              <AppButton
                text={'Back'}
                type="TERTIARY"
                buttonStyles={{
                  width: '100%',
                }}
                onClick={() => handleChangeModalType('invoiceList')}
              />
              <div className="px-4" />
              <AppButton
                text="Pay Now"
                buttonStyles={{
                  width: '100%',
                }}
                onClick={handlePayNow}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AppSelectedPayInvoicesModal;
