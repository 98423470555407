import { Modal } from '@mui/material';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { FileArrowUp, UploadSimple, Eye, Warning, CheckCircle, XCircle } from 'phosphor-react';
import { ArrowsMerge } from '@phosphor-icons/react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import AppDatePicker from '../AppDatePicker/AppDatePicker';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../../redux/store';
import {
  LienTemplate,
  UserLinkApiResponse,
  PDFFieldReleaseTemplate,
  Job,
  FromFieldTypeReleaseTemplate,
  ReleaseFieldData,
  GroupedCSVRelease,
  formatMoney,
} from '@dill/dill-shared/dist/src';
import {
  removePDFFields,
  disablePDFFields,
  populatePdfWithFieldsData,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
  convertKeysToCamelCase,
} from '../../../utils/helpers';
import { AppInputField } from '../AppInputField/AppInputField';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AppVirtualizedList } from '../AppVirtualizedList/AppVirtualizedList';
import { openMessageModal } from '../../../redux/globalSlices/genericSlice';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';
import { useDropzone } from 'react-dropzone';

interface SubSupplierUsersType {
  subSupplierName: string;
  notes: string;
  subSupplierId: string;
  user: UserLinkApiResponse;
}

interface ProcessedInvoicesData {
  amount: string | number;
  notes: string;
  job: string;
  recipient: string;
  template: string;
  throughDate: string;
  releaseExists: boolean;
  invoiceList?: { invoiceNumber: string; amount: number; invoiceDate: string }[];
}

interface CaseContainsRequiredProperties {
  data: string[];
  type: string;
}

interface UprocessedData {
  [key: string]: string;
}

interface PaymentReportData {
  jobNumber: string;
  jobName: string;
  vendorNumber: string;
  vendorName: string;
  vendorType: string;
  vendorWCompDate: string;
  vendorWarnDate: string;
  vendorAccount: string;
  voucherNumber: string;
  invoiceNumber: string;
  costCode: string;
  amount: number | string;
  invoiceWCompDate: string;
  checkNumber: string;
  date: string;
  secondParty: string;
  paid: number | string;
  retention: number | string;
  discount: number | string;
  invoiceDate: string;
  invoicePeriod: string;
  pONumber: string;
  department: string;
  description: string;
}

interface MonthlyReportData {
  jobNumber: string;
  jobName: string;
  vendorName: string;
  vendorType: string;
  voucherNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoicePeriod: string;
  amount: number | string;
  notes?: string;
  invoiceList?: { invoiceNumber: string; amount: number; invoiceDate: string; isPaid: boolean }[];
}

interface MergedReport {
  jobNumber: string;
  jobName: string;
  vendorName: string;
  vendorType: string;
  voucherNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoicePeriod: string;
  amount: number | string;
  checkNumber?: string;
  date?: string | number;
  paid?: number | string;
  retention?: number | string | null;
  discount?: number | string;
  isPaid: boolean;
  vendorNumber?: string;
  vendorWCompDate?: string;
  vendorWarnDate?: string;
  vendorAccount?: string;
  costCode?: string;
  invoiceWCompDate?: string;
  secondParty?: string;
  pONumber?: string;
  department?: string;
  description?: string;
  invoiceList?: { invoiceNumber: string; amount: number; invoiceDate: string; isPaid: boolean }[];
}

const baseStyle = {
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 4,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const AppUploadCSVInvoicesModal = ({
  open,
  handleClose,
  onPreview,
  onImportRelease,
}: {
  open: boolean;
  handleClose: () => void;
  onImportRelease: (data: GroupedCSVRelease[]) => void;
  onPreview: (
    data: {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      dueDate: string | Date;
      sentToGCTime?: Date | null;
      sentToGCEmails?: string[] | null;
    },
    url: string
  ) => void;
}) => {
  const dispatch = useAppDispatch();
  const { jobs } = useAppSelector((state) => state.jobs);
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const { buyerLienReleases } = useAppSelector((state) => state.liens);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);
  const [selected, setSelected] = useState<number[]>([]);
  const [error, setError] = useState<{ [key: number]: string[] }[]>([]);
  const [previewLoading, setPreviewLoading] = useState<null | number>(null);
  const [loading, setLoading] = useState(false);
  const [processedInvoices, setProcessedInvoices] = useState<ProcessedInvoicesData[]>([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState<MergedReport[]>([]);
  const [monthlyReport, setMonthlyReport] = useState<MonthlyReportData[]>([]);
  const [paymentReport, setPaymentReport] = useState<PaymentReportData[]>([]);
  const [hasHighligtedRows, setHasHighligtedRows] = useState(false);
  const [missingFiles, setMissingFiles] = useState<string[]>([]);
  const [fileErrors, setFileErrors] = useState<{ errorMessage: string; fileName: string }[]>([]);
  const [paymentFile, setPaymentFile] = useState<File | null>(null);
  const [monthlyReportFile, setMonthlyReportFile] = useState<File | null>(null);
  const [rejectedFiles, setRejectedFiles] = useState<{ fileName: string; file: File }[]>([]);
  const [mode, setMode] = useState<
    'UPLOADING' | 'PROCESSING' | 'WAITING FILE' | 'VIEW_DATA' | 'SHOW_UNPAID_INVOICES'
  >('WAITING FILE');

  const [selectedMainBuyerId] = useParamMainBuyerId();

  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'select', name: '', width: 80, cellType: 'SELECT' },
      { id: 'recipient', name: 'Recipient', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'job', name: 'Job', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'template', name: 'Template', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'throughDate', name: 'Through Date', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'notes', name: 'Notes', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'action', name: '', width: 80, cellType: 'VIEW_TEXT' },
    ];
    return currentColumns;
  }, [processedInvoices]);

  const uploadedFiles = useMemo(() => {
    const files: { fileName: string; fileType: string; file: File }[] = [];
    if (paymentFile) {
      files.push({ fileName: paymentFile.name, fileType: 'PAYMENT_REPORT', file: paymentFile });
    }
    if (monthlyReportFile) {
      files.push({
        fileName: monthlyReportFile.name,
        fileType: 'MONTHLY_REPORT',
        file: monthlyReportFile,
      });
    }
    return files;
  }, [paymentFile, monthlyReportFile]);

  const unPaidInvoiceColumns = useMemo(() => {
    const currentColumns = [
      { id: 'vendorName', name: 'Recipient', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'jobNumber', name: 'Job Number', width: 80, cellType: 'VIEW_TEXT' },
      { id: 'jobName', name: 'Job Name', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'throughDate', name: 'Through Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'notes', name: 'Notes', width: 240, cellType: 'VIEW_TEXT' },
    ];
    return currentColumns;
  }, [unpaidInvoices]);

  const templates = useMemo(() => {
    if (lienTemplates.length > 0) {
      const processedLienTemplates = lienTemplates.filter(
        (template) => template.status === 'processed'
      );
      return processedLienTemplates;
    }
    return [];
  }, [lienTemplates]);

  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers.reduce(
        (
          prev: {
            subSupplierName: string;
            notes: string;
            subSupplierId: string;
            user: UserLinkApiResponse;
          }[],
          curr
        ) => {
          return [
            ...prev,
            ...curr.users
              .filter((userLink) => {
                if (
                  userLink.subUser &&
                  userLink.subUser.roles &&
                  (userLink.subUser.roles.includes('REVIEWER') ||
                    userLink.subUser.roles.length == 0)
                ) {
                  return true;
                }
                return !userLink?.subUser?.roles;
              })
              .map((userLink) => {
                return {
                  subSupplierId: curr.id,
                  subSupplierName: curr.name,
                  notes: curr.notes ?? '',
                  user: userLink,
                };
              }),
          ];
        },
        []
      );
    }
    return [];
  }, [subSuppliers]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const totalUpaidInvoiceWidth = useMemo(
    () =>
      unPaidInvoiceColumns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [unPaidInvoiceColumns]
  );

  useEffect(() => {
    if (open) {
      setProcessedInvoices([]);
      setError([]);
      setSelected([]);
      setHasHighligtedRows(false);
      setMissingFiles([]);
      setFileErrors([]);
      setPaymentFile(null);
      setMonthlyReportFile(null);
      setRejectedFiles([]);
      setMode('WAITING FILE');
    }
  }, [open]);

  const populatePdf = async (
    fieldDetails: PDFFieldReleaseTemplate[],
    selectedTemplate: LienTemplate
  ) => {
    if (!selectedTemplate?.originalPdf?.url) {
      return '';
    }

    let pdfUrl = '';
    const flatPDF = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
    pdfUrl = flatPDF ?? '';
    const populatedPdfUrl = await populatePdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
    });
    if (populatedPdfUrl) {
      return populatedPdfUrl;
    }
    return '';
  };
  const containsRequiredProperties = ({ data, type }: CaseContainsRequiredProperties): boolean => {
    const fieldsToFind = ['Vendor WComp Date', 'Cost Code'];
    const commonFields = [
      'Job Number',
      'Job Name',
      'Vendor Name',
      'Vendor Type',
      'Voucher Number',
      'Invoice Number',
      'Invoice Date',
      'Invoice Period',
      'Amount',
    ];

    // Trim each item in the data array
    const trimmedData = data.map((item) => item.trim());

    const missingFields = fieldsToFind.filter((field) => !trimmedData.includes(field));
    const hasCommonFields = commonFields.filter((field) => !trimmedData.includes(field));
    if (type === 'paymentReport') {
      return missingFields.length === 0 && hasCommonFields.length === 0;
    } else if (type === 'monthlyReport') {
      return missingFields.length !== 0 && hasCommonFields.length === 0;
    }
    return false;
  };

  const populateAllPdfData = async (
    selectedJob: Job,
    subSupplierUser: SubSupplierUsersType,
    selectedTemplate: LienTemplate,
    formFieldsData: {
      [x: string]: ReleaseFieldData;
    }
  ) => {
    const specialFieldsPDFData: { fromFieldType: FromFieldTypeReleaseTemplate; dataToUse: any }[] =
      [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    if (selectedJob) {
      specialFieldsPDFData.push({ fromFieldType: 'JOB_FIELD', dataToUse: selectedJob });
    }

    if (subSupplierUser) {
      specialFieldsPDFData.push({
        fromFieldType: 'SUPPLIER_FIELD',
        dataToUse: subSupplierUser,
      });
    }

    if (selectedTemplate) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_BUYER_FIELD',
        dataToUse: selectedMainBuyer,
      });

      const allNewFormFields = { ...formFieldsData };
      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        dynamicForFieldsPdfData.push({
          fromFieldId: formFieldKey,
          value: allNewFormFields[formFieldKey].value,
        });
      });
    }
    let pdfData: PDFFieldReleaseTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && selectedTemplate) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    const pdfUrl = await populatePdf(pdfData, selectedTemplate);
    return pdfUrl;
  };

  const populateTemplateFieldData = (
    selectedTemplate: LienTemplate,
    currentThroughDate: string,
    amount: number
  ) => {
    let data: { [x: string]: ReleaseFieldData } = {};
    if (selectedTemplate) {
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
          let defaultValue: any = '';
          if (
            formField.fieldType === 'CURRENCY' &&
            formField.defaultValue !== null &&
            formField.defaultValue !== undefined
          ) {
            defaultValue = Number(formField.defaultValue).toFixed(2);
          } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
            defaultValue = `${formField.defaultValue}`;
          } else {
            defaultValue = formField.defaultValue;
          }
          data = {
            ...data,
            [formField.id]: {
              isRequired: formField.isFieldRequired,
              value:
                formField.defaultValue !== null && formField.defaultValue !== undefined
                  ? defaultValue
                  : '',
              id: formField.id,
              name: formField.fieldName,
              type: formField.fieldType,
            },
          };
        }
      });
      let throughDateData: { [x: string]: ReleaseFieldData } = {};
      for (const key of Object.keys(data)) {
        if (key.startsWith('throughDate')) {
          throughDateData = {
            ...throughDateData,
            [key]: {
              ...data[key],
              value: new Date(currentThroughDate),
            },
          };
        }
      }
      let paymentAmountData: { [x: string]: ReleaseFieldData } = {};
      for (const key of Object.keys(data)) {
        if (key.startsWith('paymentAmount')) {
          paymentAmountData = {
            ...paymentAmountData,
            [key]: {
              ...data[key],
              value: amount,
            },
          };
        }
      }
      data = { ...data, ...throughDateData, ...paymentAmountData };
    }
    return { ...data };
  };

  const checkTempateRequirement = (selectedTemplate: LienTemplate) => {
    const requredField = [];
    if (selectedTemplate) {
      let data: { [x: string]: ReleaseFieldData } = {};
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
          let defaultValue: any = '';
          if (
            formField.fieldType === 'CURRENCY' &&
            formField.defaultValue !== null &&
            formField.defaultValue !== undefined
          ) {
            defaultValue = Number(formField.defaultValue).toFixed(2);
          } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
            defaultValue = `${formField.defaultValue}`;
          } else {
            defaultValue = formField.defaultValue;
          }
          data = {
            ...data,
            [formField.id]: {
              isRequired: formField.isFieldRequired,
              value:
                formField.defaultValue !== null && formField.defaultValue !== undefined
                  ? defaultValue
                  : '',
              id: formField.id,
              name: formField.fieldName,
              type: formField.fieldType,
            },
          };
        }
      });
      for (const key of Object.keys(data)) {
        if (key.startsWith('throughDate')) {
          requredField.push('throughDate');
        }
      }
      for (const key of Object.keys(data)) {
        if (key.startsWith('paymentAmount')) {
          requredField.push('amount');
        }
      }
    }
    return requredField;
  };

  const checkIfReleaseExist = (
    jobId: string,
    templateId: string,
    recipient: string,
    amount: number,
    throughDate: string
  ) => {
    const foundRelease = buyerLienReleases.find((release) => {
      const releaseMonth = release?.releaseMonth
        ? moment(release?.releaseMonth).format('MM/DD/YYYY')
        : '';
      let releaseAmount = false;
      const template = templates.find((tmp) => tmp.id === templateId);
      if (template) {
        const templateRequirement = checkTempateRequirement(template);
        if (templateRequirement.includes('amount')) {
          const paymentAmount = release.fieldsData?.find((itm) => itm.id === 'paymentAmount');
          releaseAmount = paymentAmount?.value === Number(amount.toFixed(2));
        } else {
          releaseAmount = true;
        }
      }
      const releaseUserId = release.recipient?.subUser
        ? release.recipient?.subUser.id
        : release.recipient?.user?.id;
      return (
        release.job?.id === jobId &&
        releaseMonth === throughDate &&
        releaseAmount &&
        template?.id === release.template?.id &&
        recipient.split('__')[0] === release.subSupplier?.id &&
        recipient.split('__')[1] === releaseUserId
      );
    });
    if (foundRelease) {
      return true;
    }
    return false;
  };

  const getLatestDate = (
    data: { invoiceNumber: string; amount: number; invoiceDate: string }[]
  ): Date | undefined => {
    let latestDate: moment.Moment | undefined;

    data.forEach((invoice) => {
      const date = moment(invoice.invoiceDate, 'MM/DD/YY');
      if (!latestDate || date.isAfter(latestDate)) {
        latestDate = date;
      }
    });

    return latestDate?.toDate();
  };

  const saveData = async (data: MergedReport[]) => {
    try {
      const savedData = [];
      for (let j = 0; j < data.length; j++) {
        const elem = data[j];
        let jobId = '';
        let supplierUserId = '';
        let templateId = '';
        const foundJob = await jobs.find((jb) => jb.number === String(elem.jobNumber));
        if (foundJob) {
          jobId = foundJob.id;
          if (foundJob.templates?.PROGRESS_UNCONDITIONAL === '') {
            const template = await templates.find((temp) => temp.type === 'PROGRESS_UNCONDITIONAL');
            if (template) {
              templateId = template.id;
            }
          } else {
            templateId = foundJob.templates?.PROGRESS_UNCONDITIONAL ?? '';
          }
        } else {
          const template = await templates.find((temp) => temp.type === 'PROGRESS_UNCONDITIONAL');
          if (template) {
            templateId = template.id;
          }
        }

        const foundSupplier = await subSupplierUsers.find(
          (subSupplier) =>
            subSupplier.subSupplierName.toLowerCase().trim() ===
            elem.vendorName.toLowerCase().trim()
        );

        if (foundSupplier) {
          const userDetails = foundSupplier.user.subUser
            ? foundSupplier.user.subUser
            : foundSupplier.user.user;
          supplierUserId = `${foundSupplier.subSupplierId}__${userDetails?.id ?? ''}`;
        }
        let invoiceDate = new Date();
        if (elem.invoiceList) {
          const latestDate = getLatestDate(elem.invoiceList);
          if (latestDate) {
            invoiceDate = latestDate;
          }
        }
        const throughDate = moment(invoiceDate).add(1, 'day').format('MM/DD/YYYY');
        const releaseAvailable = checkIfReleaseExist(
          jobId,
          templateId,
          supplierUserId,
          Number(elem.amount),
          throughDate
        );
        let notes = '';
        if (elem?.invoiceList) {
          const invoiceList = elem?.invoiceList.map((inv) => inv.invoiceNumber);
          notes = invoiceList.join(', ');
        }

        savedData.push({
          amount: Number(elem.amount).toFixed(2),
          notes: notes,
          job: jobId,
          recipient: supplierUserId,
          template: templateId,
          throughDate: throughDate,
          releaseExists: releaseAvailable,
          invoiceList: elem?.invoiceList ?? [],
        });
      }
      setProcessedInvoices(savedData);
      setMode('VIEW_DATA');
    } catch (err) {
      console.log(err);
      dispatch(
        openMessageModal({
          title: 'Error saving data!',
          subTitle: `Contact customer support.`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: false,
          onClose: () => {},
        })
      );
    }
  };

  function compareAndMergeReports(
    monthlyReportData: MonthlyReportData[],
    paymentReportData: PaymentReportData[]
  ): MergedReport[] {
    const result: MergedReport[] = [];

    monthlyReportData.forEach((mr) => {
      let matchingPr = paymentReportData.find(
        (pr) =>
          mr.jobNumber === pr.jobNumber &&
          mr.vendorName === pr.vendorName &&
          (mr.invoiceNumber === pr.invoiceNumber || `0${mr.invoiceNumber}` === pr.invoiceNumber)
      );

      if (!matchingPr && paymentReportData) {
        // If not found, prepend "0" to the invoiceNumber and try again
        mr.invoiceNumber = `0${mr.invoiceNumber}`;
        matchingPr = paymentReportData.find(
          (pr) =>
            mr.jobNumber === pr.jobNumber &&
            mr.vendorName === pr.vendorName &&
            mr.invoiceNumber === pr.invoiceNumber
        );
      }

      if (matchingPr) {
        const isPaid =
          parseFloat(mr.amount.toString()) === parseFloat(matchingPr.amount.toString());
        result.push({ ...matchingPr, isPaid });
      } else {
        result.push({ ...mr, isPaid: false });
      }
    });

    return result;
  }

  const processMergedData = (
    data: MergedReport[]
  ): { paidInvoice: MergedReport[]; unPaidInvoice: MergedReport[] } => {
    try {
      const groupedData: Map<string, MergedReport> = new Map();
      data.forEach((item) => {
        const key = `${item.jobNumber}_${item.vendorName}`;
        const existingItem = groupedData.get(key);

        if (existingItem) {
          if (!existingItem.invoiceList) {
            existingItem.invoiceList = [];
          }
          // Check retention is > 0
          if (Number(item?.retention) > 0) {
            existingItem.amount = Number(existingItem.amount) + Number(item.paid);
          } else {
            existingItem.amount = Number(existingItem.amount) + Number(item.amount);
          }
          existingItem.paid = Number(existingItem.paid) + Number(item.paid);
          existingItem.invoiceList.push({
            invoiceNumber: item.invoiceNumber,
            amount: Number(item.amount),
            invoiceDate: item.invoiceDate,
            isPaid: item.isPaid,
          });
        } else {
          // Check retention  is > 0
          if (Number(item?.retention) > 0) {
            item.amount = Number(item.paid);
          } else {
            item.amount = Number(item.amount);
          }
          const newItem: MergedReport = {
            ...item,
            invoiceList: [
              {
                invoiceNumber: item.invoiceNumber,
                amount: Number(item.amount),
                invoiceDate: item?.invoiceDate ?? '',
                isPaid: item.isPaid,
              },
            ],
          };
          groupedData.set(key, newItem);
        }
      });

      const combinedData = Array.from(groupedData.values());

      return {
        paidInvoice: combinedData.filter((item) => item?.invoiceList?.every((inv) => inv.isPaid)),
        unPaidInvoice: combinedData.filter((item) => item?.invoiceList?.find((inv) => !inv.isPaid)),
      };
    } catch (err) {
      console.log(err);
      dispatch(
        openMessageModal({
          title: 'Error combining files!',
          subTitle: `Contact customer support.`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: false,
          onClose: () => {},
        })
      );
      return { paidInvoice: [], unPaidInvoice: [] };
    }
  };

  useEffect(() => {
    if (monthlyReport.length > 0 && paymentReport.length > 0) {
      const mergedReport = compareAndMergeReports(monthlyReport, paymentReport);

      const processedReport = processMergedData(mergedReport);

      saveData(processedReport.paidInvoice);
      setUnpaidInvoices(processedReport.unPaidInvoice);
    }
    setLoading(false);
  }, [monthlyReport, paymentReport]);

  const onDrop = useCallback(async (acceptedFiles: File[] | null, fileRejections: any[]) => {
    setMode('UPLOADING');
    try {
      setFileErrors([]);
      setMissingFiles([]);
      if (acceptedFiles) {
        const filesToReject: { fileName: string; file: File }[] = [];
        Array.from(acceptedFiles).forEach(async (file) => {
          const reader = new FileReader();

          reader.onload = async (event: ProgressEvent<FileReader>) => {
            if (event.target && event.target.result) {
              const result: string = event.target.result.toString();
              // CSV Upload
              if (file.name.endsWith('.csv')) {
                Papa.parse(result, {
                  complete: async (parsedData) => {
                    const header = parsedData.meta.fields;
                    const checkIifMonthlyReportFile = containsRequiredProperties({
                      data: header as string[],
                      type: 'monthlyReport',
                    });
                    const checkIifPaymentReportFile = containsRequiredProperties({
                      data: header as string[],
                      type: 'paymentReport',
                    });
                    if (checkIifPaymentReportFile) {
                      setPaymentFile(file);
                    } else if (checkIifMonthlyReportFile) {
                      setMonthlyReportFile(file);
                    } else {
                      filesToReject.push({ fileName: file.name, file });
                    }
                    setRejectedFiles(filesToReject);
                  },
                  header: true,
                });
              }
              // Excel Uplaod
              if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
                const workbook = XLSX.read(result, { type: 'binary', cellStyles: true });
                const sheetName: string = workbook.SheetNames[0]; // Assuming the data is in the first sheet
                const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
                const options = { header: 1 };
                const sheetData2 = XLSX.utils.sheet_to_json(worksheet, options);
                const header = sheetData2.shift();
                const checkIifMonthlyReportFile = containsRequiredProperties({
                  data: header as string[],
                  type: 'monthlyReport',
                });
                const checkIifPaymentReportFile = containsRequiredProperties({
                  data: header as string[],
                  type: 'paymentReport',
                });
                if (checkIifPaymentReportFile) {
                  setPaymentFile(file);
                } else if (checkIifMonthlyReportFile) {
                  setMonthlyReportFile(file);
                } else {
                  filesToReject.push({ fileName: file.name, file });
                }

                setRejectedFiles(filesToReject);
              }
            }
          };

          reader.readAsBinaryString(file);
          setMode('WAITING FILE');
        });
      }
      if (fileRejections && fileRejections.length > 0) {
        const fileReject = fileRejections.map((file) => ({
          errorMessage:
            file.errors[0]?.code === 'file-invalid-type'
              ? 'File should be either .xls, .xlsx, or .csv'
              : file.errors[0]?.message || 'File is rejected',
          fileName: file.file ? file.file.name : '',
        }));
        setFileErrors(fileReject);
      }
      setMode('WAITING FILE');
    } catch (err) {
      dispatch(
        openMessageModal({
          title: 'Error upload file!',
          subTitle: `Contact customer support.`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: false,
          onClose: () => {},
        })
      );
      setMode('WAITING FILE');
      console.log(err);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    open: openFilePicker,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
    },
    noClick: true,
    noKeyboard: true,
  });

  const handleCloseModal = () => {
    setProcessedInvoices([]);
    setError([]);
    setSelected([]);
    handleClose();
    setMissingFiles([]);
    setFileErrors([]);
    setPaymentFile(null);
    setMonthlyReportFile(null);
    setRejectedFiles([]);
    setMode('WAITING FILE');
    setMonthlyReport([]);
    setPaymentReport([]);
    setUnpaidInvoices([]);
  };

  const handleChangeValue = (key: keyof ProcessedInvoicesData, index: number, value: string) => {
    if (key !== 'releaseExists' && key !== 'invoiceList') {
      const updateData = [...processedInvoices];
      updateData[index][key] = value;
      const releaseAvailable = checkIfReleaseExist(
        updateData[index].job,
        updateData[index].template,
        updateData[index].recipient,
        Number(updateData[index].amount),
        updateData[index].throughDate
      );

      updateData[index].releaseExists = releaseAvailable;
      setProcessedInvoices(updateData);
    }
  };

  const handleBlur = (key: keyof ProcessedInvoicesData, index: number, value: string) => {
    if (key !== 'releaseExists' && key !== 'invoiceList') {
      const updateData = [...processedInvoices];
      updateData[index][key] = Number(value).toFixed(2);
      const releaseAvailable = checkIfReleaseExist(
        updateData[index].job,
        updateData[index].template,
        updateData[index].recipient,
        Number(updateData[index].amount),
        updateData[index].throughDate
      );

      updateData[index].releaseExists = releaseAvailable;
      setProcessedInvoices(updateData);
    }
  };

  const handleSelectFeature = (isChecked: boolean, index: number) => {
    const selectedArray = [...selected];
    if (isChecked) {
      selectedArray.push(index);
      setSelected(selectedArray);
    } else {
      const filterArray = selectedArray.filter((itm) => itm !== index);
      setSelected(filterArray);
    }
  };

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const numberList = Array.from({ length: processedInvoices.length }, (_, index) => index);
      setSelected(numberList);
    } else {
      setSelected([]);
    }
  };

  const errorData = (data: ProcessedInvoicesData, template?: LienTemplate) => {
    let tempRequirement: string[] = [];
    if (template) {
      tempRequirement = checkTempateRequirement(template);
    }
    const errorsFound: string[] = [];
    if (data.job === '') {
      errorsFound.push('Job');
    }
    if (data.throughDate === '' && tempRequirement.includes('throughDate')) {
      errorsFound.push('Through Date');
    }
    if (data.amount === '' && tempRequirement.includes('amount')) {
      errorsFound.push('Amount');
    }
    if (data.recipient === '') {
      errorsFound.push('Recipient');
    }
    if (data.template === '') {
      errorsFound.push('Template');
    }
    return errorsFound;
  };

  const handlePreview = async (index: number, data: ProcessedInvoicesData) => {
    const temp = templates.find((tmp) => tmp.id === data.template);
    const errorsFound = errorData(data, temp);

    if (errorsFound.length > 0) {
      setPreviewLoading(null);
      setError([{ [index]: errorsFound }]);
      return;
    }
    const job = jobs.find((jb) => jb.id === data.job);
    const recipient = subSupplierUsers.find((sub) => {
      const recipientId = sub.user.subUser ? sub.user.subUser.id : sub.user.user?.id;
      const supplierSplit = data.recipient.split('__');
      return recipientId === supplierSplit[1] && sub.subSupplierId === supplierSplit[0];
    });

    if (temp && job && recipient) {
      const tempRequirement = checkTempateRequirement(temp);
      const previewData = {
        recipient: `${
          recipient?.user?.subUser?.firstName ?? recipient?.user?.user?.firstName ?? ''
        } ${recipient?.user?.subUser?.lastName ?? recipient?.user?.user?.lastName ?? ''}`,
        type: temp?.type?.toLowerCase() ?? '',
        paymentAmount:
          data.amount && tempRequirement.includes('amount')
            ? Number(Number(data.amount).toFixed(2))
            : null,
        throughDate: tempRequirement.includes('throughDate') ? data.throughDate ?? '' : null,
        job: job?.name ?? '',
        status: 'Pending' as const,
        dueDate: '',
        sentToGCTime: null,
        sentToGCEmails: null,
      };
      const populatedTemplateFields = populateTemplateFieldData(
        temp,
        data.throughDate ?? '',
        Number(Number(data.amount).toFixed(2))
      );
      const url = await populateAllPdfData(job, recipient, temp, populatedTemplateFields);
      const flatPDFUrl = await disablePDFFields(url);
      onPreview(previewData, flatPDFUrl ?? '');
    }
    setPreviewLoading(null);
  };

  const groupRelease = async (data: ProcessedInvoicesData[]) => {
    const releaseArray = [];
    for (let index = 0; index < data.length; index++) {
      const release = data[index];

      const job = jobs.find((jb) => jb.id === release.job);
      const temp = templates.find((tmp) => tmp.id === release.template);
      const recipient = subSupplierUsers.find((sub) => {
        const recipientId = sub.user.subUser ? sub.user.subUser.id : sub.user.user?.id;
        const supplierSplit = release.recipient.split('__');
        return recipientId === supplierSplit[1] && sub.subSupplierId === supplierSplit[0];
      });
      if (temp && job && recipient && selectedMainBuyerId) {
        const populatedTemplateFields = populateTemplateFieldData(
          temp,
          release.throughDate ?? '',
          Number(Number(release.amount).toFixed(2))
        );
        const url = await populateAllPdfData(job, recipient, temp, populatedTemplateFields);
        let invoiceList: { invoiceNumber: string; amount: number | null }[] = [];
        if (release?.invoiceList) {
          invoiceList = release?.invoiceList?.map((inv) => {
            return { invoiceNumber: inv.invoiceNumber, amount: inv.amount };
          });
        }
        const ProcessedInvoicesData = {
          jobId: job.id || '',
          notes: release.notes,
          previewPdfUrl: url,
          recipientId: {
            userId: recipient.user.user?.id || '',
            subUserId: recipient.user.subUser?.id || '',
          },
          subSupplierId: recipient?.subSupplierId ?? '',
          templateId: temp?.id || '',
          fieldsData: Object.values(populatedTemplateFields),
          emailReminderFrequency: 3,
          dueDate: null,
          status: 'Requested' as const,
          type: temp.type,
          releaseMonth: new Date(release.throughDate),
          storagePathName: `buyerReleases/${selectedMainBuyerId}/${recipient?.subSupplierId}/${job?.id}`,
          invoiceList: invoiceList,
        };
        releaseArray.push(ProcessedInvoicesData);
      }
    }
    return releaseArray;
  };

  const handleImportReleases = async () => {
    setLoading(true);
    const errors: { [key: number]: string[] }[] = [];
    const selectedRelease = [];
    for (let index = 0; index < selected.length; index++) {
      const selectedIndex = selected[index];
      const release = processedInvoices[selectedIndex];
      const temp = templates.find((tmp) => tmp.id === release.template);
      const errorsFound = errorData(release, temp);
      if (errorsFound.length > 0) {
        errors.push({ [selectedIndex]: errorsFound });
      }
      selectedRelease.push(release);
    }
    if (errors.length > 0) {
      setError(errors);
      setLoading(false);
      return;
    }
    setError([]);
    const updateRelease = await groupRelease(selectedRelease);
    setLoading(false);
    onImportRelease(updateRelease);
  };

  function areObjectsEqual(obj1: ProcessedInvoicesData, obj2: ProcessedInvoicesData) {
    const keys1 = Object.keys(obj1).filter(
      (key) =>
        key !== 'amount' && key !== 'releaseExists' && key !== 'notes' && key !== 'invoiceList'
    );
    const keys2 = Object.keys(obj2).filter(
      (key) =>
        key !== 'amount' && key !== 'releaseExists' && key !== 'notes' && key !== 'invoiceList'
    );

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if ((obj1 as any)[key] !== (obj2 as any)[key]) {
        return false;
      }
    }

    return true;
  }

  function areArrayObjectsEqual(array: ProcessedInvoicesData[]) {
    for (let i = 1; i < array.length; i++) {
      if (!areObjectsEqual(array[i - 1], array[i])) {
        return false;
      }
    }
    return true;
  }

  const isSameToCombine = useMemo(() => {
    const combineData = [];
    if (selected.length > 1) {
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];
        const data = processedInvoices[element];
        combineData.push(data);
      }
      const areTheyEqual = areArrayObjectsEqual(combineData);
      return areTheyEqual;
    }
    return false;
  }, [selected, processedInvoices]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const checkErrors = () => {
    const requiredFileTypes = ['MONTHLY_REPORT', 'PAYMENT_REPORT'];
    const errors = requiredFileTypes
      .filter((type) => !uploadedFiles.some((file) => file.fileType === type))
      .map((type) => type.replace('_', ' ').toLowerCase());
    setMissingFiles(errors);
    return errors;
  };

  const handleCombine = () => {
    try {
      const combineData = [];
      if (selected.length > 1) {
        const updateData = [...processedInvoices];
        for (let index = 0; index < selected.length; index++) {
          const element = selected[index];
          const data = processedInvoices[element];
          combineData.push(data);
        }
        const combinedObject: ProcessedInvoicesData = combineData.reduce(
          (result, current) => {
            const total = Number(result.amount || 0) + Number(current.amount);
            result.amount = Number(total.toFixed(2));
            const notes = `${result.notes}, ${current.notes}`;
            result.notes = notes;
            if (!result.throughDate) {
              result.throughDate = current.throughDate;
              result.job = current.job;
              result.recipient = current.recipient;
              result.template = current.template;
              result.releaseExists = false;
            }
            return result;
          },
          {
            amount: 0,
            notes: '',
            job: '',
            recipient: '',
            template: '',
            throughDate: '',
            releaseExists: false,
          }
        );
        const combinedNotes = combinedObject.notes.split(', ').filter((item) => item !== '');
        const releaseAvailable = checkIfReleaseExist(
          combinedObject.job,
          combinedObject.template,
          combinedObject.recipient,
          Number(combinedObject.amount),
          combinedObject.throughDate
        );
        updateData[selected[0]].releaseExists = releaseAvailable;
        updateData[selected[0]].amount = String(combinedObject.amount);
        updateData[selected[0]].notes = combinedNotes.join(', ');
        const indexesToRemove: number[] = selected.filter((sel) => sel !== selected[0]);
        indexesToRemove.forEach((indx) => {
          if (indx >= 0 && indx < updateData.length) {
            updateData.splice(indx, 1);
          }
        });
        setProcessedInvoices(updateData);
        setSelected([]);
        toast.success('Successfully combined data!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          style: {
            backgroundColor: '#ECFDF3',
            color: '#054F31',
          },
        });
      }
    } catch {
      toast.error('Combining data failed!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        style: {
          backgroundColor: '#FEF0EF',
          color: '#F04438',
        },
      });
    }
  };

  const handleProcessData = async () => {
    setLoading(true);
    for (let index = 0; index < uploadedFiles.length; index++) {
      const uploadedFile = uploadedFiles[index];
      const file = uploadedFile.file;
      const reader = new FileReader();

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target && event.target.result) {
          const result: string = event.target.result.toString();
          // CSV Upload
          if (file.name.endsWith('.csv')) {
            Papa.parse(result, {
              complete: async (parsedData) => {
                const csvData = parsedData.data;
                csvData.forEach((row: any) => {
                  Object.keys(row).forEach((key) => {
                    if (row[key].includes('$')) {
                      row[key] = parseFloat(row[key].replace(/[^\d.-]/g, ''));
                    }
                  });
                });
                const convertedData: UprocessedData[] = convertKeysToCamelCase(csvData as any[]);
                if (uploadedFile.fileType === 'PAYMENT_REPORT') {
                  const allPaymentData: unknown = convertedData;
                  setPaymentReport(allPaymentData as PaymentReportData[]);
                }
                if (uploadedFile.fileType === 'MONTHLY_REPORT') {
                  const allMonthlyReportData: unknown = convertedData;
                  setMonthlyReport(allMonthlyReportData as MonthlyReportData[]);
                }
              },
              header: true,
            });
          }
          // Excel Uplaod
          if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
            const workbook = XLSX.read(result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(worksheet, {
              raw: false,
              dateNF: 'mm/dd/yyyy',
              blankrows: false,
              defval: '',
            });
            excelData.forEach((row: any) => {
              Object.keys(row).forEach((key) => {
                if (typeof row[key] === 'string' && row[key].includes('$')) {
                  row[key] = parseFloat(row[key].replace(/[^\d.-]/g, ''));
                } else if (typeof row[key] === 'number' && XLSX.SSF.isValidDate(row[key])) {
                  row[key] = XLSX.SSF.parse_date_code(row[key]);
                }
              });
            });
            const convertedData: UprocessedData[] = convertKeysToCamelCase(excelData as any[]);
            if (uploadedFile.fileType === 'PAYMENT_REPORT') {
              const allPaymentData: unknown = convertedData;
              setPaymentReport(allPaymentData as PaymentReportData[]);
            }
            if (uploadedFile.fileType === 'MONTHLY_REPORT') {
              const allMonthlyReportData: unknown = convertedData;
              setMonthlyReport(allMonthlyReportData as MonthlyReportData[]);
            }
          }
        }
      };

      reader.readAsBinaryString(uploadedFile.file);
    }
  };

  const renderRow = (item: { id: string; content: React.ReactNode }) => {
    const index = Number(item.id);
    const data = processedInvoices[index];
    return (
      <div key={index + 'oo'} className="w-full flex  px-4 justify-between items-center">
        {columns.map((column) => {
          const stringIndex = index.toString();
          type ObjectKey = keyof typeof data;
          const columnKey = column.id as ObjectKey;
          const errorList = error.find((itm) => Object.keys(itm)[0] === stringIndex);
          const errorValues = errorList ? Object.values(errorList)[0] : [];
          const temp = templates.find((tmp) => tmp.id === data.template);
          const subsuppliers = [
            ...subSupplierUsers.map((subSupplierUser) => {
              const userDetails = subSupplierUser.user.subUser
                ? subSupplierUser.user.subUser
                : subSupplierUser.user.user;
              return {
                label: `${subSupplierUser.subSupplierName} - ${userDetails?.firstName || ''} ${
                  userDetails?.lastName || ''
                } `,
                value: `${subSupplierUser.subSupplierId}__${userDetails?.id ?? ''}`,
              };
            }),
          ];
          let checkRequreTempField: string[] = [];
          if (temp) {
            checkRequreTempField = checkTempateRequirement(temp);
          }
          let value = '';
          let amount = '';
          if (column.id === 'throughDate') {
            if (checkRequreTempField.includes('throughDate') && columnKey === 'throughDate') {
              if (data[columnKey] !== 'Invalid date') {
                value = data[columnKey]?.toString();
              }
            } else {
              value = 'N/A';
            }
          }
          if (column.id === 'amount') {
            if (checkRequreTempField.includes('amount')) {
              amount = (data as any)[column.id];
            } else {
              amount = 'N/A';
            }
          }
          return (
            <div key={column.id} style={{ flex: column.width / totalWidth }} className="py-3 px-1">
              {column.cellType === 'SELECT' && column.id === 'select' && (
                <div className="flex justify-center items-center">
                  <AppCheckBox
                    isChecked={selected.includes(index)}
                    onClick={(isChecked: boolean) => {
                      handleSelectFeature(isChecked, index);
                    }}
                  />
                  {data.releaseExists ? (
                    <div
                      data-tooltip-id={`relase-exist-tooltip-${index}`}
                      className="flex p-1 ERROR_100-BG ERROR_500-CLR justify-center items-center rounded-full ml-2 cursor-pointer">
                      <Warning size={18} weight="bold" />
                    </div>
                  ) : (
                    <div className="h-2 w-11" />
                  )}
                </div>
              )}
              {data.releaseExists && (
                <ReactTooltip
                  id={`relase-exist-tooltip-${index}`}
                  place="top"
                  variant="dark"
                  opacity={'100%'}
                  style={{
                    display: 'flex',
                    background: '#334155',
                    width: '300px',
                    zIndex: '70',
                    borderRadius: '8px',
                    alignItems: 'center',
                    justifyItems: 'center',
                    fontWeight: '600',
                  }}>
                  <span>{`This release has already been created`}</span>
                </ReactTooltip>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'recipient' && (
                <AppDropDown
                  value={(data as any)[column.id]}
                  errorText={errorValues.includes(column.name) ? `${column.name} is required` : ''}
                  items={subsuppliers}
                  onSelectChange={(val) => {
                    if (val) {
                      handleChangeValue(columnKey, index, val.value);
                    }
                  }}
                  height={240}
                />
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'job' && (
                <AppDropDown
                  value={(data as any)[column.id]}
                  errorText={errorValues.includes(column.name) ? `${column.name} is required` : ''}
                  items={[
                    ...jobs
                      .filter((jb) => !jb?.archivedState?.includes('BUYER_ARCHIVED'))
                      .map((job) => {
                        return {
                          label: `${job?.number ? `${job.number} - ` : ''}${job.name}`,
                          value: job.id,
                        };
                      }),
                  ]}
                  onSelectChange={(val) => {
                    if (val) {
                      handleChangeValue(columnKey, index, val.value);
                    }
                  }}
                  height={240}
                />
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'template' && (
                <AppDropDown
                  value={(data as any)[column.id]}
                  errorText={errorValues.includes(column.name) ? `${column.name} is required` : ''}
                  items={[
                    ...templates.map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                  ]}
                  onSelectChange={(itm) => {
                    if (itm) {
                      handleChangeValue(columnKey, index, itm.value);
                    }
                  }}
                  height={240}
                />
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'amount' && (
                <div>
                  {amount === 'N/A' ? (
                    <div className="w-full ">{amount}</div>
                  ) : (
                    <AppInputField
                      inputType="number"
                      prefix={'$'}
                      errorText={
                        errorValues.includes(column.name) ? `${column.name} is required` : ''
                      }
                      onBlur={(e) => handleBlur(columnKey, index, e.target.value)}
                      value={amount}
                      onTextChange={(text) => {
                        handleChangeValue(columnKey, index, text);
                      }}
                    />
                  )}
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'notes' && (
                <div className="text-sm">
                  <AppInputField
                    inputType="text"
                    value={(data as any)[column.id]}
                    onTextChange={(text) => {
                      handleChangeValue(columnKey, index, text);
                    }}
                  />
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'throughDate' && (
                <div>
                  {value === 'N/A' ? (
                    <div>{value}</div>
                  ) : (
                    <AppDatePicker
                      errorText={
                        errorValues.includes(column.name) ? `${column.name} is required` : ''
                      }
                      selectedDate={value !== '' ? new Date((data as any)[column.id]) : null}
                      onDateSelected={(date) => {
                        if (date) {
                          handleChangeValue(columnKey, index, moment(date).format('MM/DD/YYYY'));
                        }
                      }}
                    />
                  )}
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'action' && (
                <div className="flex items-center justify-center w-full">
                  <AppButton
                    type="TERTIARY"
                    buttonStyles={{
                      padding: '8px',
                      margin: '5px 8px',
                    }}
                    icon={<Eye color={COLORS.GREY_500} size={20} />}
                    onClick={() => {
                      setPreviewLoading(index);
                      handlePreview(index, data);
                    }}
                    isLoading={previewLoading === index}
                    isDisabled={loading}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderUnpaidInvoiceRow = (item: { id: string; content: React.ReactNode }) => {
    const index = Number(item.id);
    const data = unpaidInvoices[index];
    return (
      <div key={index + 'oo'} className="w-full flex  px-4 justify-between items-center">
        {unPaidInvoiceColumns.map((column) => {
          type ObjectKey = keyof typeof data;
          const columnKey = column.id as ObjectKey;
          let value = data[columnKey]?.toString();
          if (column.id === 'amount' && value) {
            value = formatMoney(value, 2);
          }
          if (column.id === 'notes') {
            if (data?.invoiceList) {
              const invoiceList = data?.invoiceList.map((inv) => inv.invoiceNumber);
              value = invoiceList.join(', ');
            } else {
              value = '';
            }
          }
          if (column.id === 'throughDate') {
            const invoiceDate = data?.invoiceDate ? new Date(data?.invoiceDate) : new Date();
            value = moment(invoiceDate).add(1, 'day').format('MM/DD/YYYY');
          }
          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalUpaidInvoiceWidth }}
              className="py-3 px-1">
              {column.cellType === 'VIEW_TEXT' && <div className="text-sm">{value}</div>}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div>
        {mode === 'WAITING FILE' && (
          <div className="flex min-h-80 w-[540px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
            <h2 className="text-lg font-semibold mx-4 my-3">Import CSV or Excel file</h2>
            <X
              className="absolute right-4 top-4 cursor-pointer"
              color={COLORS.GREY_500}
              onClick={handleCloseModal}
            />
            <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
            <div className="mx-4 mt-3 mb-2">
              <div>
                <div
                  {...getRootProps({ style })}
                  className="flex flex-col items-center w-full PRIMARY_50-BG justify-center p-4">
                  <input {...getInputProps()} />

                  <div className="p-4 rounded-full PRIMARY_100-BG mb-2">
                    <FileArrowUp size={54} color={COLORS.PRIMARY_500} />
                  </div>

                  <div className="flex flex-col items-center">
                    <AppButton
                      text="Browse Files"
                      type="SECONDARY"
                      onClick={openFilePicker}
                      buttonStyles={{ marginRight: '8px' }}
                      icon={<UploadSimple size={20} color={COLORS.PRIMARY_500} />}
                    />
                    <p className="text-sm mt-2">Choose files or drag 'n' drop some files here</p>
                    <div className="text-sm mt-2">Accepted file .csv .xslx</div>
                  </div>
                </div>
                {uploadedFiles.length > 0 && (
                  <div className="border GREY_200-BORDER rounded p-2 mt-2">
                    {uploadedFiles.map((uploadedFile, i) => (
                      <div key={`uploaded_files_${i}`} className="flex items-center my-1">
                        <CheckCircle color={COLORS.SUCCESS_500} size={20} weight="fill" />
                        <div className="capitalize w-[140px] text-sm ml-3">
                          {`${uploadedFile.fileType.replace('_', ' ').toLowerCase()} file:`}
                        </div>
                        <div className="text-sm">{uploadedFile.fileName}</div>
                      </div>
                    ))}
                  </div>
                )}

                {fileErrors.map((fileError, i) => (
                  <div key={`file_error_${i}`} className="flex mt-2">
                    <div className="text-sm">{`${fileError.fileName}: `}</div>
                    <div className="text-sm ERROR_500-CLR">{fileError.errorMessage}</div>
                  </div>
                ))}
                {missingFiles.length > 0 && (
                  <div>
                    <div className="border ERROR_500-BORDER rounded p-2 mt-2">
                      {missingFiles.map((missingFile, i) => (
                        <div key={`missing_files_${i}`} className="flex items-center my-1">
                          <XCircle color={COLORS.ERROR_500} size={20} weight="fill" />
                          <div className="capitalize text-sm ml-2">
                            {`${missingFile.toLowerCase()} file missing`}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="text-sm ERROR_500-CLR mt-2">{`Missing file${
                      missingFiles.length > 1 ? 's' : ''
                    } above`}</div>
                  </div>
                )}
                {rejectedFiles.length > 0 && (
                  <div>
                    <div className="border ERROR_500-BORDER rounded p-2 mt-2">
                      {rejectedFiles.map((rejectedFile, i) => (
                        <div key={`missing_files_${i}`} className="flex items-center my-1">
                          <XCircle color={COLORS.ERROR_500} size={20} weight="fill" />
                          <div className="text-sm ml-2">
                            {`${rejectedFile.fileName.toLowerCase()} does not match payment or montly report file`}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
            <div className="flex justify-end mx-3 mb-2">
              <AppButton
                text="Cancel"
                type="TERTIARY"
                onClick={handleClose}
                buttonStyles={{ marginRight: '8px' }}
              />
              <AppButton
                text={'Process Files'}
                onClick={() => {
                  const missingFileError = checkErrors();
                  if (missingFileError.length === 0 && mode === 'WAITING FILE') {
                    handleProcessData();
                  }
                }}
                isLoading={loading}
                isDisabled={loading}
                buttonStyles={{
                  width: '100px',
                  height: '40px',
                }}
              />
            </div>
          </div>
        )}
        {mode === 'UPLOADING' && (
          <div className="flex min-h-80 w-[540px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
            <h2 className="text-lg font-semibold mx-4 my-3">Import CSV or Excel file</h2>
            <X
              className="absolute right-4 top-4 cursor-pointer"
              color={COLORS.GREY_500}
              onClick={handleCloseModal}
            />
            <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
            <div className="mx-4 mt-3 mb-2">
              <div className="flex flex-col items-center w-full PRIMARY_50-BG justify-center p-4">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      borderTop: `6px solid ${COLORS.PRIMARY_500}`,
                      borderRight: `6px solid ${COLORS.PRIMARY_500}`,
                      borderBottom: `6px solid ${COLORS.PRIMARY_500}`,
                      borderLeft: `6px solid ${COLORS.PRIMARY_100}`,
                      borderRadius: '50%',
                      width: 80,
                      height: 80,
                      animation: 'spin 1s linear infinite',
                    }}></div>
                </div>
                <div className="mt-2">Uploading</div>
              </div>
            </div>
          </div>
        )}
        {mode === 'VIEW_DATA' && (
          <div className="flex w-[80vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
            <h2 className="text-lg font-semibold mx-4 my-3">Import CSV or Excel file</h2>
            <X
              className="absolute right-4 top-4 cursor-pointer"
              color={COLORS.GREY_500}
              onClick={handleCloseModal}
            />
            <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
            <div className="mx-4 mt-3 mb-2">
              <div className="flex flex-col items-center w-full PRIMARY_50-BG justify-center p-4">
                <div className="flex items-center w-full mb-2 justify-between">
                  <div className="flex items-center">
                    <div className="flex w-full my-2 font-medium text-sm">
                      Select the data you want to import
                    </div>
                    {unpaidInvoices.length > 0 && (
                      <div
                        className={
                          'text-xs font-bold py-1.5 px-3 whitespace-nowrap rounded-full w-full text-center flex items-center ERROR_100-BG ERROR_500-CLR ml-2'
                        }>
                        <Warning size={15} weight="bold" />
                        <div className="w-full text-center flex">
                          <p className="ml-2 w-full">
                            {`${unpaidInvoices.length} remaining release${
                              unpaidInvoices.length > 1 ? 's' : ''
                            } in this monthly report`}
                          </p>
                          <div
                            className="ml-2 text-xs font-bold underline underline-offset-2 w-full cursor-pointer"
                            onClick={() => setMode('SHOW_UNPAID_INVOICES')}>
                            See Details
                          </div>
                        </div>
                      </div>
                    )}
                    {unpaidInvoices.length === 0 && (
                      <div
                        className={
                          'text-xs font-bold py-1.5 px-3 whitespace-nowrap rounded-full w-full text-center flex items-center SUCCESS_100-BG SUCCESS_500-CLR ml-2'
                        }>
                        <CheckCircle size={15} weight="bold" />
                        <p className="ml-2 w-full">
                          {`All releases covered in this monthly report`}
                        </p>
                      </div>
                    )}
                  </div>
                  <div data-tooltip-id={!isSameToCombine ? `combine-data-tooltip` : undefined}>
                    <AppButton
                      type="SECONDARY"
                      icon={
                        <ArrowsMerge
                          color={!isSameToCombine ? COLORS.PRIMARY_200 : COLORS.PRIMARY_500}
                          size={20}
                          style={{
                            transform: `rotate(-90deg)`,
                          }}
                        />
                      }
                      text="Combine"
                      onClick={handleCombine}
                      isDisabled={!isSameToCombine}
                    />
                  </div>
                  {!isSameToCombine && (
                    <ReactTooltip
                      id={`combine-data-tooltip`}
                      place="top"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        display: 'flex',
                        background: '#334155',
                        width: '300px',
                        zIndex: '70',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        fontWeight: '600',
                      }}>
                      <span>{`${
                        selected.length < 2
                          ? 'Please select at least two entries to combine'
                          : 'Entries selected should match except the amount'
                      }`}</span>
                    </ReactTooltip>
                  )}
                </div>
                <div className="flex w-full flex-col min-h-[40vh]  max-h-[55vh] overflow-y-scroll border WHITE-BG rounded-xl">
                  {processedInvoices.length > 0 && (
                    <div className="flex border-b px-5 items-center w-full sticky top-0 PRIMARY_50-BG py-3 z-20">
                      {columns.map((column) => {
                        return (
                          <div
                            key={column.id}
                            style={{ flex: column.width / totalWidth }}
                            className="flex w-full">
                            {column.cellType === 'SELECT' && column.id === 'select' && (
                              <AppCheckBox
                                isChecked={selected.length === processedInvoices.length}
                                onClick={(isChecked: boolean) => {
                                  handleSelectAll(isChecked);
                                }}
                              />
                            )}
                            {column.cellType !== 'SELECT' && column.id !== 'select' && (
                              <p className="TEXT_SECONDARY-CLR text-xs w-full text-left">
                                {column.name}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {processedInvoices.length === 0 && (
                    <div className="flex w-full h-[50vh] items-center justify-center font-semibold TEXT_SECONDARY-CLR">
                      {`Some of the ${
                        hasHighligtedRows ? 'highlighted' : ''
                      } invoices in the file uploaded have not been paid. No releases have been created.`}
                    </div>
                  )}
                  <div>
                    {processedInvoices.length > 0 && (
                      <div className="h-[50vh]">
                        <AppVirtualizedList
                          itemsLength={processedInvoices.length}
                          itemHeight={70}
                          containerHeight={50}
                          renderRow={renderRow}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* @Gabriel, I've commented this out for now */}
              {/* <div className="text-SM TEXT_SECONDARY-CLR  mt-5">
                Please make sure your column & row format is same with our standard, or you can
                download template file <button className="PRIMARY_500-CLR underline">here</button>
              </div> */}
            </div>
            <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
            <div className="flex justify-end mx-3 mb-2">
              <AppButton
                text="Cancel"
                type="TERTIARY"
                onClick={handleCloseModal}
                buttonStyles={{ marginRight: '8px' }}
              />
              <AppButton
                text="Import"
                onClick={handleImportReleases}
                isDisabled={selected.length === 0 || loading}
                buttonStyles={{
                  width: '100px',
                  height: '40px',
                }}
                isLoading={loading}
              />
            </div>
          </div>
        )}
        {mode === 'SHOW_UNPAID_INVOICES' && (
          <div className="flex w-[80vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
            <h2 className="text-lg font-semibold mx-4 my-3">Import CSV or Excel file</h2>
            <X
              className="absolute right-4 top-4 cursor-pointer"
              color={COLORS.GREY_500}
              onClick={handleCloseModal}
            />
            <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
            <div className="mx-4 mt-3 mb-2">
              <div className="flex flex-col items-center w-full PRIMARY_50-BG justify-center p-4">
                <div className="flex w-full flex-col min-h-[40vh]  max-h-[65vh] overflow-y-scroll border WHITE-BG rounded-xl">
                  {unpaidInvoices.length > 0 && (
                    <div className="flex border-b px-5 items-center w-full sticky top-0 PRIMARY_50-BG py-3 z-20">
                      {unPaidInvoiceColumns.map((column) => {
                        return (
                          <div
                            key={column.id}
                            style={{ flex: column.width / totalUpaidInvoiceWidth }}
                            className="flex w-full">
                            <p className="TEXT_SECONDARY-CLR text-xs w-full text-left">
                              {column.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div>
                    {unpaidInvoices.length > 0 && (
                      <div className="h-[50vh]">
                        <AppVirtualizedList
                          itemsLength={unpaidInvoices.length}
                          itemHeight={70}
                          containerHeight={50}
                          renderRow={renderUnpaidInvoiceRow}
                          className="pb-2"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
            <div className="flex justify-end mx-3 mb-2">
              <AppButton
                text="Back to Paid Invoice Releases"
                onClick={() => setMode('VIEW_DATA')}
                buttonStyles={{
                  height: '40px',
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
