import React, { useMemo } from 'react';

import emptyListImg from '../../../../../assets/images/emptyList.png';
import { LienTemplate } from '@dill/dill-shared';
import moment from 'moment';
import { DotsThreeVertical, Pencil, Trash } from 'phosphor-react';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { COLORS } from '../../../../../utils/colors';

const BuyerLienTemplatesTable = ({ lienTemplates }: { lienTemplates: LienTemplate[] }) => {
  const columns = useMemo(() => {
    return [
      { id: 'name', name: 'Name', width: 200, cellType: 'VIEW_TEXT' },
      { id: 'type', name: 'Type', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'created', name: 'Created', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 150, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  return (
    <div className="w-full h-5/6 flex flex-col border rounded-lg  overflow-y-scroll mt-4">
      <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG">
        {columns.map((column) => {
          return (
            <div key={column.id} style={{ flex: column.width / totalWidth }} className="py-3 px-1">
              <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
            </div>
          );
        })}
      </div>

      {lienTemplates.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>No Templates</p>
          </div>
        </div>
      )}

      {lienTemplates &&
        lienTemplates.map((lienTemplate, index) => {
          return (
            <div
              key={index + 'oo'}
              className="w-full flex  px-4 justify-between items-center border-b border-b-gray-200">
              {columns.map((column) => {
                return (
                  <div
                    key={column.id}
                    style={{ flex: column.width / totalWidth }}
                    className="py-3 px-1">
                    {column.id === 'name' && (
                      <p className="text-sm capitalize">{lienTemplate.name}</p>
                    )}
                    {column.id === 'type' && (
                      <p className="text-sm capitalize">
                        {lienTemplate?.type?.toLowerCase()?.replaceAll('_', ' ')}
                      </p>
                    )}
                    {column.id === 'created' && (
                      <p className="text-sm capitalize">
                        {' '}
                        {lienTemplate?.createdAt
                          ? moment(lienTemplate?.createdAt).format('MM/DD/YYYY')
                          : ''}
                      </p>
                    )}
                    {column.id === 'status' && (
                      <p className="text-sm capitalize">{lienTemplate.status}</p>
                    )}
                    {/* {column.id === 'actions' && (
                      <div className="flex relative justify-center items-center ">
                        <div className="group mr-1">
                          <div className="border px-2 py-1 rounded-lg GREY_100-BG absolute -left-3 z-10 opacity-0 hidden  group-hover:opacity-100 group-hover:flex group-hover:flex-col">
                            <div
                              className="flex items-center text-xs cursor-pointer"
                              onClick={() => {}}>
                              <Pencil />
                              <p className="ml-1">Edit</p>
                            </div>
                            <div className="flex items-center text-xs mt-1 text-red-500 cursor-pointer">
                              <Trash />
                              <p className="ml-1">Delete</p>
                            </div>
                          </div>
                          <AppButton
                            buttonColor="PRIMARY_50"
                            buttonType="OUTLINED"
                            buttonStyles={{
                              padding: '4px',
                              margin: '2px 2px',
                              backgroundColor: COLORS.PRIMARY_50,
                              borderWidth: 1,
                              borderColor: '#E4EAF1',
                            }}
                            icon={<DotsThreeVertical color={COLORS.GREY_500} size={20} />}
                            onClick={() => {}}
                          />
                        </div>
                        <AppButton text="Preview" onClick={() => {}} />
                      </div>
                    )} */}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default BuyerLienTemplatesTable;
