import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  getGroupedSuppliersInvoices,
  supplierDownloadSupplierInvoiceStatements,
  updateBuyerGroupedInvoice,
} from '../../../../redux/services/invoicesService';
import {
  AppFile,
  Buyer,
  DillInvoice,
  QuickbooksCreditMemo,
  QuickbooksCreditMemoSchema,
  QuickbooksInvoice,
  QuickbooksInvoiceSchema,
  SupplierForBuyer,
  formatMoney,
  getCurrentTimezoneDate,
  roundTo,
} from '@dill/dill-shared';
import { twMerge } from 'tailwind-merge';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { List, AutoSizer } from 'react-virtualized';
import moment from 'moment';
import { AppButton } from '../../../general/AppButton/AppButton';
import { X, Note, SortAscending, SortDescending, ArrowsDownUp, FilePlus } from 'phosphor-react';
import { Download, Eye, Mail, Search } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { downloadFile, openDillToast, truncateText } from '../../../../utils/helpers';
import { sendInvoicePaymentReminderEmail } from '../../../../redux/services/paymentsService';
import { AppPdfPreviewModal } from '../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import AppViewInvoiceWithoutPdfModal from '../../../general/AppViewInvoiceWithoutPdfModal/AppViewInvoiceWithoutPdfModal';
import { getDocumentPdf } from '../../../../redux/services/buyersService';
import { logEvent } from 'firebase/analytics';
import { myAnalytics } from '../../../../firebase/config';
import AppNotesModal from '../../../general/AppNotesModal/AppNotesModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AppInputField } from '../../../general/AppInputField/AppInputField';

const CustomerInvoicesTable = ({ selectedBuyer }: { selectedBuyer?: Buyer | null }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [currentBuyerInvoices, setCurrentBuyerInvoices] = useState<
    (QuickbooksInvoice | QuickbooksCreditMemo)[]
  >([]);

  const [previewPdfList, setPreviewPdfList] = useState([] as any);
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<QuickbooksInvoice | null>(null);
  const [selectedCreditMemo, setSelectedCreditMemo] = useState<QuickbooksCreditMemo | null>(null);
  const [isViewInvoiceWithoutPdfModalOpen, setIsViewInvoiceWithoutPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState(false);
  const [sortedInvoices, setSortedInvoices] = useState<
    (QuickbooksInvoice | QuickbooksCreditMemo)[]
  >([]);

  const columns = useMemo(() => {
    return [
      { id: 'docNumber', name: 'Document #', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'type', name: 'Type', width: 60, cellType: 'VIEW_TEXT' },
      { id: 'poNumber', name: 'PO #', width: 80, cellType: 'VIEW_TEXT' },
      { id: 'job', name: 'Job', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'dueDate', name: 'Due Date', width: 90, cellType: 'VIEW_TEXT' },
      { id: 'createdAt', name: 'Date', width: 90, cellType: 'VIEW_TEXT' },
      { id: 'terms', name: 'Terms', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'balance', name: 'Open', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'notes', name: 'Notes', width: 70, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 220, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const fetchData = async (buyerId: string) => {
    const results = await dispatch(
      getGroupedSuppliersInvoices({ buyerId: buyerId, weeksLimit: 12 })
    );
    if (results && results.type === 'suppliers/getGroupedSuppliersInvoices/fulfilled') {
      const payload = results.payload as any;
      if (payload.data.buyerSuppliers) {
        const data = payload.data.buyerSuppliers as SupplierForBuyer[];
        let invoices: (QuickbooksInvoice | QuickbooksCreditMemo)[] = [];
        data.forEach((item) => {
          invoices = [...invoices, ...(item.invoices?.pastDueInvoices.invoices || [])];
          invoices = [...invoices, ...(item.invoices?.currentInvoices.invoices || [])];
          invoices = [...invoices, ...(item.invoices?.pendingPayInvoices.invoices || [])];
          invoices = [...invoices, ...(item.invoices?.creditMemosDetails.creditMemos || [])];
          invoices = [...invoices, ...(item.invoices?.creditMemosDetails.pendingCreditMemos || [])];
        });
        setCurrentBuyerInvoices(invoices);
      } else {
        setCurrentBuyerInvoices([]);
      }
    } else {
      setCurrentBuyerInvoices([]);
    }
  };
  useEffect(() => {
    if (selectedBuyer) {
      setCurrentBuyerInvoices([]);
      fetchData(selectedBuyer?.id);
    }
    setLoading(false);

    return () => {};
  }, [selectedBuyer]);

  const filteredCurrentBuyerInvoices = useMemo(() => {
    let finalSearchedInvoices = [];
    if (currentBuyerInvoices) {
      const searched = currentBuyerInvoices.filter((documentDetails) => {
        const invoiceCheck = QuickbooksInvoiceSchema.safeParse(documentDetails);
        const creditMemoCheck = QuickbooksCreditMemoSchema.safeParse(documentDetails);
        let invoice: QuickbooksInvoice | null = null;
        let creditMemo: QuickbooksCreditMemo | null = null;
        if (invoiceCheck.success) {
          invoice = invoiceCheck.data;
        }
        if (creditMemoCheck.success) {
          creditMemo = creditMemoCheck.data;
        }
        let filters: {
          isMatchTextFilter?: boolean;
          isMatchJobFilter?: boolean;
          isMatchInvoiceFilter?: boolean;
        } = {};
        if (searchText) {
          if (
            (invoice &&
              ((invoice?.quickBooksJob?.jobName &&
                invoice?.quickBooksJob?.jobName
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
                (invoice?.quickBooksJob?.address &&
                  invoice?.quickBooksJob?.address
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.quickBooksJob?.jobNumber &&
                  invoice?.quickBooksJob?.jobNumber
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.poNumber &&
                  invoice?.poNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.amount &&
                  Number(invoice?.amount)
                    .toFixed(2)
                    .toString()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.terms &&
                  invoice?.terms.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.dueDate &&
                  moment(invoice?.dueDate)
                    .format('MM/DD/YYYY')
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.createdAt &&
                  moment(invoice?.createdAt)
                    .format('MM/DD/YYYY')
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.docNumber &&
                  invoice?.docNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.balance &&
                  Number(invoice?.balance)
                    .toFixed(2)
                    .toString()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.recordType &&
                  invoice?.recordType
                    .toString()
                    .toLowerCase()
                    .replace('_', ' ')
                    .includes(searchText.toLowerCase())))) ||
            (creditMemo &&
              ((creditMemo?.quickBooksJob?.jobName &&
                creditMemo?.quickBooksJob?.jobName
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
                (creditMemo?.quickBooksJob?.address &&
                  creditMemo?.quickBooksJob?.address
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.quickBooksJob?.jobNumber &&
                  creditMemo?.quickBooksJob?.jobNumber
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.docNumber &&
                  creditMemo?.docNumber
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.totalAmount &&
                  Number(creditMemo?.totalAmount)
                    .toFixed(2)
                    .toString()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.jobName &&
                  creditMemo?.jobName
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.createdAt &&
                  moment(creditMemo?.createdAt)
                    .format('MM/DD/YYYY')
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.remainingCredit &&
                  Number(creditMemo?.remainingCredit)
                    .toFixed(2)
                    .toString()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.recordType &&
                  creditMemo?.recordType
                    .toString()
                    .toLowerCase()
                    .replace('_', ' ')
                    .includes(searchText.toLowerCase()))))
          ) {
            filters = { ...filters, isMatchTextFilter: true };
          } else {
            filters = { ...filters, isMatchTextFilter: false };
          }
        }

        return Object.values(filters).every((item) => item);
      });
      finalSearchedInvoices = searched;
    } else {
      finalSearchedInvoices = currentBuyerInvoices;
    }
    return finalSearchedInvoices;
  }, [currentBuyerInvoices, searchText]);

  const handleSendInvoicePaymentReminder = async (invoiceId: string) => {
    if (selectedBuyer) {
      const results = await dispatch(
        sendInvoicePaymentReminderEmail({ buyerId: selectedBuyer?.id, invoiceId })
      );
      if (results.type === 'payments/sendInvoicePaymentReminderEmail/fulfilled') {
        openDillToast({ type: 'SUCCESS', message: 'Payment reminder sent!' });
        fetchData(selectedBuyer?.id);
      } else {
        const payload = results.payload as any;
        let errorMessage = 'Error sending payment reminder!';
        if (payload) {
          errorMessage = payload;
        }
        openDillToast({ type: 'ERROR', message: errorMessage });
      }
    }
  };
  const handleDownloadSelectedPdf = (url: string) => {
    downloadFile(url, `invoice-${selectedInvoice?.docNumber}-${+new Date()}.pdf`);
  };

  const handleDownloadPdf = async ({
    type,
    id,
    docNumber,
    signedPdfs,
  }: {
    type: 'INVOICE' | 'CREDIT_MEMO';
    id: string;
    docNumber: string;
    signedPdfs?: AppFile[] | undefined | null;
  }) => {
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      if (sortedPdfs.length > 1) {
      } else {
        downloadFile(sortedPdfs[0].url || '', `invoice-${docNumber}-${+new Date()}.pdf`);
        logEvent(myAnalytics, 'buyer_download_invoice', {
          userId: user ? user.id : '',
          time: new Date(),
          route: location.pathname,
        });
      }
    } else {
      const res = await dispatch(
        getDocumentPdf({
          supplierDomainId: user?.userSupplier?.domainId,
          id,
          type,
        })
      );
      if (res && res.type === 'buyers/getDocumentPdf/fulfilled') {
        openDillToast({ message: 'invoice pdf successfully downloaded', type: 'SUCCESS' });
      } else {
        openDillToast({ message: 'Failed to download invoice pdf', type: 'ERROR' });
      }

      logEvent(myAnalytics, 'buyer_download_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    }
  };

  const handlePreviewPdf = async ({
    type,
    id,
    docNumber,
    signedPdfs,
  }: {
    type: 'INVOICE' | 'CREDIT_MEMO';
    id: string;
    docNumber: string;
    signedPdfs?: AppFile[] | undefined | null;
  }) => {
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      setPreviewPdfList(sortedPdfs);
      setOpenPdfPreviewModal(true);
      logEvent(myAnalytics, 'buyer_preview_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    } else {
      const invoice = await dispatch(
        getDocumentPdf({
          supplierDomainId: user?.userSupplier?.domainId,
          id,
          type,
          preview: true,
        })
      );
      if (invoice.payload) {
        const payload = invoice.payload as any;
        if (payload?.data?.pdfUrl) {
          setPreviewPdfList([{ url: payload.data.pdfUrl, name: 'pdf', date: new Date() }]);
          setOpenPdfPreviewModal(true);
        }
      }
    }
  };

  const handlePreviewInvoiceWithoutPdf = (invoice: QuickbooksInvoice) => {
    setSelectedInvoice(invoice);
    setIsViewInvoiceWithoutPdfModalOpen(true);
  };

  const handleUpdateGroupedInvoice = async (val: string) => {
    setLoading(true);
    const resp = await dispatch(
      updateBuyerGroupedInvoice({
        invoiceId: selectedInvoice?.id ?? '',
        data: { notes: [{ content: val, date: new Date() }] },
      })
    );
    setLoading(false);
    if (resp.type === 'suppliers/updateBuyerGroupedInvoice/fulfilled') {
      setOpenNotesModal(false);
      openDillToast({ message: 'Successfully updated invoice', type: 'SUCCESS' });
      fetchData(selectedBuyer?.id ?? '');
    } else {
      openDillToast({ message: 'Failed to update invoice', type: 'ERROR' });
    }
  };

  const getDocumentType = (
    invoice: QuickbooksInvoice | null,
    creditMemo: QuickbooksCreditMemo | null
  ): string => {
    if (invoice && invoice.recordType === 'FINANCE_CHARGE') {
      return 'Finance Charge';
    } else if (invoice && invoice.recordType === 'DEBIT_MEMO') {
      return 'Debit Memo';
    } else if (invoice && !invoice.recordType) {
      return 'Invoice';
    } else if (creditMemo && creditMemo.recordType === 'PAYMENT') {
      return 'Payment';
    } else if (creditMemo && creditMemo.recordType === 'MISC_CREDITS') {
      return 'Misc Credits';
    } else if (creditMemo) {
      return 'Credit Memo';
    } else {
      return '';
    }
  };

  const renderRow = ({ index, key, style }: any) => {
    const documentDetails: any = sortedInvoices[index];
    const invoiceCheck = QuickbooksInvoiceSchema.safeParse(documentDetails);
    const creditMemoCheck = QuickbooksCreditMemoSchema.safeParse(documentDetails);
    let invoice: QuickbooksInvoice | null = null;
    let creditMemo: QuickbooksCreditMemo | null = null;
    let id: string;
    let jobName: string;
    let jobAddress: string;
    let dillInvoice: DillInvoice | null = null;
    if (invoiceCheck.success) {
      invoice = invoiceCheck.data;
      id = invoice.id;
      if (invoice.jobId) {
        jobName = invoice.quickBooksJob?.jobName || '';
        jobAddress = invoice.quickBooksJob?.address || invoice.shipAddress?.address1 || '';
      }
      if (invoice.dillInvoice) {
        dillInvoice = invoice.dillInvoice;
      }
    }
    if (creditMemoCheck.success) {
      creditMemo = creditMemoCheck.data;
      id = creditMemo.id;
      if (creditMemo.jobId) {
        jobName = `${
          creditMemo.quickBooksJob?.jobNumber ? `${creditMemo.quickBooksJob?.jobNumber} - ` : ''
        }${creditMemo.quickBooksJob?.jobName ?? ''}`;
        jobAddress = creditMemo.quickBooksJob?.address || creditMemo.shipAddress?.address1 || '';
      }
    }

    return (
      <div key={key + 'oo'} style={style} className={'flex  px-2 my-2 border-b border-b-gray-200'}>
        {columns.map((column) => {
          let value: string | undefined;

          if (invoice) {
            type ObjectKey = keyof typeof invoice;
            const columnKey = column.id as ObjectKey;
            value = invoice[columnKey]?.toString();
          }
          if (creditMemo) {
            type ObjectKey = keyof typeof creditMemo;
            const columnKey = column.id as ObjectKey;
            value = creditMemo[columnKey]?.toString();
            if (column.id === 'amount') {
              value = `${creditMemo?.totalAmount}`;
            }
          }
          let isPdfAvailable = false;
          if ((invoice && invoice.isPdfAvailable) || (creditMemo && creditMemo.isPdfAvailable)) {
            isPdfAvailable = true;
          }

          if (column.id === 'createdAt' && invoice) {
            value = invoice.createdAt ? moment(invoice.createdAt).utc().format('MM/DD/YYYY') : '';
          }

          if (column.id === 'createdAt' && creditMemo) {
            value = creditMemo.createdAt
              ? moment(creditMemo.createdAt).utc().format('MM/DD/YYYY')
              : '';
          }

          if (column.id === 'dueDate' && invoice) {
            value = value ? moment(value).utc().format('MM/DD/YYYY') : '';
          }
          if (column.id === 'dueDate' && creditMemo) {
            value = '';
          }
          if (column.id === 'balance' || column.id === 'amount') {
            value = `$${roundTo(parseFloat(value || '0'), 2)}`;
          }
          if (column.id === 'payment' && invoice) {
            value = formatMoney(invoice?.dillInvoicePaymentSummary?.amountPaid || '0', 2);
          }
          if (column.id === 'type') {
            value = getDocumentType(invoice, creditMemo);
          }

          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalWidth }}
              className="flex items-center w-full h-full  mx-0.5 ">
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'download' &&
                column.id !== 'signedBy' &&
                column.id !== 'job' &&
                column.id !== 'notes' &&
                column.id !== 'isPastDueDate' && (
                  <p
                    className={
                      'text-sm ' +
                      (column.id === 'dueDate' && invoice && invoice.status === 'PAST_DUE'
                        ? 'ERROR_500-CLR'
                        : 'TEXT_PRIMARY-CLR')
                    }>
                    {value}
                  </p>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id === 'job' &&
                documentDetails.quickBooksJob &&
                (jobName || jobAddress) && (
                  <div className={'text-sm w-full px-0.5'}>
                    <p className="font-bold text-xs text-center word-wrap">
                      {truncateText(`${jobName}`, 20)}
                    </p>
                    <p className="text-2xs  text-center word-wrap">
                      {truncateText(`${jobAddress}`, 20)}
                    </p>
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'job' &&
                column.id !== 'download' &&
                column.id !== 'isPastDueDate' &&
                column.id !== 'notes' &&
                column.id === 'signedBy' && (
                  <div className="items-center justify-center ">
                    {dillInvoice && dillInvoice.isReceived && (
                      <div className="items-center justify-center">
                        {dillInvoice.receivedBy && (
                          <p className={'text-xs font-bold text-center  ' + 'TEXT_PRIMARY-CLR'}>
                            {dillInvoice.receivedBy}
                          </p>
                        )}
                        {dillInvoice.receivedByTime && (
                          <p className={'text-xs text-center ' + 'TEXT_PRIMARY-CLR'}>
                            {moment(dillInvoice.receivedByTime).format('MM/DD  LT')}
                          </p>
                        )}
                      </div>
                    )}
                    {(!dillInvoice || (dillInvoice && !dillInvoice.isReceived)) && (
                      <p className={'text-sm  text-center ' + 'TEXT_PRIMARY-CLR'}>-</p>
                    )}
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'download' &&
                column.id !== 'notes' &&
                column.id === 'isPastDueDate' && (
                  <div
                    style={{ width: '100px' }}
                    className={
                      value === 'true'
                        ? 'rounded-lg text-sm SUCCESS_100-BG p-2 SUCCESS_600-CLR text-center'
                        : 'rounded-lg text-sm WARNING_100-BG p-2 WARNING_600-CLR text-center'
                    }>
                    {value === 'true' ? 'Past Due' : 'Current Due'}
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'notes' && invoice && (
                <div className="flex w-full items-center justify-center">
                  <div className="relative" data-tooltip-id={`invoice-notes-content-${invoice.id}`}>
                    <AppButton
                      type="TERTIARY"
                      buttonStyles={{
                        padding: '8px',
                        margin: '5px 0px ',
                        height: '30px',
                        width: '30px',
                      }}
                      icon={<Note color={COLORS.GREY_500} size={15} />}
                      onClick={() => {
                        setSelectedInvoice(invoice);
                        setOpenNotesModal(true);
                      }}
                    />
                    {invoice?.notes && invoice?.notes?.length > 0 && (
                      <div className="flex absolute -top-[5px] right-[-10px] PRIMARY_500-BG WHITE-CLR rounded-full text-2xs font-bold h-5 w-5 items-center border border-white justify-center">
                        {invoice?.notes?.length}
                      </div>
                    )}
                  </div>
                  {invoice?.notes && invoice?.notes?.length > 0 && (
                    <ReactTooltip
                      id={`invoice-notes-content-${invoice.id}`}
                      place="top"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        display: 'flex',
                        background: '#334155',
                        width: '200px',
                        zIndex: '70',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        fontWeight: '600',
                      }}>
                      <span>{`${invoice?.notes?.[0]?.content}`}</span>
                    </ReactTooltip>
                  )}
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'actions' && (
                <div className="flex w-full items-center ">
                  <>
                    {isPdfAvailable ? (
                      <AppButton
                        type="TERTIARY"
                        buttonStyles={{
                          padding: '8px',
                          margin: '5px 4px',
                          height: '30px',
                          width: '30px',
                        }}
                        icon={<Eye color={COLORS.GREY_500} size={15} />}
                        onClick={() => {
                          if (invoice) {
                            setSelectedInvoice(invoice);
                            if (invoice.isPdfAvailable) {
                              handlePreviewPdf({
                                id: invoice.id,
                                docNumber: invoice.docNumber,
                                type: 'INVOICE',
                                signedPdfs: invoice.dillInvoice?.signedPdfs,
                              });
                            } else {
                              handlePreviewInvoiceWithoutPdf(invoice);
                            }
                          }
                          if (creditMemo) {
                            setSelectedCreditMemo(creditMemo);
                            if (creditMemo.isPdfAvailable) {
                              handlePreviewPdf({
                                id: creditMemo.id,
                                docNumber: creditMemo.docNumber,
                                type: 'CREDIT_MEMO',
                              });
                            }
                          }
                        }}
                      />
                    ) : (
                      <div className="h-2 w-10"></div>
                    )}
                    {isPdfAvailable ? (
                      <AppButton
                        type="TERTIARY"
                        buttonStyles={{
                          padding: '8px',
                          margin: '5px 4px',
                          height: '30px',
                          width: '30px',
                        }}
                        icon={<Download color={COLORS.GREY_500} size={15} />}
                        onClick={() => {
                          if (invoice && invoice.isPdfAvailable) {
                            handleDownloadPdf({
                              id: invoice.id,
                              type: 'INVOICE',
                              docNumber: invoice.docNumber,
                              signedPdfs: invoice.dillInvoice?.signedPdfs,
                            });
                          }
                          if (creditMemo && creditMemo.isPdfAvailable) {
                            handleDownloadPdf({
                              id: creditMemo.id,
                              type: 'CREDIT_MEMO',
                              docNumber: creditMemo.docNumber,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div className="h-2 w-10"></div>
                    )}
                  </>
                  {invoice ? (
                    <div className="">
                      {invoice?.lastEmailed && (
                        <p className="text-2xs mb-1 ml-3">{`Last sent ${getCurrentTimezoneDate(
                          invoice.lastEmailed,
                          'MM/DD/YYYY'
                        )}`}</p>
                      )}
                      <AppButton
                        text="Email Payment Reminder"
                        buttonTextClassName="text-2xs"
                        onClick={() => {
                          if (invoice) {
                            handleSendInvoicePaymentReminder(invoice.id);
                          }
                        }}
                        icon={<Mail color={COLORS.WHITE} size={15} />}
                        buttonStyles={{
                          marginTop: '5px',
                          height: '36px',
                          margin: '5px 4px',
                          fontSize: '0.7em',
                        }}
                        buttonWidth="150px"
                      />
                    </div>
                  ) : (
                    <div className="h-[36px] w-[158px]"></div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    setSortedInvoices([...filteredCurrentBuyerInvoices]);
  }, [filteredCurrentBuyerInvoices]);

  const sortByProperty = (
    sortType: 'asc' | 'desc',
    sortKey: string,
    getter: (item: any) => any,
    compare?: (a: any, b: any) => number
  ) => {
    const filteredInvoices = filteredCurrentBuyerInvoices.filter((invoice) => {
      const value = getter(invoice);
      return value !== undefined && value !== null;
    });

    const sortedInvoicesCopy = [...filteredInvoices];
    sortedInvoicesCopy.sort((a, b) => {
      const valueA = getter(a);
      const valueB = getter(b);

      if (compare) {
        return compare(valueA, valueB) * (sortType === 'asc' ? 1 : -1);
      } else {
        const stringA = String(valueA).toUpperCase();
        const stringB = String(valueB).toUpperCase();

        if (stringA < stringB) {
          return sortType === 'asc' ? -1 : 1;
        } else if (stringA > stringB) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }
    });

    const undefinedOrNullInvoices = filteredCurrentBuyerInvoices.filter((invoice) => {
      const value = getter(invoice);
      return value === undefined || value === null;
    });

    const fnalInvoice = sortedInvoicesCopy.concat(undefinedOrNullInvoices);

    setSortedInvoices(fnalInvoice);
  };

  const handleSort = (value: string) => {
    if (filteredCurrentBuyerInvoices) {
      if (sort === value) {
        if (sorted) {
          setSortedInvoices(filteredCurrentBuyerInvoices);
          setSort('');
          setSorted(false);
        } else {
          if (value === 'docNumber') {
            sortByProperty('desc', value, (invoice) => invoice?.docNumber);
          } else if (value === 'poNumber') {
            sortByProperty('desc', value, (invoice) => invoice.poNumber);
          } else if (value === 'createdAt') {
            sortByProperty('desc', value, (invoice) => invoice.createdAt);
          } else if (value === 'dueDate') {
            sortByProperty('desc', value, (invoice) => invoice.dueDate);
          } else if (value === 'terms') {
            sortByProperty('desc', value, (invoice) => invoice.terms);
          } else if (value === 'amount') {
            sortByProperty(
              'desc',
              value,
              (invoice) => invoice.amount,
              (a, b) => a - b
            );
          } else if (value === 'job') {
            sortByProperty('desc', value, (invoice) => invoice?.quickBooksJob?.jobName);
          } else if (value === 'type') {
            sortByProperty('desc', value, (invoice) => invoice?.recordType?.toLowerCase());
          } else if (value === 'balance') {
            sortByProperty(
              'desc',
              value,
              (invoice) => invoice.balance,
              (a, b) => a - b
            );
          }

          setSorted(true);
        }
      } else {
        if (value === 'docNumber') {
          sortByProperty('asc', value, (invoice) => invoice?.docNumber);
        } else if (value === 'poNumber') {
          sortByProperty('asc', value, (invoice) => invoice.poNumber);
        } else if (value === 'createdAt') {
          sortByProperty('asc', value, (invoice) => invoice.createdAt);
        } else if (value === 'dueDate') {
          sortByProperty('asc', value, (invoice) => invoice.dueDate);
        } else if (value === 'terms') {
          sortByProperty('asc', value, (invoice) => invoice.terms);
        } else if (value === 'amount') {
          sortByProperty(
            'asc',
            value,
            (invoice) => invoice.amount,
            (a, b) => a - b
          );
        } else if (value === 'job') {
          sortByProperty('asc', value, (invoice) => invoice?.quickBooksJob?.jobName);
        } else if (value === 'type') {
          sortByProperty('asc', value, (invoice) => invoice?.recordType?.toLowerCase());
        } else if (value === 'balance') {
          sortByProperty(
            'asc',
            value,
            (invoice) => invoice.balance,
            (a, b) => a - b
          );
        }

        setSort(value);
        setSorted(false);
      }
    }
  };

  const handleDownloadInvoiceStatements = async () => {
    const res = await dispatch(
      supplierDownloadSupplierInvoiceStatements({
        buyerId: selectedBuyer?.id || '',
      })
    );
    if (res && res.type === 'invoice/supplierDownloadSupplierInvoiceStatements/fulfilled') {
      openDillToast({ type: 'SUCCESS', message: 'Invoice statement download success' });
    } else {
      openDillToast({ type: 'ERROR', message: 'Invoice statement download failed' });
    }
  };
  return (
    <div className="flex flex-col w-full h-full p-2 ">
      <AppPdfPreviewModal
        open={openPdfPreviewModal}
        handleClose={() => {
          setOpenPdfPreviewModal(false);
          setSelectedCreditMemo(null);
          setSelectedInvoice(null);
        }}
        invoice={
          selectedInvoice
            ? selectedInvoice?.docNumber || ''
            : selectedCreditMemo
            ? selectedCreditMemo?.docNumber || ''
            : ''
        }
        pdfType={selectedInvoice ? 'INVOICE' : selectedCreditMemo ? 'CREDIT_MEMO' : undefined}
        pdfList={previewPdfList}
        handleDownloadPdf={handleDownloadSelectedPdf}
        isSigned={false}
      />
      <AppNotesModal
        open={openNotesModal}
        handleClose={() => {
          setOpenNotesModal(false);
        }}
        onSubmit={(value: string) => {
          handleUpdateGroupedInvoice(value);
        }}
        title={
          selectedInvoice?.notes && selectedInvoice?.notes?.length > 0 ? 'Update Note' : 'Add Note'
        }
        primaryButtonText={
          selectedInvoice?.notes && selectedInvoice?.notes?.length > 0 ? 'Update' : 'Add'
        }
        value={selectedInvoice?.notes?.[0]?.content}
        loading={loading}
      />
      {isViewInvoiceWithoutPdfModalOpen && (
        <AppViewInvoiceWithoutPdfModal
          open={isViewInvoiceWithoutPdfModalOpen}
          handleClose={() => {
            setIsViewInvoiceWithoutPdfModalOpen(false);
          }}
          selectedInvoice={selectedInvoice}
          selectedSupplier={user?.userSupplier}
        />
      )}

      <div className="mb-1 flex justify-between">
        <AppInputField
          inputFieldStyles={{ width: '250px' }}
          id="search"
          placeholder={'Search open items'}
          icon={<Search size={15} />}
          onTextChange={(text) => {
            setSearchText(text);
          }}
        />
        <div className="">
          <AppButton
            text="Download statement"
            icon={<FilePlus color={COLORS.WHITE} size={19} />}
            onClick={handleDownloadInvoiceStatements}
          />
        </div>
      </div>

      <div className="flex flex-col h-full  w-full border rounded-lg overflow-hidden">
        <div className="flex w-full h-full flex-col">
          <div className="flex border-b px-2 items-center w-full sticky top-0 GREY_50-BG py-3 z-10">
            {columns.map((column) => {
              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  onClick={() => handleSort(column.id)}
                  className={twMerge(
                    `flex items-center h-full w-full cursor-pointer  mx-0.5`,
                    column.id === 'job' ? 'justify-center' : ''
                  )}>
                  <p
                    className={twMerge(
                      'TEXT_SECONDARY-CLR text-xs',
                      column.id === 'download' ? 'w-[180px]' : ''
                    )}>
                    {column.name}
                  </p>
                  {column.id !== 'actions' && column.id !== 'notes' && (
                    <div>
                      {sort === column.id && sorted ? (
                        <SortAscending color={COLORS.PRIMARY_500} />
                      ) : sort === column.id && !sorted ? (
                        <SortDescending color={COLORS.PRIMARY_500} />
                      ) : (
                        <ArrowsDownUp color={COLORS.GREY_400} />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {sortedInvoices.length < 1 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                <p>No invoices</p>
              </div>
            </div>
          )}

          <div className="" style={{ minHeight: 'calc(100% - 45px)' }}>
            {sortedInvoices.length > 0 && (
              <div className="w-full h-full overflow-scroll">
                <AutoSizer className="w-full ">
                  {({ height, width }) => {
                    return (
                      <List
                        className=""
                        width={width}
                        height={height}
                        rowRenderer={renderRow}
                        rowCount={sortedInvoices.length}
                        rowHeight={70}
                      />
                    );
                  }}
                </AutoSizer>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoicesTable;
