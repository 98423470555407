import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { AppInputField } from '../../components/general/AppInputField/AppInputField';
import dillLogoWithText from '../../assets/svg/dill_logo_with_text.svg';
import { AppButton } from '../../components/general/AppButton/AppButton';
import { Checks, Warning, ListChecks, CheckCircle, Users, Signpost } from 'phosphor-react';
import { COLORS } from '../../utils/colors';
import { getBasicSupplier, getMainBuyer } from '../../redux/services/buyersService';
import { myFirebaseAuth } from '../../firebase/config';
import {
  checkUserExists,
  logoutUser,
  referBuyerUsers,
  referSubSupplierUsers,
  updateSubUser,
} from '../../redux/services/authService';
import { z } from 'zod';
import { toast } from 'react-toastify';
import { updateGlobalPastPath } from '../../redux/globalSlices/authSlice';
import { AlertOctagon, Trash } from 'react-feather';
import { getSavedPastPath, isEmailValid, removeQueryParam } from '../../utils/helpers';
import { REACT_APP_FRONTEND_URL } from '../../utils/constants';
import { AppCheckBox } from '../../components/general/AppCheckBox/AppCheckBox';
import signatureImg from '../../assets/svg/signature.svg';

const referPageModes = [
  'NEW_USER',
  'ADD_NEW_REFERRAL',
  'SUCCESS_ADD_REFERRAL',
  'ERROR_ADD_REFERRAL',
] as const;
const ReferPageModeSchema = z.enum(referPageModes);
type ReferPageMode = z.infer<typeof ReferPageModeSchema>;

const ReferredUserSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  title: z.string(),
});
type ReferredUser = z.infer<typeof ReferredUserSchema>;
function ReferPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    userCheckState,
    userCheckRoles,
    loadingList: authLoadingList,
  } = useAppSelector((state) => state.auth);
  const { basicSupplier, mainBuyer } = useAppSelector((state) => state.buyers);
  const [emailFromLink, setEmailFromLink] = useState('');
  const [isShowValidationErrors, setIsShowValidationErrors] = useState(false);
  const [referPageMode, setReferPageMode] = useState<ReferPageMode>('ADD_NEW_REFERRAL');
  const [contactList, setContactList] = useState<ReferredUser[]>([]);
  const [referType, setReferType] = useState('BUYER');
  const [buyerIdFromLink, setBuyerIdFromLink] = useState('');
  const [supplierDomainIdFromLink, setSupplierDomainIdFromLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buyerNameFromLink, setBuyerNameFromLink] = useState<string | null>(null);
  const [haveColleagues, setHaveColleagues] = useState(false);
  const [showRolesError, setShowRolesError] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState('');
  const [isAcceptSetup, setIsAcceptSetup] = useState(false);

  const isAnyDataLoading = () => {
    const check = authLoadingList.filter((item) => item === 'checkUserExists');
    if (check.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkRoleSelected = () => {
    if (selectedRoles === 'REVIEWER') {
      return ['REVIEWER'];
    } else if (selectedRoles === 'SIGNER') {
      return ['SIGNER'];
    } else if (selectedRoles === 'BOTH') {
      return ['REVIEWER', 'SIGNER'];
    }
    return [];
  };

  const handleUpdateSubUser = async () => {
    const roles = checkRoleSelected();
    if (buyerIdFromLink && emailFromLink) {
      await dispatch(updateSubUser({ buyerId: buyerIdFromLink, roles, email: emailFromLink }));
    }
    if (supplierDomainIdFromLink && emailFromLink) {
      await dispatch(
        updateSubUser({ supplierDomainId: supplierDomainIdFromLink, roles, email: emailFromLink })
      );
    }
  };

  useEffect(() => {
    if (!isAnyDataLoading()) {
      const originalReferPath = localStorage.getItem('referPath');
      if (!originalReferPath) {
        return;
      }
      console.log({ originalReferPath });
      const originalReferPathObj = new URL(originalReferPath, REACT_APP_FRONTEND_URL);
      const params = originalReferPathObj.searchParams;
      const currentEmailFromLinkParam = params.get('email');
      const supplierDomainId = params.get('supplierDomainId');
      const buyerId = params.get('buyerId');
      const buyerName = params.get('buyerName');
      const isAcceptSetupParams = params.get('isAcceptSetup');

      let type = params.get('type');
      if (isAcceptSetupParams && isAcceptSetupParams === 'true') {
        setIsAcceptSetup(true);
      } else {
        setIsAcceptSetup(false);
        setHaveColleagues(true);
      }
      if (type) {
        if (type === 'null') {
          type = 'BUYER';
        }
        setReferType(type);
      }
      if (buyerId) {
        setBuyerIdFromLink(buyerId);
        dispatch(getMainBuyer({ mainBuyerId: buyerId }));
      }
      if (supplierDomainId) {
        setSupplierDomainIdFromLink(supplierDomainId);
        dispatch(getBasicSupplier({ supplierDomainId: supplierDomainId }));
      }
      if (currentEmailFromLinkParam) {
        setEmailFromLink(currentEmailFromLinkParam.toLocaleLowerCase());
      }
      if (buyerName) {
        setBuyerNameFromLink(buyerName);
      }
      if (currentEmailFromLinkParam && originalReferPath.startsWith('/refer')) {
        if (currentEmailFromLinkParam && !userCheckState) {
          dispatch(checkUserExists({ email: currentEmailFromLinkParam.toLocaleLowerCase() }));
        }
      }
    }
  }, [location.pathname, myFirebaseAuth.currentUser, userCheckState]);

  useEffect(() => {
    if (!isAnyDataLoading()) {
      if (userCheckState === 'NEW_USER') {
        dispatch(updateGlobalPastPath(''));
        navigate('/login');
      } else if (userCheckState === 'USER_EXISTS') {
        if (
          userCheckRoles.length > 0 &&
          userCheckRoles.filter((role) => role === 'BUYER' || role === 'SUB_SUPPLIER').length > 0
        ) {
          setReferPageMode('ADD_NEW_REFERRAL');
        } else if (!isAcceptSetup) {
          setReferPageMode('ADD_NEW_REFERRAL');
        } else {
          navigate('/login');
        }
      }
    }

    return () => {};
  }, [userCheckState]);

  const handleAddContactToList = () => {
    const newContact: ReferredUser = {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
    };
    setContactList([...contactList, newContact]);
  };

  const handleDeleteContactFromList = (position: number) => {
    setContactList([...contactList.filter((item, i) => i !== position)]);
  };
  const checkContactsForErrors = () => {
    let isError = false;
    contactList.forEach((contact) => {
      const emptyValue = Object.values(contact).filter((value) => !value);
      if (emptyValue.length > 0) {
        isError = true;
      }
      if (!isEmailValid(contact.email)) {
        isError = true;
      }
    });
    return isError;
  };

  useEffect(() => {
    if (contactList.length === 0) {
      handleAddContactToList();
    }

    return () => {};
  }, [contactList]);

  const handleEditContactList = (position: number, key: string, value: string) => {
    setContactList([
      ...contactList.map((item, i) => {
        if (i === position) {
          return { ...item, [key]: value };
        }
        return { ...item };
      }),
    ]);
  };

  const handleSubmitReferral = async () => {
    const role = checkRoleSelected();
    if (role.length === 0 && referType !== 'BUYER' && isAcceptSetup) {
      setShowRolesError(true);
      return;
    }
    setErrorMessage('');
    if (contactList.length === 0 || checkContactsForErrors()) {
      setIsShowValidationErrors(true);
    } else {
      setIsShowValidationErrors(false);
      let response = null;
      if (referType === 'BUYER') {
        console.log({ idReferrerAcceptSetup: isAcceptSetup });
        response = await dispatch(
          referBuyerUsers({
            idReferrerAcceptSetup: isAcceptSetup,
            referrerEmail: emailFromLink,
            supplierDomainId: basicSupplier?.domainId || '',
            referredUsers: [
              ...contactList.map((contact) => {
                let trimmedValues: typeof contact = {
                  email: '',
                  firstName: '',
                  lastName: '',
                  title: '',
                };
                Object.keys(contact).forEach((itemKey) => {
                  const key = itemKey as keyof typeof contact;
                  if (contact[key]) {
                    const item = contact[key] as string;
                    trimmedValues = { ...trimmedValues, [itemKey]: item.trim() };
                  } else {
                  }
                });
                return { ...trimmedValues };
              }),
            ],
          })
        );
      } else {
        console.log({ idReferrerAcceptSetup: isAcceptSetup });
        response = await dispatch(
          referSubSupplierUsers({
            idReferrerAcceptSetup: isAcceptSetup,
            referrerEmail: emailFromLink,
            buyerId: buyerIdFromLink,
            referredUsers: [
              ...contactList.map((contact) => {
                let trimmedValues: typeof contact = {
                  email: '',
                  firstName: '',
                  lastName: '',
                  title: '',
                };
                Object.keys(contact).forEach((itemKey) => {
                  const key = itemKey as keyof typeof contact;
                  if (contact[key]) {
                    const item = contact[key] as string;
                    trimmedValues = { ...trimmedValues, [itemKey]: item.trim() };
                  } else {
                  }
                });
                return { ...trimmedValues };
              }),
            ],
          })
        );
      }

      if (
        response &&
        (response.type === 'auth/referUsers/fulfilled' ||
          response.type === 'auth/referSubSupplierUsers/fulfilled' ||
          response.type === 'auth/referBuyerUsers/fulfilled')
      ) {
        localStorage.removeItem('referPath');
        handleUpdateSubUser();
        setReferPageMode('SUCCESS_ADD_REFERRAL');
        toast.success('Colleagues successfully invited', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          style: {
            backgroundColor: '#ECFDF3',
            color: '#054F31',
          },
        });
      } else {
        localStorage.removeItem('referPath');
        setReferPageMode('ERROR_ADD_REFERRAL');
        if (
          response &&
          response?.payload &&
          response?.payload?.errors &&
          response?.payload?.errors.length > 0
        ) {
          let errors = '';
          response.payload.errors.forEach((error: any) => {
            errors = errors + ' ' + error.msg;
          });
          setErrorMessage(errors);
        }
        toast.error('Error inviting colleagues', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          style: {
            backgroundColor: '#FEF0EF',
            color: '#F04438',
          },
        });
      }
    }
  };

  const handleSkipReferral = async () => {
    const role = checkRoleSelected();
    if (role.length === 0 && referType !== 'BUYER' && isAcceptSetup) {
      setShowRolesError(true);
      return;
    }
    dispatch(updateGlobalPastPath(''));
    if (myFirebaseAuth.currentUser) {
      const pastPath = getSavedPastPath();
      if (pastPath) {
        if (pastPath.startsWith('/refer')) {
          setReferPageMode('SUCCESS_ADD_REFERRAL');
        } else {
          if (referType === 'BUYER') {
            navigate('/companies');
          } else {
            navigate('/lienReleases');
          }
        }
      } else {
        setReferPageMode('SUCCESS_ADD_REFERRAL');
      }
      localStorage.removeItem('pastPathList');
      localStorage.removeItem('referPath');
      localStorage.removeItem('newSignIn');

      handleUpdateSubUser();
    } else {
      console.log('4');
      navigate('/login');
    }
  };

  const handleFinish = () => {
    if (haveColleagues) {
      handleSubmitReferral();
    } else {
      if (isAcceptSetup) {
        handleSkipReferral();
      } else {
        handleSubmitReferral();
      }
    }
  };

  const columns = useMemo(() => {
    return [
      { id: 'title', name: 'Job Title', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'firstName', name: 'First Name', width: 250, cellType: 'VIEW_TEXT' },
      { id: 'lastName', name: 'Last Name', width: 250, cellType: 'VIEW_TEXT' },
      { id: 'email', name: 'Email', width: 300, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  return (
    <div className="flex w-full h-full justify-center items-center">
      <div className="flex flex-col w-3/6 items-center justify-center mx-4">
        {basicSupplier && basicSupplier.logo && (
          <div className="flex flex-col items-center mb-14">
            <img className="w-2/5" src={basicSupplier.logo.url} />
            <div className="flex items-center mt-2">
              <p>Powered By</p>
              <a href="https://usedill.com/" target="_blank">
                <img className="h-8 ml-2" src={dillLogoWithText} />
              </a>
            </div>
          </div>
        )}
        {(!basicSupplier || (basicSupplier && !basicSupplier.logo)) && (
          <div className="flex flex-col items-center mb-14 w-full">
            <img className="w-2/5" src={dillLogoWithText} />
          </div>
        )}
        {referPageMode === 'ADD_NEW_REFERRAL' && (
          <div className="flex flex-col GREY_100-BG p-10 rounded-xl w-full mb-3">
            {referType !== 'BUYER' && isAcceptSetup && (
              <div className="flex flex-col bg-white p-6 rounded-xl mb-4">
                <div className="text-sm mb-4">
                  <span className="text-red-500">*</span>
                  Please choose your role in this company. You are able to select one or both.
                </div>
                <div className="flex">
                  <div
                    className={`flex ml-3 border w-52 relative rounded-lg p-2 cursor-pointer ${
                      selectedRoles === 'REVIEWER' ? 'PRIMARY_500-BORDER' : 'GREY_200-BORDER'
                    }`}
                    onClick={() => {
                      setSelectedRoles('REVIEWER');
                      setShowRolesError(false);
                    }}>
                    <div className="p-3 rounded-lg w-fit h-fit PRIMARY_100-BG">
                      <ListChecks color={COLORS.PRIMARY_500} size={24} />
                    </div>
                    <div className="ml-2 items-center">
                      <div className="text-sm text-black mb-0.5">Reviewer</div>
                      <div className="text-xs leading-none TEXT_SECONDARY-CLR ">
                        You review lien<br></br>releases.
                      </div>
                    </div>
                    {selectedRoles === 'REVIEWER' && (
                      <div className="absolute right-1 top-1">
                        <CheckCircle color={COLORS.PRIMARY_500} size={20} weight="fill" />
                      </div>
                    )}
                  </div>

                  <div
                    className={`flex ml-3 border w-52 relative rounded-lg p-2 cursor-pointer ${
                      selectedRoles === 'SIGNER' ? 'PRIMARY_500-BORDER' : 'GREY_200-BORDER'
                    }`}
                    onClick={() => {
                      setSelectedRoles('SIGNER');
                      setShowRolesError(false);
                    }}>
                    <div className="p-3 rounded-lg w-12 h-12 SUCCESS_100-BG">
                      <img
                        className="fill-green-500"
                        src={signatureImg}
                        height={24}
                        width={24}
                        alt=""
                      />
                    </div>
                    <div className="ml-2 items-center">
                      <div className="text-sm text-black mb-0.5">Signer</div>
                      <div className="text-xs leading-none TEXT_SECONDARY-CLR ">
                        You have the authority<br></br>to sign lien waivers.
                      </div>
                    </div>
                    {selectedRoles === 'SIGNER' && (
                      <div className="absolute right-1 top-1">
                        <CheckCircle color={COLORS.PRIMARY_500} size={20} weight="fill" />
                      </div>
                    )}
                  </div>

                  <div
                    className={`flex ml-3 border w-52 relative rounded-lg p-2 cursor-pointer ${
                      selectedRoles === 'BOTH' ? 'PRIMARY_500-BORDER' : 'GREY_200-BORDER'
                    }`}
                    onClick={() => {
                      setSelectedRoles('BOTH');
                      setShowRolesError(false);
                    }}>
                    <div className="p-3 rounded-lg w-fit h-fit WARNING_100-BG">
                      <Users color={COLORS.WARNING_500} size={24} />
                    </div>
                    <div className="ml-2 items-center">
                      <div className="text-sm text-black mb-0.5">Both</div>
                      <div className="text-xs leading-none TEXT_SECONDARY-CLR ">
                        You review & sign lien<br></br>releases.
                      </div>
                    </div>
                    {selectedRoles === 'BOTH' && (
                      <div className="absolute right-1 top-1">
                        <CheckCircle color={COLORS.PRIMARY_500} size={20} weight="fill" />
                      </div>
                    )}
                  </div>
                </div>
                {showRolesError && (
                  <div className="flex ERROR_100-BG w-fit px-3 py-2 rounded mt-4">
                    <div className="mr-2">
                      <Warning color={COLORS.ERROR_500} size={24} />
                    </div>
                    <div className="text-sm ERROR_500-CLR">Please select one role</div>
                  </div>
                )}
              </div>
            )}

            <div className="flex flex-col bg-white p-6 rounded-xl">
              <div className="flex">
                {isAcceptSetup && (
                  <AppCheckBox
                    className="mr-3"
                    isChecked={haveColleagues}
                    onClick={(isChecked: boolean) => {
                      setHaveColleagues(!haveColleagues);
                    }}
                  />
                )}
                {referType === 'BUYER' && (
                  <p className="text-sm mb-4">
                    Do you have other colleagues than yourself (
                    <span className="text-blue-500">{emailFromLink}</span>) that either make
                    payments or request lien waivers? Add them below.
                  </p>
                )}
                {referType !== 'BUYER' && (
                  <p className="text-sm mb-4">
                    Do you have other colleagues than yourself (
                    <span className="text-blue-500">{emailFromLink}</span>) that sign lien waivers
                    {mainBuyer?.name
                      ? ` for ${mainBuyer?.name}`
                      : buyerNameFromLink
                      ? ` for ${buyerNameFromLink}`
                      : ''}
                    ? Add them below.
                  </p>
                )}
              </div>
              {haveColleagues && (
                <div>
                  <div className="w-full GREY_100-BG p-4 rounded-xl mb-4 flex flex-col">
                    <div className="w-full px-1 flex">
                      <div className=" h-fit max-h-60 GREY_100-BG flex flex-col overflow-y-scroll py-1 w-full">
                        {contactList.length > 0 &&
                          contactList.map((contact, i) => {
                            return (
                              <div key={i + 'op'} className="w-full flex ">
                                {columns.map((column, j) => {
                                  const value = contact[column.id as keyof ReferredUser];
                                  return (
                                    <div
                                      key={j + 'oo'}
                                      style={{ flex: column.width / totalWidth }}
                                      className=" px-1 py-2  items-center">
                                      {column.id === 'email' && (
                                        <div>
                                          <AppInputField
                                            label={column.name}
                                            isRequired={true}
                                            value={value}
                                            onTextChange={(text) => {
                                              handleEditContactList(
                                                i,
                                                column.id,
                                                text.toLowerCase().trim()
                                              );
                                            }}
                                            errorText={
                                              isShowValidationErrors && !value
                                                ? 'Required'
                                                : isShowValidationErrors &&
                                                  !isEmailValid(value.toString())
                                                ? 'Email is not valid'
                                                : ''
                                            }
                                          />
                                        </div>
                                      )}
                                      {column.id !== 'actions' && column.id !== 'email' && (
                                        <div>
                                          <AppInputField
                                            label={column.name}
                                            isRequired={true}
                                            value={value}
                                            onTextChange={(text) => {
                                              handleEditContactList(i, column.id, text);
                                            }}
                                            errorText={
                                              isShowValidationErrors && !value ? 'Required' : ''
                                            }
                                          />
                                        </div>
                                      )}
                                      {column.id === 'actions' && (
                                        <div className="h-full flex  flex-col justify-end">
                                          <Trash
                                            className="bg-white rounded-lg p-2 cursor-pointer mb-1"
                                            size={35}
                                            color={COLORS.ERROR_400}
                                            onClick={() => {
                                              handleDeleteContactFromList(i);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <p
                      className="text-xs text-blue-500 cursor-pointer mt-2"
                      onClick={() => {
                        handleAddContactToList();
                      }}>
                      + Add Contact
                    </p>
                  </div>
                  <p className="text-2xs text-center">
                    Current email: <span className="PRIMARY_700-CLR ">{emailFromLink}</span>
                  </p>
                </div>
              )}
            </div>

            <div className="w-full flex flex-row justify-end mt-4">
              <AppButton
                text={'Finish'}
                isDisabled={haveColleagues && contactList.length === 0}
                onClick={handleFinish}
              />
            </div>
          </div>
        )}

        {referPageMode === 'SUCCESS_ADD_REFERRAL' && (
          <div className="flex flex-col GREY_100-BG p-10 rounded-xl w-full items-center">
            <div className="text-2xl font-semibold">You've successfully setup!</div>
            <div className="rounded-full bg-white p-6 mt-5">
              <Checks size={44} color={COLORS.PRIMARY_500} />
            </div>

            <div className="text-center mt-4 flex">
              {myFirebaseAuth.currentUser && (
                <>
                  <AppButton
                    text={'Back'}
                    buttonStyles={{ margin: '0 5px' }}
                    type="SECONDARY"
                    onClick={() => {
                      setReferPageMode('ADD_NEW_REFERRAL');
                    }}
                  />
                  <AppButton
                    text={'Proceed with Login'}
                    onClick={() => {
                      if (referType === 'BUYER') {
                        navigate('/companies');
                      } else {
                        navigate('/lienReleases');
                      }
                      localStorage.removeItem('pastPathList');
                      localStorage.removeItem('referPath');
                      localStorage.removeItem('newSignIn');
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
        {referPageMode === 'NEW_USER' && (
          <div className="flex flex-col GREY_100-BG p-10 rounded-xl w-full items-center">
            <p className="text-center font-bold text-sm">
              We see that you’re a new user. Reach out to{' '}
              <span className="PRIMARY_500-CLR font-bold cursor-pointer whitespace-nowrap">
                972-339-0799
              </span>{' '}
              to request access to the portal
            </p>
            <p className="text-center mt-4">
              <span
                className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                onClick={() => {
                  console.log('5');
                  navigate('/login');
                }}>
                Back to login page
              </span>
            </p>
            <p className="text-2xs text-center">
              Current email: <span className="PRIMARY_700-CLR ">{emailFromLink}</span>
            </p>
          </div>
        )}
        {referPageMode === 'ERROR_ADD_REFERRAL' && (
          <div className="flex flex-col GREY_100-BG p-10 rounded-xl w-full items-center">
            <p className="text-center font-semibold text-lg">Error inviting colleagues</p>
            <div className="p-3 rounded-full bg-white my-4">
              <AlertOctagon color={COLORS.ERROR_500} size={50} fontSize={2} />
            </div>
            {errorMessage && (
              <p className="my-2 self-center text-center text-sm text-red-600">{errorMessage}</p>
            )}

            <div className="text-center mt-4 flex">
              {!myFirebaseAuth.currentUser && (
                <p className="my-2 self-center text-center text-sm font-bold">
                  Contact team@usedill.com or 972-339-0799 for customer support
                </p>
              )}

              {myFirebaseAuth.currentUser && (
                <>
                  <AppButton
                    text={'Back'}
                    buttonStyles={{ margin: '0 5px' }}
                    type="SECONDARY"
                    onClick={() => {
                      setReferPageMode('ADD_NEW_REFERRAL');
                    }}
                  />
                  <AppButton
                    text={'Proceed with Login'}
                    onClick={() => {
                      navigate('/customers');
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReferPage;
