import React, { useEffect, useMemo, useState } from 'react';
import { AppInputField } from '../../components/general/AppInputField/AppInputField';
import { getAllStates, getStateAbbreviation, SupplierJobUpdate } from '@dill/dill-shared';
import { AppButton } from '../../components/general/AppButton/AppButton';
import { AppDropDown2 } from '../../components/general/AppDropDown2/AppDropDown2';
import { useLocation } from 'react-router-dom';

interface VerifyJobRequestData {
  zipCode: string;
  state: string;
  city: string;
  address: string;
  buyerId: string;
  jobNumber: string;
  name: string;
  buyerName: string;
}

type PartialSupplierJobPreVerify = Pick<
  SupplierJobUpdate,
  | 'buyerId'
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'constructionMonitorPermitId'
>;

interface AddSupplierJobFormProps {
  onFormSubmit: (jobAddressData: VerifyJobRequestData) => void;
  jobSiteData: PartialSupplierJobPreVerify & { buyerName: string };
  verifying: boolean;
}

const INITIAL_STATE = {
  name: '',
  jobNumber: '',
  jobAddress: '',
  jobCity: '',
  jobState: '',
  jobZipCode: '',
  buyerId: '',
  constructionMonitorPermitId: null,
  buyerName: '',
} as PartialSupplierJobPreVerify & { buyerName: string };

const JobDetailsForm = (props: AddSupplierJobFormProps) => {
  const location = useLocation();
  const { onFormSubmit, jobSiteData, verifying } = props;

  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [errors, setErrors] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const onTextChange = (key: string, value: string) => {
    setSupplierJobInput((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    setSupplierJobInput(jobSiteData);
  }, []);

  const allStates = useMemo(() => {
    return getAllStates();
  }, []);

  const submitForm = async () => {
    setLoading(true);
    setShowErrorAbove(false);
    const params = new URLSearchParams(location.search);
    const paramDomainId = params.get('domainId');

    const newErrors = {} as PartialSupplierJobPreVerify & { buyerName: string };

    if (!supplierJobInput.name) {
      newErrors.name = 'Please enter a job name';
    }
    if (!supplierJobInput.jobAddress) {
      newErrors.jobAddress = 'Please enter a job address';
    }
    if (!supplierJobInput.jobCity) {
      newErrors.jobCity = 'Please enter a job city';
    }
    if (!supplierJobInput.jobState) {
      newErrors.jobState = 'Please enter a job state';
    }
    if (!supplierJobInput.jobZipCode) {
      newErrors.jobZipCode = 'Please enter a job zip code';
    }
    if (!supplierJobInput.buyerName && paramDomainId) {
      newErrors.buyerName = `Please enter your company's name`;
    }
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      setShowErrorAbove(true);
      setLoading(false);
      return;
    }

    const jobAddressData: VerifyJobRequestData = {
      address: supplierJobInput.jobAddress ?? '',
      city: supplierJobInput.jobCity ?? '',
      state: getStateAbbreviation(supplierJobInput.jobState || '') || '',
      zipCode: supplierJobInput.jobZipCode ?? '',
      buyerId: supplierJobInput.buyerId ?? '',
      jobNumber: supplierJobInput.jobNumber ?? '',
      name: supplierJobInput.name ?? '',
      buyerName: supplierJobInput?.buyerName ?? '',
    };

    await onFormSubmit(jobAddressData);
    setLoading(false);
  };

  return (
    <div className="WHITE-BG w-full mt-4 rounded-lg">
      <div className="flex flex-row justify-around gap-2 p-4">
        <div className="w-full gap-2 flex">
          {jobSiteData.buyerName === '' && (
            <AppInputField
              id="buyerName"
              label="Your Company Name"
              isRequired={true}
              value={supplierJobInput.buyerName}
              errorText={errors.buyerName ? errors.buyerName : ''}
              onTextChange={(text: string) => {
                onTextChange('buyerName', text);
              }}
            />
          )}
          <AppInputField
            id="name"
            label="Job Name"
            isRequired={true}
            value={supplierJobInput.name}
            errorText={errors.name ? errors.name : ''}
            onTextChange={(text: string) => {
              onTextChange('name', text);
            }}
          />
        </div>
      </div>
      <div className="flex p-4">
        <div className="w-full">
          <AppInputField
            id="address"
            label="Job Address"
            isRequired={true}
            value={supplierJobInput.jobAddress}
            errorText={errors.jobAddress ? errors.jobAddress : ''}
            onTextChange={(text) => {
              onTextChange('jobAddress', text);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row justify-around gap-2 p-4">
        <div className="w-full">
          <AppInputField
            id="city"
            label="Job City"
            isRequired={true}
            value={supplierJobInput.jobCity}
            errorText={errors.jobCity ? errors.jobCity : ''}
            onTextChange={(text) => {
              onTextChange('jobCity', text);
            }}
          />
        </div>
        <div className="spacer" />
        <div className="w-full">
          <AppDropDown2
            isRequired
            label="Job State"
            value={supplierJobInput.jobState || null}
            errorText={errors.jobState ? errors.jobState : ''}
            items={[
              ...Object.keys(allStates).map((stateName) => {
                const key = stateName as keyof typeof allStates;
                const stateKey = allStates[key];
                return {
                  label: `${stateKey} - ${stateName}`,
                  value: stateKey,
                };
              }),
            ]}
            onSelectChange={(item) => {
              if (item && item?.value !== null) {
                const foundState = Object.values(allStates).find(
                  (stateName) => stateName === item?.value
                );
                if (foundState) {
                  onTextChange('jobState', item.value);
                }
              }
            }}
          />
        </div>
        <div className="spacer" />
        <div className="w-full">
          <AppInputField
            id="zip"
            label="Zip Code"
            isRequired={true}
            value={supplierJobInput.jobZipCode}
            errorText={errors.jobZipCode ? errors.jobZipCode : ''}
            onTextChange={(text) => {
              onTextChange('jobZipCode', text);
            }}
          />
        </div>
      </div>
      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
      {showErrorAbove && (
        <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
      )}
      <div className="flex flex-row-reverse px-4 py-2 w-full">
        <AppButton
          text="Next"
          onClick={submitForm}
          buttonStyles={{
            marginTop: '4px',
            marginBottom: '4px',
            width: '100px',
            height: '40px',
          }}
          isLoading={verifying}
          isDisabled={verifying}
        />
      </div>
    </div>
  );
};

export default JobDetailsForm;
