import { useState } from 'react';
import { Modal } from '@mui/material';
import { X } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { useAppSelector } from '../../../redux/store';

export const AppStandardInfoSheet = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { user } = useAppSelector((state) => state.auth);

  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [copied, setCopiedId] = useState<string>('');

  const handleCloseModal = () => {
    setCopiedId('');
    setIsLinkCopied(false);
    handleClose();
  };

  const linkUrl = `${process.env.REACT_APP_FRONTEND_URL}/requestJobInformation?domainId=${
    user?.userSupplier?.domainId ?? ''
  }`;

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <>
        <div
          className={`flex min-h-60 min-w-[500px] w-[50vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col `}>
          <div className="flex justify-between w-full items-center px-5 border-b py-3">
            <h3 className="font-bold text-lg">{`Standard Job Information Sheet Link`}</h3>
            <AppButton
              type="FLAT"
              buttonStyles={{ padding: '0px', margin: '5px 2px' }}
              icon={<X color={COLORS.GREY_300} size={30} />}
              onClick={handleCloseModal}
            />
          </div>
          <div className="px-6 py-3">
            <div className="text-xs font-medium my-1">
              Request job information from your customer directly via the Job Information Sheet.
              After your customer submits, it will automatically be added for your review.{' '}
            </div>
            {linkUrl && (
              <div className="flex w-full flex-col mt-2">
                <div className="w-full opacity-65 hover:opacity-100  py-3">
                  <p className="text-xs font-bold mb-0.5">Copy link for your own email</p>
                  <div className="flex flex-col  border   text-sm py-3 px-4 w-full rounded-md mx-3 bg-blue-50 ">
                    <div className="flex w-full items-center font-mono justify-between">
                      <div className="flex ">
                        <span></span>
                        <span className="overflow-hidden text-ellipsis text-xs min-w-[350px] w-[40vw] text-nowrap">{`${linkUrl}`}</span>
                      </div>
                      <button
                        className="hover:text-blue-500 cursor-pointer w-fit"
                        onClick={async () => {
                          await navigator.clipboard.writeText(linkUrl);
                          setIsLinkCopied(true);
                          setCopiedId('read-text');
                        }}>
                        {copied === 'read-text' ? 'Copied' : 'Copy'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};
