import { Modal } from '@mui/material';
import { AppButton } from '../AppButton/AppButton';
import { COLORS } from '../../../utils/colors';
import { X, Eye } from 'react-feather';
import { Link } from 'phosphor-react';
import { PreliminaryNoticeApiResponse } from '@dill/dill-shared/dist/src';
import moment from 'moment';

export const AppTrackPreliminaryNoticeModal = ({
  open,
  handleClose,
  preliminaryNotice,
  onPreviewAttachment,
}: {
  open: boolean;
  handleClose?: () => void;
  preliminaryNotice: PreliminaryNoticeApiResponse | null;
  onPreviewAttachment: (url: string, fileType?: string) => void;
}) => {
  const lenderIsNeeded =
    preliminaryNotice?.lenderStatus?.isNeeded === undefined
      ? true
      : preliminaryNotice?.lenderStatus?.isNeeded;
  const ownerIsNeeded =
    preliminaryNotice?.ownerStatus?.isNeeded === undefined
      ? true
      : preliminaryNotice?.ownerStatus?.isNeeded;
  const contractorIsNeeded =
    preliminaryNotice?.contractorStatus?.isNeeded === undefined
      ? true
      : preliminaryNotice?.contractorStatus?.isNeeded;
  const customerIsNeeded =
    preliminaryNotice?.customerStatus?.isNeeded === undefined
      ? true
      : preliminaryNotice?.customerStatus?.isNeeded;
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col min-h-8 w-[412px]">
        <h2 className="text-lg font-semibold mx-4 my-3">Track Notice</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleClose}
        />
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className="mx-4 mt-3 mb-2">
          <div className="flex justify-between items-center mb-3">
            <div className="text-[14px] font-bold w-[80px]">Submitted</div>
            <div className="text-[14px] font-medium w-[50px]">
              {preliminaryNotice?.createdAt
                ? moment(preliminaryNotice?.createdAt).format('MM/DD/YYYY')
                : ''}
            </div>
            <div className="w-[100px]"></div>
          </div>
          <div className="mb-3">
            <div className="text-[14px] font-bold">Mailed</div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-[14px] font-medium ml-[10px] w-[70px]">GC</div>
              <div className="text-[14px] font-medium w-[50px]">
                {preliminaryNotice?.contractorStatus?.mailedDate
                  ? moment(preliminaryNotice?.contractorStatus?.mailedDate).format('MM/DD/YYYY')
                  : contractorIsNeeded
                  ? 'Pending'
                  : 'N/A'}
              </div>
              <div className="w-[100px]">
                {preliminaryNotice?.contractorStatus?.trackLink && (
                  <a
                    className="cursor-pointer"
                    href={`${preliminaryNotice?.contractorStatus?.trackLink}`}
                    target="_blank"
                    rel="noreferrer">
                    <Link size={18} />
                  </a>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-[14px] font-medium ml-[10px] w-[70px]">Owner</div>
              <div className="text-[14px] font-medium w-[50px]">
                {preliminaryNotice?.ownerStatus?.mailedDate
                  ? moment(preliminaryNotice?.ownerStatus?.mailedDate).format('MM/DD/YYYY')
                  : ownerIsNeeded
                  ? 'Pending'
                  : 'N/A'}
              </div>
              <div className="w-[100px]">
                {preliminaryNotice?.ownerStatus?.trackLink && (
                  <a
                    className="cursor-pointer"
                    href={`${preliminaryNotice?.ownerStatus?.trackLink}`}
                    target="_blank"
                    rel="noreferrer">
                    <Link size={18} />
                  </a>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-[14px] font-medium ml-[10px] w-[70px]">Lender</div>
              <div className="text-[14px] font-medium w-[50px]">
                {preliminaryNotice?.lenderStatus?.mailedDate
                  ? moment(preliminaryNotice?.lenderStatus?.mailedDate).format('MM/DD/YYYY')
                  : lenderIsNeeded
                  ? 'Pending'
                  : 'N/A'}
              </div>
              <div className="w-[100px]">
                {preliminaryNotice?.lenderStatus?.trackLink && (
                  <a
                    className="cursor-pointer"
                    href={`${preliminaryNotice?.lenderStatus?.trackLink}`}
                    target="_blank"
                    rel="noreferrer">
                    <Link size={18} />
                  </a>
                )}
              </div>
            </div>
            {preliminaryNotice?.customerStatus && (
              <div className="flex justify-between items-center mb-1">
                <div className="text-[14px] font-medium ml-[10px] w-[70px]">Customer</div>
                <div className="text-[14px] font-medium w-[50px]">
                  {preliminaryNotice?.customerStatus?.status
                    ? preliminaryNotice?.customerStatus?.status
                    : preliminaryNotice?.customerStatus?.mailedDate
                    ? moment(preliminaryNotice?.customerStatus?.mailedDate).format('MM/DD/YYYY')
                    : customerIsNeeded
                    ? 'Pending'
                    : 'N/A'}
                </div>
                <div className="w-[100px]">
                  {preliminaryNotice?.customerStatus?.trackLink && (
                    <a
                      className="cursor-pointer"
                      href={`${preliminaryNotice?.customerStatus?.trackLink}`}
                      target="_blank"
                      rel="noreferrer">
                      <Link size={18} />
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="text-[14px] font-bold">Delivered</div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-[14px] font-medium ml-[10px] w-[70px]">GC</div>
              <div className="text-[14px] font-medium w-[50px] capitalize">
                {preliminaryNotice?.contractorStatus?.status
                  ? preliminaryNotice?.contractorStatus?.status
                  : preliminaryNotice?.contractorStatus?.deliveredDate
                  ? moment(preliminaryNotice?.contractorStatus?.deliveredDate).format('MM/DD/YYYY')
                  : contractorIsNeeded
                  ? 'Pending'
                  : 'N/A'}
              </div>
              <div className="w-[100px] flex">
                {preliminaryNotice?.contractorStatus?.attachments &&
                  preliminaryNotice?.contractorStatus?.attachments.length > 0 &&
                  preliminaryNotice?.contractorStatus?.attachments.map((attachment) => (
                    <AppButton
                      type="TERTIARY"
                      buttonStyles={{
                        padding: '4px',
                        height: '30px',
                        width: '30px',
                        marginRight: '4px',
                      }}
                      icon={<Eye color={COLORS.GREY_500} size={18} />}
                      onClick={() => {
                        onPreviewAttachment(attachment?.url ?? '', attachment?.fileType);
                      }}
                    />
                  ))}
              </div>
            </div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-[14px] font-medium ml-[10px] w-[70px]">Owner</div>
              <div className="text-[14px] font-medium w-[50px] capitalize">
                {preliminaryNotice?.ownerStatus?.status
                  ? preliminaryNotice?.ownerStatus?.status
                  : preliminaryNotice?.ownerStatus?.deliveredDate
                  ? moment(preliminaryNotice?.ownerStatus?.deliveredDate).format('MM/DD/YYYY')
                  : ownerIsNeeded
                  ? 'Pending'
                  : 'N/A'}
              </div>
              <div className="w-[100px] flex">
                {preliminaryNotice?.ownerStatus?.attachments &&
                  preliminaryNotice?.ownerStatus?.attachments.length > 0 &&
                  preliminaryNotice?.ownerStatus?.attachments.map((attachment) => (
                    <AppButton
                      type="TERTIARY"
                      buttonStyles={{
                        padding: '4px',
                        height: '30px',
                        width: '30px',
                        marginRight: '4px',
                      }}
                      icon={<Eye color={COLORS.GREY_500} size={18} />}
                      onClick={() => {
                        onPreviewAttachment(attachment?.url ?? '', attachment?.fileType);
                      }}
                    />
                  ))}
              </div>
            </div>
            <div className="flex justify-between items-center mb-1">
              <div className="text-[14px] font-medium ml-[10px] w-[70px]">Lender</div>
              <div className="text-[14px] font-medium w-[50px] capitalize">
                {preliminaryNotice?.lenderStatus?.status
                  ? preliminaryNotice?.lenderStatus?.status
                  : preliminaryNotice?.lenderStatus?.deliveredDate
                  ? moment(preliminaryNotice?.lenderStatus?.deliveredDate).format('MM/DD/YYYY')
                  : lenderIsNeeded
                  ? 'Pending'
                  : 'N/A'}
              </div>
              <div className="w-[100px] flex">
                {preliminaryNotice?.lenderStatus?.attachments &&
                  preliminaryNotice?.lenderStatus?.attachments.length > 0 &&
                  preliminaryNotice?.lenderStatus?.attachments.map((attachment) => (
                    <AppButton
                      type="TERTIARY"
                      buttonStyles={{
                        padding: '4px',
                        height: '30px',
                        width: '30px',
                        marginRight: '4px',
                      }}
                      icon={<Eye color={COLORS.GREY_500} size={18} />}
                      onClick={() => {
                        onPreviewAttachment(attachment?.url ?? '', attachment?.fileType);
                      }}
                    />
                  ))}
              </div>
            </div>
            {preliminaryNotice?.customerStatus && (
              <div className="flex justify-between items-center mb-1">
                <div className="text-[14px] font-medium ml-[10px] w-[70px]">Customer</div>
                <div className="text-[14px] font-medium w-[50px]">
                  {preliminaryNotice?.contractorStatus?.status
                    ? preliminaryNotice?.contractorStatus?.status
                    : preliminaryNotice?.customerStatus?.deliveredDate
                    ? moment(preliminaryNotice?.customerStatus?.deliveredDate).format('MM/DD/YYYY')
                    : customerIsNeeded
                    ? 'Pending'
                    : 'N/A'}
                </div>
                <div className="w-[100px] flex">
                  {preliminaryNotice?.customerStatus?.attachments &&
                    preliminaryNotice?.customerStatus?.attachments.length > 0 &&
                    preliminaryNotice?.customerStatus?.attachments.map((attachment) => (
                      <AppButton
                        type="TERTIARY"
                        buttonStyles={{
                          padding: '4px',
                          height: '30px',
                          width: '30px',
                          marginRight: '4px',
                        }}
                        icon={<Eye color={COLORS.GREY_500} size={18} />}
                        onClick={() => {
                          onPreviewAttachment(attachment?.url ?? '', attachment?.fileType);
                        }}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Cancel"
            type="TERTIARY"
            onClick={handleClose}
            buttonStyles={{ marginRight: '8px' }}
          />
        </div>
      </div>
    </Modal>
  );
};
