import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Edit3 } from 'react-feather';
import {
  getLienDeadlineDate,
  getPreLienDeadlineDate,
  getStateAbbreviation,
  SupplierJobApiResponse,
} from '@dill/dill-shared';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CheckCircle, Warning, CircleDashed, MegaphoneSimple } from 'phosphor-react';
import moment from 'moment';

import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import AppCreateReleaseModal from '../../../general/AppCreateReleaseModal/AppCreateReleaseModal';
import CreateSignatureModal from '../../lienWaivers/supplier/CreateSignatureModal/CreateSignatureModal';
import AppEditFirstDeliveryDateModal from '../../../general/AppEditFirstDeliveryDateModal';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { updateSupplierJob } from '../../../../redux/services/supplierJobsService';
import { AppDeleteModal } from '../../../general/AppDeleteModal/AppDeleteModal';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { useNavigate } from 'react-router-dom';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import VirtualizeSupplierJobTable from './VirtualizeSupplierJobTable';
import { Dropdown } from './Dropdown';

const SupplierJobsTable = ({
  searchedSupplierJobs,
  infiniteRef,
  hasNextPage = false,
  loading = false,
  onReload = () => {},
  dataFullyLoaded,
}: {
  searchedSupplierJobs: SupplierJobApiResponse[];
  infiniteRef?: any;
  hasNextPage?: boolean;
  loading?: boolean;
  onReload: () => void;
  dataFullyLoaded: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { selectedSupplierJobs, supplierJobsMap } = useAppSelector((state) => state.supplierJobs);
  const [selectedSupplierJob, setSelectedSupplierJob] = useState({} as SupplierJobApiResponse);
  const [editJob, setEditJob] = useState<boolean>(false);
  const [editFirstDeliveryDate, setEditFirstDeliveryDate] = useState<boolean>(false);
  const [softFirstDeliveryDate, setSoftFirstDeliveryDate] = useState<Date | null>(null);
  const [openCreateReleaseModal, setOpenCreateReleaseModal] = useState<boolean>(false);
  const [openDropdown, setOpenDropDown] = useState<boolean>(false);
  const navigate = useNavigate();
  const [openLienDropdown, setOpenLienDropdown] = useState('');
  const [isCreateSignatureModalOpen, setIsCreateSignatureModalOpen] = useState(false);
  const [signature, setSignature] = useState('');
  const [dateModalType, setDateModalType] = useState<
    'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'
  >('firstDeliveryDate');
  const [confirmMarkAsActive, setConfirmMarkAsActive] = useState<boolean>(false);
  const [sortDetails, setSortDetails] = useState<{ sortColumn: string; sortType: string }>({
    sortColumn: '',
    sortType: '',
  });

  const [dropdownModalStyle, setDropdownModalStyle] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  const { user } = useAppSelector((globalState) => globalState.auth);
  const { requestedSupplierJobs } = useAppSelector(
    (globalState) => globalState.requestJobInformation
  );

  useEffect(() => {
    if (selectedSupplierJob) {
      const updatedSupplierJob = searchedSupplierJobs.find(
        (job) => job.id === selectedSupplierJob.id
      );
      if (updatedSupplierJob) {
        setSelectedSupplierJob(updatedSupplierJob);
      }
    }
  }, [searchedSupplierJobs, selectedSupplierJob]);

  const handleAddSignature = (newSignature: string, isManual: boolean) => {
    setSignature(newSignature);
    setIsCreateSignatureModalOpen(false);
  };

  // Our timestamps are in UTC without timezone info. We need to convert them to PST
  const convertDateToPST = (date: Date) => {
    const dtString = moment.utc(date).format('YYYY-MM-DD');
    return new Date(`${dtString}T12:00:00`); // set to 12:00 PM
  };

  const getDeliveryDate = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    let dt = null;

    if (invoiceDeliveryDate) {
      dt = convertDateToPST(invoiceDeliveryDate);
    } else if (softDeliveryDt) {
      dt = convertDateToPST(softDeliveryDt);
    }

    return dt;
  };

  const handleMarkAsActive = async () => {
    const result = await dispatch(
      updateSupplierJob({
        supplierJobId: selectedSupplierJob.id ?? '',
        supplierJobDetails: { isArchived: true },
      })
    );
    if (result.type === 'supplierJobs/updateSupplierJob/fulfilled') {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'SUCCESS',
          title: 'Successfully marked job as inactive!',
          subTitle: `This job has been archived and is no longer on the active job list.`,
          onClose: () => {
            onReload();
          },
        })
      );
    } else {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Failed to mark the job as inactive',
          subTitle: `Contact customer support.`,
          onClose: () => {},
        })
      );
    }
  };

  const sortByProperty = (
    sortType: 'asc' | 'desc',
    sortKey: string,
    getter: (item: any) => any,
    compare?: (a: any, b: any) => number
  ) => {
    // Filter out undefined or null values
    const filteredSupplierJobs = searchedSupplierJobs.filter((supplierJob) => {
      const value = getter(supplierJob);
      return value !== undefined && value !== null && value !== '';
    });

    // Create a sorted copy of the filtered supplier jobs
    const sortedSupplierJobCopy = [...filteredSupplierJobs];

    sortedSupplierJobCopy.sort((a, b) => {
      const valueA = getter(a);
      const valueB = getter(b);

      // Use custom compare function if provided
      if (compare) {
        return compare(valueA, valueB) * (sortType === 'asc' ? 1 : -1);
      } else {
        // Default alphanumeric sorting
        return (
          valueA
            .toString()
            .localeCompare(valueB.toString(), undefined, { numeric: true, sensitivity: 'base' }) *
          (sortType === 'asc' ? 1 : -1)
        );
      }
    });

    // Append items with undefined or null values to the sorted list
    const undefinedOrNullSupplierJob = searchedSupplierJobs.filter((supplierJob) => {
      const value = getter(supplierJob);
      return value === undefined || value === null || value === '';
    });

    const sortedSupplierJob = sortedSupplierJobCopy.concat(undefinedOrNullSupplierJob);

    return sortedSupplierJob;
  };

  const sortedSupplierJobs = useMemo(() => {
    let result = searchedSupplierJobs;
    if (searchedSupplierJobs) {
      if (sortDetails.sortType === 'desc') {
        if (sortDetails.sortColumn === 'supplierJob') {
          result = sortByProperty('desc', 'name', (supplierJob) => supplierJob?.name);
        } else if (sortDetails.sortColumn === 'customer') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => supplierJob?.buyer?.name
          );
        } else if (sortDetails.sortColumn === 'fullAddress') {
          result = sortByProperty('desc', sortDetails.sortColumn, (supplierJob) =>
            `${supplierJob?.jobAddress || ''}`.trim()
          );
        } else if (sortDetails.sortColumn === 'owner') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => supplierJob?.owner
          );
        } else if (sortDetails.sortColumn === 'gc') {
          result = sortByProperty('desc', sortDetails.sortColumn, (supplierJob) => supplierJob?.GC);
        } else if (sortDetails.sortColumn === 'apn') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => supplierJob?.apn
          );
        } else if (sortDetails.sortColumn === 'firstDeliveryDate') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const deliveryDate = supplierJob.firstDeliveryDate
                ? supplierJob.firstDeliveryDate
                : supplierJob.firstInvoiceDate;
              return deliveryDate ? new Date(deliveryDate).getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'lastDeliveryDate') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const lastDeliveryDate = supplierJob?.lastDeliveryDate
                ? supplierJob.lastDeliveryDate
                : supplierJob.lastInvoiceDate;
              return lastDeliveryDate ? new Date(lastDeliveryDate).getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'noticeOfCompletionDate') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => {
              return supplierJob.noticeOfCompletionDate
                ? new Date(supplierJob.noticeOfCompletionDate).getTime()
                : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'prelien') {
          result = sortByProperty('desc', sortDetails.sortColumn, (supplierJob) =>
            supplierJob.preLienStatus
              ? supplierJob.preLienStatus
              : supplierJob.preLienNotice
              ? 'done'
              : 'not-yet'
          );
        } else if (sortDetails.sortColumn === 'prelienDeadline') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const firstDeliveryDate = getDeliveryDate(
                supplierJob.firstInvoiceDate,
                supplierJob.firstDeliveryDate
              );
              const processedNoticeOfCompletionDate =
                supplierJob.noticeOfCompletionDate &&
                typeof supplierJob.noticeOfCompletionDate === 'string'
                  ? new Date(supplierJob.noticeOfCompletionDate)
                  : supplierJob.noticeOfCompletionDate;
              const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
              const state =
                supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                  ? 'CA'
                  : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                  ? 'CA'
                  : supplierJobState;
              const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
              return preLienDateDeadlineDate ? preLienDateDeadlineDate.getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'lienDeadline') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const lastDeliveryDate = getDeliveryDate(
                supplierJob.lastInvoiceDate,
                supplierJob.lastDeliveryDate
              );
              const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
              const state =
                supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                  ? 'CA'
                  : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                  ? 'CA'
                  : supplierJobState;
              const lienDeadlineDate = getLienDeadlineDate(
                state,
                lastDeliveryDate,
                supplierJob.noticeOfCompletionDate,
                supplierJob?.jobSector ?? 'private'
              );
              return lienDeadlineDate ? lienDeadlineDate.getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'openAR') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) =>
              supplierJob?.invoices?.reduce((acc: any, invoice: any) => {
                return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
              }, 0) || null,
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'estimatedMaterialAmount') {
          result = sortByProperty(
            'desc',
            sortDetails.sortColumn,
            (supplierJob) => supplierJob?.estimatedMaterialAmount || null,
            (a, b) => a - b
          );
        }
      } else if (sortDetails.sortType === 'asc') {
        if (sortDetails.sortColumn === 'supplierJob') {
          result = sortByProperty('asc', 'name', (supplierJob) => supplierJob?.name);
        } else if (sortDetails.sortColumn === 'customer') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => supplierJob?.buyer?.name
          );
        } else if (sortDetails.sortColumn === 'fullAddress') {
          result = sortByProperty('asc', sortDetails.sortColumn, (supplierJob) =>
            `${supplierJob?.jobAddress || ''}`.trim()
          );
        } else if (sortDetails.sortColumn === 'owner') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => supplierJob?.owner
          );
        } else if (sortDetails.sortColumn === 'gc') {
          result = sortByProperty('asc', sortDetails.sortColumn, (supplierJob) => supplierJob?.GC);
        } else if (sortDetails.sortColumn === 'apn') {
          result = sortByProperty('asc', sortDetails.sortColumn, (supplierJob) => supplierJob?.apn);
        } else if (sortDetails.sortColumn === 'firstDeliveryDate') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const deliveryDate = supplierJob.firstDeliveryDate
                ? supplierJob.firstDeliveryDate
                : supplierJob.firstInvoiceDate;
              return deliveryDate ? new Date(deliveryDate).getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'lastDeliveryDate') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const lastDeliveryDate = supplierJob.lastDeliveryDate
                ? supplierJob.lastDeliveryDate
                : supplierJob.lastInvoiceDate;
              return lastDeliveryDate ? new Date(lastDeliveryDate).getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'noticeOfCompletionDate') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => {
              return supplierJob.noticeOfCompletionDate
                ? new Date(supplierJob.noticeOfCompletionDate).getTime()
                : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'prelien') {
          result = sortByProperty('asc', sortDetails.sortColumn, (supplierJob) =>
            supplierJob.preLienStatus
              ? supplierJob.preLienStatus
              : supplierJob.preLienNotice
              ? 'done'
              : 'not-yet'
          );
        } else if (sortDetails.sortColumn === 'prelienDeadline') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const firstDeliveryDate = getDeliveryDate(
                supplierJob.firstInvoiceDate,
                supplierJob.firstDeliveryDate
              );
              const processedNoticeOfCompletionDate =
                supplierJob.noticeOfCompletionDate &&
                typeof supplierJob.noticeOfCompletionDate === 'string'
                  ? new Date(supplierJob.noticeOfCompletionDate)
                  : supplierJob.noticeOfCompletionDate;
              const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
              const state =
                supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                  ? 'CA'
                  : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                  ? 'CA'
                  : supplierJobState;
              const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
              return preLienDateDeadlineDate ? preLienDateDeadlineDate.getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'lienDeadline') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => {
              const lastDeliveryDate = getDeliveryDate(
                supplierJob.lastInvoiceDate,
                supplierJob.lastDeliveryDate
              );

              const supplierJobState = getStateAbbreviation(supplierJob.jobState) ?? 'CA';
              const state =
                supplierJob?.supplierId === 'krAwPCRGqgEX0CZhRZva'
                  ? 'CA'
                  : !supplierJob.jobState && supplierJob?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
                  ? 'CA'
                  : supplierJobState;
              const lienDeadlineDate = getLienDeadlineDate(
                state,
                lastDeliveryDate,
                supplierJob.noticeOfCompletionDate,
                supplierJob?.jobSector ?? 'private'
              );
              return lienDeadlineDate ? lienDeadlineDate.getTime() : null;
            },
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'openAR') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) =>
              supplierJob?.invoices?.reduce((acc: any, invoice: any) => {
                return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
              }, 0) || null,
            (a, b) => a - b
          );
        } else if (sortDetails.sortColumn === 'estimatedMaterialAmount') {
          result = sortByProperty(
            'asc',
            sortDetails.sortColumn,
            (supplierJob) => supplierJob?.estimatedMaterialAmount || null,
            (a, b) => a - b
          );
        }
      } else {
        result = searchedSupplierJobs;
      }
    }
    return result;
  }, [searchedSupplierJobs, sortDetails]);

  const handleSort = (value: string) => {
    setSortDetails((prev) => ({
      sortColumn: value,
      sortType:
        prev.sortColumn === value
          ? prev.sortType === 'asc'
            ? 'desc'
            : prev.sortType === 'desc'
            ? ''
            : 'asc'
          : 'asc',
    }));
  };

  const handleEditJob = (data: SupplierJobApiResponse) => {
    setSelectedSupplierJob(data);
    setEditJob(true);
  };

  const handleConfirmMarkAsActive = (data: SupplierJobApiResponse) => {
    setSelectedSupplierJob(data);
    setConfirmMarkAsActive(true);
  };

  const handleCreatePreliminaryNotice = (data: SupplierJobApiResponse) => {
    navigate(`/preliminaryNotices/overview/addPreliminaryNotice?jobId=${data.id}`);
  };

  const handleCreateRelease = (data: SupplierJobApiResponse) => {
    setSelectedSupplierJob(data);
    setOpenCreateReleaseModal(true);
  };

  const handleEditSupplierJobDate = (
    data: SupplierJobApiResponse,
    date: Date | null,
    modalType: 'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'
  ) => {
    if (date) {
      setSoftFirstDeliveryDate(date);
    }
    setDateModalType(modalType);
    setSelectedSupplierJob(data);
    setEditFirstDeliveryDate(true);
  };

  const handleGoToSupplierDetails = (data: SupplierJobApiResponse) => {
    navigate(`/supplierJobs/${data.id}`);
  };

  const handleDropdown = (data: SupplierJobApiResponse, value: { left: number; top: number }) => {
    setDropdownModalStyle({ ...value });
    setSelectedSupplierJob(data);
    setOpenDropDown(true);
  };

  return (
    <div
      className="w-full flex flex-col border rounded-lg overflow-x-scroll overflow-y-hidden mt-4"
      style={{
        height: `${
          requestedSupplierJobs.length > 0 ? 'calc(100vh - 270px)' : 'calc(100vh - 240px)'
        }`,
      }}>
      <Dropdown
        isOpen={openDropdown}
        onClose={() => setOpenDropDown(false)}
        onSelect={(val) => {
          if (val === 'edit') {
            handleEditJob(selectedSupplierJob);
          }
          if (val === 'mark-as-inactive') {
            handleConfirmMarkAsActive(selectedSupplierJob);
          }
          if (val === 'createPreliminaryNotice') {
            handleCreatePreliminaryNotice(selectedSupplierJob);
          }
          if (val === 'manualUploadPrelimNotice') {
            navigate(
              `/preliminaryNotices/overview/manualAddPreliminaryNotice?jobId=${selectedSupplierJob.id}`
            );
          }
        }}
        options={
          selectedSupplierJob.isArchived
            ? [
                {
                  value: 'edit',
                  label: `${
                    user?.userSupplier?.ownerVerificationEnabled ? 'Edit & Verify' : 'Edit'
                  }`,
                  icon: <Edit3 size={16} />,
                },
                {
                  value: 'createPreliminaryNotice',
                  label: 'Create Preliminary Notice',
                  icon: <MegaphoneSimple size={16} />,
                },
                {
                  value: 'manualUploadPrelimNotice',
                  label: 'Upload Previous Notice',
                  icon: <MegaphoneSimple size={16} />,
                },
              ]
            : [
                {
                  value: 'edit',
                  label: `${
                    user?.userSupplier?.ownerVerificationEnabled ? 'Edit & Verify' : 'Edit'
                  }`,
                  icon: <Edit3 size={16} />,
                },
                {
                  value: 'mark-as-inactive',
                  label: 'Mark as Inactive',
                  icon: <CircleDashed size={16} />,
                },
                {
                  value: 'createPreliminaryNotice',
                  label: 'Create Preliminary Notice',
                  icon: <MegaphoneSimple size={16} />,
                },
                {
                  value: 'manualUploadPrelimNotice',
                  label: 'Upload Previous Notice',
                  icon: <MegaphoneSimple size={16} />,
                },
              ]
        }
        dropdownModalStyle={dropdownModalStyle}
      />

      <AppDeleteModal
        open={confirmMarkAsActive}
        handleClose={() => setConfirmMarkAsActive(false)}
        title={`Are you sure you want to mark ${selectedSupplierJob?.name} as a inactive?`}
        subTitle="This action will archive the job. This action can't be undone"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        onSecondButtonClick={() => {
          handleMarkAsActive();
          setConfirmMarkAsActive(false);
        }}
      />
      <AppNewSupplierJobModal
        open={editJob}
        editMode={true}
        selectedSupplierJob={selectedSupplierJob}
        handleClose={({ wasBuyerEdited }) => {
          if (wasBuyerEdited) {
            onReload();
          }
          setEditJob(false);
        }}
        onReload={() => {}}
      />
      <AppCreateReleaseModal
        open={openCreateReleaseModal}
        handleClose={() => {
          setOpenCreateReleaseModal(false);
          setSignature('');
        }}
        selectedJob={selectedSupplierJob}
        onSignSignature={() => {
          setIsCreateSignatureModalOpen(true);
        }}
        signature={signature}
        onEditSupplierJob={() => {
          setEditJob(true);
        }}
      />
      <CreateSignatureModal
        open={isCreateSignatureModalOpen}
        handleClose={() => {
          setIsCreateSignatureModalOpen(false);
        }}
        addSignature={handleAddSignature}
        onDownloadRealease={() => {}}
        defaultIsManualSign={false}
      />
      <AppEditFirstDeliveryDateModal
        open={editFirstDeliveryDate}
        handleClose={() => {
          setSoftFirstDeliveryDate(null);
          setEditFirstDeliveryDate(false);
        }}
        dateType={dateModalType}
        selectedSupplierJob={selectedSupplierJob}
        softDeliveryDate={softFirstDeliveryDate}
        onReload={() => {}}
      />
      <ReactTooltip
        id={'soft-delivery-date-tooltip'}
        place="right"
        variant="dark"
        opacity={'100%'}
        style={{
          background: '#222',
          width: '300px',
          zIndex: 99999,
        }}>
        <span>Please confirm delivery date. This is a best guess based on the invoice date.</span>
      </ReactTooltip>
      <div>
        <VirtualizeSupplierJobTable
          supplierJobs={sortedSupplierJobs}
          onCreateRelease={handleCreateRelease}
          onEditSupplierJobDate={handleEditSupplierJobDate}
          onGoToSupplierDetails={handleGoToSupplierDetails}
          onSort={handleSort}
          sortDetails={sortDetails}
          onOpenDropdown={handleDropdown}
          dataFullyLoaded={dataFullyLoaded}
        />
      </div>
    </div>
  );
};

export default SupplierJobsTable;
