import { createSlice } from '@reduxjs/toolkit';
import { PreliminaryNoticeApiResponse } from '@dill/dill-shared';
import {
  createPreliminaryNoticeExtraReducer,
  getPreliminaryNoticeExtraReducer,
  getSupplierPreliminaryNoticesExtraReducer,
  updatePreliminaryNoticeExtraReducer,
  deletePreliminaryNoticeExtraReducer,
  manualCreatePreliminaryNoticeExtraReducer,
} from '../reducers/preliminaryNoticesReducers';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface PreliminaryNoticeInitialState {
  loadingList: string[];
  preliminaryNotices: PreliminaryNoticeApiResponse[];
  loadingErrorList: { [x: string]: string[] }[];
  selectedPreliminaryNotices: PreliminaryNoticeApiResponse[];
  selectedPreliminaryNotice: PreliminaryNoticeApiResponse | null;
}

const initialState: PreliminaryNoticeInitialState = {
  loadingList: [],
  preliminaryNotices: [],
  loadingErrorList: [],
  selectedPreliminaryNotices: [],
  selectedPreliminaryNotice: null,
};

export const preliminaryNoticesSlice = createSlice({
  name: 'preliminaryNotices',
  initialState,
  reducers: {
    resetPreliminaryNoticesRootState: () => initialState,
    selectPreliminaryNotices: (state, action: PayloadAction<any[]>) => {
      const ii = [...state.selectedPreliminaryNotices.map((iu) => ({ ...iu }))];
      const filteredPreliminaryNotices = ii.filter(
        (preliminaryNotices) =>
          !action.payload.map((yy: any) => yy.id).includes(preliminaryNotices.id)
      );
      state.selectedPreliminaryNotices = [...filteredPreliminaryNotices, ...action.payload];
    },
    selectPreliminaryNotice: (
      state,
      action: PayloadAction<PreliminaryNoticeApiResponse | null>
    ) => {
      state.selectedPreliminaryNotice = action.payload;
    },
    unSelectPreliminaryNotices: (state, action: PayloadAction<any[]>) => {
      const filteredPreliminaryNotices = state.selectedPreliminaryNotices.filter(
        (preliminaryNotices) =>
          !action.payload.map((yy: any) => yy.id).includes(preliminaryNotices.id)
      );
      state.selectedPreliminaryNotices = [...filteredPreliminaryNotices];
    },

    resetPreliminaryNotices: (state) => {
      state.selectedPreliminaryNotices = [];
      state.selectedPreliminaryNotice = null;
    },
  },
  extraReducers(builder) {
    createPreliminaryNoticeExtraReducer(builder);
    getSupplierPreliminaryNoticesExtraReducer(builder);
    getPreliminaryNoticeExtraReducer(builder);
    updatePreliminaryNoticeExtraReducer(builder);
    deletePreliminaryNoticeExtraReducer(builder);
    manualCreatePreliminaryNoticeExtraReducer(builder);
  },
});

export const {
  resetPreliminaryNoticesRootState,
  selectPreliminaryNotices,
  unSelectPreliminaryNotices,
  resetPreliminaryNotices,
  selectPreliminaryNotice,
} = preliminaryNoticesSlice.actions;
