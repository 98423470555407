import { Modal } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { getManualUploadInvoices } from '../../../redux/services/manualUploadService';
import { ref as realTimeDBRef, remove } from 'firebase/database';
import { myRealtimeDB } from '../../../firebase/config';
import { twMerge } from 'tailwind-merge';
import { useLocation, useNavigate } from 'react-router-dom';

const AppManualImportProgressModal = ({
  open,
  handleClose = () => {},
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { supplierManualUploadProgress } = useAppSelector((state) => state.generic);
  const { user } = useAppSelector((state) => state.auth);
  const [currentStatus, setCurrentStatus] = useState<{
    percentage: number;
    message: string;
  } | null>({ percentage: 0, message: 'processing data' });
  const statusList = useMemo(() => {
    return {
      PROCESSING_DATA: { percentage: 5, message: 'Processing data' },
      SAVING_CUSTOMERS: { percentage: 20, message: 'Importing customers' },
      SAVING_JOBS: { percentage: 30, message: 'Importing jobs' },
      SAVING_INVOICES: { percentage: 50, message: 'Importing invoices' },
      RECONCILING_PAYMENTS: { percentage: 70, message: 'Importing payments' },
      SYNCING_CUSTOMERS: { percentage: 80, message: 'Processing customers' },
      DONE: { percentage: 100, message: 'Data was successfully uploaded' },
      ERROR: { percentage: 100, message: 'Error while uploading data' },
    };
  }, [open]);
  useEffect(() => {
    if (supplierManualUploadProgress) {
      const keyItem = supplierManualUploadProgress.status as keyof typeof statusList;
      if (statusList[keyItem]) {
        setCurrentStatus(statusList[keyItem]);
      }
    } else {
      if (open && user?.userSupplier?.integrationType !== 'LIEN_ONLY_UPLOAD') {
        dispatch(getManualUploadInvoices({ itemsPerPage: 50 }));
      } else if (open && user?.userSupplier?.integrationType === 'LIEN_ONLY_UPLOAD') {
        if (location.pathname.includes('supplierJobs')) {
          navigate('/supplierJobs?refresh=true');
        }
        if (location.pathname.includes('customers')) {
          navigate('/customers?refresh=true');
        }
      }
      handleClose();
    }
    return () => {};
  }, [supplierManualUploadProgress, statusList]);

  const handleCloseOnFinish = async () => {
    if (user?.userSupplier) {
      const suppliersManualUploadProgressRef = realTimeDBRef(
        myRealtimeDB,
        `suppliersManualUploadProgress/${user.userSupplier.id}`
      );
      await remove(suppliersManualUploadProgressRef);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex   w-[540px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <div className=" ">
          <h2 className="text-lg font-semibold mx-4 my-2">Import CSV</h2>
          <hr className="h-px my-4 bg-gray-200 border-0 " />

          {currentStatus && (
            <div className="flex flex-col items-center justify-center  h-80 border mx-5 rounded-lg">
              <CircularProgressbar
                value={currentStatus?.percentage}
                text={
                  supplierManualUploadProgress && supplierManualUploadProgress.status === 'ERROR'
                    ? 'Error'
                    : `${currentStatus?.percentage}%`
                }
                maxValue={100}
                minValue={0}
                className={twMerge(
                  'w-28 h-28  rounded-full p-3',
                  supplierManualUploadProgress && supplierManualUploadProgress.status === 'ERROR'
                    ? `ERROR_200-BG`
                    : `PRIMARY_200-BG`
                )}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  // rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',

                  // Text size
                  textSize: '20px',

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor:
                    supplierManualUploadProgress && supplierManualUploadProgress.status === 'ERROR'
                      ? `${COLORS.ERROR_600}`
                      : `${COLORS.PRIMARY_600}`,
                  textColor:
                    supplierManualUploadProgress && supplierManualUploadProgress.status === 'ERROR'
                      ? `${COLORS.ERROR_600}`
                      : `${COLORS.PRIMARY_600}`,
                  trailColor: '#ffffff',
                })}
              />
              <div className="flex flex-col mt-4 items-center">
                <p className="font-bold text-sm"> {currentStatus?.message}...</p>
                <p
                  className={twMerge(
                    'text-sm',
                    supplierManualUploadProgress && supplierManualUploadProgress.status === 'ERROR'
                      ? `ERROR_600-CLR`
                      : `PRIMARY_600-CLR`
                  )}>
                  {supplierManualUploadProgress && supplierManualUploadProgress.message}
                </p>
                {supplierManualUploadProgress &&
                  supplierManualUploadProgress.status === 'DONE' &&
                  supplierManualUploadProgress.jobs &&
                  supplierManualUploadProgress.jobs.length > 0 && (
                    <p className=" text-xs ERROR_500-CLR text-center">{`There ${
                      supplierManualUploadProgress.jobs.length > 1
                        ? `are ${supplierManualUploadProgress.jobs.length} jobs not created. They are linked to customers that have not been imported. These are the jobs:`
                        : 'is a job not created. It is linked to a customer that has not been imported. This is the job:'
                    } ${supplierManualUploadProgress.jobs.join(', ')}`}</p>
                  )}
                {!currentStatus ||
                  (currentStatus && currentStatus.percentage !== 100 && (
                    <p className=" text-xs text-gray-600"> Importing data may take a few minutes</p>
                  ))}
              </div>
            </div>
          )}
          <hr className="h-px my-4 bg-gray-200 border-0 " />
          <div className="flex justify-end w-full px-3 mb-2">
            {((currentStatus && currentStatus.percentage === 100) ||
              (supplierManualUploadProgress &&
                supplierManualUploadProgress.status === 'ERROR')) && (
              <div className="mx-3">
                <AppButton
                  text="Close"
                  onClick={() => {
                    handleCloseOnFinish();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppManualImportProgressModal;
