import { useEffect, useMemo, useState } from 'react';
import { Buyer, getAllStates, SupplierJobUpdate } from '@dill/dill-shared';
import { X } from 'phosphor-react';

import { COLORS } from '../../../utils/colors';

import { AppButton } from '../AppButton/AppButton';
import { AppInputField } from '../AppInputField/AppInputField';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// import './AppNewSupplierJobModal.scss';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import EditBuyerModal from '../../modules/suppliers/EditBuyerModal/EditBuyerModal';
import { getSupplierBuyers } from '../../../redux/services/supplierBuyersService';
import { createSupplierJob } from '../../../redux/services/supplierJobsService';
import { openDillToast } from '../../../utils/helpers';

interface AddSupplierJobFormProps {
  className?: string;
  onClose: () => void;
  onBuyerEdit?: () => void;
  onRequestInfoSheet: (data: any) => void;
  jobSiteData: PartialSupplierJobPreVerify;
}

type PartialSupplierJobPreVerify = Pick<
  SupplierJobUpdate,
  | 'buyerId'
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'constructionMonitorPermitId'
>;

const INITIAL_STATE = {
  name: '',
  jobNumber: '',
  jobAddress: '',
  jobCity: '',
  jobState: '',
  jobZipCode: '',
  buyerId: '',
  constructionMonitorPermitId: null,
} as PartialSupplierJobPreVerify;

const RequestJobInfoForm = (props: AddSupplierJobFormProps) => {
  const { className, onClose, jobSiteData, onBuyerEdit, onRequestInfoSheet } = props;

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [isEditBuyerModalOpen, setIsEditBuyerModalOpen] = useState(false);
  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [creating, setCreating] = useState(false);

  const [errors, setErrors] = useState(INITIAL_STATE);

  const onTextChange = (key: string, value: string) => {
    setSupplierJobInput((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const { supplierBuyers } = useAppSelector((state) => state.supplierBuyers);
  const { user } = useAppSelector((state) => state.auth);

  const fetchedSupplierBuyers = useMemo(() => {
    if (supplierBuyers.length > 0) {
      return supplierBuyers;
    }
    return [];
  }, [supplierBuyers]);

  const allStates = useMemo(() => {
    return getAllStates();
  }, []);

  useEffect(() => {
    if (jobSiteData && jobSiteData.buyerId && jobSiteData.buyerId !== '') {
      const foundSupplierBuyer = supplierBuyers.find((supBuy) => supBuy.id === jobSiteData.buyerId);
      if (foundSupplierBuyer) {
        onTextChange('buyerId', jobSiteData.buyerId);
      }
    }
  }, [jobSiteData, supplierBuyers]);

  const handleRequestInfoSheet = async () => {
    setShowErrorAbove(false);

    const newErrors = {} as PartialSupplierJobPreVerify;

    if (!supplierJobInput.buyerId) {
      newErrors.buyerId = 'Please select a customer';
    }
    if (supplierJobInput.name?.trim() === '') {
      newErrors.name = 'Job Name is required';
    }
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      setShowErrorAbove(true);
      setLoading(false);
      return;
    }
    setCreating(true);
    const foundSupplierBuyer = supplierBuyers.find(
      (supBuy) => supBuy.id === supplierJobInput.buyerId
    );
    const data = {
      ...supplierJobInput,
      address: supplierJobInput.jobAddress ?? '',
      city: supplierJobInput.jobCity ?? '',
      state: supplierJobInput.jobState ?? '',
      zipCode: supplierJobInput.jobZipCode ?? '',
      buyerId: supplierJobInput.buyerId ?? '',
      jobNumber: supplierJobInput.jobNumber ?? '',
      name: supplierJobInput.name ?? '',
      buyerName: foundSupplierBuyer?.name ?? '',
      buyer: foundSupplierBuyer ?? null,
    };
    const createdSupplierJob = await dispatch(
      createSupplierJob({
        supplierJobDetails: {
          supplierId: user?.userSupplier?.id || '',
          name: supplierJobInput.name || '',
          jobNumber: supplierJobInput.jobNumber || '',
          jobAddress: supplierJobInput.jobAddress || '',
          jobCity: supplierJobInput.jobCity || '',
          jobState: supplierJobInput.jobState || '',
          jobZipCode: supplierJobInput.jobZipCode || '',
          owner: '',
          ownerAddress: '',
          ownerCity: '',
          ownerState: '',
          ownerZipCode: '',
          buyerId: supplierJobInput.buyerId || '',
          GC: '',
          GCAddress: '',
          GCCity: '',
          GCState: '',
          GCZipCode: '',
          lender: '',
          lenderAddress: '',
          lenderCity: '',
          lenderState: '',
          lenderZipCode: '',
          apn: '',
          constructionMonitorPermitId: null,
          estimatedMaterialAmount: null,
          jobType: 'commercial',
          jobSector: 'private',
          customerType: 'SUB',
          jobFurnishings: '',
          hasNoLender: false,
        },
      })
    );
    if (createdSupplierJob.type === 'supplierJobs/createSupplierJob/fulfilled') {
      setCreating(false);
      onRequestInfoSheet({
        ...data,
        id: createdSupplierJob?.payload?.data?.id ?? '',
        selectedSupplierJob: createdSupplierJob?.payload?.data,
      });
    } else {
      setCreating(false);
      openDillToast({ message: 'Failed to request info sheet', type: 'ERROR' });
    }
  };

  return (
    <>
      <EditBuyerModal
        open={isEditBuyerModalOpen}
        buyer={selectedBuyer}
        handleClose={(editSuccess) => {
          if (editSuccess) {
            dispatch(getSupplierBuyers());
            if (onBuyerEdit) {
              onBuyerEdit();
            }
          }
          setIsEditBuyerModalOpen(false);
        }}
      />
      <div className={`new-job-modal-content pt-1 ${className}`}>
        <div className="new-job-modal-title-container">
          <h3 className="font-medium text-lg">{'Add Job'}</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={onClose}
          />
        </div>
        <div className="flex flex-row px-6 py-3">
          <div className="flex flex-col gap-2 w-full">
            <AppDropDown2
              isRequired
              label="Customer"
              errorText={errors.buyerId ? errors.buyerId : ''}
              value={supplierJobInput.buyerId || ''}
              items={[
                ...fetchedSupplierBuyers.map((buyer) => {
                  return {
                    label: buyer.name,
                    value: buyer.id,
                    labelInElement: (
                      <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                        <div className="text-sm">{`${buyer.name}`}</div>
                        <button
                          onClick={(e) => {
                            setIsEditBuyerModalOpen(true);
                            setSelectedBuyer(buyer);
                          }}
                          className="text-sm px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                          Edit
                        </button>
                      </div>
                    ),
                  };
                }),
              ]}
              onSelectChange={(item) => {
                if (item && item?.value !== null) {
                  const foundSupplierBuyer = supplierBuyers.find(
                    (supBuy) => supBuy.id === item?.value
                  );
                  if (foundSupplierBuyer) {
                    onTextChange('buyerId', item.value);
                  }
                }
              }}
            />
            <div className="flex flex-row justify-around gap-2">
              <div className="w-full">
                <AppInputField
                  id="name"
                  label="Job Name"
                  isRequired={true}
                  value={supplierJobInput.name}
                  errorText={errors.name ? errors.name : ''}
                  onTextChange={(text: string) => {
                    onTextChange('name', text);
                  }}
                />
              </div>
              <div className="spacer" />
              <div className="w-full">
                <AppInputField
                  id="number"
                  label="Your Job Number"
                  placeholder="12345"
                  value={supplierJobInput.jobNumber}
                  onTextChange={(text) => {
                    onTextChange('jobNumber', text);
                  }}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full">
                <AppInputField
                  id="address"
                  label="Job Address"
                  value={supplierJobInput.jobAddress}
                  onTextChange={(text) => {
                    onTextChange('jobAddress', text);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row justify-around gap-2">
              <div className="w-full">
                <AppInputField
                  id="city"
                  label="Job City"
                  value={supplierJobInput.jobCity}
                  onTextChange={(text) => {
                    onTextChange('jobCity', text);
                  }}
                />
              </div>
              <div className="spacer" />
              <div className="w-full">
                <AppDropDown2
                  label="Job State"
                  value={supplierJobInput.jobState || null}
                  items={[
                    ...Object.keys(allStates).map((state) => {
                      const key = state as keyof typeof allStates;
                      const stateKey = allStates[key];
                      return {
                        label: `${stateKey} - ${state}`,
                        value: stateKey,
                      };
                    }),
                  ]}
                  onSelectChange={(item) => {
                    if (item && item?.value !== null) {
                      const foundState = Object.values(getAllStates()).find(
                        (state) => state === item?.value
                      );
                      if (foundState) {
                        onTextChange('jobState', item.value);
                      }
                    }
                  }}
                />
              </div>
              <div className="spacer" />
              <div className="w-full">
                <AppInputField
                  id="zip"
                  label="Zip Code"
                  value={supplierJobInput.jobZipCode}
                  onTextChange={(text) => {
                    onTextChange('jobZipCode', text);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          {showErrorAbove && (
            <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
          )}
          <div className="flex flex-row justify-between self-end">
            <div className="flex flex-row self-end px-2">
              <AppButton
                text="Close"
                type="TERTIARY"
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  marginRight: '12px',
                  height: '40px',
                }}
                onClick={onClose}
              />
              <AppButton
                text="Request Job Info Sheet"
                type="SECONDARY"
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  marginRight: '12px',
                  height: '40px',
                  marginLeft: '24px',
                  width: '170px',
                }}
                onClick={handleRequestInfoSheet}
                isLoading={creating}
                isDisabled={creating}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestJobInfoForm;
