import React, { useMemo } from 'react';
import { SupplierJobUpdate } from '@dill/dill-shared';

type VerifiedJobDataPick = Pick<
  SupplierJobUpdate,
  | 'owner'
  | 'ownerAddress'
  | 'ownerCity'
  | 'ownerState'
  | 'ownerZipCode'
  | 'GC'
  | 'GCAddress'
  | 'GCCity'
  | 'GCState'
  | 'GCZipCode'
  | 'GCEmail'
  | 'lender'
  | 'lenderAddress'
  | 'lenderCity'
  | 'lenderState'
  | 'lenderZipCode'
  | 'apn'
  | 'constructionMonitorPermitId'
  | 'estimatedMaterialAmount'
  | 'jobType'
  | 'jobSector'
  | 'customerType'
  | 'jobFurnishings'
  | 'hasNoLender'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
>;

const VerifySupplierJob = ({
  verifyJobData,
  ownerVerificationEnable,
}: {
  verifyJobData: VerifiedJobDataPick;
  ownerVerificationEnable?: boolean;
}) => {
  const checkIfNotDataFound = useMemo(() => {
    const keysToCheck: Array<keyof VerifiedJobDataPick> = [
      'owner',
      'ownerAddress',
      'ownerCity',
      'ownerState',
      'ownerZipCode',
      'GC',
      'GCAddress',
      'GCCity',
      'GCState',
      'GCZipCode',
      'apn',
    ];

    return keysToCheck.every((key) => verifyJobData[key] === '');
  }, [verifyJobData]);

  return (
    <div>
      <div className="p-6">
        <div className="text-xs my-2 font-semibold">For the job address you entered:</div>
        <div className="my-2 font-semibold PRIMARY_500-CLR">{`${verifyJobData.jobAddress ?? ''}, ${
          verifyJobData.jobCity ?? ''
        }, ${verifyJobData.jobState ?? ''} ${verifyJobData.jobZipCode ?? ''}`}</div>
        {!checkIfNotDataFound && (
          <div className="text-xs my-2 font-semibold">Dill automatically identified:</div>
        )}
        {checkIfNotDataFound && ownerVerificationEnable && (
          <div className="flex flex-col h-full  w-full border rounded-lg overflow-hidden p-3 items-center">
            <div className="text-sm my-2 font-semibold text-red-500">
              No matching property found
            </div>
          </div>
        )}
        {!checkIfNotDataFound && (
          <div className="flex flex-col w-full border rounded-lg p-2 max-h-[calc(90vh-260px)] overflow-y-auto">
            {verifyJobData.jobAddress && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Job Address
                </div>
                <div className="text-sm my-2 font-semibold">{`${verifyJobData.jobAddress ?? ''}, ${
                  verifyJobData.jobCity ?? ''
                }, ${verifyJobData.jobState ?? ''} ${verifyJobData.jobZipCode ?? ''}`}</div>
              </div>
            )}
            {verifyJobData.apn && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Property APN
                </div>
                <div className="text-sm my-2 font-semibold">{verifyJobData.apn}</div>
              </div>
            )}
            {verifyJobData.owner && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Owner Name
                </div>
                <div className="text-sm my-2 font-semibold">{verifyJobData.owner}</div>
              </div>
            )}
            {verifyJobData.ownerAddress && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Owner Address, City, State, Zip
                </div>
                <div className="text-sm my-2 font-semibold">{`${
                  verifyJobData.ownerAddress ?? ''
                }, ${verifyJobData.ownerCity ?? ''}, ${verifyJobData.ownerState ?? ''} ${
                  verifyJobData.ownerZipCode ?? ''
                }`}</div>
              </div>
            )}
            {verifyJobData.GC && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  GC Name
                </div>
                <div className="text-sm my-2 font-semibold">{verifyJobData.GC}</div>
              </div>
            )}
            {verifyJobData.GCAddress && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2 pr-2">
                  GC Address, City, State, Zip
                </div>
                <div className="text-sm my-2 font-semibold">{`${verifyJobData.GCAddress ?? ''}, ${
                  verifyJobData.GCCity ?? ''
                }, ${verifyJobData.GCState ?? ''} ${verifyJobData.GCZipCode ?? ''}`}</div>
              </div>
            )}
            {verifyJobData.jobType && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2 pr-2">
                  Job Type
                </div>
                <div className="text-sm my-2 font-semibold capitalize">{verifyJobData.jobType}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifySupplierJob;
