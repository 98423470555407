import React, { useState, useEffect, useRef } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { COLORS } from '../../../utils/colors';

interface Option {
  value: string;
  label: string;
  icon?: any;
}

interface Props {
  options: Option[];
  onSelect: (option: Option) => void;
  buttonTitle: string;
  icon?: any;
  contentWidth?: number;
  buttonType?: 'PRIMARY' | 'SECONDARY' | 'FLAT' | 'TERTIARY' | 'ERROR';
  buttonIconPosition?: 'LEFT' | 'RIGHT';
  buttonStyles?: any;
}

export const AppDropdownButton: React.FC<Props> = ({
  options,
  onSelect,
  icon,
  buttonTitle,
  contentWidth = 200,
  buttonType = 'PRIMARY',
  buttonIconPosition = 'RIGHT',
  buttonStyles = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    onSelect(option);
    toggleDropdown();
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <AppButton
        text={buttonTitle}
        onClick={toggleDropdown}
        buttonStyles={{ ...buttonStyles, height: '40px' }}
        icon={icon}
        iconPosition={buttonIconPosition}
        type={buttonType}
      />
      {isOpen && (
        <div
          style={{ width: contentWidth }}
          className={`absolute top-[50px] w-full right-0 border z-20 PRIMARY_50-BG py-2 rounded shadow-xl`}>
          {options.map((option, i) => (
            <div
              key={`${option.value}_${i}`}
              onClick={() => handleOptionClick(option)}
              className="flex hover:bg-blue-100 hover:text-[#3762FB] px-1 py-2 cursor-default items-center">
              <div className="mr-2">{option.icon && option.icon}</div>
              <div className="truncate text-xs">{option.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
