import { createSlice } from '@reduxjs/toolkit';
import {
  getQuickBooksAuthUriExtraReducer,
  disconnectQuickbooksExtraReducer,
  mainBuyerDownloadSupplierInvoiceStatementsExtraReducer,
  getGroupedSuppliersInvoicesExtraReducer,
  deleteSupplierInvoiceExtraReducer,
  sendMessagesInvoiceExtraReducer,
  supplierDownloadSupplierInvoiceStatementsExtraReducer,
} from '../reducers/invoicesReducers';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  InvoiceStatus,
  QuickbooksCreditMemo,
  QuickbooksInvoice,
  Supplier,
  SupplierForBuyer,
} from '@dill/dill-shared';

export interface InvoiceInitialState {
  loadingList: string[];
  isGetQuickBooksAuthUrlFailed: boolean;
  quickBooksAuthUri: string;
  error: string | null;
  invoices: QuickbooksInvoice[];
  suppliers: Supplier[];
  selectedSupplierForBuyer: SupplierForBuyer | null;
  isViewOpenInvoices: boolean;
  isDisconnectQuickBooksFailed: boolean;
  selectedInvoices: { [x: string]: QuickbooksInvoice[] };
  groupedSuppliersInvoices: SupplierForBuyer[];
  selectedCreditMemos: { [x: string]: QuickbooksCreditMemo[] };
  // selectedInvoices: QuickbooksInvoice[];
}

const initialState: InvoiceInitialState = {
  loadingList: [],
  isGetQuickBooksAuthUrlFailed: false,
  isDisconnectQuickBooksFailed: false,
  quickBooksAuthUri: '',
  error: null,
  invoices: [],
  suppliers: [],
  selectedSupplierForBuyer: null,
  isViewOpenInvoices: false,
  selectedInvoices: {},
  selectedCreditMemos: {},
  groupedSuppliersInvoices: [],
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    resetInvoicesRootState: () => initialState,
    clearQuickBooksAuthUri: (state) => {
      state.quickBooksAuthUri = '';
      state.isGetQuickBooksAuthUrlFailed = false;
    },
    updateSelectedSupplierForBuyer: (state, action: PayloadAction<SupplierForBuyer | null>) => {
      state.selectedSupplierForBuyer = action.payload;
    },
    changeIsViewOpenInvoice: (state, action: PayloadAction<boolean>) => {
      state.isViewOpenInvoices = action.payload;
    },

    selectInvoice: (
      state,
      action: PayloadAction<{ invoices: QuickbooksInvoice[]; tabId: InvoiceStatus }>
    ) => {
      let filteredInvoices: any = [];
      if (state.selectedInvoices[action.payload.tabId]) {
        const ii = [...state.selectedInvoices[action.payload.tabId].map((iu) => ({ ...iu }))];
        filteredInvoices = ii.filter(
          (invoice) => !action.payload.invoices.map((yy) => yy.id).includes(invoice.id)
        );
      }

      // if (filteredInvoices.length === 0) {
      state.selectedInvoices = {
        ...state.selectedInvoices,
        [action.payload.tabId]: [...filteredInvoices, ...action.payload.invoices],
      };
      // }
    },
    selectCreditMemo: (
      state,
      action: PayloadAction<{ creditMemos: QuickbooksCreditMemo[]; tabId: InvoiceStatus }>
    ) => {
      let filteredCreditMemos: any = [];
      if (state.selectedCreditMemos[action.payload.tabId]) {
        const stateCreditMemos = [
          ...state.selectedCreditMemos[action.payload.tabId].map((stateCreditMemo) => ({
            ...stateCreditMemo,
          })),
        ];
        filteredCreditMemos = stateCreditMemos.filter(
          (creditMemo) => !action.payload.creditMemos.map((yy) => yy.id).includes(creditMemo.id)
        );
      }
      state.selectedCreditMemos = {
        ...state.selectedCreditMemos,
        [action.payload.tabId]: [...filteredCreditMemos, ...action.payload.creditMemos],
      };
    },
    unSelectInvoice: (
      state,
      action: PayloadAction<{ invoices: QuickbooksInvoice[]; tabId: InvoiceStatus }>
    ) => {
      let filteredInvoices: any = [];
      if (state.selectedInvoices[action.payload.tabId]) {
        filteredInvoices = state.selectedInvoices[action.payload.tabId].filter(
          (invoice) => !action.payload.invoices.map((yy) => yy.id).includes(invoice.id)
        );
      }
      state.selectedInvoices = {
        ...state.selectedInvoices,
        [action.payload.tabId]: [...filteredInvoices],
      };
    },
    unSelectCreditMemo: (
      state,
      action: PayloadAction<{ creditMemos: QuickbooksCreditMemo[]; tabId: InvoiceStatus }>
    ) => {
      let filteredCreditMemos: any = [];
      if (state.selectedCreditMemos[action.payload.tabId]) {
        filteredCreditMemos = state.selectedCreditMemos[action.payload.tabId].filter(
          (creditMemo) => !action.payload.creditMemos.map((yy) => yy.id).includes(creditMemo.id)
        );
      }
      state.selectedCreditMemos = {
        ...state.selectedCreditMemos,
        [action.payload.tabId]: [...filteredCreditMemos],
      };
    },
    resetSelectInvoice: (state) => {
      state.selectedInvoices = {};
      state.selectedCreditMemos = {};
    },
    resetSelectCreditMemo: (state) => {
      state.selectedCreditMemos = {};
    },
  },
  extraReducers(builder) {
    getQuickBooksAuthUriExtraReducer(builder);
    disconnectQuickbooksExtraReducer(builder);
    mainBuyerDownloadSupplierInvoiceStatementsExtraReducer(builder);
    supplierDownloadSupplierInvoiceStatementsExtraReducer(builder);
    getGroupedSuppliersInvoicesExtraReducer(builder);
    deleteSupplierInvoiceExtraReducer(builder);
    sendMessagesInvoiceExtraReducer(builder);
  },
});

export const {
  resetInvoicesRootState,
  selectInvoice,
  selectCreditMemo,
  unSelectInvoice,
  unSelectCreditMemo,
  clearQuickBooksAuthUri,
  updateSelectedSupplierForBuyer,
  changeIsViewOpenInvoice,
  resetSelectInvoice,
  resetSelectCreditMemo,
} = invoiceSlice.actions;
