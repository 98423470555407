import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createPreliminaryNotice,
  getSupplierPreliminaryNotices,
  getPreliminaryNotice,
  updatePreliminaryNotice,
  deletePreliminaryNotice,
  manualCreatePreliminaryNotice,
} from '../services/preliminaryNoticesService';
import { PreliminaryNoticeInitialState } from '../globalSlices/preliminaryNoticesSlice';

export const createPreliminaryNoticeExtraReducer = (
  builder: ActionReducerMapBuilder<PreliminaryNoticeInitialState>
) => {
  const reducerId = 'createPreliminaryNotice';
  builder.addCase(createPreliminaryNotice.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createPreliminaryNotice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createPreliminaryNotice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierPreliminaryNoticesExtraReducer = (
  builder: ActionReducerMapBuilder<PreliminaryNoticeInitialState>
) => {
  const reducerId = 'getSupplierPreliminaryNotices';
  builder.addCase(getSupplierPreliminaryNotices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(
    getSupplierPreliminaryNotices.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload?.success && action.payload.data) {
        state.preliminaryNotices = action.payload.data;
      }
    }
  );
  builder.addCase(getSupplierPreliminaryNotices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierPreliminaryNotices: action.payload }]),
      ];
    }
  });
};

export const getPreliminaryNoticeExtraReducer = (
  builder: ActionReducerMapBuilder<PreliminaryNoticeInitialState>
) => {
  const reducerId = 'getPreliminaryNotice';
  builder.addCase(getPreliminaryNotice.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
    state.selectedPreliminaryNotice = null;
  });
  builder.addCase(
    getPreliminaryNotice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedPreliminaryNotice = action.payload.data;
      }
    }
  );
  builder.addCase(getPreliminaryNotice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getPreliminaryNotice: action.payload }]),
      ];
    }
  });
};

export const updatePreliminaryNoticeExtraReducer = (
  builder: ActionReducerMapBuilder<PreliminaryNoticeInitialState>
) => {
  const reducerId = 'updatePreliminaryNotice';
  builder.addCase(updatePreliminaryNotice.pending, (state, action) => {
    if (!action.meta.arg?.isHideLoadingIndicator) {
      state.loadingList = [...new Set([...state.loadingList, reducerId])];
    }
  });
  builder.addCase(
    updatePreliminaryNotice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updatePreliminaryNotice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const deletePreliminaryNoticeExtraReducer = (
  builder: ActionReducerMapBuilder<PreliminaryNoticeInitialState>
) => {
  const reducerId = 'deletePreliminaryNotice';
  builder.addCase(deletePreliminaryNotice.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    deletePreliminaryNotice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(deletePreliminaryNotice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const manualCreatePreliminaryNoticeExtraReducer = (
  builder: ActionReducerMapBuilder<PreliminaryNoticeInitialState>
) => {
  const reducerId = 'manualCreatePreliminaryNotice';
  builder.addCase(manualCreatePreliminaryNotice.pending, (state, action) => {
    if (!action.meta.arg?.isHideLoadingIndicator) {
      state.loadingList = [...new Set([...state.loadingList, reducerId])];
    }
  });
  builder.addCase(
    manualCreatePreliminaryNotice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(manualCreatePreliminaryNotice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
