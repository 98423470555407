import React, { useEffect, useState } from 'react';
import { Plus, X } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { useAppDispatch } from '../../../redux/store';
import { generateRequestJobInformationLink } from '../../../redux/services/requestJobInformationService';
import { openDillToast } from '../../../utils/helpers';
import { supplierJobInfoSheetRequest } from '../../../redux/services/supplierJobsService';
import { Buyer, DocumentsSummary, SupplierJobApiResponse } from '@dill/dill-shared/dist/src';
import { AppInputField } from '../AppInputField/AppInputField';
import moment from 'moment';
import { getUserCustomer } from '../../../redux/services/suppliersService';

const RequestInfoSheetForm = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data: {
    supplierId: string;
    domainId: string;
    jobId: string;
    buyerId: string;
    buyerName: string;
    name: string;
    jobNumber: string;
    jobAddress: string;
    jobCity: string;
    jobState: string;
    jobZipCode: string;
    buyer: Buyer | null | undefined;
    selectedSupplierJob?: SupplierJobApiResponse | null;
  };
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [copied, setCopiedId] = useState<string>();
  const [copiedText, setCopiedText] = useState<string>();
  const [linkUrl, setLinkUrl] = useState<string>();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isUpdatingLinkCopyStatus, setIsUpdatingLinkCopyStatus] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const [jobInfoSheetEmails, setJobInfoSheetEmails] = useState<string[]>([]);
  const [emailTextInputValue, setEmailTextInputValue] = useState('');
  const [emailTextInputErrorMessage, setEmailTextInputErrorMessage] = useState('');
  const [customerDetails, setCustomerDetails] = useState<{
    buyer: Buyer;
    usersEmailsDetails: {
      email: string;
      isInvitedUser: boolean;
      isStatementsEmailOptOut: boolean;
      isPaymentRemindersEmailOptOut: boolean;
    }[];
    documentsSummary: DocumentsSummary;
  } | null>(null);

  const getDetailedCustomer = async (buyerId: string) => {
    const result = await dispatch(getUserCustomer({ buyerId }));
    if (result.type === 'supplier/getUserCustomer/fulfilled') {
      const payload = result.payload as any;
      if (payload.data) {
        setCustomerDetails(payload.data);
      }
    }
  };

  useEffect(() => {
    if (
      data?.selectedSupplierJob &&
      data?.selectedSupplierJob?.jobInfoSheetEmails &&
      data?.selectedSupplierJob?.jobInfoSheetEmails.length > 0
    ) {
      setJobInfoSheetEmails([...data?.selectedSupplierJob?.jobInfoSheetEmails]);
    } else if (data.buyer) {
      getDetailedCustomer(data.buyer.id);
    }

    return () => {};
  }, [data?.buyer, data?.selectedSupplierJob]);

  useEffect(() => {
    if (customerDetails) {
      setJobInfoSheetEmails([...customerDetails.usersEmailsDetails.map((item) => item.email)]);
    }

    return () => {};
  }, [customerDetails]);

  const generateLink = async () => {
    setLoading(true);
    setIsLinkCopied(false);
    const response = await dispatch(generateRequestJobInformationLink(data));
    if (
      response.type === 'requestJobInformation/generateRequestJobInformationLink/fulfilled' &&
      response?.payload
    ) {
      const payload = response.payload as any;
      const url = payload?.data?.url ?? '';
      navigator.clipboard.writeText(url);
      setLinkUrl(url);
      // openDillToast({ type: 'SUCCESS', message: 'Link copied' });
      setLoading(false);
    } else {
      openDillToast({ type: 'ERROR', message: 'Error generating link' });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!linkUrl) {
      generateLink();
    }

    return () => {};
  }, []);

  const markLinkAsSent = async () => {
    if (linkUrl) {
      setIsUpdatingLinkCopyStatus(true);
      await dispatch(
        supplierJobInfoSheetRequest({
          supplierJobId: data.jobId,
          jobInfoSheetLink: linkUrl,
        })
      );
      setIsUpdatingLinkCopyStatus(false);
    }
  };
  const handleSendEmail = async () => {
    if (linkUrl) {
      setIsSendingEmail(true);
      const resp = await dispatch(
        supplierJobInfoSheetRequest({
          supplierJobId: data.jobId,
          jobInfoSheetEmails,
          jobInfoSheetLink: linkUrl,
        })
      );
      if (resp.type === 'supplierJobs/supplierJobInfoSheetRequest/fulfilled') {
        openDillToast({ message: 'Email sent', type: 'SUCCESS' });
      } else {
        let errorMessage = '';
        if (resp.payload) {
          errorMessage = resp.payload;
        }
        openDillToast({ message: errorMessage, type: 'ERROR' });
      }
      setIsSendingEmail(false);
    }
  };

  return (
    <div
      className={`flex min-h-80 w-[50vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col `}>
      <div className="flex justify-between w-full items-center px-5 border-b py-3">
        <h3 className="font-bold text-lg">{`Request Job Information Sheet`}</h3>
        <AppButton
          type="FLAT"
          buttonStyles={{ padding: '0px', margin: '5px 2px' }}
          icon={<X color={COLORS.GREY_300} size={30} />}
          onClick={onClose}
        />
      </div>
      <div className="px-6 py-3">
        <div className="text-xs font-medium my-1">
          Request job information from your customer directly via the Job Information Sheet. After
          your customer submits, it will automatically be added for your review.{' '}
        </div>
        <div className="text-xs font-medium my-1">
          Please choose one of the two methods: copy link for your own email, or have Dill send the
          email.
        </div>
        {linkUrl && (
          <div className="flex w-full flex-col mt-2">
            <div className="w-full opacity-65 hover:opacity-100  py-3">
              <p className="text-xs font-bold mb-0.5">Copy link for your own email</p>
              <div className="flex flex-col  border   text-sm py-3 px-4 w-full rounded-md mx-3 bg-blue-50 ">
                <div className="flex w-full items-center font-mono justify-between">
                  <div className="flex ">
                    <span></span>
                    <span className="overflow-hidden text-ellipsis text-xs w-[500px] text-nowrap">{`${linkUrl}`}</span>
                  </div>
                  <button
                    className="hover:text-blue-500 cursor-pointer w-fit"
                    onClick={async () => {
                      await navigator.clipboard.writeText(linkUrl);
                      setIsLinkCopied(true);
                      setCopiedId('read-text');
                    }}>
                    {copied === 'read-text' ? 'Copied' : 'Copy'}
                  </button>
                </div>

                {isLinkCopied && (
                  <div className="flex flex-col mt-2 w-full">
                    <p className="text-xs text-blue-500 mb-1 ">
                      Link has been copied. You can paste it into your own email and afterwards,
                      mark this job info sheet as "Sent."
                    </p>
                    <div className="self-end">
                      <AppButton
                        text="Mark As Sent"
                        type="PRIMARY"
                        onClick={markLinkAsSent}
                        isLoading={isUpdatingLinkCopyStatus}
                        buttonStyles={{
                          height: '40px',
                          width: '100px',
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <hr className="h-px mt-1 mb-1 bg-gray-200 border-0 dark:bg-gray-700" />
            <p className="text-center mb-1 text-xs font-bold">Or</p>
            <div className="opacity-65 hover:opacity-100">
              <p className="text-xs font-bold mb-0.5">Have Dill send the email.</p>
              <div className="flex flex-col my-1 text-gray-800   text-sm py-3 px-4 w-full rounded-md m-3 bg-blue-50">
                <div className="flex flex-wrap">
                  {jobInfoSheetEmails.map((email, idex) => {
                    return (
                      <div
                        key={`${idex}o`}
                        className="flex items-center text-xs rounded-lg border border-blue-500  mx-1 my-1 p-1">
                        <p className="text-black m-0 p-0 mr-1">{email}</p>
                        <X
                          className="cursor-pointer"
                          onClick={() => {
                            setJobInfoSheetEmails([
                              ...jobInfoSheetEmails.filter((item, i) => i !== idex),
                            ]);
                          }}
                        />
                      </div>
                    );
                  })}
                  <div className="flex flex-nowrap w-full mb-2 items-end justify-between">
                    <div className="flex flex-nowrap w-2/3">
                      <AppInputField
                        value={emailTextInputValue}
                        inputType="email"
                        placeholder="Add email"
                        errorText={emailTextInputErrorMessage}
                        onTextChange={(text) => {
                          setEmailTextInputValue(text);
                        }}
                        onBlur={() => {
                          console.log('bluu');
                        }}
                      />
                      <div className="ml-1 flex items-end">
                        <AppButton
                          type="SECONDARY"
                          text=""
                          icon={<Plus />}
                          buttonStyles={{ padding: '13px' }}
                          onClick={() => {
                            const emailRegex = /[\w.+-]+@[\w-]+\.[a-z]{1,}/gi;
                            const isMatch = emailRegex.test(emailTextInputValue);
                            if (isMatch) {
                              setEmailTextInputErrorMessage('');
                              setJobInfoSheetEmails([
                                ...new Set([
                                  ...jobInfoSheetEmails,
                                  emailTextInputValue.toLowerCase(),
                                ]),
                              ]);
                              setEmailTextInputValue('');
                            } else {
                              setEmailTextInputErrorMessage('Invalid email');
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="  justify-self-end">
                      <AppButton
                        type="PRIMARY"
                        text="Send Email"
                        isLoading={isSendingEmail}
                        onClick={() => {
                          handleSendEmail();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data?.selectedSupplierJob &&
          data?.selectedSupplierJob?.jobInfoRequestHistory &&
          data?.selectedSupplierJob?.jobInfoRequestHistory.length > 0 && (
            <div className="">
              <p className="mb-2 mt-3 text-sm font-bold">Request History</p>
              <div className="flex flex-col overflow-y-scroll max-h-28">
                <div className="flex  sticky top-0 bg-white">
                  {['Emails', 'Date Requested'].map((column, index) => {
                    return (
                      <div
                        key={`${index}oo`}
                        className={`flex text-xs font-bold ${index === 0 ? 'w-9/12' : '3/12'}`}>
                        {column}
                      </div>
                    );
                  })}
                </div>
                {[...data?.selectedSupplierJob?.jobInfoRequestHistory]
                  .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                  .map((jobInfoRequestHistory, index) => {
                    return (
                      <div key={`${index}oo`} className="flex justify-between border-b pb-1">
                        <p className="text-xs w-9/12">
                          {jobInfoRequestHistory.emails && jobInfoRequestHistory.emails.length > 0
                            ? jobInfoRequestHistory.emails?.join(', ')
                            : '...'}
                        </p>
                        <p className="text-xs w-3/12">
                          {jobInfoRequestHistory?.date
                            ? moment(jobInfoRequestHistory?.date).format('MM-DD-YYYY HH:mm:ss')
                            : ''}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
      </div>

      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
      <div className="flex flex-row self-end px-6 gap-3 mb-2">
        {!linkUrl && (
          <AppButton
            text="Generate Link"
            type="SECONDARY"
            onClick={generateLink}
            isLoading={loading}
            isDisabled={loading || linkUrl === ''}
            buttonStyles={{
              height: '40px',
              width: '100px',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RequestInfoSheetForm;
