import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Supplier, MainBuyer, InvoiceStatus, SupplierForBuyer } from '@dill/dill-shared';
import {
  downloadMultiInvoicePdfZipExtraReducer,
  getBasicSupplierExtraReducer,
  getGroupedBuyersSuppliersExtraReducer,
  getInvoicePdfExtraReducer,
  getMainBuyerExtraReducer,
  addSendgridApiKeyToMainBuyerExtraReducer,
  downloadInvoicePdfReceiptExtraReducer,
  getDocumentPdfExtraReducer,
} from '../reducers/buyersReducers';

export interface BuyersInitialState {
  loadingList: string[];
  error: string | null;
  selectedBuyersGroupPosition: number;
  buyerSupplierInvoicesDataWeeksLimit: number;
  selectedBuyerSuppliers: SupplierForBuyer[];
  basicSupplier: Supplier | null;
  invoiceBase64: string | null;
  previewPdf: string | null;
  isDirectToInvoices: boolean;
  invoiceIdsForDownload: { [x: string]: string[] };
  mainBuyer: MainBuyer | null;
  selectedMainBuyer: MainBuyer | null;
}

const initialState: BuyersInitialState = {
  error: null,
  selectedBuyersGroupPosition: 0,
  buyerSupplierInvoicesDataWeeksLimit: 2,
  loadingList: [],
  basicSupplier: null,
  invoiceBase64: null,
  previewPdf: null,
  selectedBuyerSuppliers: [],
  invoiceIdsForDownload: {},
  isDirectToInvoices: true,
  mainBuyer: null,
  selectedMainBuyer: null,
};

export const buyersSlice = createSlice({
  name: 'buyers',
  initialState,
  reducers: {
    resetBuyersRootState: () => initialState,
    updateBuyerSupplierInvoicesDataWeeksLimit: (state, action: PayloadAction<number>) => {
      state.buyerSupplierInvoicesDataWeeksLimit = action.payload;
    },
    resetInvoiceBase64: (state) => {
      state.invoiceBase64 = null;
    },

    selectInvoiceIdsForDownload: (
      state,
      action: PayloadAction<{ invoiceIds: string[]; tabId: InvoiceStatus }>
    ) => {
      state.invoiceIdsForDownload = {
        ...state.invoiceIdsForDownload,
        [action.payload.tabId]: [
          ...new Set([
            ...(state.invoiceIdsForDownload[action.payload.tabId] || []),
            ...action.payload.invoiceIds,
          ]),
        ],
      };
    },
    unSelectInvoiceIdsForDownload: (
      state,
      action: PayloadAction<{ invoiceIds: string[]; tabId: InvoiceStatus }>
    ) => {
      state.invoiceIdsForDownload = {
        ...state.invoiceIdsForDownload,
        [action.payload.tabId]: [
          ...(state.invoiceIdsForDownload[action.payload.tabId] || []).filter(
            (invoiceId) => !action.payload.invoiceIds.includes(invoiceId)
          ),
        ],
      };
    },
    resetInvoiceIdsForDownload: (state) => {
      state.invoiceIdsForDownload = {};
    },
    disableIsDirectToInvoices: (state) => {
      state.isDirectToInvoices = false;
    },
    updateSelectedMainBuyer: (state, action: PayloadAction<MainBuyer | null>) => {
      state.selectedMainBuyer = action.payload;
    },
  },
  extraReducers(builder) {
    getGroupedBuyersSuppliersExtraReducer(builder);
    getBasicSupplierExtraReducer(builder);
    getInvoicePdfExtraReducer(builder);
    getDocumentPdfExtraReducer(builder);
    downloadMultiInvoicePdfZipExtraReducer(builder);
    getMainBuyerExtraReducer(builder);
    addSendgridApiKeyToMainBuyerExtraReducer(builder);
    downloadInvoicePdfReceiptExtraReducer(builder);
  },
});

export const {
  resetBuyersRootState,
  selectInvoiceIdsForDownload,
  unSelectInvoiceIdsForDownload,
  resetInvoiceIdsForDownload,
  updateSelectedMainBuyer,
  resetInvoiceBase64,
  disableIsDirectToInvoices,
  updateBuyerSupplierInvoicesDataWeeksLimit,
} = buyersSlice.actions;
