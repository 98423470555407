import React, { useMemo, useState, useEffect } from 'react';
import { Plus, Download, Search } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { releaseStatusOptions } from '../../../../../utils/constants';
import BuyerLienReleasesTable from '../BuyerLienReleasesTable/BuyerLienReleasesTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../../redux/store';
import {
  LienApiResponse,
  LienStatus,
  UserLinkApiResponse,
  GroupedCSVRelease,
} from '@dill/dill-shared';
import { downloadPDFsAsZip } from '../../../../../utils/helpers';
import { AppDropDown2 } from '../../../../general/AppDropDown2/AppDropDown2';
import { twMerge } from 'tailwind-merge';
import { CheckCircle, Timer, Warning, ClockClockwise, CaretLeft, CaretRight } from 'phosphor-react';
import { AppErrorPage } from '../../../../general/AppErrorPage/AppErrorPage';
import AppGroupedDropdown from '../../../../general/AppGroupedDropdown/AppGroupedDropdown';
import moment from 'moment';
import AppReleaseStatusPill from '../../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import AppReleaseOptionsDropdown from '../../../../general/AppReleaseOptionsDropdown/AppReleaseOptionsDropdown';
import { AppForwardReleaseModal } from '../../../../general/AppForwardReleaseModal/AppForwardReleaseModal';
import { getJobs } from '../../../../../redux/services/jobsService';
import { forwardRelease, createGroupedRelease } from '../../../../../redux/services/liensService';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { getSubSuppliers } from '../../../../../redux/services/subSuppliersService';
import { getLienTemplates } from '../../../../../redux/services/leinTemplatesService';
import { AppPdfPreviewModal } from '../../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import { openMessageModal } from '../../../../../redux/globalSlices/genericSlice';
import { AppUploadCSVInvoicesModal } from '../../../../general/AppUploadCSVInvoicesModal/AppUploadCSVInvoicesModal';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const dropdown = [
  {
    name: 'Amount',
    type: 'value-range',
    value: 'amount',
    item: [
      { label: 'Min', value: 'min' },
      { label: 'Max', value: 'max' },
    ],
  },
  // {
  //   name: 'Request Date',
  //   type: 'date-range',
  //   value: 'requestDate',
  //   item: [
  //     { label: 'Start', value: 'start' },
  //     { label: 'Finish', value: 'finish' },
  //   ],
  // },
  {
    name: 'Through Date',
    type: 'date-range',
    value: 'throughDate',
    item: [
      { label: 'Start', value: 'start' },
      { label: 'Finish', value: 'finish' },
    ],
  },
  {
    name: 'Release Type',
    type: 'multi-select',
    value: 'release-type',
    item: [
      { label: 'Progress Conditional', value: 'PROGRESS_CONDITIONAL' },
      { label: 'Progress Unconditional', value: 'PROGRESS_UNCONDITIONAL' },
      { label: 'Final Conditional', value: 'FINAL_CONDITIONAL' },
      { label: 'Final Unconditional', value: 'FINAL_UNCONDITIONAL' },
      { label: 'Progress Dual', value: 'PROGRESS_DUAL' },
      { label: 'Final Dual', value: 'FINAL_DUAL' },
    ],
  },
  // {
  //   name: 'Type',
  //   type: 'multi-select',
  //   value: 'type',
  //   item: [
  //     { label: 'Progress Conditional', value: 'PROGRESS_CONDITIONAL' },
  //     { label: 'Progress Unconditional', value: 'PROGRESS_UNCONDITIONAL' },
  //     { label: 'Final Conditional', value: 'FINAL_CONDITIONAL' },
  //     { label: 'Final Unconditional', value: 'FINAL_UNCONDITIONAL' },
  //     { label: 'Progress Dual', value: 'PROGRESS_DUAL' },
  //     { label: 'Final Dual', value: 'FINAL_DUAL' },
  //   ],
  // },
  // {
  //   name: 'Status',
  //   type: 'drop-down',
  //   value: 'status',
  //   item: [
  //     { label: 'Requested', value: 'Requested' },
  //     { label: 'Declined', value: 'Declined' },
  //     { label: 'Signed', value: 'Signed' },
  //     { label: 'Scheduled', value: 'Scheduled' },
  //   ],
  // },
  // {
  //   name: 'Search',
  //   type: 'input',
  //   value: 'search',
  //   item: [{ label: 'email', value: 'Email', placeholder: 'Search' }],
  // },
];
const BuyerLienReleases = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [searchText, setSearchText] = useState('');
  const [statusFilterOption, setStatusFilterOption] = useState<string[] | null>(null);
  const { buyerLienReleases, selectedBuyerLiens, loadingErrorList } = useAppSelector(
    (state) => state.liens
  );
  const { jobs } = useAppSelector((state) => state.jobs);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const [filteredBuyerReleases, setFilteredBuyerReleases] = useState<LienApiResponse[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [openForwardingModal, setOpenForwardingModal] = useState<boolean>(false);
  const [openConfirmUpload, setOpenConfirmUpload] = useState<boolean>(false);
  const [uploadedReleases, setUploadedReleases] = useState<GroupedCSVRelease[]>([]);
  const [forwardEmail, setForwardEmail] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openUploadCSVModal, setOpenUploadCSVModal] = useState<boolean>(false);
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [releasePreviewDetails, setReleasePreviewDetails] = useState(
    {} as {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      dueDate: string | Date;
      sentToGCTime?: Date | null;
      sentToGCEmails?: string[] | null;
    }
  );
  const [uploadingReleaseSuccess, setUploadingReleaseSuccess] = useState(false);
  const [uploadingReleaseFailed, setUploadingReleaseFailed] = useState(false);

  const [selectedMainBuyerId] = useParamMainBuyerId();

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getLienTemplates({ mainBuyerId: selectedMainBuyerId }));
    }
    return () => {};
  }, [selectedMainBuyerId]);

  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) =>
        obj.hasOwnProperty('getLienBuyerReleases')
      );
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getLienBuyerReleases;
      }
    }
    return null;
  }, [loadingErrorList]);

  const searchedReleases: LienApiResponse[] = useMemo(() => {
    let filteredBuyerLienReleases: LienApiResponse[] = [];
    if (buyerLienReleases && searchText) {
      const searched = buyerLienReleases.filter((buyerLienRelease) => {
        const recipientUser =
          buyerLienRelease.recipient?.subUser || buyerLienRelease.recipient?.user;
        if (
          (buyerLienRelease.job &&
            buyerLienRelease.job.name
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (buyerLienRelease.job &&
            buyerLienRelease.job.number
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (buyerLienRelease.job &&
            buyerLienRelease.job.customerNumber &&
            buyerLienRelease.job.customerNumber
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (buyerLienRelease.job &&
            buyerLienRelease.job.accountantEmails &&
            buyerLienRelease.job.accountantEmails.length > 0 &&
            buyerLienRelease.job.accountantEmails
              .join(', ')
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (buyerLienRelease.subSupplier &&
            buyerLienRelease.subSupplier.name
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          `${recipientUser?.firstName ? recipientUser?.firstName.trim() : ''} ${
            recipientUser?.lastName ? recipientUser?.lastName.trim() : ''
          }`
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase().trim())
        ) {
          return true;
        }
        return false;
      });
      filteredBuyerLienReleases = searched;
    } else {
      filteredBuyerLienReleases = buyerLienReleases;
    }

    if (buyerLienReleases && statusFilterOption) {
      filteredBuyerLienReleases = filteredBuyerLienReleases.filter((buyerLienRelease) => {
        if (statusFilterOption.includes(buyerLienRelease.status)) {
          return true;
        }
        return false;
      });
    }

    return filteredBuyerLienReleases;
  }, [buyerLienReleases, searchText, statusFilterOption, selectedMainBuyerId]);

  const handleDownloadAll = () => {
    const downloadZipFiles = [] as { url: string; name: string }[];
    selectedBuyerLiens.forEach((release) => {
      const createdAtTimeStamp = release?.createdAt
        ? new Date(release.createdAt).getTime()
        : new Date().getTime();
      if (release.signedPdf) {
        downloadZipFiles.push({
          url: release.signedPdf.url ?? '',
          name: `${release.job?.name ?? ''}-${
            release.subSupplier?.name ?? ''
          }-${createdAtTimeStamp}`,
        });
      } else {
        downloadZipFiles.push({
          url: release.previewPdf?.url ?? '',
          name: `${release.job?.name ?? ''}-${
            release.subSupplier?.name ?? ''
          }-${createdAtTimeStamp}`,
        });
      }
    });
    downloadPDFsAsZip(downloadZipFiles, 'releases');
  };
  const filteredReleases: LienApiResponse[] = useMemo(() => {
    if (selectedFilters.length === 0) {
      return searchedReleases;
    }

    return searchedReleases.filter((release) => {
      for (const filter of selectedFilters) {
        if (filter.name === 'amount') {
          const paymentAmount = release.fieldsData?.find(
            (item: any) => item.id === 'paymentAmount'
          );
          if (
            (filter.value?.min &&
              filter.value?.max &&
              (Number(paymentAmount?.value) < Number(filter.value.min) ||
                Number(paymentAmount?.value) > Number(filter.value.max))) ||
            !paymentAmount
          ) {
            return false;
          }
        } else if (filter.name === 'requestDate') {
          if (filter.value?.start && filter.value?.finish) {
            const createdAt = moment(release.createdAt).format('MM-DD-YYYY');
            const start = moment(filter.value?.start).format('MM-DD-YYYY');
            const finish = moment(filter.value?.finish).format('MM-DD-YYYY');
            if (moment(start).isAfter(createdAt) || moment(finish).isBefore(createdAt)) {
              return false;
            }
          }
        } else if (filter.name === 'throughDate') {
          if (filter.value?.start && filter.value?.finish) {
            const tdate = release?.fieldsData?.find((elem) => elem.id === 'throughDate');
            const throughDate =
              tdate && tdate?.value ? moment(tdate?.value).format('MM-DD-YYYY') : null;
            const start = moment(filter.value?.start).format('MM-DD-YYYY');
            const finish = moment(filter.value?.finish).format('MM-DD-YYYY');
            if (
              moment(start).isAfter(throughDate) ||
              moment(finish).isBefore(throughDate) ||
              !throughDate
            ) {
              return false;
            }
          }
        } else if (filter.name === 'release-type') {
          if (!filter?.value?.includes(release?.type)) {
            return false;
          }
        } else if (filter.name === 'job') {
          if (!filter?.value?.includes(release?.job?.id)) {
            return false;
          }
        } else if (filter.name === 'supplier') {
          if (!filter?.value?.includes(release?.recipient?.subUser?.id)) {
            return false;
          }
        } else if (filter.name === 'Status') {
          if (filter.value !== release.status) {
            return false;
          }
        }
      }
      return true;
    });
  }, [searchedReleases, selectedFilters, selectedMainBuyerId]);

  const handleFilterSelect = (selectFilter: any[]) => {
    setSelectedFilters(selectFilter);
  };

  const handleDropdownOption = (value: string) => {
    switch (value) {
      case 'forward':
        setOpenForwardingModal(true);
        break;
      case 'release-manual-add':
        navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview/manualAddLienRelease`);
        break;
      case 'import-csv':
        setOpenUploadCSVModal(true);
        setUploadedReleases([]);
        break;
      default:
        break;
    }
  };

  const handleForwardEmail = async ({
    notes,
    subject,
    selectedReleases,
    emails,
    cc,
  }: {
    notes: string;
    subject: string;
    emails: string[];
    cc: string;
    selectedReleases: LienApiResponse[];
  }) => {
    setForwardEmail(emails);
    const attachedFiles = [];
    const releaseIds = [];
    for (let index = 0; index < selectedReleases.length; index++) {
      const release = selectedReleases[index];
      const createdAtTimeStamp = release?.createdAt
        ? new Date(release.createdAt).getTime()
        : new Date().getTime();
      attachedFiles.push({
        url: release?.signedPdf?.url ?? '',
        name: `${release.job?.name ?? ''}-${release.buyer?.name ?? ''}-${
          release.subSupplier?.name ?? ''
        }-${createdAtTimeStamp}`,
      });
      releaseIds.push(release.id);
    }
    const data = {
      mailTo: emails,
      notes,
      cc,
      subject,
      attachements: attachedFiles,
      releaseIds,
    };
    const forwardResult = await dispatch(
      forwardRelease({ ...data, mainBuyerId: selectedMainBuyerId || '' })
    );

    if (forwardResult.type === 'liens/forwardRelease/fulfilled') {
      dispatch(
        openMessageModal({
          title: 'Successfully forwarded releases to GC',
          subTitle: `Your release email has been sent to your GC’s email ${forwardEmail.join(
            ', '
          )}`,
          buttonText: 'View All Lien Waivers',
          modalType: 'SUCCESS',
          onClose: () => {
            setRefresh(!refresh);
            setOpenForwardingModal(false);
          },
        })
      );
    } else {
      let errorMessage = '';
      if (forwardResult.type === 'liens/forwardRelease/rejected') {
        errorMessage = forwardResult.payload as any;
      }

      dispatch(
        openMessageModal({
          title: 'Forwarding releases to GC failed!',
          subTitle: `Contact customer support. ${errorMessage}`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: true,
          secondButtonText: 'Retry',
          onSecondButtonClick: () => {},
          onClose: () => {
            setOpenForwardingModal(false);
          },
        })
      );
    }
  };
  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers.reduce(
        (
          prev: {
            subSupplierName: string;
            subSupplierId: string;
            notes: string;
            user: UserLinkApiResponse;
          }[],
          curr
        ) => {
          return [
            ...prev,
            ...curr.users
              .filter((userLink) => {
                if (
                  userLink.subUser &&
                  userLink.subUser.roles &&
                  (userLink.subUser.roles.includes('REVIEWER') ||
                    userLink.subUser.roles.length == 0)
                ) {
                  return true;
                }
                return !userLink?.subUser?.roles;
              })
              .map((userLink) => {
                return {
                  subSupplierId: curr.id,
                  subSupplierName: curr.name,
                  notes: curr.notes ?? '',
                  user: userLink,
                };
              }),
          ];
        },
        []
      );
    }
    return [];
  }, [subSuppliers]);
  const handlePreviewImport = (
    data: {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      dueDate: string | Date;
      sentToGCTime?: Date | null;
      sentToGCEmails?: string[] | null;
    },
    url: string
  ) => {
    setSelectedUrl(url);
    setReleasePreviewDetails(data);
    setShowPreviewPdf(true);
  };

  const handleCreateUploadedRelease = async () => {
    const uploadGroupedRelese = await dispatch(
      createGroupedRelease({
        mainBuyerId: selectedMainBuyerId || '',
        releaseDetails: uploadedReleases,
      })
    );
    if (uploadGroupedRelese.type === 'liens/createGroupedRelease/fulfilled') {
      setUploadingReleaseSuccess(true);
    } else {
      setUploadingReleaseFailed(true);
    }
    setOpenUploadCSVModal(false);
    setOpenConfirmUpload(false);
  };

  const handleImportRelease = (data: GroupedCSVRelease[]) => {
    setOpenConfirmUpload(true);
    setUploadedReleases(data);
  };

  return (
    <div className="h-full p-8 flex ">
      <AppUploadCSVInvoicesModal
        open={openUploadCSVModal}
        handleClose={() => setOpenUploadCSVModal(false)}
        onPreview={handlePreviewImport}
        onImportRelease={handleImportRelease}
      />
      <AppSuccessModal
        type="warning"
        open={openConfirmUpload}
        title="Are you sure you want to import these releases?"
        subTitle="This will create and send releases to the vendors"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        isSecondButton={true}
        onSecondButtonClick={() => {
          handleCreateUploadedRelease();
          setOpenUploadCSVModal(false);
        }}
        handleClose={() => {
          setOpenConfirmUpload(false);
        }}
      />
      <AppPdfPreviewModal
        open={showPreviewPdf}
        handleClose={() => {
          setShowPreviewPdf(false);
        }}
        invoice={''}
        pdfType="RELEASE"
        pdfList={[
          {
            name: 'lien-release',
            url: selectedUrl,
            date: new Date(),
          },
        ]}
        handleDownloadPdf={() => {}}
        isSigned={false}
        showPendingMessage={false}
        releaseDetails={releasePreviewDetails}
        mode="Buyer"
        showHistoryButton={false}
      />
      <AppForwardReleaseModal
        open={openForwardingModal}
        handleClose={() => setOpenForwardingModal(false)}
        jobs={jobs}
        onForwardEmail={handleForwardEmail}
      />

      <AppSuccessModal
        open={uploadingReleaseSuccess}
        modalImage={successChecksCircle}
        title="Successfully imported releases!"
        subTitle={`Your releases have been created and sent to the vendors`}
        buttonText={'View All Lien Waivers'}
        handleClose={() => {
          setUploadingReleaseSuccess(false);
          setRefresh(!refresh);
        }}
      />
      <AppSuccessModal
        open={uploadingReleaseFailed}
        modalImage={errorCircle}
        title="Uploading releases failed!"
        subTitle="Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setUploadingReleaseFailed(false);
          setOpenConfirmUpload(true);
        }}
        handleClose={() => {
          setUploadingReleaseFailed(false);
        }}
      />
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between flex-wrap z-20">
          <div className="flex mb-2">
            <AppInputField
              inputFieldStyles={{ width: '200px' }}
              id="search"
              value={searchText}
              placeholder={'Search by recipient or job'}
              icon={<Search size={15} />}
              onTextChange={(text) => {
                setSearchText(text);
              }}
            />
            <AppGroupedDropdown
              options={[
                ...dropdown,
                {
                  name: 'Supplier',
                  type: 'multi-select-drop-down',
                  value: 'supplier',
                  item: [
                    ...subSupplierUsers.map((subSupplierUser, i) => {
                      const userDetails = subSupplierUser.user.subUser
                        ? subSupplierUser.user.subUser
                        : subSupplierUser.user.user;
                      return {
                        label: `${subSupplierUser.subSupplierName} - ${
                          userDetails?.firstName || ''
                        } ${userDetails?.lastName || ''} `,
                        value: userDetails?.id ?? '',
                      };
                    }),
                  ],
                },
                {
                  name: 'Job',
                  type: 'multi-select-drop-down',
                  value: 'job',
                  item: [
                    ...jobs
                      .filter((jb) => !jb?.archivedState?.includes('BUYER_ARCHIVED'))
                      .map((job) => {
                        return {
                          label: `${job?.number ? `${job.number} - ` : ''}${job.name}`,
                          value: job.id,
                        };
                      }),
                  ],
                },
              ]}
              inputFieldStyles={{ width: '200px', marginLeft: '8px' }}
              onSelect={handleFilterSelect}
            />

            {/* <AppDropDown
              inputFieldStyles={{ width: '200px', marginLeft: '8px' }}
              isSearchable={false}
              isCreatable={false}
              items={[...weeksOptions]}
              value={''}
              onSelectChange={(data) => {
                if (data?.value) {
                }
              }}
            /> */}
            <div className="relative">
              <AppDropDown2
                inputFieldStyles={{ width: '200px', marginLeft: '8px' }}
                placeholder="Status"
                isSearchable={false}
                isCreatable={false}
                closeMenu={false}
                items={[
                  ...releaseStatusOptions.map((status) => ({
                    label: status,
                    value: status,
                    labelInElement: (
                      <div className="flex m-2 items-center">
                        <AppCheckBox
                          className="mr-2"
                          isChecked={statusFilterOption?.includes(status)}
                        />
                        {status !== 'All' && <AppReleaseStatusPill status={status as LienStatus} />}
                        {status === 'All' && <div className="text-black">{status}</div>}
                      </div>
                    ),
                  })),
                ]}
                isMulti
                onMultiSelectChange={(data, oldMultiValue) => {
                  if (data) {
                    const valueList = data.map((item) => item.value);
                    const valueListWithoutAll = valueList.filter((item) => item !== 'All');
                    if (
                      !valueList.includes('All') &&
                      oldMultiValue &&
                      oldMultiValue.includes('All') &&
                      valueListWithoutAll.length === releaseStatusOptions.length
                    ) {
                      setStatusFilterOption([]);
                    } else if (
                      valueList.includes('All') &&
                      valueListWithoutAll.length < releaseStatusOptions.length &&
                      oldMultiValue &&
                      oldMultiValue.includes('All')
                    ) {
                      setStatusFilterOption(valueListWithoutAll);
                    } else if (
                      (valueList.includes('All') &&
                        valueListWithoutAll.length === 0 &&
                        oldMultiValue &&
                        !oldMultiValue.includes('All')) ||
                      valueList.includes('All') ||
                      (valueListWithoutAll.length === releaseStatusOptions.length &&
                        !valueList.includes('All'))
                    ) {
                      setStatusFilterOption(releaseStatusOptions);
                    } else if (valueList.length > 0) {
                      if (
                        releaseStatusOptions.length - 1 === valueList.length &&
                        oldMultiValue &&
                        !oldMultiValue.includes('All')
                      ) {
                        setStatusFilterOption(releaseStatusOptions);
                      } else if (
                        releaseStatusOptions.length - 1 === valueList.length &&
                        oldMultiValue &&
                        oldMultiValue.includes('All')
                      ) {
                        setStatusFilterOption(null);
                      } else {
                        setStatusFilterOption(valueList);
                      }
                    } else {
                      setStatusFilterOption(null);
                    }
                  }
                }}
                value={null}
                multiValue={statusFilterOption}
              />
              {statusFilterOption && statusFilterOption?.length > 0 && (
                <div className="flex absolute bottom-6 right-[-12px] PRIMARY_500-BG WHITE-CLR rounded-full text-xs font-bold h-8 w-8 items-center justify-center">
                  {statusFilterOption.length === releaseStatusOptions.length
                    ? statusFilterOption?.length - 1
                    : statusFilterOption?.length}
                </div>
              )}
            </div>
          </div>
          <div className="flex mb-2">
            <AppButton
              text="Add Release Request"
              icon={<Plus color={COLORS.WHITE} />}
              buttonStyles={{ padding: '8px', height: '40px' }}
              onClick={() => {
                if (selectedMainBuyerId) {
                  navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview/addLienRelease`);
                }
              }}
            />
            {selectedBuyerLiens.length > 0 && (
              <AppButton
                text="Bulk Download"
                buttonStyles={{ marginLeft: '5px', padding: '8px', height: '40px' }}
                type="TERTIARY"
                onClick={handleDownloadAll}
                icon={<Download size={20} color={COLORS.TEXT_SECONDARY} />}
              />
            )}
            <AppReleaseOptionsDropdown
              onSelect={handleDropdownOption}
              inputFieldStyles={{ marginLeft: '8px', height: '40px' }}
            />
          </div>
        </div>
        {error ? (
          <AppErrorPage
            title="Error loading  releases."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <BuyerLienReleasesTable releases={filteredReleases} refresh={refresh} />
        )}
      </div>
      {/* <LienWaiverDocumentView /> */}
    </div>
  );
};

export default BuyerLienReleases;
