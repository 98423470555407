import React, { Fragment } from 'react';
import { Box, Modal } from '@mui/material';

export const Dropdown = ({
  options,
  onSelect,
  onClose,
  isOpen,
  dropdownModalStyle,
}: {
  options: {
    value: string;
    label: string;
    type?: 'error' | 'primary' | 'warning';
    icon?: any;
  }[];
  onSelect: (value: string) => void;
  onClose: () => void;
  isOpen: boolean;
  dropdownModalStyle: {
    top: number;
    left: number;
  };
}) => {
  const handleSelectedOption = (val: string) => {
    onSelect(val);
  };

  return (
    <Fragment>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="dropdown-modal"
        BackdropProps={{
          sx: { backgroundColor: 'transparent' },
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: `${dropdownModalStyle.top}px`,
            left: `${dropdownModalStyle.left}px`,
            transform: 'translate(-100%, 0%)',
            width: 220,
            bgcolor: '#f9faff',
            py: 1.5,
            borderRadius: 1,
            borderColor: '#e4eaf1',
            borderWidth: 1,
          }}>
          {options.map((option, index) => (
            <Box
              className="hover:bg-blue-100"
              key={index}
              onClick={() => {
                handleSelectedOption(option.value);
                onClose();
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                px: 2,
                py: 1,
                cursor: 'pointer',
              }}>
              {option.icon}
              <div className="text-sm">{option.label}</div>
            </Box>
          ))}
        </Box>
      </Modal>
    </Fragment>
  );
};
