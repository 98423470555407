import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { SuppliersInitialState } from '../globalSlices/suppliersSlice';
import {
  addDistributionOneSupplierConnection,
  addSage100SupplierConnection,
  assignDriverToInvoice,
  getSupplierSummary,
  getUserCustomer,
  getUserCustomers,
  getUserDrivers,
  getUserInvoices,
  getUserSalesReceipts,
  getUserSuppliers,
  sendEmailStatementsForBuyers,
  sendEmailStatementsForAllBuyers,
  addP21SupplierConnection,
  recursiveGetUserCustomers,
} from '../services/suppliersService';
import { Buyer, DocumentsSummary } from '@dill/dill-shared/dist/src';

export const getUserSuppliersExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  builder.addCase(getUserSuppliers.pending, (state, action) => {
    state.isLoading = true;
  });
  builder.addCase(
    getUserSuppliers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.isLoading = false;
      if (action.payload && action.payload.success && action.payload?.data) {
        state.suppliers = action.payload?.data;
      }
    }
  );
  builder.addCase(getUserSuppliers.rejected, (state, action: PayloadAction<any>) => {
    state.isLoading = false;
  });
};

export const getUserCustomersExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'getUserCustomers';
  builder.addCase(getUserCustomers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getUserCustomers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload?.success && action.payload?.data) {
        const ids = action.payload?.data.map(
          (item: {
            buyer: Buyer;
            usersEmailsDetails: {
              email: string;
              isStatementsEmailOptOut: boolean;
              isPaymentRemindersEmailOptOut: boolean;
              isInvitedUser: boolean;
            }[];
            documentsSummary: DocumentsSummary;
          }) => item.buyer.id
        );
        const currentState = state.customers.filter((item) => !ids.includes(item.buyer.id));
        state.customers = [...new Set([...currentState, ...action.payload?.data])];
      }
    }
  );
  builder.addCase(getUserCustomers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const recursiveGetUserCustomersExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'recursiveGetUserCustomers';
  builder.addCase(recursiveGetUserCustomers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    recursiveGetUserCustomers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload?.success && action.payload?.data) {
        const ids = action.payload?.data.map(
          (item: {
            buyer: Buyer;
            usersEmailsDetails: {
              email: string;
              isStatementsEmailOptOut: boolean;
              isPaymentRemindersEmailOptOut: boolean;
              isInvitedUser: boolean;
            }[];
            documentsSummary: DocumentsSummary;
          }) => item.buyer.id
        );
        const currentState = state.customers.filter((item) => !ids.includes(item.buyer.id));
        state.customers = [...new Set([...currentState, ...action.payload?.data])];
      }
    }
  );
  builder.addCase(recursiveGetUserCustomers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierSummaryExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'getSupplierSummary';
  builder.addCase(getSupplierSummary.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierSummary.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);

      if (action.payload && action.payload.success && action.payload?.data?.totalDocumentsSummary) {
        state.supplierTotalDocumentsSummary = action.payload?.data.totalDocumentsSummary;
      }
    }
  );
  builder.addCase(getSupplierSummary.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getUserInvoicesExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'getUserInvoices';
  builder.addCase(getUserInvoices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getUserInvoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.userSupplierInvoices = action.payload?.data;
      }
    }
  );
  builder.addCase(getUserInvoices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getUserSalesReceiptsExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'getUserSalesReceipts';
  builder.addCase(getUserSalesReceipts.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getUserSalesReceipts.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.userSupplierSalesReceipts = action.payload?.data;
      }
    }
  );
  builder.addCase(getUserSalesReceipts.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getUserDriversExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'getUserDrivers';
  builder.addCase(getUserDrivers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getUserDrivers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.supplierDrivers = action.payload?.data;
      }
    }
  );
  builder.addCase(getUserDrivers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const assignDriverToInvoiceExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'assignDriverToInvoice';
  builder.addCase(assignDriverToInvoice.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    assignDriverToInvoice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(assignDriverToInvoice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const sendEmailStatementsForBuyersExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'sendEmailStatementsForBuyers';
  builder.addCase(sendEmailStatementsForBuyers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    sendEmailStatementsForBuyers.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(sendEmailStatementsForBuyers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const addDistributionOneSupplierConnectionExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'addDistributionOneSupplierConnection';
  builder.addCase(addDistributionOneSupplierConnection.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    addDistributionOneSupplierConnection.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(
    addDistributionOneSupplierConnection.rejected,
    (state, action: PayloadAction<any>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
};

export const addSage100SupplierConnectionExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'addSage100SupplierConnection';
  builder.addCase(addSage100SupplierConnection.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    addSage100SupplierConnection.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(addSage100SupplierConnection.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const addP21SupplierConnectionExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'addP21SupplierConnection';
  builder.addCase(addP21SupplierConnection.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    addP21SupplierConnection.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(addP21SupplierConnection.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const sendEmailStatementsForAllBuyersExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'sendEmailStatementsForAllBuyers';
  builder.addCase(sendEmailStatementsForAllBuyers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    sendEmailStatementsForAllBuyers.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(sendEmailStatementsForAllBuyers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getUserCustomerExtraReducer = (
  builder: ActionReducerMapBuilder<SuppliersInitialState>
) => {
  const reducerId = 'getUserCustomer';
  builder.addCase(getUserCustomer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getUserCustomer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(getUserCustomer.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
