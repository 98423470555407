import { useEffect, useMemo, useState } from 'react';
import { Buyer, getAllStates, getStateAbbreviation, SupplierJobUpdate } from '@dill/dill-shared';
import { X } from 'phosphor-react';

import { COLORS } from '../../../utils/colors';

import { AppButton } from '../AppButton/AppButton';
import { AppInputField } from '../AppInputField/AppInputField';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

import './AppNewSupplierJobModal.scss';
import { VerifyJobRequestData } from '../../../redux/services/verifyAddressService';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import EditBuyerModal from '../../modules/suppliers/EditBuyerModal/EditBuyerModal';
import { getSupplierBuyers } from '../../../redux/services/supplierBuyersService';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface AddSupplierJobFormProps {
  className?: string;
  onClose: () => void;
  onBuyerEdit?: () => void;
  onFormSubmit: (jobAddressData: VerifyJobRequestData) => void;
  onRequestInfoSheet: (data: any) => void;
  editMode: 'ADD' | 'EDIT';
  jobSiteData: PartialSupplierJobPreVerify;
  onVerifyJobCheckBox: (val: boolean) => void;
  verifyJobCheckBox: boolean;
}

type PartialSupplierJobPreVerify = Pick<
  SupplierJobUpdate,
  | 'buyerId'
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'constructionMonitorPermitId'
>;

const INITIAL_STATE = {
  name: '',
  jobNumber: '',
  jobAddress: '',
  jobCity: '',
  jobState: '',
  jobZipCode: '',
  buyerId: '',
  constructionMonitorPermitId: null,
} as PartialSupplierJobPreVerify;

const AddSupplierJobForm = (props: AddSupplierJobFormProps) => {
  const {
    className,
    onClose,
    editMode,
    onFormSubmit,
    jobSiteData,
    onBuyerEdit,
    onRequestInfoSheet,
    onVerifyJobCheckBox,
    verifyJobCheckBox,
  } = props;

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [isEditBuyerModalOpen, setIsEditBuyerModalOpen] = useState(false);
  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [errors, setErrors] = useState(INITIAL_STATE);

  const onTextChange = (key: string, value: string) => {
    setSupplierJobInput((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const { supplierBuyers } = useAppSelector((state) => state.supplierBuyers);
  const { user } = useAppSelector((state) => state.auth);

  const fetchedSupplierBuyers = useMemo(() => {
    if (supplierBuyers.length > 0) {
      return supplierBuyers;
    }
    return [];
  }, [supplierBuyers]);

  const allStates = useMemo(() => {
    return getAllStates();
  }, []);

  useEffect(() => {
    if (jobSiteData) {
      setSupplierJobInput({
        name: jobSiteData.name,
        jobNumber: jobSiteData.jobNumber,
        jobAddress: jobSiteData.jobAddress,
        jobCity: jobSiteData.jobCity,
        jobState: getStateAbbreviation(jobSiteData.jobState || '') || '',
        jobZipCode: jobSiteData.jobZipCode,
        buyerId: jobSiteData.buyerId,
        constructionMonitorPermitId: jobSiteData.constructionMonitorPermitId,
      });
    }
  }, [jobSiteData]);

  useEffect(() => {
    if (jobSiteData && jobSiteData.buyerId && jobSiteData.buyerId !== '') {
      const foundSupplierBuyer = supplierBuyers.find((supBuy) => supBuy.id === jobSiteData.buyerId);
      if (foundSupplierBuyer) {
        onTextChange('buyerId', jobSiteData.buyerId);
      }
    }
  }, [jobSiteData, supplierBuyers]);

  const submitFom = async () => {
    setLoading(true);
    setShowErrorAbove(false);

    const newErrors = {} as PartialSupplierJobPreVerify;

    if (!supplierJobInput.name) {
      newErrors.name = 'Please enter a job name';
    }
    if (!supplierJobInput.jobAddress) {
      newErrors.jobAddress = 'Please enter a job address';
    }
    if (!supplierJobInput.jobCity) {
      newErrors.jobCity = 'Please enter a job city';
    }
    if (!supplierJobInput.jobState) {
      newErrors.jobState = 'Please enter a job state';
    }
    if (!supplierJobInput.jobZipCode) {
      newErrors.jobZipCode = 'Please enter a job zip code';
    }
    if (!supplierJobInput.buyerId) {
      newErrors.buyerId = 'Please select a contractor';
    }
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      setShowErrorAbove(true);
      setLoading(false);
      return;
    }

    const jobAddressData: VerifyJobRequestData = {
      address: supplierJobInput.jobAddress ?? '',
      city: supplierJobInput.jobCity ?? '',
      state: supplierJobInput.jobState ?? '',
      zipCode: supplierJobInput.jobZipCode ?? '',
      buyerId: supplierJobInput.buyerId ?? '',
      jobNumber: supplierJobInput.jobNumber ?? '',
      name: supplierJobInput.name ?? '',
    };

    await onFormSubmit(jobAddressData);
    setLoading(false);
  };

  const handleRequestInfoSheet = () => {
    setShowErrorAbove(false);

    const newErrors = {} as PartialSupplierJobPreVerify;

    if (!supplierJobInput.buyerId) {
      newErrors.buyerId = 'Please select a customer';
    }
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      setShowErrorAbove(true);
      setLoading(false);
      return;
    }
    const foundSupplierBuyer = supplierBuyers.find(
      (supBuy) => supBuy.id === supplierJobInput.buyerId
    );
    const data = {
      ...supplierJobInput,
      address: supplierJobInput.jobAddress ?? '',
      city: supplierJobInput.jobCity ?? '',
      state: supplierJobInput.jobState ?? '',
      zipCode: supplierJobInput.jobZipCode ?? '',
      buyerId: supplierJobInput.buyerId ?? '',
      jobNumber: supplierJobInput.jobNumber ?? '',
      name: supplierJobInput.name ?? '',
      buyerName: foundSupplierBuyer?.name ?? '',
      buyer: foundSupplierBuyer ?? null,
    };
    onRequestInfoSheet(data);
  };

  return (
    <>
      <EditBuyerModal
        open={isEditBuyerModalOpen}
        buyer={selectedBuyer}
        handleClose={(editSuccess) => {
          if (editSuccess) {
            dispatch(getSupplierBuyers());
            if (onBuyerEdit) {
              onBuyerEdit();
            }
          }
          setIsEditBuyerModalOpen(false);
        }}
      />
      <div className={`new-job-modal-content pt-3 ${className}`}>
        <div className="new-job-modal-title-container">
          <h3 className="font-medium text-lg">{editMode == 'EDIT' ? 'Edit Job' : 'New Job'}</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={onClose}
          />
        </div>
        <div className="content-job !overflow-y-auto">
          <div className="flex flex-row px-6 py-3">
            <div className="flex flex-col gap-2 w-full">
              <AppDropDown2
                isRequired
                label="Customer"
                errorText={errors.buyerId ? errors.buyerId : ''}
                value={supplierJobInput.buyerId || ''}
                items={[
                  ...fetchedSupplierBuyers.map((buyer) => {
                    return {
                      label: buyer.name,
                      value: buyer.id,
                      labelInElement: (
                        <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                          <div className="text-sm">{`${buyer.name}`}</div>
                          <button
                            onClick={(e) => {
                              setIsEditBuyerModalOpen(true);
                              setSelectedBuyer(buyer);
                            }}
                            className="text-sm px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                            Edit
                          </button>
                        </div>
                      ),
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item && item?.value !== null) {
                    const foundSupplierBuyer = supplierBuyers.find(
                      (supBuy) => supBuy.id === item?.value
                    );
                    if (foundSupplierBuyer) {
                      onTextChange('buyerId', item.value);
                    }
                  }
                }}
              />
              <div className="flex flex-row justify-around gap-2">
                <div className="w-full">
                  <AppInputField
                    id="name"
                    label="Job Name"
                    isRequired={true}
                    value={supplierJobInput.name}
                    errorText={errors.name ? errors.name : ''}
                    onTextChange={(text: string) => {
                      onTextChange('name', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="number"
                    label="Your Job Number"
                    placeholder="12345"
                    value={supplierJobInput.jobNumber}
                    onTextChange={(text) => {
                      onTextChange('jobNumber', text);
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-full">
                  <AppInputField
                    id="address"
                    label="Job Address"
                    isRequired={true}
                    value={supplierJobInput.jobAddress}
                    errorText={errors.jobAddress ? errors.jobAddress : ''}
                    onTextChange={(text) => {
                      onTextChange('jobAddress', text);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-around gap-2">
                <div className="w-full">
                  <AppInputField
                    id="city"
                    label="Job City"
                    isRequired={true}
                    value={supplierJobInput.jobCity}
                    errorText={errors.jobCity ? errors.jobCity : ''}
                    onTextChange={(text) => {
                      onTextChange('jobCity', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppDropDown2
                    isRequired
                    label="Job State"
                    value={supplierJobInput.jobState || null}
                    errorText={errors.jobState ? errors.jobState : ''}
                    items={[
                      ...Object.keys(allStates).map((state) => {
                        const key = state as keyof typeof allStates;
                        const stateKey = allStates[key];
                        return {
                          label: `${stateKey} - ${state}`,
                          value: stateKey,
                        };
                      }),
                    ]}
                    onSelectChange={(item) => {
                      if (item && item?.value !== null) {
                        const foundState = Object.values(getAllStates()).find(
                          (state) => state === item?.value
                        );
                        if (foundState) {
                          onTextChange('jobState', item.value);
                        }
                      }
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="zip"
                    label="Zip Code"
                    isRequired={true}
                    value={supplierJobInput.jobZipCode}
                    errorText={errors.jobZipCode ? errors.jobZipCode : ''}
                    onTextChange={(text) => {
                      onTextChange('jobZipCode', text);
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full items-center">
                <div data-tooltip-id={'locked_checkbox_user_feature'}>
                  <AppCheckBox
                    className="mr-2"
                    isChecked={verifyJobCheckBox}
                    onClick={(val: boolean) => {
                      onVerifyJobCheckBox(val);
                    }}
                    isDisabled={!user?.userSupplier?.ownerVerificationEnabled}
                  />
                </div>
                <div className="text-black text-sm">
                  Instantly verify job against permit database
                </div>
              </div>
              {!user?.userSupplier?.ownerVerificationEnabled && (
                <ReactTooltip
                  id={'locked_checkbox_user_feature'}
                  place="right"
                  variant="dark"
                  opacity={'100%'}
                  style={{
                    background: '#222',
                    width: '300px',
                    zIndex: 99999,
                  }}>
                  <span>Please speak with the team at Dill if you’d like access</span>
                </ReactTooltip>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          {showErrorAbove && (
            <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
          )}
          <div className="flex flex-row justify-between">
            {editMode === 'EDIT' ? (
              <AppButton
                text="Request Job Info Sheet"
                type="SECONDARY"
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  marginRight: '12px',
                  height: '40px',
                  marginLeft: '24px',
                }}
                onClick={handleRequestInfoSheet}
              />
            ) : (
              <div></div>
            )}
            <div className="flex flex-row self-end px-6">
              <AppButton
                text="Close"
                type="TERTIARY"
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  marginRight: '12px',
                  height: '40px',
                }}
                onClick={onClose}
              />
              <AppButton
                text={
                  editMode == 'EDIT'
                    ? `${
                        user?.userSupplier?.ownerVerificationEnabled && verifyJobCheckBox
                          ? 'Edit & Verify'
                          : 'Next'
                      }`
                    : `${
                        user?.userSupplier?.ownerVerificationEnabled && verifyJobCheckBox
                          ? 'Add & Verify'
                          : 'Next'
                      }`
                }
                onClick={submitFom}
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  width: '100px',
                  height: '40px',
                }}
                isLoading={loading}
                isDisabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSupplierJobForm;
