import { Viewer } from '@react-pdf-viewer/core';
import {
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { AppDropDown } from '../../../../general/AppDropDown/AppDropDown';
import { AppDropDown2 } from '../../../../general/AppDropDown2/AppDropDown2';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import AppDatePicker from '../../../../general/AppDatePicker/AppDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getLienTemplates } from '../../../../../redux/services/leinTemplatesService';
import {
  Job,
  LienTemplate,
  UserLinkApiResponse,
  ReleaseFieldData,
  FromFieldTypeReleaseTemplate,
  ReleaseTemplateField,
  formatMoney,
  PDFFieldReleaseTemplate,
  JobDetails,
} from '@dill/dill-shared';
import {
  removePDFFields,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
  populatePagesPdfWithFieldsData,
} from '../../../../../utils/helpers';
import { AppConfirmationModal } from '../../../../general/AppConfirmationModal/AppConfirmationModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import sendCircle from '../../../../../assets/svg/send_circle.svg';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import { getJobs, getJob, updateJob } from '../../../../../redux/services/jobsService';
import {
  getSubSuppliers,
  updateSubSupplier,
} from '../../../../../redux/services/subSuppliersService';
import { AppNewJobModal } from '../../../../general/AppNewJobModal/AppNewJobModal';
import {
  createLienRelease,
  getInvoicesUsedOnReleases,
} from '../../../../../redux/services/liensService';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ChevronDown, Plus } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import AppScheduleModal from '../../../../general/AppScheduleModal/AppScheduleModal';
import AppFilePicker from '../../../../general/AppFilePicker/AppFilePicker';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { useTrivialValueDebounce } from '../../../../../utils/hooks/useDebounce';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Notepad } from 'phosphor-react';
import { getUserByBuyers } from '../../../../../redux/services/authService';
import EditInvoiceList from '../../../payments/EditInvoiceList';
import { InvoiceListButtonStyle, InvoiceReference } from '../../../../../constants/invoice';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';
import { getGroupedBuyersSuppliers } from '../../../../../redux/services/buyersService';
import { AppInvoiceTable } from '../../../../general/AppInvoiceTable/AppInvoiceTable';

const BuyerAddLienRelease = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { jobs } = useAppSelector((state) => state.jobs);
  const { selectedMainBuyer, selectedBuyerSuppliers } = useAppSelector((state) => state.buyers);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);

  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [selectedTemplate, setSelectedTemplate] = useState<LienTemplate | null>(null);
  const [selectedTemplateFields, setSelectedTemplateFields] = useState<
    ReleaseTemplateField[] | null
  >(null);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedSupplierUserPosition, setSelectedSupplierUserPosition] = useState<number | null>(
    null
  );
  const [selectedSubSupplierUser, setSelectedSubSupplierUser] = useState<{
    subSupplierName: string;
    subSupplierId: string;
    syncSupplierId: string;
    notes: string;
    user: UserLinkApiResponse;
  } | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [notes, setNotes] = useState('');
  const [emailRemainderCount, setEmailRemainderCount] = useState(3);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [isConfirmCreateLeanReleaseModalOpen, setIsConfirmCreateLeanReleaseModalOpen] =
    useState(false);
  const [isErrorCreateLeanReleaseModalOpen, setIsErrorCreateLeanReleaseModalOpen] = useState(false);
  const [isLienReleaseCreateSuccessModalOpen, setIsLienReleaseCreateSuccessModalOpen] =
    useState(false);
  const [isWetSignature, setIsWetSignature] = useState(false);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const params = useParams();
  const [currentPdfUrl, setCurrentPdfUrl] = useState('');
  const [flattenedPdfUrl, setFlattenedPdfUrl] = useState('');
  const [showSchedule, setShowSchedule] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [debouncedPopulatePdfChange, triggerDebouncedPopulatePdf] = useTrivialValueDebounce(200);
  const [openScheduleSuccessModal, setOpenScheduleSuccessModal] = useState(false);
  const [openScheduleErrorModal, setOpenScheduleErrorModal] = useState(false);
  const [missingJobDetails, setMissingJobDetails] = useState(false);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [formFieldsData, setFormFieldsData] = useState<{
    [x: string]: ReleaseFieldData;
  }>({});
  const [openJobModal, setOpenJobModal] = useState(false);
  const [isEditJob, setIsEditJob] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const todayDate = new Date();
  const nextDay = new Date();
  nextDay.setDate(todayDate.getDate() + 1);

  const [showInvoiceListNotes, setShowInvoiceListNotes] = useState(true);
  const [invoiceList, setInvoiceList] = useState<InvoiceReference[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [usedInvoices, setUsedInvoices] = useState<
    { type: string; invoiceNumber: string; date: string }[]
  >([]);

  const onShowInvoiceClick = () => {
    setShowInvoiceListNotes(!showInvoiceListNotes);
  };

  const checkMissingJobDetails = (job: Job | null) => {
    if (job) {
      if (
        !job?.owner ||
        job?.owner === '' ||
        !job?.city ||
        job?.city === '' ||
        !job?.state ||
        job?.state === '' ||
        !job?.zipCode ||
        !job?.address ||
        job?.address === ''
      ) {
        return true;
      }
    }
    return false;
  };

  const templates = useMemo(() => {
    if (lienTemplates.length > 0) {
      const processedLienTemplates = lienTemplates.filter(
        (template) => template.status === 'processed'
      );
      if (processedLienTemplates.length > 0 && location.pathname.split('/').length !== 6) {
        const template = processedLienTemplates[0];
        setSelectedTemplate(template);
        if (template.originalPdf?.url) {
          setCurrentPdfUrl(template.originalPdf?.url);
        }
      }
      return processedLienTemplates;
    }
    return [];
  }, [lienTemplates]);

  const subUsersInvoices = useMemo(() => {
    if (selectedBuyerSuppliers && selectedSubSupplierUser) {
      const unPaidInvoices = [];
      const paidInvoices = [];
      for (let index = 0; index < selectedBuyerSuppliers.length; index++) {
        const selectedBuyerSupplier = selectedBuyerSuppliers[index];
        if (selectedBuyerSupplier.id === selectedSubSupplierUser.syncSupplierId) {
          const invoices = selectedBuyerSupplier.invoices;
          if (invoices) {
            const filteredPaid = [...invoices.paidInvoices.invoices].filter(
              (inv) => inv.jobId === selectedJob?.providerJobId
            );
            paidInvoices.push(...filteredPaid);
            const todayInvoices = invoices.todayInvoices.invoices;
            const currentInvoices = invoices.currentInvoices.invoices;
            const pastDueInvoices = invoices.pastDueInvoices.invoices;
            const pendingPayInvoices = invoices.pendingPayInvoices.invoices;
            const filterUnpaidInvoice = [
              ...todayInvoices,
              ...currentInvoices,
              ...pastDueInvoices,
              ...pendingPayInvoices,
            ].filter((inv) => inv.jobId === selectedJob?.providerJobId);
            unPaidInvoices.push(...filterUnpaidInvoice);
          }
        }
      }
      const result = {
        paidInvoices: paidInvoices,
        unPaidInvoices: unPaidInvoices,
      };
      return result;
    }

    return {
      paidInvoices: [],
      unPaidInvoices: [],
    };
  }, [selectedBuyerSuppliers, selectedSubSupplierUser, selectedJob]);

  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers
        .filter((subsup) => !subsup.archivedState?.includes('BUYER_ARCHIVED'))
        .reduce(
          (
            prev: {
              subSupplierName: string;
              subSupplierId: string;
              syncSupplierId: string;
              notes: string;
              user: UserLinkApiResponse;
            }[],
            curr
          ) => {
            return [
              ...prev,
              ...curr.users
                .filter((userLink) => {
                  if (
                    userLink.subUser &&
                    userLink.subUser.roles &&
                    (userLink.subUser.roles.includes('REVIEWER') ||
                      userLink.subUser.roles.length == 0)
                  ) {
                    return true;
                  }
                  return !userLink?.subUser?.roles;
                })
                .map((userLink) => {
                  return {
                    subSupplierId: curr.id,
                    subSupplierName: curr.name,
                    syncSupplierId: curr.syncSupplierId ?? '',
                    notes: curr.notes ?? '',
                    user: userLink,
                  };
                }),
            ];
          },
          []
        );
    }
    return [];
  }, [subSuppliers]);

  const fetchData = async () => {
    if (selectedMainBuyerId) {
      await dispatch(getLienTemplates({ mainBuyerId: selectedMainBuyerId }));
      await dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));

      if (params.jobId) {
        const jobId = params.jobId;
        await dispatch(getJob({ jobId, mainBuyerId: selectedMainBuyerId }));
      }
      await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
      await dispatch(getUserByBuyers({ buyerId: selectedMainBuyerId }));
      await dispatch(
        getGroupedBuyersSuppliers({
          mainBuyerId: selectedMainBuyerId,
          weeksLimit: 24,
        })
      );
    }
  };

  const fetchUsedInvoices = async ({
    mainBuyerId,
    buyerJobId,
  }: {
    mainBuyerId: string;
    buyerJobId: string;
  }) => {
    const usedInvoicesRes: any = await dispatch(
      getInvoicesUsedOnReleases({
        mainBuyerId,
        buyerJobId,
        type: 'MAIN_BUYER',
      })
    );
    if (usedInvoicesRes.type === 'releases/getInvoicesUsedOnReleases/fulfilled') {
      if (
        usedInvoicesRes?.payload &&
        usedInvoicesRes?.payload?.usedInvoices &&
        usedInvoicesRes.payload.usedInvoices.length > 0
      ) {
        const invoices: { type: string; invoiceNumber: string; date: string }[] =
          usedInvoicesRes.payload.usedInvoices;
        setUsedInvoices(invoices);
      }
    }
  };

  useEffect(() => {
    fetchData();

    if (invoiceList.length == 0) {
      setInvoiceList([
        {
          invoiceNumber: '',
          amount: null,
        },
      ]);
    }

    return () => {};
  }, [selectedMainBuyerId]);

  useEffect(() => {
    if (selectedMainBuyerId && selectedJob) {
      fetchUsedInvoices({ mainBuyerId: selectedMainBuyerId, buyerJobId: selectedJob.id });
    }

    return () => {};
  }, [selectedMainBuyerId, selectedJob]);

  useEffect(() => {
    if (!showInvoiceListNotes && invoiceList.length !== 0) {
      setInvoiceList([]);
    }
  }, [showInvoiceListNotes]);

  const populatePdf = async (fieldDetails: PDFFieldReleaseTemplate[]) => {
    if (!selectedTemplate?.originalPdf?.url) {
      return;
    }

    let pdfUrl = '';
    if (flattenedPdfUrl) {
      pdfUrl = flattenedPdfUrl;
    } else {
      const flatPDF = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
      pdfUrl = flatPDF ?? '';
    }
    if (!pdfUrl) {
      return;
    }
    const populatedPdfUrl = await populatePagesPdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
    });
    if (populatedPdfUrl) {
      setCurrentPdfUrl(populatedPdfUrl);
    }
  };

  const getVendorDetails = () => {
    let vendorCityStateData: { [x: string]: ReleaseFieldData } = {};
    let vendorAddressData: { [x: string]: ReleaseFieldData } = {};
    if (selectedTemplate) {
      const citystatezipcode = `${city ? `${city}, ` : ''}${state ? `${state} ` : ''}${
        zipCode ? `${zipCode} ` : ''
      }`;
      const foundVendorCityState = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      const foundVendorAddress = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      if (foundVendorCityState) {
        const updateField = formFieldsData[foundVendorCityState.id];
        if (updateField) {
          vendorCityStateData = {
            [updateField.id]: { ...updateField, value: citystatezipcode },
          };
        }
      }
      if (foundVendorAddress) {
        const updateAddressField = formFieldsData[foundVendorAddress.id];
        if (updateAddressField) {
          vendorAddressData = {
            [updateAddressField.id]: { ...updateAddressField, value: address },
          };
        }
      }
    }
    return { ...vendorCityStateData, ...vendorAddressData };
  };

  const populateTemplateFieldData = () => {
    let data: { [x: string]: ReleaseFieldData } = {};
    let vendorPhone: { [x: string]: ReleaseFieldData } = {};
    if (selectedTemplate) {
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
          let defaultValue: any = '';
          if (
            formField.fieldType === 'CURRENCY' &&
            formField.defaultValue !== null &&
            formField.defaultValue !== undefined
          ) {
            defaultValue = Number(formField.defaultValue).toFixed(2);
          } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
            defaultValue = `${formField.defaultValue}`;
          } else {
            defaultValue = formField.defaultValue;
          }
          data = {
            ...data,
            [formField.id]: {
              isRequired: formField.isFieldRequired,
              value:
                formFieldsData[formField.id] && formFieldsData[formField.id].value
                  ? formFieldsData[formField.id].value
                  : formField.defaultValue !== null && formField.defaultValue !== undefined
                  ? defaultValue
                  : '',
              id: formField.id,
              name: formField.fieldName,
              type: formField.fieldType,
            },
          };
        }
      });
      const currentDate = moment();
      const endOfLastMonth = currentDate.subtract(1, 'months').endOf('month').startOf('day');
      const endOfMonthDate = endOfLastMonth.toDate();
      const searchParams = new URLSearchParams(location.search);
      const currentThroughDate = searchParams.get('currentThroughDate');
      let throughDateData: { [x: string]: ReleaseFieldData } = {};
      for (const key of Object.keys(data)) {
        if (key.startsWith('throughDate')) {
          throughDateData = {
            ...throughDateData,
            [key]: {
              ...data[key],
              value: currentThroughDate ? new Date(currentThroughDate) : new Date(endOfMonthDate),
            },
          };
        }
      }
      data = { ...data, ...throughDateData };

      if (selectedSupplierUserPosition !== null) {
        const foundSubSupplier = subSuppliers.find(
          (item) => item.id === subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId
        );
        if (foundSubSupplier) {
          const foundPhoneInput = selectedTemplate?.formFields?.find(
            (formFields) =>
              formFields.fromFieldType === 'NEW_FIELD' &&
              formFields.whoPopulatesField === 'BUYER' &&
              formFields.id.startsWith('vendorPhone')
          );
          if (foundPhoneInput) {
            const updateField = data[foundPhoneInput.id];
            if (updateField) {
              vendorPhone = {
                [foundPhoneInput.id]: {
                  ...updateField,
                  value: foundSubSupplier?.phoneNumber ? `${foundSubSupplier?.phoneNumber}` : '',
                },
              };
            }
          }
        }
      }
    }
    return { ...data, ...vendorPhone };
  };

  const populateAllPdfData = () => {
    const specialFieldsPDFData: { fromFieldType: FromFieldTypeReleaseTemplate; dataToUse: any }[] =
      [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    if (selectedJob) {
      specialFieldsPDFData.push({ fromFieldType: 'JOB_FIELD', dataToUse: selectedJob });
    }

    if (selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]) {
      specialFieldsPDFData.push({
        fromFieldType: 'SUPPLIER_FIELD',
        dataToUse: subSupplierUsers[selectedSupplierUserPosition],
      });
    }

    if (selectedTemplate) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_BUYER_FIELD',
        dataToUse: selectedMainBuyer,
      });
      const vendorDetails = getVendorDetails();
      const allNewFormFields = { ...formFieldsData, ...vendorDetails };
      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        dynamicForFieldsPdfData.push({
          fromFieldId: formFieldKey,
          value: allNewFormFields[formFieldKey].value,
        });
      });
    }
    let pdfData: PDFFieldReleaseTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && selectedTemplate) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    if (pdfData.length > 0) {
      populatePdf(pdfData);
    }
  };

  useEffect(() => {
    const pathlist = location.pathname.split('/');
    const searchParams = new URLSearchParams(location.search);
    const templateId = searchParams.get('templateId');
    const templateType = searchParams.get('templateType');
    const subSupplierId = searchParams.get('subSupplierId');
    const currentThroughDate = searchParams.get('currentThroughDate');
    if (
      location.pathname.startsWith(`/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs`)
    ) {
      const jobId = pathlist[pathlist.length - 2];

      if (subSupplierId) {
        const subSupplierIndex = subSupplierUsers.findIndex(
          (subSupplierUser) => subSupplierUser?.subSupplierId === subSupplierId
        );
        setSelectedSupplierUserPosition(subSupplierIndex);
      }
      if (jobs) {
        const jobSelect = jobs.find((val) => val.id === jobId);
        if (jobSelect) {
          if (currentThroughDate) {
            const currentDate = moment();
            const endOfLastMonth = currentDate.subtract(1, 'months').endOf('month').startOf('day');
            const endOfMonthDate = endOfLastMonth.toDate();
            const formFieldsDataKey = Object.keys(formFieldsData);
            let throughDateData: { [x: string]: ReleaseFieldData } = {};
            for (const key of Object.keys(formFieldsData)) {
              if (key.startsWith('throughDate')) {
                throughDateData = {
                  ...throughDateData,
                  [key]: {
                    ...formFieldsData[key],
                    value: currentThroughDate
                      ? new Date(currentThroughDate)
                      : new Date(endOfMonthDate),
                  },
                };
              }
            }
            const vendorDetails = getVendorDetails();
            setFormFieldsData({ ...formFieldsData, ...throughDateData, ...vendorDetails });
          }
          setSelectedJob(jobSelect);
        }
      }
      if (templateId) {
        const templateSelect = templates.find((val) => val.id === templateId);
        if (templateSelect) {
          setSelectedTemplate(templateSelect);
          if (templateSelect?.originalPdf?.url) {
            setCurrentPdfUrl(templateSelect?.originalPdf?.url);
          }
        }
      } else if (templateType && templateType !== '') {
        const templateSelect = templates.find((val) => val.type === templateType);
        if (templateSelect) {
          setSelectedTemplate(templateSelect);
          if (templateSelect?.originalPdf?.url) {
            setCurrentPdfUrl(templateSelect?.originalPdf?.url);
          }
        }
      } else {
        const templateSelect = templates[0];
        if (templateSelect) {
          setSelectedTemplate(templateSelect);
          if (templateSelect?.originalPdf?.url) {
            setCurrentPdfUrl(templateSelect?.originalPdf?.url);
          }
        }
      }
    }
    setMissingJobDetails(false);
    return () => {};
  }, [jobs]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef?.current && !dropdownRef?.current?.contains(event?.target)) {
        setShowSchedule(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const hasCityStateZipCode = useMemo(() => {
    if (selectedTemplate) {
      const hasAddress = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      return hasAddress.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  const hasVendorAddress = useMemo(() => {
    if (selectedTemplate) {
      const hasAddress = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      return hasAddress.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  const selectedSubSupplier = useMemo(() => {
    let foundSelectedSubSupplier = null;
    if (selectedSupplierUserPosition !== null && subSupplierUsers) {
      const foundSubSupplier = subSuppliers.find(
        (item) => item.id === subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId
      );
      if (foundSubSupplier) {
        foundSelectedSubSupplier = foundSubSupplier;
      }
    }
    return foundSelectedSubSupplier;
  }, [selectedSupplierUserPosition]);

  const handleSubmitLienRelease = async (date?: Date) => {
    if (selectedSupplierUserPosition == null || !selectedMainBuyerId) {
      return;
    }
    const selectedUser = subSupplierUsers[selectedSupplierUserPosition];
    let results;
    const releaseData = {
      jobId: selectedJob?.id || '',
      notes,
      previewPdfUrl: '',
      recipientId: {
        userId: selectedUser.user.user?.id || '',
        subUserId: selectedUser.user.subUser?.id || '',
      },
      subSupplierId: selectedUser?.subSupplierId ?? '',
      templateId: selectedTemplate?.id || '',
      fieldsData: Object.values(formFieldsData),
      emailReminderFrequency: Number(emailRemainderCount),
      dueDate: dueDate || new Date(),
      isWetSignature,
      invoiceList: invoiceList
        .map((invoice: any) => ({
          ...invoice,
          amount: isNaN(parseFloat(invoice.amount)) ? null : parseFloat(invoice.amount),
        }))
        .filter((invoice) => invoice.invoiceNumber && !isNaN(invoice.amount)), // Removes empty invoices
    };
    let selectedThroughDate;
    const currentThroughDate = Object.values(formFieldsData).find(
      (formFields) => formFields.id === 'throughDate'
    );
    const pathlist = location.pathname.split('/');
    const searchParams = new URLSearchParams(location.search);
    const paramCurrentThroughDate = searchParams.get('currentThroughDate');
    if (currentThroughDate && currentThroughDate?.value) {
      selectedThroughDate = new Date(currentThroughDate.value);
    } else if (paramCurrentThroughDate && selectedTemplate?.type.startsWith('FINAL')) {
      selectedThroughDate = new Date(paramCurrentThroughDate);
    } else {
      const currentDate = moment();
      const endOfLastMonth = currentDate.endOf('month').startOf('day');
      const endOfMonthDate = endOfLastMonth.toDate();
      selectedThroughDate = new Date(endOfMonthDate);
    }
    if (date) {
      results = await dispatch(
        createLienRelease({
          mainBuyerId: selectedMainBuyerId,
          details: {
            releaseDetails: {
              ...releaseData,
              status: 'Scheduled',
              createdAt: date,
              releaseMonth: selectedThroughDate,
            },
            releasePdf: currentPdfUrl,
            attachmentsFiles: files,
            storagePathName: `buyerReleases/${selectedMainBuyerId}/${subSupplierUsers[selectedSupplierUserPosition]?.subSupplierId}/${selectedJob?.id}`,
          },
        })
      );
    } else {
      results = await dispatch(
        createLienRelease({
          mainBuyerId: selectedMainBuyerId,
          details: {
            releaseDetails: {
              ...releaseData,
              status: 'Requested',
              releaseMonth: selectedThroughDate,
            },
            releasePdf: currentPdfUrl,
            attachmentsFiles: files,
            storagePathName: `buyerReleases/${selectedMainBuyerId}/${subSupplierUsers[selectedSupplierUserPosition]?.subSupplierId}/${selectedJob?.id}`,
          },
        })
      );
    }
    if (hasCityStateZipCode || hasVendorAddress) {
      const foundAddress = selectedTemplate?.formFields.find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      const foundPhone = selectedTemplate?.formFields.find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorPhone')
      );
      await dispatch(
        updateSubSupplier({
          mainBuyerId: selectedMainBuyerId,
          subSupplierId: subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId ?? '',
          subSupplierDetails: {
            city,
            state,
            zipCode: zipCode,
            phoneNumber: foundPhone?.id ? formFieldsData?.[foundPhone?.id]?.value ?? '' : '',
            address: foundAddress?.id ? formFieldsData?.[foundAddress?.id]?.value ?? '' : '',
          },
        })
      );
      await dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
    }
    setScheduledDate(null);
    if (results.type === 'liens/createLienRelease/fulfilled') {
      if (date) {
        setOpenScheduleSuccessModal(true);
      } else {
        setIsLienReleaseCreateSuccessModalOpen(true);
      }
    } else {
      if (date) {
        setOpenScheduleErrorModal(true);
      } else {
        setIsErrorCreateLeanReleaseModalOpen(true);
      }
    }
  };

  const handleScheduleDate = async (date: Date) => {
    setScheduledDate(date);
    handleSubmitLienRelease(date);
  };

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedJob) {
        if (selectedJob.defaultThroughDate) {
          const currentDate = moment();
          const endOfLastMonth = currentDate.subtract(1, 'months').endOf('month').startOf('day');
          const endOfMonthDate = endOfLastMonth.toDate();
          const formFieldsDataKey = Object.keys(formFieldsData);
          let throughDateData: { [x: string]: ReleaseFieldData } = {};
          for (const key of Object.keys(formFieldsData)) {
            if (key.startsWith('throughDate')) {
              throughDateData = {
                ...throughDateData,
                [key]: {
                  ...formFieldsData[key],
                  value: formFieldsData?.[key]?.value
                    ? formFieldsData[key].value
                    : new Date(endOfMonthDate),
                },
              };
            }
          }
          const vendorDetails = getVendorDetails();
          setFormFieldsData({ ...formFieldsData, ...throughDateData, ...vendorDetails });
        }
        setInvoiceList([]);
      }
    }

    return () => {};
  }, [selectedJob]);

  useEffect(() => {
    populateAllPdfData();
  }, [debouncedPopulatePdfChange]);

  useEffect(() => {
    triggerDebouncedPopulatePdf();
    return () => {};
  }, [
    // selectedTemplate,
    selectedJob,
    selectedSupplierUserPosition,
    formFieldsData,
  ]);

  useEffect(() => {
    const populatedTemplateFields = populateTemplateFieldData();
    let vendorPhone: { [x: string]: ReleaseFieldData } = {};
    if (selectedSupplierUserPosition !== null) {
      const foundSubSupplier = subSuppliers.find(
        (item) => item.id === subSupplierUsers?.[selectedSupplierUserPosition]?.subSupplierId
      );
      if (foundSubSupplier) {
        setCity(foundSubSupplier?.city ?? '');
        setState(foundSubSupplier?.state ?? '');
        setZipCode(foundSubSupplier?.zipCode ? `${foundSubSupplier?.zipCode}` : '');
        setAddress(foundSubSupplier?.address ? `${foundSubSupplier?.address}` : '');
      }
      if (foundSubSupplier) {
        const foundPhoneInput = selectedTemplate?.formFields?.find(
          (formFields) =>
            formFields.fromFieldType === 'NEW_FIELD' &&
            formFields.whoPopulatesField === 'BUYER' &&
            formFields.id.startsWith('vendorPhone')
        );
        if (foundPhoneInput) {
          const updateField = populatedTemplateFields[foundPhoneInput.id];
          if (updateField) {
            vendorPhone = {
              [foundPhoneInput.id]: {
                ...updateField,
                value: foundSubSupplier?.phoneNumber ? `${foundSubSupplier?.phoneNumber}` : '',
              },
            };
          }
        }
      }
    }
    const vendorDetails = getVendorDetails();
    setFormFieldsData({
      ...populatedTemplateFields,
      ...vendorDetails,
      ...vendorPhone,
    });

    return () => {};
  }, [selectedSupplierUserPosition, flattenedPdfUrl]);

  const setupOriginalAndFlattenPDF = async () => {
    if (selectedTemplate) {
      const flat = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
      if (flat) {
        setCurrentPdfUrl(flat);
        setFlattenedPdfUrl(flat);
      } else {
        setCurrentPdfUrl(selectedTemplate?.originalPdf?.url ?? '');
        setFlattenedPdfUrl(selectedTemplate?.originalPdf?.url ?? '');
      }
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      setupOriginalAndFlattenPDF();
      setIsWetSignature(selectedTemplate?.isWetSignature ?? false);
      setSelectedTemplateFields(
        [...selectedTemplate.formFields]
          .sort((A, B) => {
            if (
              (A.position === null || A.position === undefined) &&
              B.position !== null &&
              B.position !== undefined
            ) {
              return 1;
            }
            if (
              (B.position === null || B.position === undefined) &&
              A.position !== null &&
              A.position !== undefined
            ) {
              return -1;
            }
            if (
              A.position !== null &&
              A.position !== undefined &&
              B.position !== null &&
              B.position !== undefined &&
              A.position > B?.position
            ) {
              return 1;
            }
            return -1;
          })
          .filter(
            (formFields) =>
              formFields.fromFieldType === 'NEW_FIELD' &&
              formFields.whoPopulatesField === 'BUYER' &&
              !formFields.id.startsWith('vendorCityStateZip') &&
              !formFields.id.startsWith('vendorAddress')
          )
      );
    }

    return () => {};
  }, [selectedTemplate]);

  const checkErrors = () => {
    let fields: string[] = [];

    if (selectedSupplierUserPosition === null) {
      fields = [...fields, 'supplier'];
    }
    if (!selectedJob) {
      fields = [...fields, 'job'];
    }
    if (!emailRemainderCount) {
      fields = [...fields, 'emailRemainderCount'];
    }
    if (!dueDate) {
      fields = [...fields, 'dueDate'];
    }
    if (!selectedTemplate) {
      fields = [...fields, 'template'];
    }
    if (hasVendorAddress && address === '') {
      fields = [...fields, 'address'];
    }
    if (hasCityStateZipCode && city === '') {
      fields = [...fields, 'city'];
    }
    if (hasCityStateZipCode && state === '') {
      fields = [...fields, 'state'];
    }
    if (hasCityStateZipCode && zipCode === '') {
      fields = [...fields, 'zipCode'];
    }
    if (invoiceList.filter((invoice) => !invoice.invoiceNumber && invoice.amount).length > 0) {
      fields = [...fields, 'invoiceList'];
    }

    Object.keys(formFieldsData).forEach((fieldKey) => {
      const fieldData = formFieldsData[fieldKey];
      if (
        fieldData.isRequired &&
        !fieldData.value &&
        !fieldData.id.startsWith('vendorCityStateZip') &&
        !fieldData.id.startsWith('vendorAddress')
      ) {
        fields = [...fields, fieldKey];
      }
    });
    setErrorFields(fields);
    return fields;
  };

  const handleShowSchedule = () => {
    const fieldsWithErrors = checkErrors();
    const checkMissingDetails = checkMissingJobDetails(selectedJob);
    setShowErrorAbove(fieldsWithErrors.length !== 0 || checkMissingDetails);
    setMissingJobDetails(checkMissingDetails);
    if (fieldsWithErrors.length === 0 && !checkMissingDetails) {
      setShowSchedule(!showSchedule);
    }
  };

  const handleSelectFile = (uploadedFiles: File[]) => {
    setFiles(uploadedFiles);
  };

  useEffect(() => {
    if (selectedTemplate) {
      const citystatezipcode = `${city ? `${city}, ` : ''}${state ? `${state} ` : ''}${
        zipCode ? `${zipCode} ` : ''
      }`;
      const foundTemplateInput = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      const foundAddressInput = [...selectedTemplate.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      if (foundTemplateInput || foundAddressInput) {
        const updateField = foundTemplateInput ? formFieldsData[foundTemplateInput.id] : undefined;
        const updateAddressField = foundAddressInput
          ? formFieldsData[foundAddressInput.id]
          : undefined;
        let releaseData = { ...formFieldsData };

        if (updateField) {
          releaseData = {
            ...formFieldsData,
            [updateField.id]: { ...updateField, value: citystatezipcode },
          };
        }
        if (updateAddressField) {
          releaseData = {
            ...formFieldsData,
            [updateAddressField.id]: { ...updateAddressField, value: address },
          };
        }
        setFormFieldsData(releaseData);
      }
    }
  }, [city, state, zipCode, address]);

  const handleEditJob = (e: React.MouseEvent<HTMLButtonElement>, job: Job) => {
    e.stopPropagation();
    setIsEditJob(true);
    setSelectedJob(job);
    setOpenJobModal(true);
  };

  useEffect(() => {
    if (selectedJob && jobs) {
      const updatedJob = jobs.find((item) => {
        return item.id === selectedJob.id;
      });
      if (updatedJob) {
        setSelectedJob(updatedJob);
      }
    }
    return () => {};
  }, [jobs]);

  return (
    <div className="flex flex-row h-full py-4">
      <AppNewJobModal
        open={openJobModal}
        editMode={isEditJob}
        handleClose={async () => {
          setOpenJobModal(false);
          if (selectedMainBuyerId) {
            await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
          }
        }}
        subSuppliers={subSuppliers}
        selectedJob={isEditJob ? selectedJob : null}
      />
      <AppConfirmationModal
        open={isConfirmCreateLeanReleaseModalOpen}
        modalImage={sendCircle}
        negativeButtonText="No, cancel"
        highlightedText={`${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
            : ''
        } - ${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? `${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                ''
              } ${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                ''
              }`
            : ''
        }`}
        positiveButtonText="Yes, send it"
        title="Are you sure you want to request this lien waiver?"
        subTitle="This release will be sent for signature to"
        handleClose={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
        }}
        onNegativeButtonClick={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
        }}
        onPositiveButtonClick={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
          handleSubmitLienRelease();
        }}
      />
      <AppSuccessModal
        open={isLienReleaseCreateSuccessModalOpen}
        modalImage={successChecksCircle}
        highlightedText={`${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
            : ''
        } - ${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? `${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                ''
              } ${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                ''
              }`
            : ''
        }`}
        title="Lien waiver requested!"
        subTitle="This release will be sent for signature to"
        buttonText={
          location.pathname.split('/').length === 6 ? 'View Job Details' : 'View All Lien Waivers'
        }
        handleClose={() => {
          setIsLienReleaseCreateSuccessModalOpen(false);
          navigate(
            location.pathname.split('/').length === 6
              ? `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${selectedJob?.id}`
              : `/buyerLienReleases/${selectedMainBuyerId}/overview`
          );
        }}
      />
      <AppSuccessModal
        open={isErrorCreateLeanReleaseModalOpen}
        modalImage={errorCircle}
        title="Release request failed!"
        subTitle="Your lien waiver request failed to send. Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setIsErrorCreateLeanReleaseModalOpen(false);
          handleSubmitLienRelease();
        }}
        handleClose={() => {
          setIsErrorCreateLeanReleaseModalOpen(false);
        }}
      />
      <AppScheduleModal
        open={openScheduleModal}
        handleClose={() => {
          setOpenScheduleModal(false);
          setShowSchedule(false);
        }}
        onSelectDate={handleScheduleDate}
      />
      <AppSuccessModal
        open={openScheduleSuccessModal}
        modalImage={successChecksCircle}
        highlightedText={`${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
            : ''
        } - ${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? `${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                ''
              } ${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                ''
              }`
            : ''
        }`}
        title="Release scheduled!"
        subTitle="This release will be sent for signature to"
        buttonText={
          location.pathname.split('/').length === 6 ? 'View Job Details' : 'View All Lien Waivers'
        }
        handleClose={() => {
          setOpenScheduleModal(false);
          setScheduledDate(null);
          navigate(
            location.pathname.split('/').length === 6
              ? `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${selectedJob?.id}`
              : `/buyerLienReleases/${selectedMainBuyerId}/overview`
          );
        }}
      />
      <AppSuccessModal
        open={openScheduleErrorModal}
        modalImage={errorCircle}
        title="Scheduling failed!"
        subTitle="Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setOpenScheduleErrorModal(false);
          if (scheduledDate) {
            handleScheduleDate(scheduledDate);
          }
        }}
        handleClose={() => {
          setOpenScheduleErrorModal(false);
          setScheduledDate(null);
        }}
      />
      <div className={twMerge('flex flex-col w-6/12 mx-4 h-5/6 overflow-scroll hidescrollbar')}>
        <div className={twMerge('flex flex-col  bg-white px-4 py-3 mb-3')}>
          <div className="my-2 flex justify-between">
            <div className="w-1/2">
              <div className="flex justify-end relative">
                <button
                  onClick={() => {
                    setIsEditJob(false);
                    setOpenJobModal(true);
                  }}
                  className="absolute flex items-center PRIMARY_500-BORDER border-b cursor-pointer mr-1 mb-1">
                  <Plus size={12} color={COLORS.PRIMARY_500} />
                  <div className="PRIMARY_500-CLR text-xs">Add New Job</div>
                </button>
              </div>
              <AppDropDown2
                label="Job"
                isRequired={true}
                errorText={
                  errorFields.includes('job')
                    ? 'Please select a job'
                    : `${
                        missingJobDetails
                          ? 'Missing owner or address. Please edit job in dropdown in order to submit release'
                          : ''
                      }`
                }
                value={selectedJob?.id || ''}
                items={[
                  ...jobs
                    .filter((jb) => !jb?.archivedState?.includes('BUYER_ARCHIVED'))
                    .map((job) => {
                      return {
                        label: `${job?.number ? `${job.number} - ` : ''}${job.name}`,
                        value: job.id,
                        labelInElement: (
                          <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                            <div className="text-xs">{`${job?.number ? `${job.number} - ` : ''}${
                              job.name
                            }`}</div>
                            <button
                              onClick={(e) => {
                                handleEditJob(e, job);
                              }}
                              className="text-xs px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                              Edit
                            </button>
                          </div>
                        ),
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = jobs.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedJob(uu);
                      setMissingJobDetails(false);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
              {selectedJob?.notes && (
                <div
                  data-tooltip-id={'job-notes-tooltip'}
                  className="flex INFO_200-BG py-3 px-2 items-center rounded-lg overflow-hidden w-full my-2">
                  <div className="flex font-bold items-center">
                    <Notepad color="#000000" size={20} />
                    <div className="mx-1 text-sm">Notes:</div>
                  </div>
                  <div className="truncate text-sm TEXT_SECONDARY-CLR font-medium ml-2 w">
                    {selectedJob?.notes}
                  </div>
                </div>
              )}
              <ReactTooltip
                id={'job-notes-tooltip'}
                place="top"
                variant="dark"
                opacity={'100%'}
                style={{
                  display: 'flex',
                  background: '#334155',
                  width: '300px',
                  zIndex: '70',
                  borderRadius: '8px',
                  alignItems: 'center',
                  justifyItems: 'center',
                  fontWeight: '600',
                }}>
                <span>{selectedJob?.notes}</span>
              </ReactTooltip>
            </div>
            <div className="w-1/2 ml-1">
              <AppDropDown
                label="Supplier"
                isRequired={true}
                errorText={errorFields.includes('supplier') ? 'Please select a supplier' : ''}
                value={
                  (selectedSupplierUserPosition !== null ? selectedSupplierUserPosition : '') + ''
                }
                items={[
                  ...subSupplierUsers.map((subSupplierUser, i) => {
                    const userDetails = subSupplierUser.user.subUser
                      ? subSupplierUser.user.subUser
                      : subSupplierUser.user.user;
                    return {
                      label: `${subSupplierUser.subSupplierName} - ${
                        userDetails?.firstName || ''
                      } ${userDetails?.lastName || ''} `,
                      value: i + '',
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value !== null && item?.value !== undefined) {
                    const uu = subSupplierUsers.find((val, i) => i === parseInt(item?.value));
                    if (uu) {
                      setSelectedSubSupplierUser(uu);
                      setSelectedSupplierUserPosition(parseInt(item?.value));
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
              {selectedSubSupplier?.notes && (
                <div
                  data-tooltip-id={'subsupplier-notes-tooltip'}
                  className="flex INFO_200-BG py-3 px-2 items-center rounded-lg overflow-hidden w-full my-2">
                  <div className="flex font-bold items-center">
                    <Notepad color="#000000" size={20} />
                    <div className="mx-1 text-sm">Notes:</div>
                  </div>
                  <div className="truncate text-sm TEXT_SECONDARY-CLR font-medium ml-2 w">
                    {selectedSubSupplier?.notes}
                  </div>
                </div>
              )}
              <ReactTooltip
                id={'subsupplier-notes-tooltip'}
                place="top"
                variant="dark"
                opacity={'100%'}
                style={{
                  display: 'flex',
                  background: '#334155',
                  width: '300px',
                  zIndex: '70',
                  borderRadius: '8px',
                  alignItems: 'center',
                  justifyItems: 'center',
                  fontWeight: '600',
                }}>
                <span>{selectedSubSupplier?.notes}</span>
              </ReactTooltip>
            </div>
          </div>
          <div className="my-1">
            <AppDropDown
              label="Waiver Template"
              isRequired={true}
              errorText={errorFields.includes('template') ? 'Please select template' : ''}
              value={selectedTemplate ? selectedTemplate.id : ''}
              items={[
                ...templates.map((template) => {
                  return {
                    label: template.name,
                    value: template.id,
                  };
                }),
              ]}
              onSelectChange={(item) => {
                if (item?.value) {
                  const uu = templates.find((val) => val.id === item.value);
                  if (uu) {
                    setSelectedTemplate(uu);
                    setShowErrorAbove(false);
                  }
                }
              }}
            />
          </div>
          <div className="my-1">
            <button className="flex gap-2" onClick={onShowInvoiceClick}>
              <AppCheckBox isChecked={showInvoiceListNotes} />
              <div className="  text-sm font-medium">Include Invoice List Notes</div>
            </button>
          </div>
          {showInvoiceListNotes && (
            <div>
              {subUsersInvoices.paidInvoices.length === 0 &&
              subUsersInvoices.unPaidInvoices.length === 0 ? (
                <div className="my-1">
                  <EditInvoiceList
                    invoices={invoiceList}
                    setInvoices={setInvoiceList}
                    isShowErrors={errorFields.includes('invoiceList')}
                    invoiceAmountRequired={false}
                    invoiceButtonStyle={InvoiceListButtonStyle.BOTTOM}
                  />
                </div>
              ) : (
                <div className="w-full overflow-x-scroll">
                  <AppInvoiceTable
                    invoices={subUsersInvoices}
                    onSelectInvoice={(inv) => {
                      setInvoiceList(inv);
                    }}
                    usedInvoices={usedInvoices}
                    paidInvoicesWeeksAgo={24}
                  />
                </div>
              )}
            </div>
          )}
          {hasVendorAddress && (
            <div className="w-full flex">
              <div className="w-full mb-3">
                <AppInputField
                  errorText={errorFields.includes('address') ? 'Required' : ''}
                  label="Vendor Address"
                  isRequired={true}
                  value={address}
                  inputType={'text'}
                  onTextChange={(text) => {
                    setAddress(text);
                    setShowErrorAbove(false);
                  }}
                />
              </div>
            </div>
          )}
          {hasCityStateZipCode && (
            <div className="w-full flex">
              <div className="w-full mr-2 mb-3">
                <AppInputField
                  errorText={errorFields.includes('city') ? 'Required' : ''}
                  label="Vendor City"
                  isRequired={true}
                  value={city}
                  inputType={'text'}
                  onTextChange={(text) => {
                    setCity(text);
                    setShowErrorAbove(false);
                  }}
                />
              </div>
              <div className="w-full mr-2 mb-3">
                <AppInputField
                  errorText={errorFields.includes('state') ? 'Required' : ''}
                  label="Vendor State"
                  isRequired={true}
                  value={state}
                  inputType={'text'}
                  onTextChange={(text) => {
                    setState(text);
                    setShowErrorAbove(false);
                  }}
                />
              </div>
              <div className="w-full mb-3">
                <AppInputField
                  errorText={errorFields.includes('zipCode') ? 'Required' : ''}
                  label="Vendor Zip"
                  isRequired={true}
                  value={zipCode}
                  inputType={'text'}
                  onTextChange={(text) => {
                    setZipCode(text);
                    setShowErrorAbove(false);
                  }}
                />
              </div>
            </div>
          )}
          {selectedTemplate && (
            <div className="flex flex-wrap ">
              {selectedTemplateFields &&
                selectedTemplateFields.length > 0 &&
                [...selectedTemplateFields].map((formField, i) => {
                  const {
                    id,
                    fieldType,
                    fieldName,
                    isFieldRequired,
                    suffix,
                    prefix,
                    maxValue,
                    minValue,
                  } = formField;
                  const fieldValue = formFieldsData[id]?.value || '';
                  return (
                    <div
                      key={i + 'oo'}
                      className={twMerge(
                        'w-1/2 my-2',
                        i % 2 === 0 ? 'pr-1' : 'pl-1',
                        i ===
                          selectedTemplate.formFields.filter(
                            (formFields) =>
                              formFields.fromFieldType === 'NEW_FIELD' &&
                              formFields.whoPopulatesField === 'BUYER' &&
                              !formFields.id.startsWith('vendorCityStateZip') &&
                              !formFields.id.startsWith('vendorAddress')
                          ).length -
                            1 && i % 2 === 0
                          ? 'w-full p-0'
                          : '',
                        fieldType === 'TEXT_AREA' ? 'w-full p-0' : ''
                      )}>
                      {(fieldType === 'TEXT' ||
                        fieldType === 'NUMBER' ||
                        fieldType === 'CURRENCY' ||
                        fieldType === 'TEXT_AREA') && (
                        <AppInputField
                          label={fieldName}
                          inputType={
                            fieldType === 'TEXT_AREA'
                              ? 'textarea'
                              : fieldType === 'NUMBER' || fieldType === 'CURRENCY'
                              ? 'number'
                              : 'text'
                          }
                          value={fieldValue !== null && fieldValue !== undefined ? fieldValue : ''}
                          isRequired={isFieldRequired}
                          errorText={errorFields.includes(id) ? 'Required' : ''}
                          suffix={suffix || ''}
                          prefix={prefix || ''}
                          onBlur={(e) => {
                            let val = e.target.value;
                            if (minValue && parseInt(val) <= minValue) {
                              setFormFieldsData({
                                ...formFieldsData,
                                [id]: { ...formFieldsData[id], value: minValue },
                              });
                              return;
                            }
                            if (maxValue && parseInt(val) >= maxValue) {
                              setFormFieldsData({
                                ...formFieldsData,
                                [id]: { ...formFieldsData[id], value: maxValue },
                              });
                              return;
                            }
                            if (fieldType === 'CURRENCY') {
                              if (!val) {
                                val = null;
                              } else {
                                val = Number(Number(val)).toFixed(2);
                              }
                              setFormFieldsData({
                                ...formFieldsData,
                                [id]: { ...formFieldsData[id], value: val },
                              });
                            }
                          }}
                          onTextChange={(text) => {
                            let val = text;

                            if (fieldType === 'NUMBER') {
                              if (text !== undefined && text !== null) {
                                val = text;
                              } else {
                                val = null;
                              }
                            } else if (fieldType === 'CURRENCY') {
                              if (text !== undefined && text !== null) {
                                val = text;
                              } else {
                                val = null;
                              }
                            }
                            setShowErrorAbove(false);
                            setFormFieldsData({
                              ...formFieldsData,
                              [id]: { ...formFieldsData[id], value: val },
                            });
                          }}
                        />
                      )}
                      {fieldType === 'DATE' && (
                        <AppDatePicker
                          label={fieldName}
                          selectedDate={fieldValue}
                          isRequired={isFieldRequired}
                          errorText={errorFields.includes(id) ? 'Required' : ''}
                          onDateSelected={(date) => {
                            setShowErrorAbove(false);
                            setFormFieldsData({
                              ...formFieldsData,
                              [id]: { ...formFieldsData[id], value: date },
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          )}
          <div className="my-2 flex">
            <div className="w-full ">
              <AppInputField
                label="Email Reminder Every"
                isRequired={true}
                value={emailRemainderCount}
                errorText={errorFields.includes('emailRemainderCount') ? 'Required' : ''}
                suffix="Business Days"
                inputType="number"
                onTextChange={(text) => {
                  if (text && parseInt(text) >= 3) {
                    setShowErrorAbove(false);
                    setEmailRemainderCount(parseInt(text));
                  }
                }}
              />
            </div>
            <div className="w-full ml-1">
              <AppDatePicker
                label="Due Date"
                errorText={errorFields.includes('dueDate') ? 'Due date is required' : ''}
                selectedDate={dueDate}
                isRequired={true}
                minumumDate={nextDay}
                onDateSelected={(date) => {
                  setShowErrorAbove(false);
                  if (date) {
                    setDueDate(date);
                  } else {
                    setDueDate(null);
                  }
                }}
              />
            </div>
          </div>
          <div className="my-2">
            <AppInputField
              label="Notes"
              inputType="textarea"
              value={notes}
              onTextChange={(text) => {
                setNotes(text);
              }}
            />
          </div>
          <div className="my-2">
            <div className="text-xs mb-0.5  whitespace-nowrap font-medium">Attachments</div>
            <AppFilePicker onSelectFile={handleSelectFile} />
          </div>
          <div className="my-3">
            <hr className="h-px mb-3 bg-gray-200 border-0 dark:bg-gray-200"></hr>
            <button className="flex" onClick={() => setIsWetSignature(!isWetSignature)}>
              <AppCheckBox isChecked={isWetSignature} />
              <div className="ml-2 text-sm font-medium">Require wet signature?</div>
            </button>
          </div>
        </div>
        {showErrorAbove && (
          <div className="my-2 text-xs ERROR_500-CLR text-center">Please see errors above</div>
        )}
        <div className="flex flex-row justify-end ">
          <AppButton
            text="Close"
            type="SECONDARY"
            onClick={() => {
              navigate(
                location.pathname.split('/').length === 6
                  ? `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${selectedJob?.id}`
                  : `/buyerLienReleases/${selectedMainBuyerId}/overview`
              );
            }}
          />
          <div className="flex flex-row PRIMARY_500-BG ml-4 rounded-lg justify-center align-center">
            <AppButton
              text="Submit"
              onClick={() => {
                const fieldsWithErrors = checkErrors();
                const checkMissingDetails = checkMissingJobDetails(selectedJob);
                setShowErrorAbove(fieldsWithErrors.length !== 0 || checkMissingDetails);
                setMissingJobDetails(checkMissingDetails);
                if (fieldsWithErrors.length === 0 && !checkMissingDetails) {
                  setIsConfirmCreateLeanReleaseModalOpen(true);
                }
              }}
            />
            <div className="bg-white h-4/6 w-px mt-1.5" />
            <AppButton
              buttonStyles={{ width: '5px' }}
              text=""
              onClick={handleShowSchedule}
              icon={<ChevronDown size={15} color={COLORS.WHITE} />}
            />
          </div>
        </div>
        {showSchedule && (
          <div className="flex w-full justify-end">
            <div className="bg-white w-64 mt-2 rounded-lg px-6 py-5 border" ref={dropdownRef}>
              <div className="mb-2.5 uppercase text-xs font-normal tracking-widest TEXT_SECONDARY-CLR">
                Schedule Request
              </div>
              <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200 w-full"></hr>
              <button
                onClick={() => {
                  setOpenScheduleModal(true);
                  setShowSchedule(false);
                }}
                className="text-base font-semibold TEXT_PRIMARY-CLR ">
                Custom time
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col w-7/12 h-full">
        <div className="h-5/6 relative  overflow-scroll flex flex-col items-center pb-3">
          <div
            style={{
              width: '612px',
              background: '#F4F7FA',
            }}
            className="sticky top-0  z-10 flex justify-between  mb-0.5"></div>

          <div
            style={{
              width: '612px',
              height: '792px',
            }}
            className="relative ">
            {currentPdfUrl && (
              <Viewer
                defaultScale={1}
                fileUrl={currentPdfUrl}
                plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                initialPage={currentPage}
                onPageChange={(e) => setCurrentPage(e.currentPage)}
              />
            )}
          </div>
        </div>

        <div className="flex flex-row justify-center">
          <div className="mx-4">
            <CurrentPageLabel>
              {(props: RenderCurrentPageLabelProps) => (
                <span>{`page ${props.currentPage + 1} / ${props.numberOfPages}`}</span>
              )}
            </CurrentPageLabel>
          </div>

          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />
        </div>
      </div>
    </div>
  );
};

export default BuyerAddLienRelease;
