import React, { useEffect, useState, useRef } from 'react';
import './AppLoadingProgress.scss';

interface CircularProgressProps {
  progressPercentage: number;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ progressPercentage }) => {
  const radius = 20;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progressPercentage / 100) * circumference;

  return (
    <div className="relative circular-progress-svg-container">
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#f2f2f2"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="rgb(21, 129, 244)"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{
            transition: 'all 0.3s',
            strokeDashoffset,
            backfaceVisibility: 'hidden',
            boxShadow: '0 0 1px rgba(255,255,255,0)',
          }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};

const getRandomNum = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

interface CircularProgressContainerProps {
  loading: boolean;
  loaded: boolean;
  progressPercentage: number;
}

const CircularProgressContainer: React.FC<CircularProgressContainerProps> = ({
  loading,
  loaded,
  progressPercentage,
}) => {
  const intervalIdRef = useRef<number | undefined>();

  useEffect(() => {
    if (!loading && !loaded) {
      clearInterval(intervalIdRef.current);
    }

    if (loaded) {
      clearInterval(intervalIdRef.current);
    } else if (loading) {
      intervalIdRef.current = window.setInterval(() => {
        const nextProgressPercentage = progressPercentage;
        if (nextProgressPercentage <= 100 && nextProgressPercentage > progressPercentage) {
        } else {
          clearInterval(intervalIdRef.current);
        }
      }, 1000);
    } else {
      clearInterval(intervalIdRef.current);
    }

    return () => clearInterval(intervalIdRef.current);
  }, [loaded, progressPercentage, loading]);

  return (
    <div className="circular-progress-container">
      <CircularProgress progressPercentage={progressPercentage} />
    </div>
  );
};

interface AppLoadingProgressProps {
  text?: string;
  currentCount: number;
  totalCount: number;
  showTotalLoading: boolean;
}

export const AppLoadingProgress: React.FC<AppLoadingProgressProps> = ({
  text,
  currentCount,
  totalCount,
  showTotalLoading = false,
}) => {
  const [loading, updateLoading] = useState(true);
  const [loaded, updateLoaded] = useState(false);
  let progress = 0;
  useEffect(() => {
    if (currentCount >= totalCount && totalCount > 0) {
      updateLoading(false);
      updateLoaded(true);
    } else {
      updateLoading(true);
      updateLoaded(false);
    }
  }, [currentCount, totalCount]);

  if (currentCount && totalCount) {
    progress = (currentCount / totalCount) * 100;
  }

  return (
    <div className="flex items-center">
      {loading && totalCount > 0 && (
        <div className="flex items-center">
          <div style={{ position: 'relative' }}>
            <CircularProgressContainer
              loading={loading}
              loaded={loaded}
              progressPercentage={progress}
            />
          </div>
          <div className="text-xs">{`${currentCount}/${totalCount} ${text}${
            totalCount > 0 ? 's' : ''
          }`}</div>
        </div>
      )}
      {!loading && totalCount > 0 && (
        <div>
          {showTotalLoading ? (
            <div className="flex items-center text-xs">
              <span className="font-semibold ">{totalCount}</span>
              <span className="ml-1">{`${text}${totalCount > 0 ? 's' : ''}`}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
