import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../utils/colors';
import { twMerge } from 'tailwind-merge';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { QuickbooksInvoice, formatMoney } from '@dill/dill-shared/dist/src';
import emptyListImg from '../../../assets/images/emptyList.png';
import moment from 'moment';
import { InvoiceReference } from '../../../constants/invoice';

export const AppInvoiceTable = ({
  invoices,
  onSelectInvoice,
  invoicesSelected,
  paidInvoicesWeeksAgo,
  tableStyle = {},
  usedInvoices,
}: {
  invoices: {
    paidInvoices: QuickbooksInvoice[];
    unPaidInvoices: QuickbooksInvoice[];
  };
  onSelectInvoice: (invoices: InvoiceReference[]) => void;
  invoicesSelected?: QuickbooksInvoice[];
  paidInvoicesWeeksAgo?: number;
  tableStyle?: React.CSSProperties;
  usedInvoices?: { invoiceNumber: string; type: string; date: string }[];
}) => {
  const [selectedInvoices, setSelectedInvoices] = useState<QuickbooksInvoice[]>([]);

  const columns = useMemo(() => {
    if (usedInvoices && usedInvoices.length > 0) {
      return [
        { id: 'select', name: '', width: 50, cellType: 'VIEW_TEXT' },
        { id: 'invoiceDate', name: 'Ship Date/ Invoice Date', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'invoiceNumber', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'comment', name: 'Comment', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'status', name: 'Status', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'alreadyOn', name: 'Already On', width: 180, cellType: 'VIEW_TEXT' },
      ];
    }
    return [
      { id: 'select', name: '', width: 50, cellType: 'VIEW_TEXT' },
      { id: 'invoiceDate', name: 'Ship Date/ Invoice Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'invoiceNumber', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'comment', name: 'Comment', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, [usedInvoices]);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const allInvoices = useMemo(() => {
    if (!invoicesSelected) {
      setSelectedInvoices([]);
    }
    return [...invoices.paidInvoices, ...invoices.unPaidInvoices].sort((a, b) => {
      const dateA = a?.shipDate
        ? new Date(a?.shipDate)
        : a?.createdAt
        ? new Date(a.createdAt)
        : null;
      const dateB = b?.shipDate
        ? new Date(b?.shipDate)
        : b?.createdAt
        ? new Date(b.createdAt)
        : null;

      if (dateA && dateB) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [invoices]);

  const totalAmount = useMemo(() => {
    let accumulatedAmount = 0;
    if (selectedInvoices.length > 0) {
      selectedInvoices.forEach((invoice) => {
        if (invoice.amount) {
          accumulatedAmount += invoice.amount;
        }
      });
    }
    return accumulatedAmount;
  }, [selectedInvoices]);

  useEffect(() => {
    if (invoicesSelected) {
      setSelectedInvoices(invoicesSelected);
    }
  }, [invoicesSelected]);

  useEffect(() => {
    const invoicesUpdate = selectedInvoices.map((selInv) => {
      return {
        invoiceNumber: selInv.docNumber,
        amount: Number(selInv.amount),
      };
    });
    onSelectInvoice(invoicesUpdate);
  }, [selectedInvoices]);

  return (
    <div className="min-w-[500px]">
      <div className="text-xs px-2 py-1">{`Paid invoices are shown only up to ${paidInvoicesWeeksAgo} weeks ago`}</div>
      <div className="border rounded">
        <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center z-1 ">
          {columns.map((column, i) => {
            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className={twMerge('flex py-3 mx-0.5')}>
                {column.id === 'select' && (
                  <AppCheckBox
                    isChecked={
                      allInvoices.length > 0 && selectedInvoices.length === allInvoices.length
                    }
                    onClick={(isChecked: boolean) => {
                      if (isChecked) {
                        setSelectedInvoices(allInvoices);
                      } else {
                        setSelectedInvoices([]);
                      }
                    }}
                  />
                )}

                {column.id !== 'select' && column.id !== 'actions' ? (
                  <div
                    className={`flex align-center ${
                      column.id === 'alreadyOn' ? 'justify-center w-full' : ''
                    }`}>
                    <p className="text-xs TEXT_SECONDARY-CLR mr-2">{column.name}</p>
                  </div>
                ) : (
                  <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
                )}
              </div>
            );
          })}
        </div>
        {allInvoices.length === 0 ? (
          <div className="w-full flex items-center justify-center max-h-[500px]" style={tableStyle}>
            <div className="flex flex-col items-center mb-2">
              <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
              <p>{`No Paid or Unpaid Invoices`}</p>
            </div>
          </div>
        ) : (
          <div>
            <div className="max-h-[500px] overflow-scroll" style={tableStyle}>
              {allInvoices.length > 0 &&
                allInvoices.map((filteredInvoice, index) => {
                  const invoice = usedInvoices
                    ? usedInvoices.find((inv) => inv.invoiceNumber === filteredInvoice.docNumber)
                    : null;
                  return (
                    <div
                      key={index + 'oo'}
                      className="w-full flex items-center  px-4 justify-between border-b border-b-gray-200">
                      {columns.map((column, i) => {
                        return (
                          <div
                            key={column.id}
                            style={{ flex: column.width / totalWidth }}
                            className={`flex py-3 mx-0.5 ${
                              columns.length - 1 !== i ? 'overflow-hidden' : ''
                            }`}>
                            {column.id === 'select' && (
                              <AppCheckBox
                                isChecked={selectedInvoices.some(
                                  (obj) => obj.id === filteredInvoice.id
                                )}
                                onClick={(isChecked: boolean) => {
                                  if (isChecked) {
                                    const combInvoices = [...selectedInvoices];
                                    combInvoices.push(filteredInvoice);
                                    setSelectedInvoices(combInvoices);
                                  } else {
                                    const combInvoices = [...selectedInvoices].filter(
                                      (inv) => inv.id !== filteredInvoice.id
                                    );
                                    setSelectedInvoices(combInvoices);
                                  }
                                }}
                              />
                            )}
                            {column.id === 'invoiceDate' && (
                              <p className="capitalize w-full text-xs">
                                {filteredInvoice?.shipDate
                                  ? moment(filteredInvoice?.shipDate).utc().format('MM/DD/YYYY')
                                  : filteredInvoice.createdAt
                                  ? moment(filteredInvoice.createdAt).utc().format('MM/DD/YYYY')
                                  : ''}
                              </p>
                            )}
                            {column.id === 'invoiceNumber' && (
                              <p className="capitalize w-full text-xs">
                                {filteredInvoice.docNumber ? `${filteredInvoice.docNumber}` : ''}
                              </p>
                            )}
                            {column.id === 'amount' && (
                              <div className="flex w-full flex-col">
                                <p className="w-full text-xs">{`${
                                  filteredInvoice.amount
                                    ? formatMoney(filteredInvoice.amount ?? '', 2)
                                    : ''
                                }`}</p>
                              </div>
                            )}
                            {column.id === 'status' && (
                              <div
                                className={`${
                                  filteredInvoice.status === 'PAID'
                                    ? 'px-2 py-1 capitalize text-xs SUCCESS_500-CLR SUCCESS_100-BG rounded-full'
                                    : 'px-2 py-1 capitalize text-xs ERROR_500-CLR ERROR_100-BG rounded-full'
                                }`}>
                                {filteredInvoice.status === 'PAID' ? 'Paid' : 'Unpaid'}
                              </div>
                            )}
                            {column.id === 'comment' && (
                              <div className="text-xs">{filteredInvoice.note}</div>
                            )}
                            {column.id === 'alreadyOn' && invoice && (
                              <div className="flex items-center rounded-lg px-2 py-1 PRIMARY_100-BG">
                                <p className="text-[11px] w-full PRIMARY_500-CLR capitalize font-semibold text-xs">
                                  {`${invoice.type.split('_').join(' ').toLowerCase()} ${
                                    invoice.date ? `| ${invoice.date}` : ''
                                  }`}
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
            {allInvoices.length > 0 && (
              <div className="flex p-2 border-t flex-row-reverse text-xs">
                <div>{`Total:  ${formatMoney(totalAmount ?? '', 2)}`}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
