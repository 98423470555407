import { Viewer } from '@react-pdf-viewer/core';
import {
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { AppButton } from '../../../general/AppButton/AppButton';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import AppDatePicker from '../../../general/AppDatePicker/AppDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { SupplierJobApiResponse } from '@dill/dill-shared';
import { AppConfirmationModal } from '../../../general/AppConfirmationModal/AppConfirmationModal';
import successChecksCircle from '../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../assets/svg/error_circle.svg';
import sendCircle from '../../../../assets/svg/send_circle.svg';
import { AppSuccessModal } from '../../../general/AppSuccessModal/AppSuccessModal';
import { useNavigate } from 'react-router-dom';
import { Upload } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { FileArrowUp } from 'phosphor-react';
import { openDillToast } from '../../../../utils/helpers';
import { AppDropDown2 } from '../../../general/AppDropDown2/AppDropDown2';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import { getSupplierJobs } from '../../../../redux/services/supplierJobsService';
import { manualCreatePreliminaryNotice } from '../../../../redux/services/preliminaryNoticesService';

const AddManualPrelimNotice = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { supplierJobsNoInvoicesMap } = useAppSelector((globalState) => globalState.supplierJobs);

  const [selectedJob, setSelectedJob] = useState<SupplierJobApiResponse | null>(null);
  const [selectedPdfFile, setSelectedPdfFile] = useState<{ name: string; url: string } | null>(
    null
  );
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [isConfirmCreateLeanReleaseModalOpen, setIsConfirmCreateLeanReleaseModalOpen] =
    useState(false);
  const [isErrorCreatePreliminaryNoticeModalOpen, setIsErrorCreatePreliminaryNoticeModalOpen] =
    useState(false);
  const [isPreliminaryNoticeCreateSuccessModalOpen, setIsPreliminaryNoticeCreateSuccessModalOpen] =
    useState(false);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const [createdAt, setCreatedAt] = useState<Date | null | undefined>();
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [missingJobDetails, setMissingJobDetails] = useState(false);
  const [openEditJob, setOpenEditJob] = useState(false);
  const [dataFullyLoaded, setDataFullyLoaded] = useState<boolean>(false);
  const [missingJobDetailsList, setMissingJobDetailsList] = useState<string[]>([]);

  const handleSubmitPreliminaryNotice = async () => {
    if (!selectedPdfFile) {
      openDillToast({ message: 'Please select a file', type: 'ERROR' });
      return;
    }

    const preliminaryNoticeData = {
      supplierId: user?.userSupplier?.id ?? '',
      jobId: selectedJob?.id ?? '',
      templateId: '',
      ownerStatus: null,
      contractorStatus: null,
      lenderStatus: null,
      status: 'completed',
      fieldsData: [],
      pdf: { url: '', name: selectedPdfFile?.name ?? '' },
    };
    const results = await dispatch(
      manualCreatePreliminaryNotice({
        preliminaryNoticeDetails: {
          ...preliminaryNoticeData,
        },
        preliminaryNoticePdf: selectedPdfFile.url ?? '',
        createdAt: createdAt ?? null,
      })
    );

    if (results.type === 'preliminaryNotices/manualCreatePreliminaryNotice/fulfilled') {
      setIsPreliminaryNoticeCreateSuccessModalOpen(true);
    } else {
      setIsErrorCreatePreliminaryNoticeModalOpen(true);
    }
  };

  useEffect(() => {
    const pathSplit = location.pathname.split('/');
    if (pathSplit.length === 4 && pathSplit[3] === 'manualAddPreliminaryNotice') {
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('jobId');

      if (jobId) {
        const foundJob = supplierJobsNoInvoicesMap[jobId];
        if (foundJob && !selectedJob) {
          setSelectedJob(foundJob);
        }
      }
    }
    return () => {};
  }, [location.pathname, supplierJobsNoInvoicesMap]);

  const checkErrors = () => {
    let fields: string[] = [];

    if (!selectedPdfFile) {
      fields = [...fields, 'selectFile'];
    }
    if (!createdAt) {
      fields = [...fields, 'createdAt'];
    }
    if (!selectedJob) {
      fields = [...fields, 'job'];
    }

    setErrorFields(fields);
    return fields;
  };

  const handleEditJob = (e: React.MouseEvent<HTMLButtonElement>, job: SupplierJobApiResponse) => {
    e.stopPropagation();
    setSelectedJob(job);
    setOpenEditJob(true);
  };
  const recursiveFetchSupplierJob = async ({
    lastDockId,
    fetchJobs,
  }: {
    lastDockId: string;
    fetchJobs: SupplierJobApiResponse[];
  }) => {
    const pageSize = 100;
    let currentPreviousLastDocumentId = lastDockId;
    setDataFullyLoaded(false);

    const results = await dispatch(
      getSupplierJobs({
        includeInvoices: false,
        includePreLiens: false,
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortOrder: 'asc',
        sortBy: 'name',
        isHideLoadingIndicator: true,
        isOnlyArchivedJobs: false,
      })
    );
    if (results.type === 'supplierJobs/getSupplierJobs/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
        const lastDocumentId = payload.lastDocumentId;
        if (
          lastDocumentId === currentPreviousLastDocumentId ||
          fetchedSupplierJobs.length === pageSize
        ) {
          currentPreviousLastDocumentId = lastDocumentId;
          await recursiveFetchSupplierJob({
            lastDockId: lastDocumentId,
            fetchJobs: [...fetchJobs, ...fetchedSupplierJobs],
          });
        } else {
          console.log('No more pages to fetch');
          setDataFullyLoaded(true);
        }
      }
    } else {
      console.log('Fetch failed or interrupted');
      setDataFullyLoaded(true);
    }
  };

  const fetchData = async () => {
    recursiveFetchSupplierJob({ lastDockId: '', fetchJobs: [] });
  };

  useEffect(() => {
    fetchData();

    return () => {};
  }, []);

  return (
    <div className="flex flex-row h-full py-4 justify-between px-4">
      <AppNewSupplierJobModal
        open={openEditJob}
        editMode={true}
        selectedSupplierJob={selectedJob}
        handleClose={({ wasBuyerEdited }) => {
          setOpenEditJob(false);
          if (wasBuyerEdited) {
            recursiveFetchSupplierJob({ lastDockId: '', fetchJobs: [] });
          }
        }}
      />
      <AppConfirmationModal
        open={isConfirmCreateLeanReleaseModalOpen}
        modalImage={sendCircle}
        negativeButtonText="No, cancel"
        highlightedText={``}
        positiveButtonText="Yes, add it"
        title="Are you sure you want to manually add this preliminary notice?"
        subTitle="This will be added as a completed preliminary notice."
        handleClose={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
        }}
        onNegativeButtonClick={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
        }}
        onPositiveButtonClick={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
          handleSubmitPreliminaryNotice();
        }}
      />
      <AppSuccessModal
        open={isPreliminaryNoticeCreateSuccessModalOpen}
        modalImage={successChecksCircle}
        highlightedText={``}
        title="Preliminary Notice added!"
        subTitle="This has been added as a completed preliminary notice."
        buttonText={'View All Preliminary Notices'}
        handleClose={() => {
          setIsPreliminaryNoticeCreateSuccessModalOpen(false);
          navigate('/preliminaryNotices/overview');
        }}
      />
      <AppSuccessModal
        open={isErrorCreatePreliminaryNoticeModalOpen}
        modalImage={errorCircle}
        title="Adding your preliminary notice failed!"
        subTitle="Failed to add your preliminary notice. Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setIsErrorCreatePreliminaryNoticeModalOpen(false);
          handleSubmitPreliminaryNotice();
        }}
        handleClose={() => {
          setIsErrorCreatePreliminaryNoticeModalOpen(false);
        }}
      />

      <div className="w-full flex justify-between bg-white px-3 py-4">
        <div className={twMerge('flex flex-col w-5/12  h-5/6 overflow-scroll hidescrollbar pr-3')}>
          <div className={twMerge('flex flex-col  bg-white px-4 py-3 mb-3')}>
            <div
              className={twMerge(
                'flex flex-col items-center justify-center PRIMARY_50-BG py-2 ',
                errorFields.includes('selectFile') ? 'border border-red-600' : ''
              )}>
              <div className="h-12 w-12 my-1 rounded-full flex flex-col items-center justify-center PRIMARY_100-BG ">
                <FileArrowUp size={30} color={COLORS.PRIMARY_500} />
              </div>
              <div className="relative my-0.5 ">
                <input
                  id="upload"
                  className="absolute w-full h-full top-0 bottom-0 opacity-0 cursor-pointer"
                  type="file"
                  accept="application/pdf"
                  onChange={(event) => {
                    if (!event?.target.files) {
                      return;
                    }
                    const file = event?.target?.files[0];

                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String: any = reader.result;
                        setSelectedPdfFile({ url: base64String, name: file.name });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  // onClick={(event) => {}}
                />
                <AppButton
                  type="SECONDARY"
                  text="Upload Document"
                  buttonStyles={{
                    // padding: '3px 12px',
                    // borderRadius: '5px',
                    height: '36px',
                    cursor: 'pointer',
                  }}
                  icon={<Upload size={20}></Upload>}
                />
              </div>
              <p className="text-xs text-gray-500">*Accepted File .pdf</p>
              {selectedPdfFile && (
                <p className="text-sm text-center mt-4 mb-1">{selectedPdfFile.name}</p>
              )}
            </div>
            <div className="my-2 w-full">
              <AppDropDown2
                label="Job"
                isRequired={true}
                errorText={
                  errorFields.includes('job')
                    ? 'Please select a job'
                    : `${
                        missingJobDetails
                          ? `Job is missing key information like ${missingJobDetailsList.join(
                              ', '
                            )}. Please update the job in the dropdown above.`
                          : ''
                      }`
                }
                value={selectedJob?.id || ''}
                isLoading={!dataFullyLoaded}
                items={[
                  ...Object.values(supplierJobsNoInvoicesMap)
                    .filter((jb) => !jb?.isArchived)
                    .map((job) => {
                      return {
                        label: `${job?.jobNumber ? `${job.jobNumber} - ` : ''}${job.name}`,
                        value: job.id,
                        labelInElement: (
                          <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                            <div className="text-xs">{`${
                              job?.jobNumber ? `${job.jobNumber} - ` : ''
                            }${job.name}`}</div>
                            <button
                              onClick={(e) => {
                                handleEditJob(e, job);
                              }}
                              className="text-xs px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                              Edit
                            </button>
                          </div>
                        ),
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = supplierJobsNoInvoicesMap[item.value];
                    if (uu) {
                      setSelectedJob(uu);
                      setMissingJobDetails(false);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
            </div>
            <div className="my-2 w-full">
              <AppDatePicker
                label="Created Date"
                isRequired={true}
                selectedDate={createdAt}
                errorText={errorFields.includes('createdAt') ? 'Required' : ''}
                onDateSelected={(date) => {
                  setCreatedAt(date);
                }}
              />
            </div>
            <div className="flex flex-row justify-end bg-white">
              <AppButton
                text="Close"
                type="SECONDARY"
                onClick={() => {
                  navigate('/preliminaryNotices/overview');
                }}
              />
              <div className="flex flex-row PRIMARY_500-BG ml-4 rounded-lg justify-center align-center">
                <AppButton
                  text="Submit"
                  onClick={() => {
                    const fieldsWithErrors = checkErrors();

                    if (fieldsWithErrors.length === 0) {
                      setIsConfirmCreateLeanReleaseModalOpen(true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-7/12 h-full PRIMARY_50-BG px-3 py-3 rounded-2xl">
          <div className="h-5/6 relative  overflow-scroll flex flex-col items-center pb-3">
            <div
              style={{
                width: '612px',
                background: '#F4F7FA',
              }}
              className="sticky top-0  z-10 flex justify-between  mb-0.5"></div>

            <div
              style={{
                width: '612px',
                height: '792px',
              }}
              className="relative flex justify-center">
              {selectedPdfFile && (
                <Viewer
                  defaultScale={1}
                  fileUrl={selectedPdfFile.url}
                  plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                />
              )}
              {!selectedPdfFile && <p className="text-sm self-center ">No file selected</p>}
            </div>
          </div>

          <div className="flex flex-row justify-center">
            <div className="mx-4">
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <span>{`page ${props.currentPage + 1} / ${props.numberOfPages}`}</span>
                )}
              </CurrentPageLabel>
            </div>

            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddManualPrelimNotice;
