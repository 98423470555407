import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLORS } from '../../../../utils/colors';
import { AppButton } from '../../../general/AppButton/AppButton';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { Eye, Edit3 } from 'react-feather';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import moment from 'moment';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import { twMerge } from 'tailwind-merge';
import {
  CaretLeft,
  CaretRight,
  ArrowsDownUp,
  SortDescending,
  SortAscending,
  Trash,
  UploadSimple,
  Truck,
} from 'phosphor-react';
import { AppPdfPreviewModal } from '../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import { PreliminaryNoticeApiResponse } from '@dill/dill-shared';
import ReactPaginate from 'react-paginate';
import { downloadFile, disablePDFFields, flattenPDF } from '../../../../utils/helpers';
import { getSupplierPreliminaryNotices } from '../../../../redux/services/preliminaryNoticesService';
import { AppTrackPreliminaryNoticeModal } from '../../../general/AppTrackPreliminaryNoticeModal/AppTrackPreliminaryNoticeModal';
import { AppImagePreviewModal } from '../../../general/AppImagePreviewModal/AppImagePreviewModal';
import AppCustomDropdown from '../../../general/AppCustomDropdown/AppCustomDropdown';

const PreliminaryNoticesTable = ({
  searchedPreliminaryNotices,
  refresh,
}: {
  searchedPreliminaryNotices: PreliminaryNoticeApiResponse[];
  refresh: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { preliminaryNotices } = useAppSelector((state) => state.preliminaryNotices);
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [showTrackNoticeModal, setShowTrackNoticeModal] = useState(false);
  const [selectedPreliminaryNotice, setSelectedPreliminaryNotice] =
    useState<PreliminaryNoticeApiResponse | null>(null);
  const [openDropdown, setOpenDropDown] = useState('');

  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState(false);
  const [sortedPreliminaryNotices, setSortedPreliminaryNotices] = useState<
    PreliminaryNoticeApiResponse[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [previewAttachment, setPreviewAttachment] = useState(false);

  // Pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20; // Number of items per page
  const pageCount = Math.ceil(searchedPreliminaryNotices.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = sortedPreliminaryNotices.slice(itemOffset, endOffset);
  const currentDate = new Date();
  const endOfMonthCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const startOfMonthThreeMonthsAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    1
  );
  const utcOffset = moment().utcOffset();
  const hours = Math.floor(utcOffset / 60);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % searchedPreliminaryNotices.length;
    setItemOffset(newOffset);
  };
  const cummulativeOffset = useMemo(() => {
    let result = 0;
    const currentTotalItems = endOffset / itemsPerPage;
    if (pageCount === currentTotalItems) {
      result = searchedPreliminaryNotices.length;
    } else if (searchedPreliminaryNotices.length === 0) {
      result = 0;
    } else {
      const itemsTotal = currentTotalItems * itemsPerPage;
      result = itemsTotal;
    }
    return result;
  }, [endOffset, searchedPreliminaryNotices]);

  useEffect(() => {
    setSortedPreliminaryNotices(searchedPreliminaryNotices);
    setItemOffset(0);
    setCurrentPage(1);
  }, [searchedPreliminaryNotices]);

  useEffect(() => {
    dispatch(getSupplierPreliminaryNotices());

    return () => {};
  }, [refresh]);

  const fetchPdf = async (url: string) => {
    const res = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    if (res.data) {
      return res.data;
    }
    return null;
  };

  const columns = useMemo(() => {
    return [
      { id: 'submittedOn', name: 'Submitted Date', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'job', name: 'Job', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'customer', name: 'Customer', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'gc', name: 'GC', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'owner', name: 'Owner', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'lender', name: 'Lender', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 170, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const handleDownloadSelectedPdf = async (preliminaryNotice: PreliminaryNoticeApiResponse) => {
    if (preliminaryNotice?.pdf?.url) {
      const flattenedPDF = await flattenPDF(preliminaryNotice?.pdf?.url);
      if (flattenedPDF) {
        const createdAtTimeStamp = preliminaryNotice?.createdAt
          ? new Date(preliminaryNotice.createdAt).getTime()
          : new Date().getTime();
        downloadFile(
          flattenedPDF || '',
          `${preliminaryNotice.job?.name ?? ''}-${
            preliminaryNotice.job?.buyer?.name ?? ''
          }-${createdAtTimeStamp}.pdf`
        );
      }
    }
  };
  const handlePreviewPdf = async (preliminaryNotice: PreliminaryNoticeApiResponse) => {
    setShowPreviewPdf(true);
  };

  const sortByProperty = (
    sortType: 'asc' | 'desc',
    sortKey: string,
    getter: (item: any) => any,
    compare?: (a: any, b: any) => number
  ) => {
    const filteredReleases = searchedPreliminaryNotices.filter((preliminaryNotice) => {
      const value = getter(preliminaryNotice);
      return value !== undefined && value !== null;
    });

    const sortedReleasesCopy = [...filteredReleases];
    sortedReleasesCopy.sort((a, b) => {
      const valueA = getter(a);
      const valueB = getter(b);

      if (compare) {
        return compare(valueA, valueB) * (sortType === 'asc' ? 1 : -1);
      } else {
        const stringA = String(valueA).toUpperCase();
        const stringB = String(valueB).toUpperCase();

        if (stringA < stringB) {
          return sortType === 'asc' ? -1 : 1;
        } else if (stringA > stringB) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }
    });

    const undefinedOrNullReleases = searchedPreliminaryNotices.filter((preliminaryNotice) => {
      const value = getter(preliminaryNotice);
      return value === undefined || value === null;
    });

    const sortedReleases = sortedReleasesCopy.concat(undefinedOrNullReleases);

    setSortedPreliminaryNotices(sortedReleases);
  };

  useEffect(() => {
    if (location.pathname.startsWith('/preliminaryNotices/overview')) {
      const params = new URLSearchParams(location.search);
      const preliminaryNoticeId = params.get('preliminaryNoticeId');
      if (preliminaryNoticeId) {
        const preliminaryNotice = searchedPreliminaryNotices.find(
          (prelien) => prelien.id === preliminaryNoticeId
        );
        if (preliminaryNotice) {
          setSelectedPreliminaryNotice(preliminaryNotice);
          handlePreviewPdf(preliminaryNotice);
        }
      }
    }
  }, [location.pathname, searchedPreliminaryNotices]);

  const handleSort = (value: string) => {
    if (searchedPreliminaryNotices) {
      if (sort === value) {
        if (sorted) {
          setSortedPreliminaryNotices(searchedPreliminaryNotices);
          setSort('');
          setSorted(false);
        } else {
          if (value === 'submittedOn') {
            sortByProperty('desc', value, (preliminaryNotice) => preliminaryNotice.createdAt);
          } else if (value === 'customer') {
            sortByProperty(
              'desc',
              value,
              (preliminaryNotice) => preliminaryNotice.job?.buyer?.name
            );
          } else if (value === 'owner') {
            sortByProperty('desc', value, (preliminaryNotice) => preliminaryNotice?.job?.owner);
          } else if (value === 'lender') {
            sortByProperty('desc', value, (preliminaryNotice) => preliminaryNotice?.job?.lender);
          } else if (value === 'job') {
            sortByProperty(
              'desc',
              value,
              (preliminaryNotice) => preliminaryNotice.job?.jobNumber ?? 0,
              (a, b) => a - b
            );
          }

          setSorted(true);
        }
      } else {
        if (value === 'submittedOn') {
          sortByProperty('asc', value, (preliminaryNotice) => preliminaryNotice.createdAt);
        } else if (value === 'customer') {
          sortByProperty('asc', value, (preliminaryNotice) => preliminaryNotice.job?.buyer?.name);
        } else if (value === 'owner') {
          sortByProperty('asc', value, (preliminaryNotice) => preliminaryNotice?.job?.owner);
        } else if (value === 'lender') {
          sortByProperty('asc', value, (preliminaryNotice) => preliminaryNotice?.job?.lender);
        } else if (value === 'job') {
          sortByProperty(
            'asc',
            value,
            (preliminaryNotice) => preliminaryNotice.job?.jobNumber ?? 0,
            (a, b) => a - b
          );
        }
        setSort(value);
        setSorted(false);
      }
    }
  };

  const handlePreviewAttachmentPDF = (url: string, fileType?: string) => {
    if (fileType && fileType !== 'pdf') {
      setAttachmentUrl(url);
      setShowPreviewImage(true);
    } else {
      setAttachmentUrl(url);
      setPreviewAttachment(true);
      setShowPreviewPdf(true);
    }
  };

  const handleEditPreliminaryNotice = (preliminaryNoticeId: string) => {
    navigate(`/preliminaryNotices/overview/editPreliminaryNotice/${preliminaryNoticeId}`);
  };

  return (
    <div className="w-full h-[calc(100vh-210px)] flex flex-col">
      <div className="w-full h-full flex flex-col border rounded-lg  overflow-y-scroll mt-4">
        <AppTrackPreliminaryNoticeModal
          open={showTrackNoticeModal}
          handleClose={() => {
            setShowTrackNoticeModal(false);
            setPreviewAttachment(false);
          }}
          preliminaryNotice={selectedPreliminaryNotice}
          onPreviewAttachment={handlePreviewAttachmentPDF}
        />
        <AppImagePreviewModal
          open={showPreviewImage}
          handleClose={() => {
            setShowPreviewImage(false);
          }}
          title="Return Attachment"
          url={attachmentUrl}
        />
        <AppPdfPreviewModal
          open={showPreviewPdf}
          handleClose={() => {
            setShowPreviewPdf(false);
            setAttachmentUrl('');
            if (location.pathname.startsWith('/preliminaryNotices/overview')) {
              const params = new URLSearchParams(location.search);
              const preliminaryNoticeId = params.get('preliminaryNoticeId');
              if (preliminaryNoticeId) {
                navigate('/preliminaryNotices/overview');
              }
            }
          }}
          invoice={''}
          pdfType="PRELIMINARY_NOTICE"
          pdfList={[
            {
              name: previewAttachment ? 'Return Attachment' : 'Preliminary Notice',
              url: previewAttachment ? attachmentUrl : selectedPreliminaryNotice?.pdf?.url || '',
              date: new Date(),
            },
          ]}
          handleDownloadPdf={() => {
            if (selectedPreliminaryNotice) {
              handleDownloadSelectedPdf(selectedPreliminaryNotice);
            }
          }}
          isSigned={false}
          mode="Supplier"
        />
        <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center z-10">
          {columns.map((column, i) => {
            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className={twMerge('flex py-3 mx-1', 'items-center')}>
                {column.id !== 'status' && column.id !== 'actions' ? (
                  <div onClick={() => handleSort(column.id)} className="cursor-pointer flex">
                    <p className="text-xs TEXT_SECONDARY-CLR mr-2">{column.name}</p>
                    <div>
                      {sort === column.id && sorted ? (
                        <SortAscending color={COLORS.GREY_400} />
                      ) : sort === column.id && !sorted ? (
                        <SortDescending color={COLORS.GREY_400} />
                      ) : (
                        <ArrowsDownUp color={COLORS.GREY_400} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="min-w-[150px]">
                    <p className="text-xs TEXT_SECONDARY-CLR items-center">{column.name}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {searchedPreliminaryNotices.length === 0 && (
          <div className="w-full h-[70vh] flex items-center justify-center">
            <div className="flex flex-col items-center">
              <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
              <p>No Preliminary Notices</p>
            </div>
          </div>
        )}
        {currentItems.length > 0 &&
          currentItems.map((preliminaryNotice, index) => {
            const lenderIsNeeded =
              preliminaryNotice?.lenderStatus?.isNeeded === undefined
                ? true
                : preliminaryNotice?.lenderStatus?.isNeeded;
            const ownerIsNeeded =
              preliminaryNotice?.ownerStatus?.isNeeded === undefined
                ? true
                : preliminaryNotice?.ownerStatus?.isNeeded;
            const contractorIsNeeded =
              preliminaryNotice?.contractorStatus?.isNeeded === undefined
                ? true
                : preliminaryNotice?.contractorStatus?.isNeeded;
            const customerIsNeeded = preliminaryNotice?.customerStatus?.isNeeded ?? false;
            const checkMailedStatus =
              (lenderIsNeeded
                ? preliminaryNotice?.lenderStatus?.mailedDate && lenderIsNeeded
                : true) &&
              (ownerIsNeeded
                ? preliminaryNotice?.ownerStatus?.mailedDate && ownerIsNeeded
                : true) &&
              (contractorIsNeeded
                ? preliminaryNotice?.contractorStatus?.mailedDate && contractorIsNeeded
                : true) &&
              (customerIsNeeded
                ? preliminaryNotice?.customerStatus?.mailedDate && contractorIsNeeded
                : true);
            const status =
              preliminaryNotice?.status === 'completed'
                ? 'Complete'
                : checkMailedStatus
                ? 'Complete'
                : 'Pending';
            return (
              <div
                key={index + 'oo'}
                className="w-full flex items-center  px-4 justify-between border-b border-b-gray-200">
                {columns.map((column, i) => {
                  return (
                    <div
                      key={column.id}
                      style={{ flex: column.width / totalWidth }}
                      className={`flex py-3 mx-1 ${
                        columns.length - 1 !== i ? 'overflow-hidden' : ''
                      }`}>
                      {column.id === 'submittedOn' && (
                        <p className="text-sm capitalize w-full">
                          {preliminaryNotice?.createdAt
                            ? moment(preliminaryNotice?.createdAt).format('MM/DD/YYYY')
                            : ''}
                        </p>
                      )}
                      {column.id === 'customer' && (
                        <div className="flex  w-full">
                          <div className="">
                            <div className="flex">
                              <p className="text-sm capitalize ">
                                {preliminaryNotice?.job?.buyer?.name.toLocaleLowerCase() || ''}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {column.id === 'gc' && (
                        <p className="text-sm capitalize w-full">{preliminaryNotice?.job?.GC}</p>
                      )}
                      {column.id === 'owner' && (
                        <p className="text-sm capitalize w-full">
                          {preliminaryNotice?.job?.owner || ''}
                        </p>
                      )}
                      {column.id === 'lender' && (
                        <p className="text-sm capitalize w-full">
                          {preliminaryNotice?.job?.lender || 'N/A'}
                        </p>
                      )}
                      {column.id === 'job' && (
                        <div className="w-full flex flex-col">
                          <p className="text-xs capitalize font-bold">
                            {preliminaryNotice.job?.jobNumber || ''}
                          </p>
                          <p className="text-xs capitalize ">{preliminaryNotice.job?.name || ''}</p>
                        </div>
                      )}
                      {column.id === 'status' && (
                        <div className="w-full">
                          <p className="text-xs capitalize ">{status}</p>
                        </div>
                      )}
                      {column.id === 'actions' && (
                        <div>
                          <div className="flex min-w-[150px] gap-1.5">
                            {preliminaryNotice.templateId === '' && (
                              <div>
                                <div className="w-[35px] h-4"></div>
                              </div>
                            )}
                            {!checkMailedStatus && preliminaryNotice?.status !== 'completed' ? (
                              <div className="flex">
                                <AppCustomDropdown
                                  inputFieldStyles={{ width: '35px' }}
                                  onSelect={(val) => {
                                    setSelectedPreliminaryNotice(preliminaryNotice);
                                    if (val === 'edit') {
                                      handleEditPreliminaryNotice(preliminaryNotice.id);
                                    }
                                  }}
                                  options={[
                                    {
                                      value: 'edit',
                                      label: 'Edit',
                                      icon: <Edit3 size={16} />,
                                    },
                                  ]}
                                  open={openDropdown === preliminaryNotice.id}
                                  onOpen={() => setOpenDropDown(preliminaryNotice.id)}
                                  onClose={() => setOpenDropDown('')}
                                  width="100px"
                                />
                              </div>
                            ) : (
                              <div>
                                <div className="w-[35px] h-4"></div>
                              </div>
                            )}
                            <AppButton
                              type="TERTIARY"
                              buttonStyles={{
                                padding: '8px',
                                height: '35px',
                                width: '35px',
                              }}
                              icon={<Eye color={COLORS.GREY_500} size={20} />}
                              onClick={() => {
                                setSelectedPreliminaryNotice(preliminaryNotice);
                                handlePreviewPdf(preliminaryNotice);
                                navigate(
                                  `/preliminaryNotices/overview?preliminaryNoticeId=${preliminaryNotice?.id}`
                                );
                              }}
                            />
                            {preliminaryNotice.templateId !== '' && (
                              <AppButton
                                type="TERTIARY"
                                buttonStyles={{
                                  padding: '8px',
                                  height: '35px',
                                  width: '35px',
                                }}
                                icon={<Truck color={COLORS.GREY_500} size={20} />}
                                onClick={() => {
                                  setSelectedPreliminaryNotice(preliminaryNotice);
                                  setShowTrackNoticeModal(true);
                                }}
                              />
                            )}
                            <AppButton
                              type="PRIMARY"
                              text="Download"
                              buttonStyles={{
                                height: '35px',
                              }}
                              onClick={() => {
                                handleDownloadSelectedPdf(preliminaryNotice);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      {/* Pagination component */}
      <div className="flex flex-row justify-between items-center w-3/5 mt-3">
        <div className="text-xs GREY_500-CLR">{`Showing ${
          searchedPreliminaryNotices.length !== 0 ? `${itemOffset + 1} to` : ''
        } ${cummulativeOffset} from ${searchedPreliminaryNotices.length} Preliminary Notice${
          searchedPreliminaryNotices.length > 1 ? 's' : ''
        }`}</div>
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          forcePage={Math.min(Math.max(0, currentPage - 1), pageCount - 1)}
          previousLabel={<CaretLeft size={20} />}
          nextLabel={<CaretRight size={20} />}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

export default PreliminaryNoticesTable;
