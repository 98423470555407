import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';

import { RequestJobInformationInitialState } from '../globalSlices/requestJobInformationSlice';

import {
  createRequestedSupplierJob,
  deleteRequestedSupplierJob,
  generateRequestJobInformationLink,
  getRequestedSupplierJob,
  getRequestedSupplierJobs,
  updateRequestedSupplierJob,
} from '../services/requestJobInformationService';
import { RequestedSupplierJob } from '@dill/dill-shared';

export const generateRequestJobInformationLinkExtraReducer = (
  builder: ActionReducerMapBuilder<RequestJobInformationInitialState>
) => {
  const reducerId = 'generateRequestJobInformationLink';
  builder.addCase(generateRequestJobInformationLink.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    generateRequestJobInformationLink.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(
    generateRequestJobInformationLink.rejected,
    (state, action: PayloadAction<any>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
};

export const createRequestSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<RequestJobInformationInitialState>
) => {
  const reducerId = 'createRequestSupplierJob';
  builder.addCase(createRequestedSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createRequestedSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createRequestedSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getRequestSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<RequestJobInformationInitialState>
) => {
  const reducerId = 'getRequestSupplierJobs';
  builder.addCase(getRequestedSupplierJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getRequestedSupplierJobs.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload?.success && action.payload?.data) {
        state.requestedSupplierJobs = action.payload.data;
      }
    }
  );
  builder.addCase(getRequestedSupplierJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const updateRequestedSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<RequestJobInformationInitialState>
) => {
  const reducerId = 'updateRequestedSupplierJob';
  builder.addCase(updateRequestedSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateRequestedSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action?.payload?.success && action?.payload?.data) {
        const data: RequestedSupplierJob = action.payload.data;
        const combined = state.requestedSupplierJobs.map((item) => {
          if (item.id === data.id) {
            return { ...item, ...data };
          }
          return item;
        });
        state.requestedSupplierJobs = combined;
      }
    }
  );
  builder.addCase(updateRequestedSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getRequestedSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<RequestJobInformationInitialState>
) => {
  const reducerId = 'getRequestedSupplierJob';
  builder.addCase(getRequestedSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getRequestedSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedRequestedSupplierJob = action.payload.data;
      }
    }
  );
  builder.addCase(getRequestedSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const deleteRequestedSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<RequestJobInformationInitialState>
) => {
  const reducerId = 'deleteRequestedSupplierJob';
  builder.addCase(deleteRequestedSupplierJob.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    deleteRequestedSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(deleteRequestedSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
