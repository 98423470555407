import { Viewer } from '@react-pdf-viewer/core';
import {
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import { useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { AppDropDown } from '../../../../general/AppDropDown/AppDropDown';
import moment from 'moment';
import momentTz from 'moment-timezone';
import AppDatePicker from '../../../../general/AppDatePicker/AppDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { Job, UserLinkApiResponse, LienTemplateTypes } from '@dill/dill-shared';
import { AppConfirmationModal } from '../../../../general/AppConfirmationModal/AppConfirmationModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import sendCircle from '../../../../../assets/svg/send_circle.svg';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import { getJobs } from '../../../../../redux/services/jobsService';
import { getSubSuppliers } from '../../../../../redux/services/subSuppliersService';
import { manualCreateLienRelease } from '../../../../../redux/services/liensService';
import { useNavigate, useLocation } from 'react-router-dom';
import { Upload } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FileArrowUp, Notepad } from 'phosphor-react';
import { AppRadioButton } from '../../../../general/AppRadioButton/AppRadioButton';
import { openDillToast } from '../../../../../utils/helpers';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerManualAddLienRelease = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { jobs } = useAppSelector((state) => state.jobs);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const { user } = useAppSelector((state) => state.auth);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedPdfFile, setSelectedPdfFile] = useState<{ name: string; url: string } | null>(
    null
  );
  const [selectedSupplierUserPosition, setSelectedSupplierUserPosition] = useState<number | null>(
    null
  );
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [isConfirmCreateLeanReleaseModalOpen, setIsConfirmCreateLeanReleaseModalOpen] =
    useState(false);
  const [isErrorCreateLeanReleaseModalOpen, setIsErrorCreateLeanReleaseModalOpen] = useState(false);
  const [isLienReleaseCreateSuccessModalOpen, setIsLienReleaseCreateSuccessModalOpen] =
    useState(false);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const [throughDate, setThroughDate] = useState<Date | null | undefined>();

  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers.reduce(
        (
          prev: {
            subSupplierName: string;
            subSupplierId: string;
            notes: string;
            user: UserLinkApiResponse;
          }[],
          curr
        ) => {
          return [
            ...prev,
            ...curr.users
              .filter((userLink) => {
                if (
                  userLink.subUser &&
                  userLink.subUser.roles &&
                  (userLink.subUser.roles.includes('REVIEWER') ||
                    userLink.subUser.roles.length == 0)
                ) {
                  return true;
                }
                return !userLink?.subUser?.roles;
              })
              .map((userLink) => {
                return {
                  subSupplierId: curr.id,
                  subSupplierName: curr.name,
                  notes: curr.notes ?? '',
                  user: userLink,
                };
              }),
          ];
        },
        []
      );
    }
    return [];
  }, [subSuppliers]);

  const [releaseTypeOptions, setReleaseTypeOptions] = useState<
    { id: LienTemplateTypes; name: string; isSelected: boolean }[]
  >([
    { id: 'PROGRESS_CONDITIONAL', name: 'Progress Conditional', isSelected: false },
    { id: 'PROGRESS_UNCONDITIONAL', name: 'Progress Unconditional', isSelected: false },
    { id: 'FINAL_CONDITIONAL', name: 'Final Conditional', isSelected: false },
    { id: 'FINAL_UNCONDITIONAL', name: 'Final Unconditional', isSelected: false },
    { id: 'PROGRESS_DUAL', name: 'Progress Dual', isSelected: false },
    { id: 'FINAL_DUAL', name: 'Final Dual', isSelected: false },
  ]);

  const handleSelectReleaseType = ({
    optionId,
    isSelected,
  }: {
    optionId: string;
    isSelected: boolean;
  }) => {
    setReleaseTypeOptions([
      ...releaseTypeOptions.map((option) => {
        if (option.id === optionId) {
          return { ...option, isSelected: isSelected };
        }
        return { ...option, isSelected: false };
      }),
    ]);
  };

  const fetchData = async () => {
    if (selectedMainBuyerId) {
      await dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
      await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [selectedMainBuyerId]);

  const selectedReleaseType: LienTemplateTypes | null = useMemo(() => {
    const selectedOption = releaseTypeOptions.find(
      (releaseTypeOption) => releaseTypeOption.isSelected
    );

    if (selectedOption) {
      if (
        selectedOption.id !== 'PROGRESS_CONDITIONAL' &&
        selectedOption.id !== 'PROGRESS_UNCONDITIONAL' &&
        selectedOption.id !== 'PROGRESS_DUAL'
      ) {
        setThroughDate(null);
      }
      if (
        selectedOption.id === 'PROGRESS_CONDITIONAL' ||
        selectedOption.id === 'PROGRESS_UNCONDITIONAL' ||
        selectedOption.id === 'PROGRESS_DUAL'
      ) {
        const currentDate = moment();
        const endOfLastMonth = currentDate.subtract(1, 'months').endOf('month').startOf('day');
        const endOfMonthDate = endOfLastMonth.toDate();
        setThroughDate(endOfMonthDate);
      }
      return selectedOption.id;
    }
    return null;
  }, [releaseTypeOptions]);

  const selectedSubSupplier = useMemo(() => {
    let foundSelectedSubSupplier = null;
    if (selectedSupplierUserPosition !== null && subSupplierUsers) {
      const foundSubSupplier = subSuppliers.find(
        (item) => item.id === subSupplierUsers?.[selectedSupplierUserPosition].subSupplierId
      );
      if (foundSubSupplier) {
        foundSelectedSubSupplier = foundSubSupplier;
      }
    }
    return foundSelectedSubSupplier;
  }, [selectedSupplierUserPosition]);

  const handleSubmitLienRelease = async () => {
    if (selectedSupplierUserPosition === null || !selectedMainBuyerId) {
      return;
    }
    if (!selectedPdfFile) {
      openDillToast({ message: 'Please select a file', type: 'ERROR' });
      return;
    }

    const selectedUser = subSupplierUsers[selectedSupplierUserPosition];
    const releaseData = {
      jobId: selectedJob?.id || '',
      notes: '',
      previewPdfUrl: '',
      recipientId: {
        userId: selectedUser.user.user?.id || '',
        subUserId: selectedUser.user.subUser?.id || '',
      },
      subSupplierId: selectedUser.subSupplierId,
      templateId: '',
      emailReminderFrequency: 0,
      dueDate: new Date(),
      isWetSignature: false,
    };
    let selectedThroughDate;
    if (throughDate) {
      selectedThroughDate = new Date(throughDate);
    } else {
      const currentDate = moment();
      const endOfLastMonth = currentDate.endOf('month').startOf('day');
      const endOfMonthDate = endOfLastMonth.toDate();
      selectedThroughDate = new Date(endOfMonthDate);
    }
    const currentTimeInDesiredTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const validTimezone = momentTz.tz.zone(currentTimeInDesiredTimeZone)
      ? currentTimeInDesiredTimeZone
      : 'America/Los_Angeles';
    const results = await dispatch(
      manualCreateLienRelease({
        mainBuyerId: selectedMainBuyerId,
        releaseDetails: {
          ...releaseData,
          signedTime: new Date(),
          type: selectedReleaseType,
          fieldsData: throughDate
            ? [
                {
                  id: 'throughDate',
                  isRequired: true,
                  name: 'Through Date',
                  type: 'DATE',
                  value: throughDate,
                },
              ]
            : [],
          status: 'Signed',
          dueDate: null,
          releaseMonth: selectedThroughDate,
          emailReminderFrequency: 3,
          isWetSignature: false,
        },
        timezone: validTimezone,
        releasePdf: selectedPdfFile?.url || '',
        signedPdfFileName: selectedPdfFile?.name || '',

        storagePathName: `buyerReleases/${selectedMainBuyerId}/${subSupplierUsers[selectedSupplierUserPosition].subSupplierId}/${selectedJob?.id}`,
      })
    );

    if (results.type === 'liens/manualCreateLienRelease/fulfilled') {
      setIsLienReleaseCreateSuccessModalOpen(true);
    } else {
      setIsErrorCreateLeanReleaseModalOpen(true);
    }
  };

  const checkErrors = () => {
    let fields: string[] = [];

    if (selectedSupplierUserPosition === null) {
      fields = [...fields, 'supplier'];
    }
    if (!selectedJob) {
      fields = [...fields, 'job'];
    }
    if (!selectedPdfFile) {
      fields = [...fields, 'selectFile'];
    }
    if (!selectedReleaseType) {
      fields = [...fields, 'releaseType'];
    }
    if (
      !throughDate &&
      selectedReleaseType &&
      (selectedReleaseType === 'PROGRESS_CONDITIONAL' ||
        selectedReleaseType === 'PROGRESS_UNCONDITIONAL' ||
        selectedReleaseType === 'PROGRESS_DUAL')
    ) {
      fields = [...fields, 'throughDate'];
    }

    setErrorFields(fields);
    return fields;
  };

  return (
    <div className="flex flex-row h-full py-4 justify-between px-4">
      <AppConfirmationModal
        open={isConfirmCreateLeanReleaseModalOpen}
        modalImage={sendCircle}
        negativeButtonText="No, cancel"
        highlightedText={`${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
            : ''
        } - ${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? `${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                ''
              } ${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                ''
              }`
            : ''
        }`}
        positiveButtonText="Yes, add it"
        title="Are you sure you want to manually add this lien waiver?"
        subTitle="This will be added as a signed release from"
        handleClose={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
        }}
        onNegativeButtonClick={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
        }}
        onPositiveButtonClick={() => {
          setIsConfirmCreateLeanReleaseModalOpen(false);
          handleSubmitLienRelease();
        }}
      />
      <AppSuccessModal
        open={isLienReleaseCreateSuccessModalOpen}
        modalImage={successChecksCircle}
        highlightedText={`${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? subSupplierUsers[selectedSupplierUserPosition].subSupplierName
            : ''
        } - ${
          selectedSupplierUserPosition !== null && subSupplierUsers[selectedSupplierUserPosition]
            ? `${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.firstName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.firstName ||
                ''
              } ${
                subSupplierUsers[selectedSupplierUserPosition].user.subUser?.lastName ||
                subSupplierUsers[selectedSupplierUserPosition].user.user?.lastName ||
                ''
              }`
            : ''
        }`}
        title="Lien waiver added!"
        subTitle="This has been added as a signed release from"
        buttonText={
          location.pathname.split('/').length === 5 ? 'View Job Details' : 'View All Lien Waivers'
        }
        handleClose={() => {
          setIsLienReleaseCreateSuccessModalOpen(false);
          navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
        }}
      />
      <AppSuccessModal
        open={isErrorCreateLeanReleaseModalOpen}
        modalImage={errorCircle}
        title="Adding your release failed!"
        subTitle="Failed to add your lien waiver. Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setIsErrorCreateLeanReleaseModalOpen(false);
          handleSubmitLienRelease();
        }}
        handleClose={() => {
          setIsErrorCreateLeanReleaseModalOpen(false);
        }}
      />

      <div className="w-full flex justify-between bg-white px-3 py-4">
        <div className={twMerge('flex flex-col w-5/12  h-5/6 overflow-scroll hidescrollbar pr-3')}>
          <div className={twMerge('flex flex-col  bg-white px-4 py-3 mb-3')}>
            <div
              className={twMerge(
                'flex flex-col items-center justify-center PRIMARY_50-BG py-2 ',
                errorFields.includes('selectFile') ? 'border border-red-600' : ''
              )}>
              <div className="h-12 w-12 my-1 rounded-full flex flex-col items-center justify-center PRIMARY_100-BG ">
                <FileArrowUp size={30} color={COLORS.PRIMARY_500} />
              </div>
              <div className="relative my-0.5 ">
                <input
                  id="upload"
                  className="absolute w-full h-full top-0 bottom-0 opacity-0 cursor-pointer"
                  type="file"
                  accept="application/pdf"
                  onChange={(event) => {
                    if (!event?.target.files) {
                      return;
                    }
                    const file = event?.target?.files[0];

                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String: any = reader.result;
                        setSelectedPdfFile({ url: base64String, name: file.name });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  // onClick={(event) => {}}
                />
                <AppButton
                  type="SECONDARY"
                  text="Upload Document"
                  buttonStyles={{
                    // padding: '3px 12px',
                    // borderRadius: '5px',
                    height: '36px',
                    cursor: 'pointer',
                  }}
                  icon={<Upload size={20}></Upload>}
                />
              </div>
              <p className="text-xs text-gray-500">*Accepted File .pdf</p>
              {selectedPdfFile && (
                <p className="text-sm text-center mt-4 mb-1">{selectedPdfFile.name}</p>
              )}
            </div>

            <div className="my-2 w-full">
              <AppDropDown
                label="Job"
                isRequired={true}
                errorText={errorFields.includes('job') ? 'Please select a job' : ''}
                value={selectedJob?.id || ''}
                items={[
                  ...jobs
                    .filter((jb) => !jb?.archivedState?.includes('BUYER_ARCHIVED'))
                    .map((job) => {
                      return {
                        label: `${job?.number ? `${job.number} - ` : ''}${job.name}`,
                        value: job.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = jobs.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedJob(uu);
                    }
                  }
                }}
              />
              {selectedJob?.notes && (
                <div
                  data-tooltip-id={'job-notes-tooltip'}
                  className="flex INFO_200-BG py-3 px-2 items-center rounded-lg overflow-hidden w-full my-2">
                  <div className="flex font-bold items-center">
                    <Notepad color="#000000" size={20} />
                    <div className="mx-1 text-sm">Notes:</div>
                  </div>
                  <div className="truncate text-sm TEXT_SECONDARY-CLR font-medium ml-2 w">
                    {selectedJob?.notes}
                  </div>
                </div>
              )}
              <ReactTooltip
                id={'job-notes-tooltip'}
                place="top"
                variant="dark"
                opacity={'100%'}
                style={{
                  display: 'flex',
                  background: '#334155',
                  width: '300px',
                  zIndex: '70',
                  borderRadius: '8px',
                  alignItems: 'center',
                  justifyItems: 'center',
                  fontWeight: '600',
                }}>
                <span>{selectedJob?.notes}</span>
              </ReactTooltip>
            </div>
            <div className="my-2 w-full">
              <AppDropDown
                label="Supplier"
                isRequired={true}
                errorText={errorFields.includes('supplier') ? 'Please select a supplier' : ''}
                value={
                  (selectedSupplierUserPosition !== null ? selectedSupplierUserPosition : '') + ''
                }
                items={[
                  ...subSupplierUsers.map((subSupplierUser, i) => {
                    const userDetails = subSupplierUser.user.subUser
                      ? subSupplierUser.user.subUser
                      : subSupplierUser.user.user;
                    return {
                      label: `${subSupplierUser.subSupplierName} - ${
                        userDetails?.firstName || ''
                      } ${userDetails?.lastName || ''} `,
                      value: i + '',
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value !== null && item?.value !== undefined) {
                    const uu = subSupplierUsers.find((val, i) => i === parseInt(item?.value));
                    if (uu) {
                      setSelectedSupplierUserPosition(parseInt(item?.value));
                    }
                  }
                }}
              />
              {selectedSubSupplier?.notes && (
                <div
                  data-tooltip-id={'subsupplier-notes-tooltip'}
                  className="flex INFO_200-BG py-3 px-2 items-center rounded-lg overflow-hidden w-full my-2">
                  <div className="flex font-bold items-center">
                    <Notepad color="#000000" size={20} />
                    <div className="mx-1 text-sm">Notes:</div>
                  </div>
                  <div className="truncate text-sm TEXT_SECONDARY-CLR font-medium ml-2 w">
                    {selectedSubSupplier?.notes}
                  </div>
                </div>
              )}
              <ReactTooltip
                id={'subsupplier-notes-tooltip'}
                place="top"
                variant="dark"
                opacity={'100%'}
                style={{
                  display: 'flex',
                  background: '#334155',
                  width: '300px',
                  zIndex: '70',
                  borderRadius: '8px',
                  alignItems: 'center',
                  justifyItems: 'center',
                  fontWeight: '600',
                }}>
                <span>{selectedSubSupplier?.notes}</span>
              </ReactTooltip>
            </div>

            <div
              className={twMerge(
                '',
                errorFields.includes('releaseType') ? 'border border-red-600' : ''
              )}>
              <p className="text-xs mb-0.5  whitespace-nowrap font-medium">
                <span className="text-red-500">*</span>Type
              </p>
              <div className="mt-2">
                {releaseTypeOptions.map((releaseTypeOption, releaseTypeOptionIndex) => {
                  return (
                    <div
                      key={releaseTypeOptionIndex + 'o'}
                      className="flex my-1 items-center cursor-pointer"
                      onClick={() => {
                        handleSelectReleaseType({
                          optionId: releaseTypeOption.id,
                          isSelected: true,
                        });
                      }}>
                      <AppRadioButton isChecked={releaseTypeOption.isSelected} />
                      <p className="ml-1 text-sm">{releaseTypeOption.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            {selectedReleaseType &&
              (selectedReleaseType === 'PROGRESS_CONDITIONAL' ||
                selectedReleaseType === 'PROGRESS_UNCONDITIONAL' ||
                selectedReleaseType === 'PROGRESS_DUAL') && (
                <div className="my-2 w-full">
                  <AppDatePicker
                    label="Through Date"
                    isRequired={true}
                    selectedDate={throughDate}
                    errorText={errorFields.includes('throughDate') ? 'Required' : ''}
                    onDateSelected={(date) => {
                      setThroughDate(date);
                    }}
                  />
                </div>
              )}
          </div>
          <div className="flex flex-row justify-end bg-white">
            <AppButton
              text="Close"
              type="SECONDARY"
              onClick={() => {
                navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
              }}
            />
            <div className="flex flex-row PRIMARY_500-BG ml-4 rounded-lg justify-center align-center">
              <AppButton
                text="Add"
                onClick={() => {
                  const fieldsWithErrors = checkErrors();
                  console.log(fieldsWithErrors);

                  if (fieldsWithErrors.length === 0) {
                    setIsConfirmCreateLeanReleaseModalOpen(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-7/12 h-full PRIMARY_50-BG px-3 py-3 rounded-2xl">
          <div className="h-5/6 relative  overflow-scroll flex flex-col items-center pb-3">
            <div
              style={{
                width: '612px',
                background: '#F4F7FA',
              }}
              className="sticky top-0  z-10 flex justify-between  mb-0.5"></div>

            <div
              style={{
                width: '612px',
                height: '792px',
              }}
              className="relative flex justify-center">
              {selectedPdfFile && (
                <Viewer
                  defaultScale={1}
                  fileUrl={selectedPdfFile.url}
                  plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                />
              )}
              {!selectedPdfFile && <p className="text-sm self-center ">No file selected</p>}
            </div>
          </div>

          <div className="flex flex-row justify-center">
            <div className="mx-4">
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <span>{`page ${props.currentPage + 1} / ${props.numberOfPages}`}</span>
                )}
              </CurrentPageLabel>
            </div>

            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerManualAddLienRelease;
