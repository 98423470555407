import { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import { X } from 'phosphor-react';

import { Job } from '@dill/dill-shared';
import { useAppDispatch } from '../../../../../redux/store';
import { COLORS } from '../../../../../utils/colors';
import { openDillToast } from '../../../../../utils/helpers';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { bulkUpdateBuyerJobs } from '../../../../../redux/services/jobsService';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

export const AppBulkEditBuyerJobsModal = ({
  open,
  handleClose,
  selectedJobs = [],
}: {
  open: boolean;
  selectedJobs: Job[];
  handleClose: () => void;
}) => {
  const INITIAL_STATE = {
    owner: '',
    GC: '',
  };

  const [selectedMainBuyerId] = useParamMainBuyerId();
  const dispatch = useAppDispatch();

  const [buyerJobInput, setBuyerJobInput] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [checkedFields, setCheckedFields] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setCheckedFields([]);
      setBuyerJobInput(INITIAL_STATE);
      setLoading(false);
    }
  }, [open]);

  const handleCloseModal = () => {
    handleClose();
    setBuyerJobInput(INITIAL_STATE);
    setCheckedFields([]);
    setLoading(false);
  };

  const toggleSelectField = (field: string) => {
    setCheckedFields((prevFields) =>
      prevFields.includes(field)
        ? prevFields.filter((item) => item !== field)
        : [...prevFields, field]
    );
  };

  const handleSubmitJob = async () => {
    setLoading(true);
    let data = {};
    checkedFields.forEach((field) => {
      if (field === 'owner') {
        data = {
          ...data,
          owner: buyerJobInput.owner || '',
        };
      }

      if (field === 'gc') {
        data = {
          ...data,
          GC: buyerJobInput.GC || '',
        };
      }
    });
    const bulkUpdateBuyerJobsResponse = await dispatch(
      bulkUpdateBuyerJobs({
        buyerJobIds: selectedJobs.map((job) => job.id),
        buyerJobDetails: { ...data },
        mainBuyerId: selectedMainBuyerId || '',
      })
    );

    console.log({ bulkUpdateBuyerJobsResponse });
    if (bulkUpdateBuyerJobsResponse.type === 'jobs/bulkUpdateBuyerJobs/fulfilled') {
      const success = bulkUpdateBuyerJobsResponse?.payload?.success ?? true;
      if (success) {
        openDillToast({
          message: `Job${selectedJobs.length > 1 ? 's' : ''} successfully updated!`,
          type: 'SUCCESS',
        });
      } else {
        const updatedJobs: any[] = bulkUpdateBuyerJobsResponse?.payload?.data ?? [];
        const failedJobs = selectedJobs.filter(
          (job) => !updatedJobs.map((updated) => updated.id).includes(job.id)
        );
        openDillToast({
          message: `Failed to update ${
            selectedJobs.length > 1 ? ' some jobs' : 'this job'
          }: ${failedJobs.map((job) => job.name).join(', ')}`,
          type: 'ERROR',
        });
        setLoading(false);
      }
      handleCloseModal();
    } else {
      openDillToast({
        message: `Failed to update job${selectedJobs.length > 1 ? 's' : ''}`,
        type: 'ERROR',
      });
      setLoading(false);
    }
  };

  const onTextChange = (key: string, value: string) => {
    setBuyerJobInput((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <>
        <div className={`new-job-modal-content pt-3`}>
          <div className="new-job-modal-title-container">
            <h3 className="font-medium text-lg">{'Bulk Edit Jobs'}</h3>
            <AppButton
              type="FLAT"
              buttonStyles={{ padding: '0px', margin: '5px 2px' }}
              icon={<X color={COLORS.GREY_300} size={30} />}
              onClick={handleClose}
            />
          </div>
          <div className="flex flex-col w-full px-6 py-3">
            <div className="text-sm font-semibold mb-2">Select Fields to Edit</div>
            <div className="flex justify-between">
              <div className="flex flex-row">
                <div className="flex items-center mb-1 mx-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('owner')}
                    onClick={() => {
                      toggleSelectField('owner');
                    }}
                  />
                  <div className="text-sm ml-2">Owner</div>
                </div>
                <div className="flex items-center mb-1  mx-1">
                  <AppCheckBox
                    isChecked={checkedFields.includes('gc')}
                    onClick={() => {
                      toggleSelectField('gc');
                    }}
                  />
                  <div className="text-sm ml-2">GC</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full px-6 py-3 max-h-[60vh] !overflow-y-auto">
            {checkedFields.includes('owner') && (
              <div className="flex flex-col w-full mb-2">
                <div className="w-full">
                  <AppInputField
                    id="owner"
                    label="Owner"
                    placeholder="Owner"
                    value={buyerJobInput.owner}
                    onTextChange={(text) => {
                      onTextChange('owner', text);
                    }}
                  />
                </div>
              </div>
            )}

            {checkedFields.includes('gc') && (
              <div className="flex flex-row  pb-3">
                <div className="w-full relative">
                  <div className="w-full ">
                    <AppInputField
                      id="gc"
                      label="GC"
                      inputType="text"
                      placeholder="GC"
                      value={buyerJobInput.GC}
                      onTextChange={(text) => {
                        onTextChange('GC', text);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full mb-3">
            <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
            <div className="flex flex-row self-end px-6">
              <AppButton
                text="Close"
                type="TERTIARY"
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  marginRight: '12px',
                  height: '40px',
                }}
                onClick={handleClose}
              />
              <AppButton
                text={'Bulk Edit Jobs'}
                onClick={() => handleSubmitJob()}
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  height: '40px',
                  width: '120px',
                }}
                isDisabled={loading || checkedFields.length === 0}
                isLoading={loading}
              />
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};
