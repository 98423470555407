import { Modal } from '@mui/material';
import React, { useState, useMemo, useEffect } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X, Check } from 'react-feather';
import { toast } from 'react-toastify';
import './AppNewJobModal.scss';
import { COLORS } from '../../../utils/colors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { createJob, updateJob, getJobs } from '../../../redux/services/jobsService';
import { AppInputField } from '../AppInputField/AppInputField';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import {
  Job,
  UserLinkApiResponse,
  SubSupplierApiResponse,
  LienTemplate,
  AppUserApiResponse,
} from '@dill/dill-shared';
import AppDatePicker from '../AppDatePicker/AppDatePicker';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import { AppMultiSelectDropDown } from '../AppMultiSelectDropDown/AppMultiSelectDropDown';
import { FormOptionItem } from '../../../utils/interfaces/generalInterfaces';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import { isEmailValid, extractEmailAddresses } from '../../../utils/helpers';
import moment from 'moment';
import { getUserByBuyers } from '../../../redux/services/authService';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';

export const AppNewJobModal = ({
  open,
  handleClose,
  editMode = false,
  selectedJob,
  subSuppliers,
}: {
  open: boolean;
  handleClose: () => void;
  editMode?: boolean;
  selectedJob?: Job | null;
  subSuppliers: SubSupplierApiResponse[];
}) => {
  const dispatch = useAppDispatch();
  const INITIAL_STATE = {
    name: '',
    number: '',
    customerNumber: '',
    // supplierNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    owner: '',
    accountantEmails: '',
    notes: '',
    GC: '',
  };
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { userBuyers, user } = useAppSelector((state) => state.auth);
  const [jobInput, setJobInput] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({
    ...INITIAL_STATE,
    startDate: '',
    finishDate: '',
    supplierId: '',
    finalReleases: '',
    progressReleases: '',
    finalCondReleasesTemplate: '',
    progressCondReleasesTemplate: '',
    finalUncondReleasesTemplate: '',
    progressUncondReleasesTemplate: '',
    finalDualReleasesTemplate: '',
    progressDualReleasesTemplate: '',
    buyerRep: '',
  });
  const [loading, setLoading] = useState(false);
  const [defaultThroughDate, setDefaultThroughDate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [finishDate, setFinishDate] = useState<Date | null>(null);
  const [selectedSupliers, setSelectedSupliers] = useState<FormOptionItem[]>([]);
  const [progressReleases, setProgressReleases] = useState<string[] | null>(null);
  const [finalReleases, setFinalReleases] = useState<string[] | null>(null);
  const [selectedFinalCondTemplate, setSelectedFinalCondTemplate] = useState<LienTemplate | null>(
    null
  );
  const [selectedFinalUnCondTemplate, setSelectedFinalUnCondTemplate] =
    useState<LienTemplate | null>(null);
  const [selectedProgresCondTemplate, setSelectedProgresCondTemplate] =
    useState<LienTemplate | null>(null);
  const [selectedProgresUnCondTemplate, setSelectedProgresUnCondTemplate] =
    useState<LienTemplate | null>(null);
  const [selectedProgresDualTemplate, setSelectedProgresDualTemplate] =
    useState<LienTemplate | null>(null);
  const [selectedFinalDualTemplate, setSelectedFinalDualTemplate] = useState<LienTemplate | null>(
    null
  );
  const [buyerRep, setBuyerRep] = useState<AppUserApiResponse | null>(null);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const isConditionalOrUnconditional =
    finalReleases?.includes('UNCONDITIONAL') ||
    finalReleases?.includes('CONDITIONAL') ||
    finalReleases?.includes('DUAL') ||
    progressReleases?.includes('UNCONDITIONAL') ||
    progressReleases?.includes('CONDITIONAL') ||
    progressReleases?.includes('DUAL');

  useEffect(() => {
    if (selectedMainBuyerId && open) {
      dispatch(getUserByBuyers({ buyerId: selectedMainBuyerId }));
    }
  }, [open]);

  const fetchUserBuyer = useMemo(() => {
    if (userBuyers.length > 0) {
      return userBuyers;
    }
    return [];
  }, [userBuyers]);

  const handleCloseModal = () => {
    setErrors({
      ...INITIAL_STATE,
      startDate: '',
      finishDate: '',
      supplierId: '',
      finalReleases: '',
      progressReleases: '',
      finalCondReleasesTemplate: '',
      progressCondReleasesTemplate: '',
      finalUncondReleasesTemplate: '',
      progressUncondReleasesTemplate: '',
      finalDualReleasesTemplate: '',
      progressDualReleasesTemplate: '',
      buyerRep: '',
    });
    setJobInput(INITIAL_STATE);
    setStartDate(null);
    setFinishDate(null);
    setSelectedSupliers([]);
    handleClose();
    setProgressReleases(null);
    setFinalReleases(null);
    setSelectedFinalCondTemplate(null);
    setSelectedFinalUnCondTemplate(null);
    setSelectedProgresCondTemplate(null);
    setSelectedProgresUnCondTemplate(null);
    setSelectedFinalDualTemplate(null);
    setSelectedProgresDualTemplate(null);
    setBuyerRep(null);
    setShowErrorAbove(false);
    setLoading(false);
  };

  const selectedSupplierId = () => {
    const result: string[] = [];
    if (selectedSupliers.length > 0) {
      for (let index = 0; index < selectedSupliers.length; index++) {
        const element = selectedSupliers[index];
        result.push(element.value);
      }
    }
    return result;
  };

  const templates = useMemo(() => {
    if (lienTemplates.length > 0) {
      const processedLienTemplates = lienTemplates.filter(
        (template) => template.status === 'processed'
      );
      return processedLienTemplates;
    }
    return [];
  }, [lienTemplates]);

  useEffect(() => {
    if (selectedJob && open) {
      setJobInput({
        name: selectedJob?.name ?? '',
        number: selectedJob?.number ? String(selectedJob?.number) : '',
        customerNumber: selectedJob?.customerNumber ? String(selectedJob?.customerNumber) : '',
        // supplierNumber: selectedJob?.supplierNumber ? String(selectedJob?.supplierNumber) : '',
        address: selectedJob?.address ?? '',
        owner: selectedJob?.owner ?? '',
        accountantEmails: selectedJob?.accountantEmails?.join(', ') ?? '',
        city: selectedJob?.city ?? '',
        state: selectedJob?.state ?? '',
        zipCode: selectedJob?.zipCode ? String(selectedJob?.zipCode) : '',
        notes: selectedJob?.notes ?? '',
        GC: selectedJob?.GC ?? '',
      });
      setDefaultThroughDate(selectedJob?.defaultThroughDate ?? true);
      setStartDate(selectedJob?.startDate ? new Date(selectedJob.startDate) : null);
      setFinishDate(selectedJob?.endDate ? new Date(selectedJob?.endDate) : null);

      if (selectedJob?.subSupplierIds && selectedJob.subSupplierIds.length > 0) {
        const results = selectedJob?.subSupplierIds.map((idToFind) => {
          const index = subSuppliers.findIndex((item) => item.id === idToFind);
          const name = index !== -1 ? `${subSuppliers[index].name}` : '';
          const subsupplierId = index !== -1 ? `${subSuppliers[index].id}` : '';
          return { value: subsupplierId, label: name };
        });
        setSelectedSupliers(results);
      }
      if (selectedJob?.releaseRequirements) {
        if (selectedJob.releaseRequirements?.FINAL) {
          setFinalReleases(selectedJob.releaseRequirements?.FINAL);
        }
        if (selectedJob.releaseRequirements?.PROGRESS) {
          setProgressReleases(selectedJob.releaseRequirements?.PROGRESS);
        }
      }
      if (selectedJob?.templates) {
        if (selectedJob.templates?.FINAL_CONDITIONAL !== '') {
          const selected = templates.filter(
            (item) => item.id === selectedJob.templates?.FINAL_CONDITIONAL
          );
          setSelectedFinalCondTemplate(selected[0] ?? null);
        } else {
          setSelectedFinalCondTemplate(null);
        }
        if (selectedJob.templates?.FINAL_UNCONDITIONAL !== '') {
          const selected = templates.filter(
            (item) => item.id === selectedJob.templates?.FINAL_UNCONDITIONAL
          );
          setSelectedFinalUnCondTemplate(selected[0] ?? null);
        } else {
          setSelectedFinalUnCondTemplate(null);
        }
        if (selectedJob.templates?.FINAL_DUAL !== '') {
          const selected = templates.filter(
            (item) => item.id === selectedJob.templates?.FINAL_DUAL
          );
          setSelectedFinalDualTemplate(selected[0] ?? null);
        } else {
          setSelectedFinalDualTemplate(null);
        }
        if (selectedJob.templates?.PROGRESS_CONDITIONAL !== '') {
          const selected = templates.filter(
            (item) => item.id === selectedJob.templates?.PROGRESS_CONDITIONAL
          );
          setSelectedProgresCondTemplate(selected[0] ?? null);
        } else {
          setSelectedProgresCondTemplate(null);
        }
        if (selectedJob.templates?.PROGRESS_UNCONDITIONAL !== '') {
          const selected = templates.filter(
            (item) => item.id === selectedJob.templates?.PROGRESS_UNCONDITIONAL
          );
          setSelectedProgresUnCondTemplate(selected[0] ?? null);
        } else {
          setSelectedProgresUnCondTemplate(null);
        }
        if (selectedJob.templates?.PROGRESS_DUAL !== '') {
          const selected = templates.filter(
            (item) => item.id === selectedJob.templates?.PROGRESS_DUAL
          );
          setSelectedProgresDualTemplate(selected[0] ?? null);
        } else {
          setSelectedProgresDualTemplate(null);
        }
      }
      if (selectedJob?.billingRep) {
        if (selectedJob?.billingRep?.userId) {
          const selected = fetchUserBuyer.filter(
            (item) => item.userIds?.userId === selectedJob.billingRep?.userId
          );
          setBuyerRep(selected[0] ?? null);
        } else {
          setBuyerRep(null);
        }
      }
    }
  }, [selectedJob, open, subSuppliers, lienTemplates, fetchUserBuyer]);
  useEffect(() => {
    if (!editMode) {
      if (isConditionalOrUnconditional) {
        setDefaultThroughDate(isConditionalOrUnconditional);
        setBuyerRep(fetchUserBuyer[0] ?? null);
      } else {
        setDefaultThroughDate(false);
        setBuyerRep(null);
      }
    }
  }, [isConditionalOrUnconditional]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setJobInput((values) => ({ ...values, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    setShowErrorAbove(false);
  };

  const onChange2 = ({ name, value }: { name: string; value: string }) => {
    setJobInput((values) => ({ ...values, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    setShowErrorAbove(false);
  };

  const validateInputs = () => {
    const newErrors = {
      name: '',
      number: '',
      customerNumber: '',
      // supplierNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      owner: '',
      accountantEmails: '',
      startDate: '',
      finishDate: '',
      supplierId: '',
      finalReleases: '',
      progressReleases: '',
      finalCondReleasesTemplate: '',
      progressCondReleasesTemplate: '',
      finalUncondReleasesTemplate: '',
      progressUncondReleasesTemplate: '',
      finalDualReleasesTemplate: '',
      progressDualReleasesTemplate: '',
      buyerRep: '',
      notes: '',
      GC: '',
    };

    let isValid = true;

    if (jobInput.name.trim() === '') {
      newErrors.name = 'Name is required.';
      isValid = false;
    }

    if (jobInput.number.trim() === '') {
      newErrors.number = 'Job Number is required.';
      isValid = false;
    }

    if (jobInput.address.trim() === '') {
      newErrors.address = 'Address is required.';
      isValid = false;
    }
    if (jobInput.city.trim() === '') {
      newErrors.city = 'City is required.';
      isValid = false;
    }
    if (jobInput.state.trim() === '') {
      newErrors.state = 'State is required.';
      isValid = false;
    }
    if (jobInput.zipCode.trim() === '') {
      newErrors.zipCode = 'Zip Code is required.';
      isValid = false;
    }
    if (jobInput.owner.trim() === '' && isConditionalOrUnconditional) {
      newErrors.owner = 'Owner is required.';
      isValid = false;
    }
    if (startDate === null && isConditionalOrUnconditional) {
      newErrors.startDate = 'Start Date is required';
      isValid = false;
    }

    if (finishDate === null && isConditionalOrUnconditional) {
      newErrors.finishDate = 'End Date is required';
      isValid = false;
    }
    if (selectedSupliers.length === 0 && isConditionalOrUnconditional) {
      newErrors.supplierId = 'Select at least one supplier';
      isValid = false;
    }
    if (finalReleases === null) {
      newErrors.finalReleases = 'Select at least one option';
      isValid = false;
    }
    if (progressReleases === null) {
      newErrors.progressReleases = 'Select at least one option';
      isValid = false;
    }
    if (
      progressReleases !== null &&
      progressReleases?.includes('CONDITIONAL') &&
      selectedProgresCondTemplate === null
    ) {
      newErrors.progressCondReleasesTemplate = 'Template is required';
      isValid = false;
    }
    if (
      progressReleases !== null &&
      progressReleases?.includes('UNCONDITIONAL') &&
      selectedProgresUnCondTemplate === null
    ) {
      newErrors.progressUncondReleasesTemplate = 'Template is required';
      isValid = false;
    }
    if (
      progressReleases !== null &&
      progressReleases?.includes('DUAL') &&
      selectedProgresDualTemplate === null
    ) {
      newErrors.progressDualReleasesTemplate = 'Template is required';
      isValid = false;
    }
    if (
      finalReleases !== null &&
      finalReleases?.includes('CONDITIONAL') &&
      selectedFinalCondTemplate === null
    ) {
      newErrors.finalCondReleasesTemplate = 'Template is required';
      isValid = false;
    }
    if (
      finalReleases !== null &&
      finalReleases?.includes('UNCONDITIONAL') &&
      selectedFinalUnCondTemplate === null
    ) {
      newErrors.finalUncondReleasesTemplate = 'Template is required';
      isValid = false;
    }
    if (
      finalReleases !== null &&
      finalReleases?.includes('DUAL') &&
      selectedFinalDualTemplate === null
    ) {
      newErrors.finalDualReleasesTemplate = 'Template is required';
      isValid = false;
    }
    if (buyerRep === null && isConditionalOrUnconditional) {
      newErrors.buyerRep = 'Billing Rep is required';
      isValid = false;
    }

    if (jobInput.accountantEmails.trim() !== '' && isConditionalOrUnconditional) {
      const emailRegex = /[\w.+-]+@[\w-]+\.[a-z]{1,}/gi;
      const isMatch = emailRegex.test(jobInput.accountantEmails);
      if (!isMatch) {
        const inputEmail = jobInput.accountantEmails.split(/[;, ]/);
        newErrors.accountantEmails =
          inputEmail.length === 1
            ? 'Invalid email'
            : 'Email list should either be separated by ";" "," or space';
        isValid = false;
      } else {
        const validEmails = extractEmailAddresses(jobInput.accountantEmails);
        const inputEmail = jobInput.accountantEmails.split(/[;, ]/);
        if (inputEmail > validEmails) {
          newErrors.accountantEmails = 'Email list contains an invalid email';
          isValid = false;
        }
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const showSuccessMessage = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: '#ECFDF3',
        color: '#054F31',
      },
    });
  };

  const showErrorMessage = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: '#FEF0EF',
        color: '#F04438',
      },
    });
  };

  const handleAddJob = async () => {
    const valInputs = validateInputs();
    setShowErrorAbove(!valInputs);
    if (!validateInputs() || !selectedMainBuyerId) {
      return;
    }
    setLoading(true);
    const jobData = {
      ...jobInput,
      name: jobInput.name,
      number: jobInput.number,
      customerNumber: jobInput.customerNumber !== '' ? jobInput.customerNumber : '',
      // customerNumber: jobInput.customerNumber !== '' ? jobInput.customerNumber : null,
      // supplierNumber: jobInput.supplierNumber !== '' ? jobInput.supplierNumber : null,
      accountantEmails: extractEmailAddresses(jobInput.accountantEmails, true),
      defaultThroughDate,
      address: jobInput.address,
      city: jobInput.city,
      state: jobInput.state,
      notes: jobInput.notes,
      zipCode: jobInput.zipCode,
      startDate: isConditionalOrUnconditional ? startDate : null,
      endDate: isConditionalOrUnconditional ? finishDate : null,
      subSupplierIds: isConditionalOrUnconditional ? selectedSupplierId() : [],
      releaseRequirements: {
        PROGRESS: progressReleases ?? [],
        FINAL: finalReleases ?? [],
      },
      templates: {
        PROGRESS_UNCONDITIONAL: !progressReleases?.includes('UNCONDITIONAL')
          ? ''
          : isConditionalOrUnconditional
          ? selectedProgresUnCondTemplate?.id ?? ''
          : '',
        PROGRESS_CONDITIONAL: !progressReleases?.includes('CONDITIONAL')
          ? ''
          : isConditionalOrUnconditional
          ? selectedProgresCondTemplate?.id ?? ''
          : '',
        PROGRESS_DUAL: !progressReleases?.includes('DUAL')
          ? ''
          : isConditionalOrUnconditional
          ? selectedProgresDualTemplate?.id ?? ''
          : '',
        FINAL_UNCONDITIONAL: !finalReleases?.includes('UNCONDITIONAL')
          ? ''
          : isConditionalOrUnconditional
          ? selectedFinalUnCondTemplate?.id ?? ''
          : '',
        FINAL_CONDITIONAL: !finalReleases?.includes('CONDITIONAL')
          ? ''
          : isConditionalOrUnconditional
          ? selectedFinalCondTemplate?.id ?? ''
          : '',
        FINAL_DUAL: !finalReleases?.includes('DUAL')
          ? ''
          : isConditionalOrUnconditional
          ? selectedFinalDualTemplate?.id ?? ''
          : '',
      },
      owner: isConditionalOrUnconditional ? jobInput.owner : '',
      billingRep: isConditionalOrUnconditional ? buyerRep?.userIds ?? null : null,
    };
    let result;
    if (!editMode) {
      const createresult = await dispatch(
        createJob({ details: { ...jobData }, mainBuyerId: selectedMainBuyerId })
      );
      if (createresult.type === 'jobs/createJob/fulfilled') {
        showSuccessMessage('Job Added!');
        if (selectedMainBuyerId) {
          await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
        }
        handleCloseModal();
      } else {
        showErrorMessage('Error adding job!');
      }
    } else {
      let updateSupplierDateRange: {
        [key: string]: { startDate: Date; endDate: Date };
      } | null = null;
      if (selectedJob?.supplierDateRanges && startDate && finishDate) {
        const supplierDateRanges = selectedJob.supplierDateRanges;
        updateSupplierDateRange = Object.keys(supplierDateRanges).reduce((acc, supplierId) => {
          const dateRange = supplierDateRanges[supplierId];
          const startDateMoment = moment(startDate);
          const endDateMoment = moment(finishDate);
          const startDateString = moment(startDate).format('MM/DD/YYYY');
          const endDateString = moment(finishDate).format('MM/DD/YYYY');
          const newEndDate = moment(startDate).endOf('month').format('MM/DD/YYYY');
          const calculatedEndDate = endDateMoment.isBefore(dateRange.endDate)
            ? finishDate
            : startDateMoment.isAfter(dateRange.endDate)
            ? new Date(newEndDate)
            : new Date(dateRange.endDate);
          return {
            ...acc,
            [supplierId]: {
              startDate: startDate,
              endDate: calculatedEndDate,
            },
          };
        }, {});
      }
      if (updateSupplierDateRange) {
        for (const key in updateSupplierDateRange) {
          if (!jobData?.subSupplierIds?.includes(key)) {
            delete updateSupplierDateRange?.[key];
          }
        }
      }
      updateSupplierDateRange =
        updateSupplierDateRange && Object.keys(updateSupplierDateRange).length === 0
          ? null
          : updateSupplierDateRange;
      const editresult = await dispatch(
        updateJob({
          mainBuyerId: selectedMainBuyerId,
          jobId: selectedJob?.id ?? '',
          jobDetails: { ...jobData, supplierDateRanges: updateSupplierDateRange },
        })
      );
      if (editresult.type === 'jobs/updateJob/fulfilled') {
        showSuccessMessage('Job Updated!');
        if (selectedMainBuyerId) {
          await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
        }
        handleCloseModal();
      } else {
        showErrorMessage('Error updating job!');
      }
    }
    setLoading(false);
  };

  const handleStartDate = (value: Date) => {
    setStartDate(value);
    if (!finishDate) {
      const year = value.getFullYear();
      const month = value.getMonth();
      const lastDayOfMonth = new Date(year, month + 1, 0);
      setFinishDate(lastDayOfMonth);
    }
  };

  const handleFinishDate = (value: Date) => {
    setFinishDate(value);
  };

  const handleSelectProgress = (value: string) => {
    setShowErrorAbove(false);
    if (progressReleases === null) {
      setProgressReleases([value]);
    } else if (progressReleases.includes(value)) {
      setProgressReleases(progressReleases.filter((item) => item !== value));
    } else if (value === 'DUAL') {
      setProgressReleases([value]);
    } else {
      if (progressReleases.includes('DUAL')) {
        const filteredRelease = progressReleases.filter((item) => item !== 'DUAL');
        setProgressReleases([...filteredRelease, value]);
      } else {
        setProgressReleases([...progressReleases, value]);
      }
    }
    if (value === '') {
      setProgressReleases([]);
    }
    if (!progressReleases?.includes('CONDITIONAL')) {
      setSelectedProgresCondTemplate(null);
    } else if (!progressReleases?.includes('UNCONDITIONAL')) {
      setSelectedProgresUnCondTemplate(null);
    } else if (!progressReleases?.includes('DUAL')) {
      setSelectedProgresDualTemplate(null);
    }
  };
  const handleSelectFinal = (value: string) => {
    setShowErrorAbove(false);
    if (finalReleases === null) {
      setFinalReleases([value]);
    } else if (finalReleases.includes(value)) {
      setFinalReleases(finalReleases.filter((item) => item !== value));
    } else if (value === 'DUAL') {
      setFinalReleases([value]);
    } else {
      if (finalReleases.includes('DUAL')) {
        const filteredRelease = finalReleases.filter((item) => item !== 'DUAL');
        setFinalReleases([...filteredRelease, value]);
      } else {
        setFinalReleases([...finalReleases, value]);
      }
    }
    if (value === '') {
      setFinalReleases([]);
    }
    if (!finalReleases?.includes('CONDITIONAL')) {
      setSelectedFinalCondTemplate(null);
    } else if (!finalReleases?.includes('UNCONDITIONAL')) {
      setSelectedFinalUnCondTemplate(null);
    } else if (!finalReleases?.includes('DUAL')) {
      setSelectedFinalDualTemplate(null);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="new-job-modal-content pt-3">
        <div className="new-job-modal-title-container">
          <h3 className="font-medium text-lg">{editMode ? 'Edit Job' : 'New Job'}</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleCloseModal}
          />
        </div>
        <div className="content-job">
          <div className="input-container">
            <AppInputField
              id="name"
              label="Name"
              isRequired={true}
              value={jobInput.name}
              errorText={errors.name ? errors.name : ''}
              onTextChange={(text) => {
                onChange2({ name: 'name', value: text });
              }}
            />
          </div>
          <div className="row-container">
            <div className="w-full">
              <AppInputField
                id="number"
                label="Your Job Number"
                inputType="text"
                placeholder="12345"
                isRequired={true}
                value={jobInput.number}
                errorText={errors.number ? errors.number : ''}
                onTextChange={(text) => {
                  onChange2({ name: 'number', value: text });
                }}
              />
            </div>
            <div className="spacer" />
            <div className="w-full">
              <AppInputField
                id="customerNumber"
                label="Customer Job Number"
                inputType="text"
                placeholder="12345"
                value={jobInput.customerNumber}
                errorText={errors.customerNumber ? errors.customerNumber : ''}
                onTextChange={(text) => {
                  onChange2({ name: 'customerNumber', value: text });
                }}
              />
            </div>
            {/* <div className="spacer" />
            <div className="input-row-container">
              <AppInputField
                id="supplierNumber"
                label="Supplier Job Number"
                inputType="text"
                placeholder="12345"
                value={jobInput.supplierNumber}
                errorText={errors.supplierNumber ? errors.supplierNumber : ''}
                onTextChange={(text) => {
                  onChange2({ name: 'supplierNumber', value: text });
                }}
              />
            </div> */}
          </div>
          <div className="flex flex-row px-6 py-3">
            <div className="w-full ">
              <AppInputField
                id="address"
                label="Address"
                inputType="text"
                placeholder="Address"
                isRequired={true}
                value={jobInput.address}
                errorText={errors.address ? errors.address : ''}
                onTextChange={(text) => {
                  onChange2({ name: 'address', value: text });
                }}
              />
            </div>
          </div>
          <div className="flex flex-row px-6 pb-3">
            <div className="w-full ">
              <AppInputField
                id="city"
                label="City"
                inputType="text"
                placeholder="San Francisco"
                isRequired={true}
                value={jobInput.city}
                errorText={errors.city ? errors.city : ''}
                onTextChange={(text) => {
                  onChange2({ name: 'city', value: text });
                }}
              />
            </div>
            <div className="spacer" />
            <div className="w-full">
              <AppInputField
                id="state"
                label="State"
                inputType="text"
                placeholder="CA"
                isRequired={true}
                value={jobInput.state}
                errorText={errors.state ? errors.state : ''}
                onTextChange={(text) => {
                  onChange2({ name: 'state', value: text });
                }}
              />
            </div>
            <div className="spacer" />
            <div className="w-full">
              <AppInputField
                id="zipCode"
                label="Zip Code"
                inputType="text"
                placeholder="12345"
                isRequired={true}
                value={jobInput.zipCode}
                errorText={errors.zipCode ? errors.zipCode : ''}
                onTextChange={(text) => {
                  onChange2({ name: 'zipCode', value: text });
                }}
              />
            </div>
          </div>
          <div className="mb-3">
            <div className="ml-6 new-job-label">
              <span>*</span> Release Requirements
            </div>
            <div>
              <div className="GREY_500-CLR mx-6 text-sm">Progress</div>
              <div className="flex mx-6 justify-between mt-1">
                <button
                  className="flex items-center"
                  onClick={() => handleSelectProgress('CONDITIONAL')}>
                  <AppCheckBox
                    checkSize={10}
                    isChecked={
                      progressReleases !== null && progressReleases?.includes('CONDITIONAL')
                    }
                  />
                  <div className="ml-2 text-sm">Conditional</div>
                </button>
                <button className="flex" onClick={() => handleSelectProgress('UNCONDITIONAL')}>
                  <AppCheckBox
                    checkSize={10}
                    isChecked={
                      progressReleases !== null && progressReleases?.includes('UNCONDITIONAL')
                    }
                  />
                  <div className="ml-2 text-sm">Unconditional</div>
                </button>
                <button className="flex" onClick={() => handleSelectProgress('DUAL')}>
                  <AppCheckBox
                    checkSize={10}
                    isChecked={progressReleases !== null && progressReleases?.includes('DUAL')}
                  />
                  <div className="ml-2 text-sm">Dual</div>
                </button>
                <button className="flex" onClick={() => handleSelectProgress('')}>
                  <AppCheckBox
                    isChecked={progressReleases !== null && progressReleases?.length === 0}
                  />
                  <div className="ml-2 text-sm">No Release Required</div>
                </button>
              </div>
              {errors.progressReleases && (
                <p className="mx-6 text-xs ERROR_500-CLR">{errors.progressReleases}</p>
              )}
            </div>
            <div>
              <div className="GREY_500-CLR mx-6 text-sm">Final</div>
              <div className="flex mx-6 justify-between mt-1">
                <button
                  className="flex items-center"
                  onClick={() => handleSelectFinal('CONDITIONAL')}>
                  <AppCheckBox
                    checkSize={10}
                    isChecked={finalReleases !== null && finalReleases?.includes('CONDITIONAL')}
                  />
                  <div className="ml-2 text-sm">Conditional</div>
                </button>
                <button className="flex" onClick={() => handleSelectFinal('UNCONDITIONAL')}>
                  <AppCheckBox
                    checkSize={10}
                    isChecked={finalReleases !== null && finalReleases?.includes('UNCONDITIONAL')}
                  />
                  <div className="ml-2 text-sm">Unconditional</div>
                </button>
                <button className="flex" onClick={() => handleSelectFinal('DUAL')}>
                  <AppCheckBox
                    checkSize={10}
                    isChecked={finalReleases !== null && finalReleases?.includes('DUAL')}
                  />
                  <div className="ml-2 text-sm">Dual</div>
                </button>
                <button className="flex" onClick={() => handleSelectFinal('')}>
                  <AppCheckBox isChecked={finalReleases !== null && finalReleases?.length === 0} />
                  <div className="ml-2 text-sm">No Release Required</div>
                </button>
              </div>
              {errors.finalReleases && (
                <p className="mx-6 text-xs ERROR_500-CLR">{errors.finalReleases}</p>
              )}
            </div>
          </div>
          {progressReleases !== null && progressReleases?.includes('CONDITIONAL') && (
            <div className="mb-3 full mx-6">
              <AppDropDown
                isRequired
                label="Progress Conditional Template"
                errorText={errors.progressCondReleasesTemplate}
                value={selectedProgresCondTemplate ? selectedProgresCondTemplate.id : ''}
                items={[
                  ...templates
                    .filter((template) => template?.type === 'PROGRESS_CONDITIONAL')
                    .map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = templates.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedProgresCondTemplate(uu);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
            </div>
          )}
          {progressReleases !== null && progressReleases?.includes('UNCONDITIONAL') && (
            <div className="mb-3 full mx-6">
              <AppDropDown
                isRequired
                label="Progress Unconditional Template"
                errorText={errors.progressUncondReleasesTemplate}
                value={selectedProgresUnCondTemplate ? selectedProgresUnCondTemplate.id : ''}
                items={[
                  ...templates
                    .filter((template) => template?.type === 'PROGRESS_UNCONDITIONAL')
                    .map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = templates.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedProgresUnCondTemplate(uu);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
            </div>
          )}
          {progressReleases !== null && progressReleases?.includes('DUAL') && (
            <div className="mb-3 full mx-6">
              <AppDropDown
                isRequired
                label="Dual Progress Template"
                errorText={errors.progressDualReleasesTemplate}
                value={selectedProgresDualTemplate ? selectedProgresDualTemplate.id : ''}
                items={[
                  ...templates
                    .filter((template) => template?.type === 'PROGRESS_DUAL')
                    .map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = templates.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedProgresDualTemplate(uu);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
            </div>
          )}
          {finalReleases !== null && finalReleases?.includes('CONDITIONAL') && (
            <div className="mb-3 full mx-6">
              <AppDropDown
                isRequired
                label="Final Conditional Template"
                errorText={errors.finalCondReleasesTemplate}
                value={selectedFinalCondTemplate ? selectedFinalCondTemplate.id : ''}
                items={[
                  ...templates
                    .filter((template) => template?.type === 'FINAL_CONDITIONAL')
                    .map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = templates.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedFinalCondTemplate(uu);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
            </div>
          )}
          {finalReleases !== null && finalReleases?.includes('UNCONDITIONAL') && (
            <div className="mb-3 full mx-6">
              <AppDropDown
                isRequired
                label="Final Unconditional Template"
                errorText={errors.finalUncondReleasesTemplate}
                value={selectedFinalUnCondTemplate ? selectedFinalUnCondTemplate.id : ''}
                items={[
                  ...templates
                    .filter((template) => template?.type === 'FINAL_UNCONDITIONAL')
                    .map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = templates.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedFinalUnCondTemplate(uu);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
            </div>
          )}
          {finalReleases !== null && finalReleases?.includes('DUAL') && (
            <div className="mb-3 full mx-6">
              <AppDropDown
                isRequired
                label="Dual Final Template"
                errorText={errors.finalDualReleasesTemplate}
                value={selectedFinalDualTemplate ? selectedFinalDualTemplate.id : ''}
                items={[
                  ...templates
                    .filter((template) => template?.type === 'FINAL_DUAL')
                    .map((template) => {
                      return {
                        label: template.name,
                        value: template.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    const uu = templates.find((val) => val.id === item.value);
                    if (uu) {
                      setSelectedFinalDualTemplate(uu);
                      setShowErrorAbove(false);
                    }
                  }
                }}
              />
            </div>
          )}
          {isConditionalOrUnconditional && (
            <div>
              <div className="mb-3 full mx-6">
                <AppDropDown
                  isRequired
                  label="Billing Rep"
                  errorText={errors.buyerRep}
                  value={buyerRep ? buyerRep.userIds?.userId ?? null : null}
                  items={[
                    ...fetchUserBuyer.map((userBuyer) => {
                      return {
                        label: `${userBuyer?.firstName ?? ''} ${userBuyer?.lastName ?? ''}`,
                        value: userBuyer.userIds?.userId ?? '',
                      };
                    }),
                  ]}
                  onSelectChange={(item) => {
                    if (item?.value) {
                      const uu = fetchUserBuyer.find((val) => val.id === item.value);
                      if (uu) {
                        setBuyerRep(uu);
                        setShowErrorAbove(false);
                      }
                    }
                  }}
                />
              </div>
              <div className="input-container  my-2">
                <AppInputField
                  id="owner"
                  label="Owner"
                  inputType="text"
                  placeholder="Owner Name"
                  isRequired={true}
                  value={jobInput.owner}
                  errorText={errors.owner ? errors.owner : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'owner', value: text });
                  }}
                />
              </div>
              <div className="input-container  my-2">
                <AppInputField
                  id="GC"
                  label="GC"
                  inputType="text"
                  placeholder="GC"
                  value={jobInput.GC}
                  onTextChange={(text) => {
                    onChange2({ name: 'GC', value: text });
                  }}
                />
              </div>
              <div className="input-container  my-2">
                <AppInputField
                  id="accountantEmails"
                  label="General Contractor Accountant Email"
                  inputType="text"
                  placeholder="Accountant Email"
                  value={jobInput.accountantEmails}
                  onTextChange={(text) => {
                    onChange2({ name: 'accountantEmails', value: text });
                  }}
                />
              </div>
              <div>
                <div className="ml-6 new-job-label">
                  <span>*</span> Date Range
                </div>
                <div className="row-container">
                  <div className="full">
                    <AppDatePicker
                      label={'Start'}
                      errorText={errors.startDate ? errors.startDate : ''}
                      onDateSelected={(date) => {
                        if (date) {
                          handleStartDate(date);
                          setShowErrorAbove(false);
                        }
                      }}
                      selectedDate={startDate || null}
                      dateFormat="MMM yyyy"
                      showMonthYearPicker={true}
                      maximumDate={finishDate || null}
                    />
                  </div>
                  <div className="full ml-4">
                    <AppDatePicker
                      label="Finish"
                      errorText={errors.finishDate ? errors.finishDate : ''}
                      onDateSelected={(date) => {
                        if (date) {
                          const year = date.getFullYear();
                          const month = date.getMonth();
                          const lastDayOfMonth = new Date(year, month + 1, 0);
                          handleFinishDate(lastDayOfMonth);
                          setShowErrorAbove(false);
                        }
                      }}
                      selectedDate={finishDate || null}
                      dateFormat="MMM yyyy"
                      showMonthYearPicker={true}
                      minumumDate={startDate || null}
                    />
                  </div>
                </div>
              </div>
              <div className="input-container  my-2">
                <AppMultiSelectDropDown
                  items={[
                    ...subSuppliers
                      .filter((subsup) => !subsup.archivedState?.includes('BUYER_ARCHIVED'))
                      .map((subSupplier, i) => {
                        return {
                          label: `${subSupplier.name ?? ''}`,
                          value: subSupplier?.id ?? '',
                        };
                      }),
                  ]}
                  onSelect={(data) => {
                    setSelectedSupliers(data);
                    setShowErrorAbove(false);
                  }}
                  label="Suppliers"
                  isRequired
                  isSearchable
                  value={selectedSupliers}
                  errorText={errors.supplierId ? errors.supplierId : ''}
                />
              </div>
              <div className="input-container  my-2">
                <AppInputField
                  id="notes"
                  label="Notes"
                  inputType="textarea"
                  placeholder="Accountant Email"
                  value={jobInput.notes}
                  onTextChange={(text) => {
                    onChange2({ name: 'notes', value: text });
                  }}
                />
              </div>
              {/* <div className="checkbox-container  my-2">
                <AppCheckBox
                  isChecked={defaultThroughDate}
                  onClick={(isChecked: boolean) => {
                    setDefaultThroughDate(!defaultThroughDate);
                  }}
                />
                <div className="input-label ml-2 mt-1">
                  {' '}
                  Pre-set Through Date for this job's releases to always be end of the month
                </div>
              </div> */}
            </div>
          )}
        </div>
        <div className="flex flex-col w-full  mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          {showErrorAbove && (
            <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
          )}
          <div className="flex flex-row self-end px-6">
            <AppButton
              text="Close"
              type="TERTIARY"
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                marginRight: '12px',
              }}
              onClick={handleCloseModal}
            />
            <AppButton
              text={editMode ? 'Save' : 'Add New'}
              onClick={handleAddJob}
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                width: '100px',
                height: '40px',
              }}
              isLoading={loading}
              // isDisabled={validateInputs}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
