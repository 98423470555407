import React, { useEffect, useState } from 'react';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { twMerge } from 'tailwind-merge';
import { Viewer } from '@react-pdf-viewer/core';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { AppDropDown } from '../../../../general/AppDropDown/AppDropDown';
import { useLocation, useNavigate } from 'react-router-dom';
import { Files, CaretCircleLeft, CaretCircleRight } from 'phosphor-react';
import { COLORS } from '../../../../../utils/colors';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import successChecksCircle from '../../../../../assets/svg/success_checks_circle.svg';
import { createBuyerLienTemplate } from '../../../../../redux/services/leinTemplatesService';
import { useAppDispatch } from '../../../../../redux/store';
import { LienTemplateTypes, capitalizeWordsInSentence } from '@dill/dill-shared';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { openDillToast, removePDFFields } from '../../../../../utils/helpers';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerAddLienTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel, jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

  const [selectedPdf, setSelectedPdf] = useState<string | null>('');
  const [selectedFlattenedPdf, setSelectedFlattenedPdf] = useState<string | null>('');
  const [isAddTemplateSuccessModalOpen, setIsAddTemplateSuccessModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateType, setTemplateType] = useState<LienTemplateTypes>('PROGRESS_UNCONDITIONAL');
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [isWetSignature, setIsWetSignature] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedMainBuyerId] = useParamMainBuyerId();

  useEffect(() => {
    if (state?.pdf) {
      setTemplateName(state.name);
      setSelectedPdf(state.pdf);
    }
    return () => {};
  }, [state]);

  const handleFlattenPdf = async (url: string) => {
    const flattened = await removePDFFields(url);
    if (flattened) {
      setSelectedFlattenedPdf(flattened);
    } else {
      openDillToast({ type: 'ERROR', message: 'Error loading pdf' });
      setSelectedFlattenedPdf(null);
    }
  };
  useEffect(() => {
    if (selectedPdf) {
      handleFlattenPdf(selectedPdf);
    }
    return () => {};
  }, [selectedPdf]);

  const handleCreateTemplate = async () => {
    if (selectedPdf && templateName && selectedFlattenedPdf && selectedMainBuyerId) {
      const results = await dispatch(
        createBuyerLienTemplate({
          mainBuyerId: selectedMainBuyerId,
          details: {
            pdfUrl: selectedFlattenedPdf,
            type: templateType,
            name: templateName,
            isWetSignature,
          },
        })
      );
      if (results.type === 'lienTemplates/createBuyerLienTemplate/fulfilled') {
        setIsAddTemplateSuccessModalOpen(true);
      }
    }
    if (!templateName) {
      setErrorFields([...errorFields, 'templateName']);
    } else {
      setErrorFields([...errorFields.filter((field) => field !== 'templateName')]);
    }
  };

  return (
    <div className="flex h-full p-5 relative">
      <AppSuccessModal
        open={isAddTemplateSuccessModalOpen}
        modalImage={successChecksCircle}
        title={'New template added!'}
        subTitle="Your template has been sent to the Dill team for processing which will take 1-2 hours. After that, your template will be ready to use"
        buttonText="Back to All Templates"
        handleClose={() => {
          setIsAddTemplateSuccessModalOpen(false);
          navigate(`/buyerLienReleases/${selectedMainBuyerId}/templates`);
        }}
      />
      <div className="w-full h-full flex bg-white p-5 justify-between pb-40">
        <div className="w-4/12 px-2 h-full bg-white">
          <div className="my-3 mb-4">
            <AppInputField
              label="Name"
              isRequired={true}
              errorText={errorFields.includes('templateName') ? 'Required' : ''}
              value={templateName}
              placeholder="New York"
              onTextChange={(text) => {
                setTemplateName(text);
              }}
            />
          </div>

          <div className="my-3">
            <AppDropDown
              label="Type"
              value={templateType}
              items={[
                'PROGRESS_UNCONDITIONAL',
                'PROGRESS_CONDITIONAL',
                'FINAL_UNCONDITIONAL',
                'FINAL_CONDITIONAL',
                'PROGRESS_DUAL',
                'FINAL_DUAL',
              ].map((type) => ({
                label: capitalizeWordsInSentence(type.replaceAll('_', ' ')),
                value: type,
              }))}
              onSelectChange={(item) => {
                setTemplateType(item?.value);
              }}
            />
          </div>

          <div className="my-5">
            <button className="flex" onClick={() => setIsWetSignature(!isWetSignature)}>
              <AppCheckBox isChecked={isWetSignature} />
              <div className="ml-2 text-sm font-medium">Require wet signature?</div>
            </button>
          </div>

          <div className="">
            <label className="text-xs mb-0.5  whitespace-nowrap font-medium">File</label>
            <div className="flex items-center">
              <Files
                color={COLORS.GREY_900}
                weight="fill"
                size={50}
                className="p-2 GREY_100-BG rounded-full mr-4"
              />
              <div className="relative">
                <input
                  id="upload"
                  className="absolute w-full h-full top-0 bottom-0 opacity-0"
                  type="file"
                  accept="application/pdf"
                  onChange={(event) => {
                    if (!event?.target.files) {
                      return;
                    }
                    const file = event?.target?.files[0];

                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String: any = reader.result;
                        setSelectedPdf(base64String);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  // onClick={(event) => {}}
                />
                <AppButton
                  type="SECONDARY"
                  text="Change"
                  buttonStyles={{
                    padding: '3px 12px',
                    borderRadius: '5px',
                    height: '26px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-full GREY_200-BG overflow-y-scroll p-5 w-8/12">
          <div
            style={{
              width: '612px',
              height: '792px',
            }}
            className="relative ">
            {selectedFlattenedPdf && (
              <Viewer
                defaultScale={1}
                fileUrl={selectedFlattenedPdf}
                plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                initialPage={currentPage}
                onPageChange={(e) => setCurrentPage(e.currentPage)}
              />
            )}
            {/* 792px */}
            <canvas
              style={{
                // backgroundColor: 'red',
                width: '612px',
                height: '792px',
                left: '50%',
                transform: 'translateX( -50%)',
              }}
              className={twMerge('h-full w-full absolute top-0 left-0 opacity-100')}
            />
          </div>
        </div>
        {/* <div className="w-3/12 h-full bg-white"></div> */}
      </div>

      <div className="absolute w-full h-40 bg-white left-0  bottom-0 z-20 flex justify-end shadow-lg p-2">
        <div className="m-2">
          <CurrentPageLabel>
            {(props: RenderCurrentPageLabelProps) => {
              return (
                <div className="mx-4 flex items-center justify-center">
                  {props.numberOfPages > 1 && (
                    <button
                      className={`mr-2 flex items-center justify-center py-[2px] px-2 ${
                        props.numberOfPages > props.currentPage + 1
                          ? 'GREY_500-CLR cursor-default'
                          : 'hover:bg-blue-100  hover:text-[#3762FB]'
                      }`}
                      onClick={() => {
                        jumpToPreviousPage();
                      }}>
                      <CaretCircleLeft className="mr-1" />
                      Previous Page
                    </button>
                  )}
                  <span>{`${props.numberOfPages > 1 ? '' : 'page'} ${props.currentPage + 1} / ${
                    props.numberOfPages
                  }`}</span>
                  {props.numberOfPages > 1 && (
                    <button
                      className={`ml-2 flex items-center justify-center py-[2px] px-2 ${
                        props.numberOfPages - 1 < props.currentPage + 1
                          ? 'GREY_500-CLR cursor-default'
                          : 'hover:bg-blue-100 hover:text-[#3762FB]'
                      }`}
                      onClick={() => {
                        jumpToNextPage();
                      }}>
                      Next Page
                      <CaretCircleRight className="ml-1" />
                    </button>
                  )}
                </div>
              );
            }}
          </CurrentPageLabel>
        </div>
        <div className="flex relative h-fit">
          <AppButton
            text="Close"
            type="TERTIARY"
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
              marginRight: '12px',
            }}
            onClick={() => {
              navigate(`/buyerLienReleases/${selectedMainBuyerId}/templates`);
            }}
          />
          <AppButton
            text="Add Template"
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
              width: '100px',
            }}
            isDisabled={!selectedFlattenedPdf}
            onClick={() => {
              handleCreateTemplate();
            }}
            // isDisabled={validateInputs}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyerAddLienTemplate;
