import { useEffect, useMemo, useState } from 'react';
import emptyListImg from '../../../../assets/images/emptyList.png';
import moment from 'moment';
import './ManualUploadInvoicesTable.scss';
import { roundTo, QuickbooksInvoice } from '@dill/dill-shared';
import { CaretLeft, CaretRight, Trash } from 'phosphor-react';
import { List, AutoSizer } from 'react-virtualized';
import ReactPaginate from 'react-paginate';
import { deleteSupplierInvoice } from '../../../../redux/services/invoicesService';
import {
  getManualUploadInvoices,
  recursiveGetManualUploadInvoices,
} from '../../../../redux/services/manualUploadService';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { AppInfiniteScroll } from '../../../general/AppInfiniteScroll/AppInfiniteScroll';

export const ManualUploadInvoicesTable = ({ invoices }: { invoices: QuickbooksInvoice[] }) => {
  const dispatch = useAppDispatch();
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items per page
  const pageCount = Math.ceil(invoices.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = invoices.slice(itemOffset, endOffset);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { user } = useAppSelector((state) => state.auth);
  const { numberOfManualInvoices, manualUploadInvoices: savedManualUploadInvoice } = useAppSelector(
    (state) => state.manualUpload
  );

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % invoices.length;
    setItemOffset(newOffset);
  };
  const cummulativeOffset = useMemo(() => {
    let result = 0;
    const currentTotalItems = endOffset / itemsPerPage;
    if (pageCount === currentTotalItems) {
      result = invoices.length;
    } else if (invoices.length === 0) {
      result = 0;
    } else {
      const itemsTotal = currentTotalItems * itemsPerPage;
      result = itemsTotal;
    }
    return result;
  }, [endOffset, invoices]);

  useEffect(() => {
    setItemOffset(0);
    setCurrentPage(1);
  }, [invoices]);

  const columns = useMemo(() => {
    const currentColumns =
      user?.userSupplier?.integrationType === 'ABLE_MANUAL_UPLOAD'
        ? [
            { id: 'invoiceNumber', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'poNumber', name: 'PO #', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'customer', name: 'Customer', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'job', name: 'Job', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'invoiceDate', name: 'Date', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'dueDate', name: 'Due Date', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'terms', name: 'Terms', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'balance', name: 'Open', width: 100, cellType: 'VIEW_TEXT' },
          ]
        : [
            { id: 'invoiceNumber', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'poNumber', name: 'PO #', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'customer', name: 'Customer', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'job', name: 'Job', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'invoiceDate', name: 'Date', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'dueDate', name: 'Due Date', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'terms', name: 'Terms', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'balance', name: 'Open', width: 100, cellType: 'VIEW_TEXT' },
            { id: 'action', name: '', width: 100, cellType: 'VIEW_TEXT' },
          ];

    return currentColumns;
  }, [invoices, user]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleDeleteSupplierInvoice = async (invoiceId: string) => {
    dispatch(
      openMessageModal({
        buttonText: 'Close',
        modalType: 'WARN',
        title: 'Are you sure you would like to delete this invoice?',
        subTitle:
          'Doing so will delete it from the portal as well as any associated payments that you have imported on your side.',
        secondButtonText: 'Proceed',
        isSecondButton: true,
        onSecondButtonClick: async () => {
          const res = await dispatch(deleteSupplierInvoice({ invoiceId: invoiceId }));
          if (res.type === 'invoice/deleteSupplierInvoice/fulfilled') {
          }
          dispatch(getManualUploadInvoices({ itemsPerPage: 50 }));
        },
      })
    );
  };

  const fetchData = async () => {
    setLoading(true);
    if (savedManualUploadInvoice.length !== numberOfManualInvoices) {
      if (savedManualUploadInvoice.length > 0) {
        console.log({
          itemsPerPage: 50,
          startAfter: savedManualUploadInvoice[savedManualUploadInvoice.length - 1],
        });
        await dispatch(
          recursiveGetManualUploadInvoices({
            itemsPerPage: 50,
            startAfter: savedManualUploadInvoice[savedManualUploadInvoice.length - 1].docNumber,
          })
        );
      }
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const renderData = (ref: React.RefCallback<HTMLElement>) => {
    return invoices.map((invoice, index) => {
      return (
        <div
          key={index + 'oo'}
          ref={index === invoices.length - 1 ? ref : null}
          className={'flex  px-5 my-2 border-b border-b-gray-200'}>
          {columns.map((column) => {
            type ObjectKey = keyof typeof invoice;
            const columnKey = column.id as ObjectKey;
            let value = invoice[columnKey]?.toString();
            if (
              column.id === 'createdAt' ||
              column.id === 'dueDate' ||
              column.id === 'invoiceDate'
            ) {
              value = moment(value).utc().format('MM/DD/YYYY');
            }
            if (column.id === 'balance' || column.id === 'amount') {
              value = `$${roundTo(parseFloat(value || '0'), 2)}`;
            }
            if (column.id === 'invoiceNumber') {
              value = invoice.id;
            }

            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className="flex w-full h-full ">
                {column.cellType === 'VIEW_TEXT' &&
                  column.id !== 'job' &&
                  column.id !== 'customer' && <p className={'text-sm '}>{value}</p>}
                {column.cellType === 'VIEW_TEXT' && column.id === 'job' && (
                  <p className={'text-sm '}>{invoice.quickBooksJob?.jobName ?? ''}</p>
                )}
                {column.cellType === 'VIEW_TEXT' && column.id === 'customer' && (
                  <p className={'text-sm '}>{invoice.billAddress?.name ?? ''}</p>
                )}

                {column.cellType === 'VIEW_TEXT' && column.id === 'action' && (
                  <Trash
                    className="text-red-600 cursor-pointer"
                    onClick={() => {
                      handleDeleteSupplierInvoice(invoice.id);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };
  return (
    <div className=" h-full overflow-y-scroll">
      <div className="flex flex-col overflow-x-scroll h-full  w-full border rounded-lg">
        <div className="flex w-full h-full flex-col overflow-y-scroll ">
          <div className="flex border-b px-5 items-center w-full sticky top-0 WHITE-BG py-3 z-10">
            {columns.map((column) => {
              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  className="flex w-full h-full">
                  {column.cellType !== 'SELECT' && (
                    <div>
                      {column.id === 'customerName' ? (
                        <p className="TEXT_SECONDARY-CLR text-xs" style={{ width: column.width }}>
                          {column.name}
                        </p>
                      ) : (
                        <p className="TEXT_SECONDARY-CLR text-xs ">{column.name}</p>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {invoices.length < 1 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                <p>No invoices</p>
              </div>
            </div>
          )}
          {invoices.length > 0 && (
            <AppInfiniteScroll
              fetchData={fetchData}
              renderList={renderData}
              loading={loading}
              hasMore={hasMore}
            />
          )}
        </div>
      </div>
    </div>
  );
};
