import { Modal } from '@mui/material';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import { AppInputField } from '../AppInputField/AppInputField';
import React, { useState } from 'react';

export const AppSubmitEmailModal = ({
  open,
  handleClose,
  onSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  onSubmit: (val: string) => void;
}) => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const [error, setError] = useState('');

  const handleCloseModal = () => {
    setTextFieldValue('');
    handleClose();
  };
  const handleSubmit = () => {
    if (!textFieldValue) {
      setError('Email is required');
      return;
    } else {
      const emailRegex = /[\w.+-]+@[\w-]+\.[a-z]{1,}/gi;
      const isMatch = emailRegex.test(textFieldValue);
      if (!isMatch) {
        setError('Email is not valid');
        return;
      }
    }
    onSubmit(textFieldValue);
    handleCloseModal();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col w-[412px]">
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleCloseModal}
        />
        <div className="text-lg font-semibold  my-3 mt-10 w-full item-center text-center">
          Are you sure you would like to submit this job information sheet?
        </div>
        <div className="flex flex-col w-full p-4">
          <AppInputField
            isRequired={true}
            label={'Enter your email for confirmation'}
            inputType="email"
            errorText={error}
            onTextChange={(text) => {
              setTextFieldValue(text);
              setError('');
            }}
            value={textFieldValue}
          />
        </div>
        <div className="flex flex-row m-3 gap-2 items-center justify-center">
          <AppButton
            text={'Close'}
            type="TERTIARY"
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={handleCloseModal}
          />
          <AppButton
            text={'Submit'}
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};
