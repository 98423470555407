import { useMemo, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { List, AutoSizer } from 'react-virtualized';
import emptyListImg from '../../../../assets/images/emptyList.png';
import './BuyerPaymentTable.scss';
import { AppButton } from '../../../general/AppButton/AppButton';
import { COLORS } from '../../../../utils/colors';
import { Timer, CheckCircle, Warning, CreditCard, Bank, Coins } from 'phosphor-react';
import { AppPaidInvoicesModal } from '../../../general/AppPaidInvoicesModal/AppPaidInvoicesModal';
import moment from 'moment';
import { DillTransaction, formatMoney } from '@dill/dill-shared';
import AppTransactionStatusPill from '../../../general/AppTransactionStatusPill/AppTransactionStatusPill';
import { getTransactionById } from '../../../../redux/services/paymentsService';
import { useParamMainBuyerId } from '../../../../utils/hooks/useParamMainBuyerId';

export interface InvoiceType {
  amount: number;
  amountPaid: number;
  dueDate?: Date | undefined;
  invoiceId: string;
  invoiceNumber: string;
  jobId?: string;
}
export const BuyerPaymentTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { buyerTransactions } = useAppSelector((state) => state.payments);
  const [openPaidInvoiceDetails, setOpenPaidInvoiceDetails] = useState(false);
  const [reciepient, setReciepient] = useState('' as string);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [sender, setSender] = useState('' as string);
  const [selectedStatus, setSelectedStatus] = useState('' as string);
  const [transactionError, setTransactionError] = useState('' as string | undefined | null);
  const [paymentType, setPaymentType] = useState('' as string);
  const [paymentAmount, setPaymentAmount] = useState(0 as number);
  const [partialPaymentReason, setPartialPaymentReason] = useState('' as string | undefined | null);
  const [selectedTransaction, setSelectedTransaction] = useState<DillTransaction | null>(null);
  const [selectedDate, setSelectedDate] = useState('' as string);
  const [selectedPaymentName, setSelectedPaymentName] = useState('' as string);

  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'invoice', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'createdAt', name: 'Date', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'paidTo', name: 'Paid To', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'transactionStatus', name: 'Status', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'method', name: 'Method', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'viewDetails', name: '', width: 150, cellType: 'VIEW_TEXT' },
    ];
    return currentColumns;
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleShowPaidInvoices = async (
    receiver: string,
    senderName: string,
    transactionStatus: string,
    transactionErrorMsg: string | undefined | null,
    paymentTransactionType: string,
    transactionAmount: number,
    partialPayment: string | undefined | null,
    payment: DillTransaction
  ) => {
    const transactionResults = await dispatch(getTransactionById({ transactionId: payment.id }));
    let finalTransaction = payment;
    if (transactionResults.type === 'payments/getTransactionById/fulfilled') {
      const res = transactionResults?.payload as any;
      finalTransaction = res.data as DillTransaction;
    }
    setReciepient(receiver);
    setSender(senderName);
    setSelectedStatus(transactionStatus);
    setTransactionError(transactionErrorMsg);
    setPaymentType(paymentTransactionType);
    setPaymentAmount(transactionAmount);
    setPartialPaymentReason(partialPayment);
    setSelectedTransaction(finalTransaction);
    setSelectedDate(moment(payment.createdAt).format('MMM DD, YYYY'));
    setSelectedPaymentName(payment?.paymentMethod?.paymentMethodName ?? '');
    setOpenPaidInvoiceDetails(true);
  };

  useEffect(() => {
    if (location.pathname.startsWith(`/companies/${selectedMainBuyerId}/payments`)) {
      const params = new URLSearchParams(location.search);
      const transactionId = params.get('transactionId');
      if (transactionId) {
        const transaction = buyerTransactions.find((trans) => trans.id === transactionId);
        if (transaction) {
          handleShowPaidInvoices(
            transaction?.recipient?.name ?? '',
            transaction?.buyer?.name ?? '',
            transaction.transactionStatus,
            transaction.transactionErrorMessage,
            transaction.type,
            transaction.totalAmountPaid,
            transaction.partialPaymentReason,
            transaction
          );
        }
      }
    }
  }, [location.pathname, buyerTransactions]);

  const handleViewMainPayment = (val: string) => {
    const transaction = buyerTransactions.find((trans) => trans.id === val);
    if (transaction) {
      navigate(`/companies/${selectedMainBuyerId}/payments?transactionId=${val}`);
      handleShowPaidInvoices(
        transaction?.recipient?.name ?? '',
        transaction?.buyer?.name ?? '',
        transaction.transactionStatus,
        transaction.transactionErrorMessage,
        transaction.type,
        transaction.totalAmountPaid,
        transaction.partialPaymentReason,
        transaction
      );
    }
  };

  const renderRow = ({ index, key, style }: any) => {
    const payment = buyerTransactions[index];
    return (
      <div
        key={key + 'buyer-payment-transaction'}
        className={'flex  px-5 my-2 border-b border-b-gray-200'}
        style={style}>
        {columns.map((column) => {
          type ObjectKey = keyof typeof payment;
          const columnKey = column.id as ObjectKey;
          let value = payment[columnKey]?.toString();
          const invoices = payment.invoicePayments;
          const paymentMethod = payment.paymentMethod;
          const methodType =
            payment.type === 'DILL_FACILITATION_FEE_PAYMENT_BUYER' ||
            payment.type === 'DILL_FACILITATION_FEE_PAYMENT_SUPPLIER'
              ? 'FEES'
              : payment.type;
          if (column.id === 'amount') {
            value = formatMoney(payment.totalAmountPaid ?? 0, 2);
          }
          if (column.id === 'invoice') {
            if (invoices.length > 0) {
              if (invoices.length > 1) {
                value = `${invoices[0].invoiceNumber}...${
                  invoices[invoices.length - 1].invoiceNumber
                }`;
              } else {
                value = invoices[0].invoiceNumber;
              }
            } else {
              value = '-';
            }
          }
          if (column.id === 'paidTo') {
            value = payment?.recipient?.name ?? '';
          }
          if (column.id === 'updatedAt') {
            value = moment(value).format('MM/DD/YYYY');
          }
          if (column.id === 'createdAt') {
            value = moment(value).format('MM/DD/YYYY');
          }

          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalWidth }}
              className="flex items-center w-full h-full  justify-center ">
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'viewDetails' &&
                column.id !== 'method' &&
                column.id !== 'transactionStatus' && (
                  <p className={'text-sm text-left w-full'}>{value}</p>
                )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'method' && (
                <div className="w-full flex">
                  <div className="payment-type-logos">
                    {payment?.paymentMethod?.type === 'BANK_ACCOUNT' ? (
                      <Bank size={24} />
                    ) : payment.type === 'CREDIT_PAYMENT' ? (
                      <Coins size={24} />
                    ) : (
                      <CreditCard size={24} />
                    )}
                  </div>
                  <div>
                    {payment?.paymentMethod?.type === 'BANK_ACCOUNT' ? (
                      <div className="payment-method">Bank Transfer</div>
                    ) : payment.type === 'CREDIT_PAYMENT' ? (
                      <div className="payment-method">Credits</div>
                    ) : (
                      <div className="payment-method">Credit/Debit Card</div>
                    )}
                    <div className="payment-method-details">{`${
                      payment?.paymentMethod?.paymentMethodName ?? ''
                    }`}</div>
                  </div>
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'transactionStatus' && (
                <div className="w-full">
                  <AppTransactionStatusPill status={payment.transactionStatus} />
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'viewDetails' && (
                <div className="flex">
                  <AppButton
                    text="Details"
                    type="TERTIARY"
                    buttonStyles={{
                      padding: '8px',
                    }}
                    onClick={() => {
                      handleShowPaidInvoices(
                        payment?.recipient?.name ?? '',
                        payment?.buyer?.name ?? '',
                        payment.transactionStatus,
                        payment.transactionErrorMessage,
                        payment.type,
                        payment.totalAmountPaid,
                        payment.partialPaymentReason,
                        payment
                      );
                      if (
                        location.pathname.startsWith(`/companies/${selectedMainBuyerId}/payments`)
                      ) {
                        navigate(
                          `/companies/${selectedMainBuyerId}/payments?transactionId=${payment.id}`
                        );
                      }
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="overflow-y-scroll h-full">
      <AppPaidInvoicesModal
        open={openPaidInvoiceDetails}
        handleClose={() => {
          setOpenPaidInvoiceDetails(false);
          if (location.pathname.startsWith('/companies')) {
            navigate(`/companies/${selectedMainBuyerId}/payments`);
          }
        }}
        paymentTo={reciepient}
        paymentFrom={sender}
        status={selectedStatus}
        accountType="buyer"
        transactionError={transactionError}
        paymentType={paymentType}
        transactionAmount={paymentAmount}
        partialPaymentReason={partialPaymentReason}
        selectedTransaction={selectedTransaction}
        selectedDate={selectedDate}
        selectedPaymentName={selectedPaymentName}
        onViewMainPayment={handleViewMainPayment}
      />
      <div className="flex flex-col overflow-x-scroll w-full border rounded-lg WHITE-BG h-full">
        <div className="flex w-full h-full flex-col overflow-y-scroll ">
          <div className="flex border-b px-5 items-center w-full sticky top-0 bg-zinc-50 py-3 z-10">
            {columns.map((column) => {
              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  className="flex items-center w-full h-full   justify-center ">
                  {column.cellType !== 'SELECT' && (
                    <p className="TEXT_SECONDARY-CLR text-xs text-left w-full">{column.name}</p>
                  )}
                </div>
              );
            })}
          </div>
          {buyerTransactions.length < 1 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                <p>No Payments</p>
              </div>
            </div>
          )}
          {buyerTransactions.length > 0 && (
            <div className="w-full h-full overflow-scroll">
              <AutoSizer className="w-full ">
                {({ height, width }) => {
                  return (
                    <List
                      className=""
                      width={width}
                      height={height}
                      rowRenderer={renderRow}
                      rowCount={buyerTransactions.length}
                      rowHeight={70}
                    />
                  );
                }}
              </AutoSizer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
