import { useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';

import { appDelay, formatMoney, calculateWillCallFeesAndAmount } from '@dill/dill-shared';
import { AppButton } from '../../../general/AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { supplierPayWillCallInvoices } from '../../../../redux/services/paymentsService';
import { closeMessageModal, openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { twMerge } from 'tailwind-merge';
import EditInvoiceList from '../EditInvoiceList';
import { InvoiceReference } from '../../../../constants/invoice';

export const SupplierAddWillCallPaymentModal = ({
  open,
  handleClose = () => {},
  handlePaymentDone = () => {},
}: {
  open: boolean;
  handleClose: () => void;
  handlePaymentDone: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [cardStatus, setCardStatus] = useState({ isCardComplete: false, errorMessage: '' });
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [invoices, setInvoices] = useState<InvoiceReference[]>([]);
  const [whoPaysFee, setWhoPaysFee] = useState<'SUPPLIER' | 'BUYER'>('BUYER');

  useEffect(() => {
    if (user?.userSupplier) {
      if (
        user?.userSupplier.defaultWhoPaysCreditCardFee === 'BUYER' ||
        user?.userSupplier.defaultWhoPaysCreditCardFee === 'SUPPLIER'
      ) {
        setWhoPaysFee(user?.userSupplier.defaultWhoPaysCreditCardFee);
      } else {
        setWhoPaysFee('BUYER');
      }
    }
  }, [user, open]);

  useEffect(() => {
    if (open) {
      setIsShowErrors(false);
      setCardStatus({ isCardComplete: false, errorMessage: '' });
      setInvoices([
        {
          invoiceNumber: '',
          amount: null,
        },
      ]);
    } else {
      setInvoices([]);
    }

    return () => {};
  }, [open]);

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    // console.log(event);
    setCardStatus({
      isCardComplete: event.complete,
      errorMessage: !event.complete ? event.error?.message || '' : '',
    });
  };

  const invoicesData = useMemo(() => {
    return invoices.map((invoice) => {
      return {
        amount: invoice.amount,
        amountPaid: invoice.amount,
        invoiceNumber: invoice.invoiceNumber || '',
      };
    });
  }, [invoices]);

  const totalInvoicePayment = useMemo(() => {
    return invoicesData.reduce((prev, curr) => {
      return prev + Number(curr.amountPaid);
    }, 0);
  }, [invoicesData]);

  const { buyerFee } = useMemo(() => {
    if (user?.userSupplier && whoPaysFee) {
      const supplier = user?.userSupplier;
      const amount = totalInvoicePayment;
      const calculateAmountFee = calculateWillCallFeesAndAmount({
        amount,
        supplier,
        defaultWhoPaysFee: whoPaysFee,
      });
      return { buyerFee: calculateAmountFee.buyerFees };
    }
    return { buyerFee: 0 };
  }, [totalInvoicePayment, whoPaysFee]);

  const checkForErrors = () => {
    let isError = false;

    invoices.forEach((invoice) => {
      if (invoice.amount && invoice.amount < 1) {
        isError = true;
      }
      if (!invoice.invoiceNumber) {
        isError = true;
      }
      if (invoice.amount === null || invoice.amount === undefined) {
        isError = true;
      }
    });
    if (!cardStatus.isCardComplete) {
      isError = true;
    }
    return isError;
  };

  const handlePay = async () => {
    setIsShowErrors(true);
    if (!stripe || !elements) {
      return;
    }
    if (checkForErrors()) {
      return;
    }
    if (!cardStatus.isCardComplete) {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Error getting card details',
          subTitle: 'Make sure you entered the right details',
        })
      );
      return;
    }

    if (totalInvoicePayment === 0) {
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Total Invoice amount must be grater than $0',
          subTitle: '',
        })
      );
      return;
    }

    dispatch(
      openMessageModal({
        buttonText: 'Cancel',
        isSecondButton: true,
        secondButtonText: 'Pay Now',
        modalType: 'WARN',
        title: `Confirm payment below`,
        subTitle: 'The submitted card details will be charged the given amount.',

        onSecondButtonClick: async () => {
          await appDelay(10);
          dispatch(
            openMessageModal({
              buttonText: '',
              modalType: 'LOADING',
              title: 'Processing payment',
              subTitle: 'This may take a few minutes. Please do not refresh the page',
            })
          );
          const cardElement = elements.getElement(CardElement);

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
          console.log(error);
          if (error) {
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'ERROR',
                title: 'Error validating card',
                subTitle: `${error}`,
              })
            );
            return;
          }

          const paymentResponse = await dispatch(
            supplierPayWillCallInvoices({
              whoPaysFee,
              invoiceData: {
                buyerId: '',
                invoices: invoicesData,
                receivingSupplierId: user?.userSupplier?.id || '',
                senderStripePaymentMethodId: paymentMethod.id,
              },
            })
          );

          if (paymentResponse.type === 'payments/supplierPayWillCallInvoices/fulfilled') {
            const dataResults: any = paymentResponse.payload;
            if (dataResults && dataResults?.data?.data?.clientSecret) {
              const clientSecret = dataResults?.data?.data?.clientSecret;
              try {
                const stripeConfirmRes = await stripe?.confirmCardPayment(clientSecret, {});
                console.log({ stripeConfirmRes });
                if (stripeConfirmRes?.error) {
                  dispatch(
                    openMessageModal({
                      buttonText: 'Close',
                      modalType: 'ERROR',
                      title: 'Error processing payment',
                      subTitle: `${stripeConfirmRes?.error?.message || ''}`,
                    })
                  );
                  return;
                }
              } catch (err) {
                dispatch(
                  openMessageModal({
                    buttonText: 'Close',
                    modalType: 'ERROR',
                    title: 'Error processing payment',
                    subTitle: 'Error validating payment. Please try again',
                  })
                );
                return;
              }
            }
            dispatch(closeMessageModal());
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'SUCCESS',
                title: 'Payment successfully sent!',
                subTitle: `Please check your Transaction History for confirmation of final payment status`,
                onClose: () => {
                  handlePaymentDone();
                },
              })
            );
          } else if (paymentResponse.type === 'payments/supplierPayWillCallInvoices/rejected') {
            dispatch(closeMessageModal());
            let errorMessage = '';
            if (paymentResponse.type === 'payments/supplierPayWillCallInvoices/rejected') {
              errorMessage = paymentResponse.payload as any;
            }
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'ERROR',
                title: 'Error processing payment',
                subTitle: `Contact customer support. ${errorMessage}`,
              })
            );
          } else {
            dispatch(closeMessageModal());
          }
        },
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-5/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg flex-col px-3 py-1">
        <div className="flex justify-between items-center mb-2 ">
          <div className="flex">
            <h3 className="font-medium text-lg">Add Will Call Payment</h3>
          </div>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleClose}
          />
        </div>
        <div className="w-full mb-3">
          <EditInvoiceList className="mb-3" invoices={invoices} setInvoices={setInvoices} />
          <div className="flex flex-col justify-between w-full pb-2  p-3 mt-3  bg-slate-50 rounded-md">
            <div className="flex flex-row justify-between w-full pt-1 ">
              <div className="text-base font-bold TEXT_SECONDARY-CLR">Amount</div>
              <div className="text-black font-bold text-lg">{`${formatMoney(
                totalInvoicePayment,
                2
              )}`}</div>
            </div>
            {buyerFee > 0 && (
              <div className="flex flex-row justify-between w-full pt-1">
                <div className="text-base font-bold TEXT_SECONDARY-CLR">Buyer Fee</div>
                <div className="text-black font-bold text-lg">{`${formatMoney(buyerFee, 2)}`}</div>
              </div>
            )}
            <div className="flex my-2">
              <div
                onClick={() => {
                  if (whoPaysFee === 'SUPPLIER') {
                    setWhoPaysFee('BUYER');
                  } else {
                    setWhoPaysFee('SUPPLIER');
                  }
                }}
                className={`w-[40px] h-[22px] rounded-full p-[1px] grid flex-col cursor-pointer ${
                  whoPaysFee === 'BUYER' ? 'justify-items-end bg-[#3862fb]' : 'GREY_200-BG'
                }`}>
                <div className="bg-white w-[20px] h-[20px] rounded-full" />
              </div>
              <div className="w-[180px] ml-2 font-semibold TEXT_SECONDARY-CLR text-sm">
                Fees paid by contractor
              </div>
            </div>
            <div className="flex flex-row justify-between w-full pt-1  border-t">
              <div className="text-base font-bold">Total Payment</div>
              <div className="PRIMARY_500-CLR font-bold text-lg">{`${formatMoney(
                totalInvoicePayment + buyerFee,
                2
              )}`}</div>
            </div>
          </div>

          <div className="flex flex-col p-4 PRIMARY_50-BG mt-6">
            <p className="mb-3">Card Details</p>
            <div
              className={twMerge(
                'px-1 py-3  rounded-sm',
                isShowErrors && !cardStatus.isCardComplete ? 'border border-[#F04438]' : ''
              )}>
              <label className="text-xs mb-1" htmlFor="card-element">
                {/* Card details: */}
                <CardElement
                  id="card-element"
                  onChange={handleCardChange}
                  options={{ disableLink: true }}
                />
              </label>
              {isShowErrors && !cardStatus.isCardComplete && (
                <div className="flex flex-col">
                  <p className="text-[#F04438] text-2xs mt-3">
                    Make sure to enter the right card details.
                  </p>
                  <p className="text-[#F04438] text-2xs">{cardStatus?.errorMessage}</p>
                </div>
              )}
            </div>
          </div>

          <div className="mt-3 w-full flex justify-end ">
            <div className=" flex">
              <div className="mr-2">
                <AppButton text="Close" type="TERTIARY" onClick={handleClose} />
              </div>

              <AppButton text="Pay" onClick={handlePay} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
