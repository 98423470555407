import React, { useMemo } from 'react';
import { Ellipsis } from 'react-css-spinners';
import './AppProgressSpinner.scss';
import { useAppSelector } from '../../../redux/store';
import { twMerge } from 'tailwind-merge';

export const AppProgressSpinner = () => {
  const { loadingList: buyerLoadingList } = useAppSelector((state) => state.buyers);
  const { loadingList: supplierLoadingList } = useAppSelector((state) => state.supplier);
  const { loadingList: paymentLoadingList } = useAppSelector((state) => state.payments);
  const { loadingList: supplierJobsLoadingList } = useAppSelector((state) => state.supplierJobs);

  const isShowExtra = useMemo(() => {
    return (
      (buyerLoadingList.length > 0 && buyerLoadingList.includes('getGroupedBuyersSuppliers')) ||
      (paymentLoadingList.length > 0 &&
        paymentLoadingList.some(
          (item) =>
            item === 'getSupplierRegistrationStatus' ||
            item === 'getBuyerTransactions' ||
            item === 'getSupplierTransactions'
        )) ||
      (supplierLoadingList.length > 0 &&
        supplierLoadingList
          .filter((item) => item !== 'recursiveGetUserCustomers')
          .some(
            (item) =>
              item === 'getUserCustomers' || item === 'getUserInvoices' || item === 'getUserDrivers'
          )) ||
      (supplierJobsLoadingList.length > 0 && supplierJobsLoadingList.includes('getSupplierJobs'))
    );
  }, [buyerLoadingList, supplierLoadingList, supplierJobsLoadingList]);
  return (
    <div className="progress-spinner-wrapper flex flex-col bg-white h-4">
      <div
        className={twMerge(
          'w-fit flex flex-col items-center justify-center rounded-lg ',
          isShowExtra ? 'bg-white py-2 border border-blue-600' : ''
        )}>
        {isShowExtra && (
          <p className="w-1/2 text-center text-sm">
            Because of increased traffic volume, longer loading times may be expected. Thank you for
            your patience.
          </p>
        )}
        <Ellipsis color="#3762FB" size={70} />
      </div>
    </div>
  );
};
