import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { PreliminaryNoticeCreate, PreliminaryNoticeUpdate } from '@dill/dill-shared';
import { firebaseFileUploadHelper } from '../../utils/helpers';

export const getSupplierPreliminaryNotices = createAsyncThunk(
  'preliminaryNotices/getSupplierPreliminaryNotices',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/preliminaryNotices/getSupplierPreliminaryNotices`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getPreliminaryNotice = createAsyncThunk(
  'releases/getPreliminaryNotice',
  async ({ preliminaryNoticeId }: { preliminaryNoticeId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/suppliers/preliminaryNotices/getPreliminaryNotice/${preliminaryNoticeId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updatePreliminaryNotice = createAsyncThunk(
  'preliminaryNotices/updatePreliminaryNotice',
  async (
    {
      preliminaryNoticeId,
      preliminaryNoticeDetails,
    }: {
      preliminaryNoticeId: string;
      preliminaryNoticeDetails: PreliminaryNoticeUpdate;
      isHideLoadingIndicator?: boolean;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      let preliminaryNoticeData = {
        ...preliminaryNoticeDetails,
      };

      if (
        preliminaryNoticeDetails.pdf?.url &&
        preliminaryNoticeId &&
        preliminaryNoticeDetails?.jobId
      ) {
        const storagePathName = `preliminaryNotices/${preliminaryNoticeDetails.supplierId ?? ''}/${
          preliminaryNoticeDetails?.jobId ?? ''
        }/${preliminaryNoticeId}`;
        const downloadUrl = await firebaseFileUploadHelper({
          blobUrl: preliminaryNoticeDetails.pdf?.url,
          fileNameWithExtension: `preliminaryNotice-${preliminaryNoticeId}-${new Date().getTime()}.pdf`,
          storagePath: storagePathName,
        });
        preliminaryNoticeData = {
          ...preliminaryNoticeData,
          pdf: { url: downloadUrl ?? '', name: preliminaryNoticeDetails.pdf?.name ?? '' },
        };
      }

      const response = await axios.put<AppServerResponse>(
        BASE_API_URL +
          `/suppliers/preliminaryNotices/updatePreliminaryNotice/${preliminaryNoticeId}`,
        {
          ...preliminaryNoticeData,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createPreliminaryNotice = createAsyncThunk(
  'preliminaryNotices/createPreliminaryNotice',
  async (
    {
      preliminaryNoticeDetails,
    }: {
      preliminaryNoticeDetails: PreliminaryNoticeCreate;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const preliminaryNoticeData = {
        ...preliminaryNoticeDetails,
      };

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/preliminaryNotices/createPreliminaryNotice`,
        { ...preliminaryNoticeData },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response?.data?.data?.id) {
        const storagePathName = `preliminaryNotices/${preliminaryNoticeDetails.supplierId ?? ''}/${
          preliminaryNoticeDetails?.jobId ?? ''
        }/${response.data.data.id}`;
        let preliminaryNoticeUpdateData = {};

        if (preliminaryNoticeDetails.pdf?.url) {
          const downloadUrl = await firebaseFileUploadHelper({
            blobUrl: preliminaryNoticeDetails.pdf?.url,
            fileNameWithExtension: `preliminaryNotice-${
              response.data.data.id
            }-${new Date().getTime()}.pdf`,
            storagePath: storagePathName,
          });

          preliminaryNoticeUpdateData = {
            pdf: { url: downloadUrl ?? '', name: preliminaryNoticeDetails.pdf?.name ?? '' },
          };
          await thunkApi.dispatch(
            updatePreliminaryNotice({
              preliminaryNoticeId: response.data.data.id,
              preliminaryNoticeDetails: preliminaryNoticeUpdateData,
            })
          );
        }
      }
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deletePreliminaryNotice = createAsyncThunk(
  'preliminaryNotices/deletePreliminaryNotice',
  async (
    {
      preliminaryNoticeId,
    }: {
      preliminaryNoticeId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.delete<AppServerResponse>(
        BASE_API_URL +
          `/suppliers/preliminaryNotices/deletePreliminaryNotice/${preliminaryNoticeId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const manualCreatePreliminaryNotice = createAsyncThunk(
  'preliminaryNotices/manualCreatePreliminaryNotice',
  async (
    {
      preliminaryNoticeDetails,
      preliminaryNoticePdf,
      createdAt,
      isHideLoadingIndicator,
    }: {
      preliminaryNoticeDetails: PreliminaryNoticeCreate;
      preliminaryNoticePdf: string;
      createdAt: Date | null;
      isHideLoadingIndicator?: boolean;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/preliminaryNotices/manualCreatePreliminaryNotice`,
        { preliminaryNoticeDetails, createdAt },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response?.data?.data?.id) {
        const storagePathName = `preliminaryNotices/${preliminaryNoticeDetails.supplierId ?? ''}/${
          preliminaryNoticeDetails?.jobId ?? ''
        }/${response.data.data.id}`;
        let preliminaryNoticeUpdateData = {};

        if (preliminaryNoticePdf) {
          const downloadUrl = await firebaseFileUploadHelper({
            blobUrl: preliminaryNoticePdf,
            fileNameWithExtension: `preliminaryNotice-${
              response.data.data.id
            }-${new Date().getTime()}.pdf`,
            storagePath: storagePathName,
          });

          preliminaryNoticeUpdateData = {
            pdf: { url: downloadUrl ?? '', name: preliminaryNoticeDetails.pdf.name ?? '' },
          };
          await thunkApi.dispatch(
            updatePreliminaryNotice({
              preliminaryNoticeId: response.data.data.id,
              preliminaryNoticeDetails: preliminaryNoticeUpdateData,
              isHideLoadingIndicator,
            })
          );
        }
      }
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
