import { Modal } from '@mui/material';
import React from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import './AppImagePreviewModal.scss';

interface SignedPdf {
  name: string;
  url: string;
  date: Date;
}

export const AppImagePreviewModal = ({
  open,
  handleClose,
  title,
  url,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  url: string;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="image-preview-modal-content">
        <div className="image-preview-title-container">
          <div className="image-preview-title-text">{title}</div>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleClose}
          />
        </div>
        <div className="flex w-[600px] items-center justify-center p-2">
          <img src={url} alt="alternateText"></img>
        </div>
      </div>
    </Modal>
  );
};
