import React, { useMemo } from 'react';
import { X } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { VerifiedJobDataPick } from './SupplierVerifyJobForm';

const CompareJobData = ({
  onClose,
  verifyJobData,
  onCompareData,
  onContinueEdit,
}: {
  onClose: () => void;
  verifyJobData: VerifiedJobDataPick;
  onCompareData: () => void;
  onContinueEdit: () => void;
}) => {
  const checkIfNotDataFound = useMemo(() => {
    const keysToCheck: Array<keyof VerifiedJobDataPick> = [
      'owner',
      'ownerAddress',
      'ownerCity',
      'ownerState',
      'ownerZipCode',
      'GC',
      'GCAddress',
      'GCCity',
      'GCState',
      'GCZipCode',
      'apn',
    ];

    return keysToCheck.every((key) => verifyJobData[key] === '');
  }, [verifyJobData]);

  return (
    <div className={`new-job-modal-content pt-3`}>
      <div className="new-job-modal-title-container">
        <h3 className="font-medium text-lg">{`Verify Job`}</h3>
        <AppButton
          type="FLAT"
          buttonStyles={{ padding: '0px', margin: '5px 2px' }}
          icon={<X color={COLORS.GREY_300} size={30} />}
          onClick={onClose}
        />
      </div>
      <div className="p-6">
        <div className="text-xs my-2 font-semibold">For the job address you entered:</div>
        <div className="my-2 font-semibold PRIMARY_500-CLR">{`${verifyJobData.jobAddress ?? ''}, ${
          verifyJobData.jobCity ?? ''
        }, ${verifyJobData.jobState ?? ''} ${verifyJobData.jobZipCode ?? ''}`}</div>
        {!checkIfNotDataFound && (
          <div className="text-xs my-2 font-semibold">Dill automatically identified:</div>
        )}
        {checkIfNotDataFound ? (
          <div className="flex flex-col h-full  w-full border rounded-lg overflow-hidden p-3 items-center">
            <div className="text-sm my-2 font-semibold text-red-500">
              No matching property found
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full border rounded-lg p-2 max-h-[calc(90vh-260px)] overflow-y-auto">
            {verifyJobData.jobAddress && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Job Address
                </div>
                <div className="text-sm my-2 font-semibold">{`${verifyJobData.jobAddress ?? ''}, ${
                  verifyJobData.jobCity ?? ''
                }, ${verifyJobData.jobState ?? ''} ${verifyJobData.jobZipCode ?? ''}`}</div>
              </div>
            )}
            {verifyJobData.apn && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Property APN
                </div>
                <div className="text-sm my-2 font-semibold">{verifyJobData.apn}</div>
              </div>
            )}
            {verifyJobData.owner && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Owner Name
                </div>
                <div className="text-sm my-2 font-semibold">{verifyJobData.owner}</div>
              </div>
            )}
            {verifyJobData.ownerAddress && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  Owner Address, City, State, Zip
                </div>
                <div className="text-sm my-2 font-semibold">{`${
                  verifyJobData.ownerAddress ?? ''
                }, ${verifyJobData.ownerCity ?? ''}, ${verifyJobData.ownerState ?? ''} ${
                  verifyJobData.ownerZipCode ?? ''
                }`}</div>
              </div>
            )}
            {verifyJobData.GC && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2">
                  GC Name
                </div>
                <div className="text-sm my-2 font-semibold">{verifyJobData.GC}</div>
              </div>
            )}
            {verifyJobData.GCAddress && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2 pr-2">
                  GC Address, City, State, Zip
                </div>
                <div className="text-sm my-2 font-semibold">{`${verifyJobData.GCAddress ?? ''}, ${
                  verifyJobData.GCCity ?? ''
                }, ${verifyJobData.GCState ?? ''} ${verifyJobData.GCZipCode ?? ''}`}</div>
              </div>
            )}
            {verifyJobData.jobType && (
              <div className="flex items-center">
                <div className="text-sm my-2 font-semibold GREY_500-CLR w-[140px] pr-2 pr-2">
                  Job Type
                </div>
                <div className="text-sm my-2 font-semibold capitalize">{verifyJobData.jobType}</div>
              </div>
            )}
          </div>
        )}
      </div>

      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
      <div className="flex flex-row self-end px-6 gap-3 mb-2">
        <AppButton text={'Close'} type="TERTIARY" onClick={onClose} />
        <AppButton
          text={checkIfNotDataFound ? 'Continue Edit' : 'Compare Data'}
          type="PRIMARY"
          onClick={() => {
            if (checkIfNotDataFound) {
              onContinueEdit();
              return;
            }
            onCompareData();
          }}
        />
      </div>
    </div>
  );
};

export default CompareJobData;
