import { useEffect, useMemo, useState } from 'react';
import { Envelope, Phone, MapPinLine, CurrencyCircleDollar, Clock } from 'phosphor-react';
import { COLORS } from '../../../../utils/colors';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { Buyer, DocumentsSummary, formatMoney } from '@dill/dill-shared';
import { getUserCustomer } from '../../../../redux/services/suppliersService';
import { openDillToast } from '../../../../utils/helpers';
import CustomerInvoicesTable from '../CustomerInvoicesTable/CustomerInvoicesTable';
import { updateSupplierBuyer } from '../../../../redux/services/supplierBuyersService';

export const CustomerDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [currentBuyerId, setCurrentBuyerId] = useState<string>('');
  const [updatingLienStatus, setUpdatingLienStatus] = useState('');
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [documentsSummary, setDocumentsSummary] = useState<DocumentsSummary | null>(null);
  const [customerDetails, setCustomerDetails] = useState<{
    buyer: Buyer;
    usersEmailsDetails: {
      email: string;
      isInvitedUser: boolean;
      isStatementsEmailOptOut: boolean;
      isPaymentRemindersEmailOptOut: boolean;
    }[];
    documentsSummary: DocumentsSummary;
  } | null>(null);

  const fetchBuyer = async (buyerId: string) => {
    const result = await dispatch(getUserCustomer({ buyerId }));
    if (result.type === 'supplier/getUserCustomer/fulfilled') {
      const payload = result.payload as any;
      if (payload.data) {
        setCustomerDetails(payload.data);
      }
      if (payload?.data?.buyer) {
        const buyer: Buyer = payload.data.buyer;
        setSelectedBuyer(buyer);
      }
      if (payload?.data?.documentsSummary) {
        const buyerSummary: DocumentsSummary = payload.data.documentsSummary;
        setDocumentsSummary(buyerSummary);
      }
    } else {
      openDillToast({ message: 'Failed to fetch buyer.', type: 'ERROR' });
    }
  };

  useEffect(() => {
    const pathlist = location.pathname.split('/');
    const buyerId = pathlist[pathlist.length - 1];
    setCurrentBuyerId(buyerId);
    fetchBuyer(buyerId);
  }, [location]);

  const handleUpdateBuyerLienNotification = async (val: boolean, buyerId: string) => {
    setUpdatingLienStatus(buyerId);
    const resp = await dispatch(
      updateSupplierBuyer({
        supplierBuyerId: buyerId,
        supplierBuyerDetails: { isPrelienNotificationsDisabled: val },
      })
    );
    if (resp.type === 'supplierBuyers/updateSupplierBuyer/fulfilled') {
      await fetchBuyer(buyerId);
      openDillToast({
        message: `Lien-related notifications to your team about this customer is turned ${
          val ? 'off' : 'on'
        }`,
        type: 'SUCCESS',
      });
      setUpdatingLienStatus('');
    } else {
      setUpdatingLienStatus('');
      openDillToast({
        message: 'Failed to update customer for lien-related notifications',
        type: 'ERROR',
      });
    }
  };

  const buyerInitial = useMemo(() => {
    if (selectedBuyer) {
      const selectedBuyerName = selectedBuyer?.name ?? '';
      const selectedBuyerNameArray = selectedBuyerName.split(' ');
      if (selectedBuyerNameArray.length > 1) {
        const letter1 = selectedBuyerNameArray[0].substring(0, 1);
        const letter2 = selectedBuyerNameArray[1].substring(0, 1);
        return `${letter1}${letter2}`.toUpperCase();
      } else {
        const letter1 = selectedBuyerNameArray[0].substring(0, 1);
        return `${letter1}`.toUpperCase();
      }
    }
    return '';
  }, [selectedBuyer]);

  const buyerAddress = useMemo(() => {
    if (selectedBuyer) {
      const selectedAddress = selectedBuyer?.address ? `${selectedBuyer?.address}, ` : '';
      const selectedCity = selectedBuyer?.city ? `${selectedBuyer?.city}, ` : '';
      const selectedState = selectedBuyer?.state ? `${selectedBuyer?.state} ` : '';
      const selectedZipCode = selectedBuyer?.zipCode ?? '';
      return `${selectedAddress}${selectedCity}${selectedState}${selectedZipCode}`;
    }
    return '';
  }, [selectedBuyer]);

  const summaryDetails = useMemo(() => {
    if (documentsSummary) {
      return [
        {
          key: 'summary_1',
          icon: (
            <CurrencyCircleDollar
              color={COLORS.SUCCESS_500}
              size={30}
              className="mr-2 p-1 rounded-full SUCCESS_100-BG"
            />
          ),
          value: formatMoney(documentsSummary?.totalAmountDueInvoices ?? '', 2),
          line1: 'AMOUNT',
          line2: 'DUE',
        },
        {
          key: 'summary_',
          icon: (
            <Clock
              color={COLORS.PRIMARY_500}
              size={24}
              className="mr-2 p-1 rounded-full PRIMARY_100-BG"
            />
          ),
          value: formatMoney(documentsSummary?.totalCurrentDueInvoices ?? '', 2),
          line1: 'CURRENT',
          line2: 'DUE',
        },
        {
          key: 'summary_3',
          icon: (
            <div className="mr-2 text-[#0456A2] bg-[#E8FFFF] p-1 rounded-full font-semibold text-xs">
              30
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDue1To30Invoices ?? '', 2),
          line1: '1 - 30 DAYS',
          line2: 'PAST DUE',
        },
        {
          key: 'summary_4',
          icon: (
            <div className="mr-2 WARNING_100-BG WARNING_500-CLR p-1 rounded-full font-semibold text-xs">
              60
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDue31To60Invoices ?? '', 2),
          line1: '31 - 60 DAYS',
          line2: 'PAST DUE',
        },
        {
          key: 'summary_5',
          icon: (
            <div className="mr-2 text-[#8C04A2] bg-[#FAEFFF] p-1 rounded-full font-semibold text-xs">
              90
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDue61To90Invoices ?? '', 2),
          line1: '61 - 90 DAYS',
          line2: 'PAST DUE',
        },
        {
          key: 'summary_6',
          icon: (
            <div className="mr-2 text-[#A20404] bg-[#FFE8E8] py-1 px-1 rounded-full font-semibold text-xs">
              {`>90`}
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDueOver90Invoices ?? '', 2),
          line1: 'OVER 90 DAYS',
          line2: 'PAST DUE',
        },
      ];
    }
    return [];
  }, [documentsSummary]);

  return (
    <div className="w-full h-full p-4">
      <div className="w-full rounded-lg WHITE-BG px-4 py-2">
        <div className="flex items-center">
          {buyerInitial && (
            <div className="flex PRIMARY_100-BG PRIMARY_500-CLR h-[60px] w-[60px] text-base rounded-full items-center justify-center font-medium mr-2">
              {buyerInitial}
            </div>
          )}
          <div className="w-1/2">
            <div className="flex items-center mb-1">
              <div className="text-[20px] font-bold ">{selectedBuyer?.name ?? ''}</div>
            </div>

            <div className="flex ">
              {selectedBuyer?.phoneNumbers && selectedBuyer?.phoneNumbers.length > 0 && (
                <div className="flex items-center GREY_500-CLR font-semibold text-sm">
                  <Phone size={20} className="mr-1" />
                  <div className="text-xs">{selectedBuyer?.phoneNumbers?.join(', ') ?? ''}</div>
                </div>
              )}
              {buyerAddress && (
                <div className="flex items-center GREY_500-CLR font-semibold text-sm">
                  <div className="PRIMARY_200-BG mr-2 rounded-full ml-2 h-[5px] w-[5px]" />
                  <MapPinLine size={20} className="mr-1" />
                  <div className="text-xs">{buyerAddress}</div>
                </div>
              )}
            </div>
            {customerDetails?.usersEmailsDetails &&
              customerDetails?.usersEmailsDetails.length > 0 && (
                <div className="flex PRIMARY_100-BG PRIMARY_500-CLR items-center p-2  rounded-full h-[24px]  text-xs mt-1">
                  <Envelope color={COLORS.PRIMARY_500} size={20} className="mr-1" />
                  <div className="text-2xs">
                    {customerDetails?.usersEmailsDetails.map((item) => item.email)?.join(', ') ??
                      ''}
                  </div>
                </div>
              )}
          </div>
          <div className="flex flex-col ml-4">
            <div className="flex  items-center">
              <div className="w-10">
                {updatingLienStatus === selectedBuyer?.id ? (
                  <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                ) : (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdateBuyerLienNotification(
                        selectedBuyer?.isPrelienNotificationsDisabled ? false : true,
                        selectedBuyer?.id ?? ''
                      );
                    }}
                    className={`w-[36px] h-[20px] rounded-full p-[1px] grid flex-col cursor-pointer ${
                      selectedBuyer && !selectedBuyer?.isPrelienNotificationsDisabled
                        ? 'justify-items-end bg-[#3862fb]'
                        : 'GREY_500-BG'
                    }`}>
                    <div className="bg-white w-[18px] h-[18px] rounded-full" />
                  </div>
                )}
              </div>
              <div className="text-xs ml-2">
                Lien-related notifications to your team about this customer is enabled
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full overflow-x-auto">
          {summaryDetails &&
            summaryDetails.length > 0 &&
            summaryDetails.map((summary) => {
              return (
                <div
                  key={summary.key}
                  className="GREY_200-BORDER border min-w-[160px] p-2 rounded-lg mr-4">
                  <div className="flex items-center mb-2">
                    {summary.icon}
                    <div className="font-bold text-[16px] ">{summary.value}</div>
                  </div>
                  <div className="text-xs GREY_500-CLR font-medium tracking-wider">
                    {summary.line1}
                  </div>
                  <div className="text-2xs GREY_500-CLR font-medium tracking-wider">
                    {summary.line2}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex w-full rounded-lg WHITE-BG mt-3 h-[calc(95vh-260px)] overflow-y-hidden">
        <CustomerInvoicesTable selectedBuyer={selectedBuyer} />
      </div>
    </div>
  );
};
